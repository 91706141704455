.smart-agent-wrapper {
  padding: 2rem;
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .controls-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .labels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .sub-header {
        font-size: 13px;
        color: #809fb8;
        line-height: 40px;
      }

      .header {
        font-family: $font-site;
        font-size: 20px;
        color: #06152b;
        line-height: 40px;

        .input-error {
          color: red;
          font-size: 13px;
          display: block;
          padding: 5px;
        }
      }
    }

    .controls {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      > * {
        box-sizing: border-box;
        height: 41px;
      }

      .when-wrapper {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        //max-width: 7rem;

        .hour-select-wrapper {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #c1c1c1;
          border-radius: 8px;
          opacity: 1;
          height: 20px;
          min-width: 75px;
          cursor: pointer;
        }

        .time-select-input {
          border: 1px solid #c1c1c1;
          border-radius: 0.5rem;
          max-width: 5rem;

          .time-placeholder {
            padding-left: 1rem;
          }

          .arrow-down {
            padding-right: 10px;
            font-size: 30px;
            height: 30px;
            width: 30px;
          }
        }

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
          padding: 0;
        }

        .when-label {
          color: black;
          font-size: 13px;
          opacity: 0.5;
        }
      }

      .checkbox-wrapper {
        display: flex;
        align-items: center;
        gap: 0.4em;

        > input[type='checkbox'] {
          @include checkbox(18px);
        }

        > label {
          color: black;
          font-size: 13px;
          opacity: 0.5;
        }
      }

      .accent-btn {
        background-color: #0a287c;
        border-radius: 8px;
        color: white;
        font-size: 14px;
      }

      .create-btn {
        font-family: $font-site;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 1rem;
        height: 41px;
        box-sizing: border-box;
        cursor: pointer;

        > img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .search-row {
    display: flex;
    justify-content: flex-start;

    .smart-agent-search {
      height: 41px;
      box-sizing: border-box;
      width: 35%;
    }

    > input[type='text'] {
      padding-left: 1.5rem;
      font-size: 0.8125rem;
    }

    ::placeholder {
      opacity: 0.5;
      font-size: 0.8125rem;
    }

    .search-border {
      border: 1px solid #c1c1c1;
      border-radius: 8px;
      height: 42px;
      box-sizing: border-box;
    }
  }

  .table-container {
    width: 100%;
    //max-height: 50%;
  }
}

app-smart-agent {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    height: 30px;
    display: flex;
    border-width: 0;
    align-items: center;
  }

  mat-form-field {
    width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: black;
    padding-left: 16px;
  }
}

.hours-popover {
  display: flex;
  flex-direction: column;
  background: white;
  overflow: scroll;
  max-height: 300px;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  opacity: 1;
  width: 100%;

  .hour-option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    gap: 10px;
  }
}

.small-text {
  font-size: 14px;
}

// rtl
html[dir='rtl'] {
  .search-row {
    > input[type='text'] {
      padding-right: 1.5rem;
      font-size: 0.8125rem;
    }
  }
}
// mobile
@media (max-width: 776px) {
  .smart-agent-wrapper .search-row .smart-agent-search {
    width: 100%;
  }
  .smart-agent-wrapper {
    padding: 0.5rem;
    .controls-row {
      flex-direction: column;
    }
  }
}
