.general-settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 150px 40px 20px;
  gap: 30px;
  overflow: scroll;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .title {
      font-size: 18px;
      color: #171725;
    }

    .subtitle {
      font-size: 18px;
      color: #0a287c;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #d7d7d7;
    visibility: visible;
  }

  &__password-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #0a287c;
    }

    .reset-password-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: 50%;

      .input-box {
        padding: 10px 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        border: 1px solid #c1c1c1;
        flex: 1;

        .text {
          font-size: 14px;
          color: #c1c1c1;
        }
      }

      .link {
        cursor: pointer;
        font-size: 12px;
        text-decoration: underline;
        color: #8a8c98;
      }
    }
  }

  &__users-and-permissions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .disabled {
      pointer-events: none;
      cursor: none;
      opacity: 0.6;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 14px;
        font-weight: bold;
        color: #0a287c;
      }

      .add-user-button {
        width: 100px;
        height: 40px;
        padding: 0 15px;
        cursor: pointer;
        background: #0a287c 0% 0% no-repeat padding-box;
        border-radius: 20px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font: normal normal normal 14px Open Sans;
        letter-spacing: 0;
        font-family: 'OpenSans', sans-serif;

        .text {
          font-size: 12px;
          width: 100%;
        }

        .icon {
          height: 18px;
          width: 18px;
          font-size: 18px;
          line-height: 18px;
          font-weight: bold;
        }

        &__disabled {
          background: #dfdfdf;
          border: 1px solid #dfdfdf;
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .users-table {
      border-collapse: collapse;
      border-spacing: 0;

      &__header {
        .header-row {
          .header-cell {
            cursor: default;
            padding: 10px;
            text-align: start;
            font-size: 12px;
            color: #8a8c98;
          }
        }
      }

      &__body {
        .table-row {
          background-color: #f0f3fa;

          .table-cell {
            cursor: default;
            padding: 10px;
            text-align: start;
            font-size: 12px;
            color: #212128;

            .user-role {
              cursor: pointer;
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;

              .text {
              }

              .icon {
              }
            }

            .disabled {
              pointer-events: none;
              cursor: default;
              opacity: 0.6;
            }
          }
        }

        .table-row-bottom {
          height: 2px;
          border-top: 1px solid #c1c1c1;
        }
      }
    }
  }
}

.general-settings-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .save-button {
    width: 100px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    background: #0a287c 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}

// user roles options popover
.user-roles-popover {
  width: 100%;
  background-color: white;
  border: 1px solid #f0f3fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;

  .user-roles-option {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;

    .text {
      color: black;
      font-size: 12px;
    }
  }

  .user-roles-option:hover {
    background-color: #c1c1c1;
  }
}

// rtl
html[dir='rtl'] {
  .general-settings {
    padding: 40px 20px 40px 150px;
  }

  .general-settings-wrapper {
    .save-button {
      right: unset;
      left: 10px;
    }
  }
}
