.favourites-main-wrapper {
  .favourites-main {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .favourites-main-header {
      text-align: left;
      font: normal normal medium 20px/36px 'OpenSans';
      font-family: 'OpenSans', sans-serif;
      letter-spacing: 0.08px;
      color: #171725;
      min-height: 20px;
    }

    .favourites-search-sort {
      display: flex;
      justify-content: space-between;
      width: 95%;
      align-items: center;
      min-height: 150px;

      .search {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid #d9e1e7;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 8px;
        min-width: 343px;
        min-height: 28px;
        color: #99b2c6;
        height: 28px;

        .search-input {
          cursor: pointer;
          text-align: initial;
          letter-spacing: 0px;
          color: #809fb8;
        }
      }

      .select-manu {
        font-size: 14px;
        letter-spacing: 0.08px;
        color: #92929d;
        display: flex;
        align-items: center;

        .mat-select {
          display: inline-block;
          width: fit-content;
          outline: none;
        }

        .mat-select-value-text {
          margin-right: 0.5vh;
          font-weight: 600;
          padding-left: 0.5vh;
          color: #44444f;
        }
      }

      .sort-section {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .sort-icon {
          height: 24px;
          width: fit-content;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            display: flex;
            margin: 0;
          }
        }

        .sort-selected-text {
          font-size: 13px;
          cursor: pointer;
          color: black;
          padding: 5px;
        }

        .icon-wrapper {
          height: 24px;
          width: 24px;
          border: 2px solid #d9e1e7;
          border-radius: 10px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .sort-popover {
        display: flex;
        flex-direction: column;
        background: white;
        box-shadow: 0 5px 13px #16347729;
        border: 2px solid #d9e1e7;
        border-radius: 10px;

        .popover-text {
          font-size: 12px;
          cursor: pointer;
          padding: 10px;
          color: black;
          display: inline-block;
          flex-direction: row;
          justify-content: flex-start;
        }
      }
    }

    .favourites-table {
      width: 96%;
      position: relative;
      min-height: 200px;
      max-height: 100%;

      .table-container {
        width: 100%;
        box-shadow: none;
        min-width: 800px;

        .white {
          background-color: white !important;
        }

        .mat-row .mat-cell {
          border-bottom: 1px solid transparent;
          border-top: 1px solid transparent;
          //width: 35%;
          text-align: initial;
          font: normal normal medium 12px/36px Roboto;
          letter-spacing: 0.05px;
          color: #000000;
        }

        th.mat-column-deadline,
        th.mat-column-status {
          text-align: initial;
          align-content: center;
          min-width: 90px;
        }

        .mat-column-Preview {
          cursor: pointer;
        }

        th.mat-header-cell:first-of-type,
        td.mat-cell:first-of-type,
        td.mat-footer-cell:first-of-type {
          padding-left: 8px;
          width: 15%;
          text-align: initial;
          font: normal normal medium 12px/14px Roboto;
          letter-spacing: 0px;
          color: #809fb8;
        }

        td.mat-cell.mat-column-Preview {
          color: #809fb8;
        }

        td.mat-cell:last-of-type,
        th.mat-header-cell:last-of-type,
        td.mat-footer-cell:last-of-type {
          width: 20px;
          cursor: pointer;
          text-align: center;
          text-decoration: underline;
          font: normal normal medium 12px/36px Roboto;
          font-family: 'Roboto', sans-serif;
          letter-spacing: 0.05px;
          color: #809fb8;
        }

        .action-icon {
          cursor: pointer;
          margin-right: 0.5rem;
          width: 1rem;
          height: 1rem;
        }

        .mat-row:nth-child(odd) {
          background-color: #f0f3fa;
        }

        th.mat-header-cell:not(.mat-column-action, .mat-column-moreOptions) {
          font-size: 14px;
          font-family: OpenSans, sans-serif;
          color: #8a8c98;
          font-weight: 100;
          text-align: initial;
        }
      }
    }
  }
}

// rtl
html[dir='rtl'] {
  .favourites-main-wrapper {
    .favourites-main {
      .favourites-table {
        .table-container {
          th.mat-header-cell:first-of-type,
          td.mat-cell:first-of-type,
          td.mat-footer-cell:first-of-type {
            padding-left: unset;
            padding-right: 8px;
          }

          .action-icon {
            margin-right: unset;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
// mobile
@media (max-width: 776px) {
  .favourites-main-wrapper {
    .favourites-main {
      .favourites-search-sort {
        min-height: unset;
        flex-direction: column;
        width: unset;
      }
    }
  }
}
