.mat-grid-tile-content {
  align-items: start !important;
}

.outlined-btn {
  display: flex;
  gap: 10px;
  height: 35px;
  cursor: pointer;
  color: #0a287c;
  font-size: $font-small;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  background-color: white;
  justify-content: center;
  border: 1px solid #0a287c;
  //width: clamp(150px, 50%, 160px);
  font-family: $font-site;
  margin-bottom: 15px;
  padding: 0px 15px;

  > img {
    height: 18px;
    width: 18px;
  }
}

.add-work-plan-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fecb2e;
  border-radius: 10px;
  cursor: pointer;
  color: #0a287c;
  gap: $gap-small;
  font-size: $font-medium;
  font-weight: bold;
  padding: 5px 10px 5px 10px;

  > img {
    height: 22px;
    width: 22px;
  }
}

.overview-page {
  display: flex;
  flex-direction: column;
  position: relative;
  // height: calc(100vh - 96px);
  overflow-x: hidden;
  justify-content: space-between;

  .funds-list-item {
    padding: 10px 10px 0px 10px;
  }

  .funds-list-item {
    flex-direction: unset;

    &__content {
      padding: 27px 5px;
      max-width: 65%;
    }
  }

  .mat-grid-tile-content {
    display: block;
  }

  .mat-tab-body-wrapper {
    height: 673px;
  }

  &__header {
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20%;

    .information {
      display: flex;
      flex-direction: column;
      gap: $gap-medium;

      .details {
        display: flex;
        flex-direction: row;

        .text {
          color: #809fb8;
          font-size: $font-medium;
        }
      }

      .welcome {
        display: flex;
        flex-direction: column;
        /*gap: 5px;*/

        .title {
          color: #06152b;
          font-size: $font-large;
          font-weight: 800;
        }

        .text {
          color: #000000;
          font-size: $font-medium;
        }
      }

      .talk-to-expert-button {
        cursor: pointer;
        min-width: 170px;
        padding: 5px 15px 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: left;
        letter-spacing: 0;
        background: #0a287c;
        color: white;
        border-radius: 100px;
        gap: $gap-medium;

        .text {
          font-size: $font-medium;
          font-weight: 500;
        }

        .icon {
          display: block;
          height: 24px;
          width: auto;
        }

        .icon-hover {
          height: 24px;
          width: auto;
          display: none;
        }

        &:hover {
          background-color: #fecb2e;
          color: #0a287c;

          .icon {
            display: none;
          }

          .icon-hover {
            display: block;
          }
        }
      }
    }

    .cards {
      // min-width: 100px;
      display: flex;
      flex-direction: row;
      height: 5.6rem;
      justify-content: space-between;
      width: 100%;

      .overview-card-work-plan {
        cursor: pointer;
        padding: 5px 60px 5px 10px;
        margin-right: $gap-large;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background-color: #f0f3f9;
        //border: 1px solid #dfe1e6;
        border-radius: 10px;
        //gap: 10px;
        border: solid 2px #e9ebf1;
        width: unset;

        &:hover {
          background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
          /*.icon {
            display: none;
            width: 85px;
            position: absolute;
            top: 0px;
            bottom: 0;
            left: 135px;
            right: 0px;
            margin: auto;
          }

          .icon-active {
            display: unset;
            width: 85px;
            position: absolute;
            top: 0px;
            bottom: 0;
            left: 135px;
            right: 0px;
            margin: auto;
          }*/
        }

        &__title {
          font-size: $font-medium;
          color: #8da9c0;
        }

        &__content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0px;

          .value {
            font-size: 2.2rem;
            font-weight: 300;
            color: #000000;
          }

          .customize-button {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 10px;
            background-color: #d7e1eb;
            color: #141516;
            font-size: $font-small;
            font-weight: bold;
          }
        }

        &__icon {
          width: 57px;
          position: absolute;
          top: 0px;
          bottom: 0;
          left: 140px;
          right: 0px;
          margin: auto;
          opacity: 1;
          transform: scaleX(-1);
        }
        /*.icon-active {
          display: none;
          width: 85px;
          position: absolute;
          top: 0px;
          bottom: 0;
          left: 135px;
          right: 0px;
          margin: auto;
        }*/
      }

      .overview-card-search {
        cursor: pointer;
        padding: 5px 60px 5px 10px;
        margin-right: $gap-large;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background-color: #f0f3f9;
        //border: 1px solid #dfe1e6;
        border-radius: 10px;
        border: solid 2px #e9ebf1;
        //gap: 10px;
        width: unset;

        &:hover {
          background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
          /*.icon {
            display: none;
            width: 85px;
            position: absolute;
            top: 0px;
            bottom: 0;
            left: 135px;
            right: 0px;
            margin: auto;
          }

          .icon-active {
            display: unset;
            width: 85px;
            position: absolute;
            top: 0px;
            bottom: 0;
            left: 135px;
            right: 0px;
            margin: auto;
          }

          .customize-button {
            visibility: visible;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 10px;
            background-color: #d7e1eb;
            color: #141516;
            font-size: $font-small;
            font-weight: bold;
          }*/
        }

        &__title {
          font-size: $font-medium;
          color: #8da9c0;
        }

        &__content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .value {
            font-size: 2.2rem;
            font-weight: 300;
            color: #000000;
          }

          .customize-button {
            visibility: hidden;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 10px;
            background-color: #d7e1eb;
            color: #141516;
            font-size: $font-small;
            font-weight: bold;
          }
        }

        &__icon {
          width: 60px;
          position: absolute;
          top: 4px;
          bottom: 0;
          left: 176px;
          right: 0px;
          margin: auto;
          opacity: 1;
          transform: scaleX(-1);
        }
        /*.icon-active {
          display: none;
          width: 85px;
          position: absolute;
          top: 0px;
          bottom: 0;
          left: 135px;
          right: 0px;
          margin: auto;
        }*/
      }

      .overview-card-funds {
        cursor: pointer;
        padding: 5px 60px 5px 10px;
        margin-right: $gap-large;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background-color: #f0f3f9;
        //border: 1px solid #dfe1e6;
        border-radius: 10px;
        //gap: 10px;
        font-family: $font-site;
        border: solid 2px #e9ebf1;
        width: unset;

        &:hover {
          background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
        }

        &__title {
          font-size: $font-medium;
          color: #8da9c0;
        }

        &__content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .value {
            font-size: 2.2rem;
            font-weight: 300;
            color: #000000;
          }

          .customize-button {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 10px;
            background-color: #d7e1eb;
            color: #141516;
            font-size: $font-small;
            font-weight: bold;
          }
        }

        &__icon {
          width: 52px;
          position: absolute;
          top: 0px;
          bottom: 4px;
          left: 119px;
          right: 0px;
          margin: auto;
          opacity: 1;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    //padding: 20px;
    height: 100%;

    .calendar {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-radius: 10px;
      border: 1px solid rgba(217, 225, 231, 0.8);
      align-items: center;
      height: 100%;

      &__timeline {
        width: 100%;
        //width: 60%;
        height: 100%;
        //display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 10px;

        &__header {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 30px;

          .title {
            font-size: $font-large;
            font-weight: 800;
            color: #06152b;
          }

          .more-options {
            font-size: 18px;
            line-height: 18px;
            color: #809fb8;
            height: 30px;
          }
        }
      }

      .accent-btn {
        background-color: #0a287c;
        border-radius: 8px;
        color: white;
        font-size: 14px;
      }

      .btn-title-section {
        display: flex;
        align-items: center;
      }

      .new-event-btn {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 1rem;
        height: 41px;
        box-sizing: border-box;
        cursor: pointer;

        > img {
          width: 18px;
          height: 18px;
        }
      }

      .divider {
        height: 100%;
        width: 1px;
        background-color: #d9e1e7cc;
      }

      &__events {
        // width: 40%;
        height: 100%;
        //padding: 10px;
        //display: flex;
        flex-direction: column;
        justify-content: center;

        &__header {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 30px;

          .title {
            font-size: $font-medium;
            color: #06152b;
            font-weight: 800;
          }

          .sub-title {
            font-size: 12px;
            color: #06152b;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;
            border-radius: 20px;
            padding: 5px;
            background: #f1f1f1;
            padding-inline: 12px;

            .logo {
              width: 20px;
              height: 20px;
              background: transparent;
            }
          }

          .more-options {
            font-size: 18px;
            line-height: 18px;
            color: #809fb8;
            height: 30px;
          }
        }

        &__content {
          //padding: 10px;
          overflow: auto;
          height: 90%;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: #e4e4e4;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background: #0a287c 0 0 no-repeat padding-box;
            border-radius: 3px;
          }

          .calendar-events-list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            height: 100%;

            .calendar-event-list-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              .tag {
                cursor: pointer;
                //width: 50px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 5px 10px;
                border-radius: 15px;

                .text {
                  font-size: $font-small;
                }
              }

              .event-title {
                cursor: pointer;
                font-size: $font-medium;
                font: normal normal medium 12px/52px Roboto;

                // cut off long text
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .date-tag {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                &__text {
                  font-size: $font-small;
                  color: #06152b;
                }

                &__icon {
                  font-size: $font-small;
                  line-height: $font-small;
                }
              }

              .more-options {
                color: #99b2c6;
                font-size: 16px;
                line-height: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

  &__footer {
    z-index: 1;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 -7px 26px #0000000b;
    background: white linear-gradient(91deg, #ffffff14 0, #0a287c5a 100%) 0 0 no-repeat padding-box;
    //gap: 100px;
    width: 100%;

    .details {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .icon {
        height: 24px;
        width: 24px;
      }

      .date-text {
        font-size: 12px;
        color: #171725;
      }

      .title {
        font-size: 12px;
        font-weight: bold;
        color: #171725;
        min-width: 170px;
      }
    }

    .carousel {
      display: flex;
      flex-direction: row;

      .slick-prev {
        display: none !important;
      }

      .carousel-item {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8a8c98;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto 5px;
        border-radius: 12px;
        background: #ffffff 0 0 no-repeat padding-box;
        gap: 5px;

        .title {
          font-size: 12px;
          font-weight: bold;
        }

        .text {
          font-size: 12px;
        }
      }
    }
  }
}

// tablet
@media (max-width: 1300px) {
  .overview-page {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      height: 50%;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .overview-page {
  }
}

// rtl
html[dir='rtl'] {
  .overview-page {
    &__header {
      .cards {
        .overview-card-work-plan {
          //cursor: pointer;
          padding: 5px 10px 5px 60px;
          margin-right: 0;
          margin-left: 1rem;
          border: solid 2px #e9ebf1;

          &:hover {
          }

          &__icon {
            left: 0px;
            right: 137px;
            transform: scaleX(1);
          }
        }
      }

      .overview-card-search {
        //cursor: pointer;
        padding: 5px 10px 5px 60px;
        margin-right: 0;
        margin-left: 1rem;
        border: solid 2px #e9ebf1;

        &:hover {
        }

        &__icon {
          left: 0px;
          right: 142px;
          transform: scaleX(1);
        }
      }

      .overview-card-funds {
        //cursor: pointer;
        padding: 5px 10px 5px 60px;
        margin-right: 0;
        margin-left: 0rem;
        border: solid 2px #e9ebf1;

        &:hover {
        }

        .customize-button {
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 10px;
          background-color: #d7e1eb;
          color: #141516;
          font-size: $font-small;
          font-weight: bold;
        }

        &__icon {
          left: 0px;
          right: 155px;
        }
      }
    }
  }
}
// mobile
@media (max-width: 776px) {
  .overview-page {
    .mat-grid-tile-content {
      padding-right: 5%;
      padding-left: 5%;
    }
    &__content {
      height: 100%;
      .calendar {
        flex-direction: column;
        justify-content: unset;
        &__timeline {
          width: 100%;
        }
        &__events {
          width: 100%;
        }
      }
    }
    &__header {
      display: block;
      height: 100%;
    }
    .overview-page__header .cards {
      flex-direction: column;
      height: 100%;

      .overview-card-work-plan {
        padding: 5px 40px 5px 40px !important;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: unset !important;
        margin-left: unset !important;
      }

      .overview-card-search,
      .overview-card-funds {
        margin-bottom: 20px;
        margin-right: unset;
        margin-left: 0;
        padding: 5px 40px 5px 40px !important;
      }
    }
  }
}

.fund-table-cell {
  padding: 15px 8px;
}

.funds-table {
  width: 100%;
}

.even-row {
  background-color: #f4f3fd95;
}

.odd-row {
}

.fund-name {
  font-size: 14px;
  color: #171725;
  font-weight: 700;
  font-family: $font-site;
}

.fund-summary {
  font-size: 14px;
  color: #171725;
  font-weight: 400;
  font-family: $font-site;
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;

  .fund-type {
    cursor: default;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 10px;
  }

  .separator {
    font-size: 14px;
    color: #8a8c98;
  }

  .organization-number {
    font-size: 14px;
    color: #8a8c98;
  }

  .score {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    border-radius: 10px;
    color: white;
    justify-content: center;
  }

  .icon {
    height: 14px;
    width: 14px;
    font-size: 14px;
  }
}

.mat-tab-label-content {
  font-size: 16px;
  font-weight: 700;
  color: #0a287c;
}
