.billing-and-plans {
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 150px 40px 40px;
  gap: 30px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .title {
      font-size: 18px;
      color: #171725;
    }

    .subtitle {
      font-size: 18px;
      color: #0a287c;
    }
  }

  .current-plan {
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #0a287c;
    }

    .card {
      height: 200px;
      background: #f6f6f7 0% 0% no-repeat padding-box;
      border-radius: 20px;
      opacity: 0.75;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 80%;

      .card-row {
        display: flex;
        justify-content: space-between;
        padding: 0 50px;
        align-items: center;
        width: 80%;

        .left-content {
          display: flex;
          align-items: center;
          width: 40%;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .right-content {
          display: flex;
          align-items: center;
          gap: 15px;
        }

        .upgrade-text {
          color: #0a287c;
          text-decoration: underline;
          font-size: 14px;
          cursor: pointer;
        }

        .text {
          font-size: 14px;
        }

        .grey {
          color: #9a9a9a;
        }

        .left-amount {
          display: flex;
          align-items: center;
          gap: 4px;
          padding-right: 10px;
        }
      }

      .curr-plan {
        color: #0f57f5;
        background-color: rgba(15, 87, 245, 0.29);
      }

      .green {
        color: #22d598;
        background-color: rgba(34, 213, 152, 0.15);
      }

      .red {
        color: #f19494;
        background-color: rgba(251, 226, 226, 255);
      }

      .container {
        width: 60px;
        font-size: 13px;
        padding: 0.2rem 1rem;
        line-height: 25px;
        border-radius: 20px;
        text-align: center;
      }
    }
  }

  .invoice-history {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 20px;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #0a287c;
    }

    .invoices-table {
      border-collapse: collapse;
      border-spacing: 0;

      &__header {
        .header-row {
          .header-cell {
            cursor: default;
            padding: 10px;
            text-align: start;
            font-size: 12px;
            color: #8a8c98;
            opacity: 1;
            width: 25%;
          }
        }
      }

      &__body {
        .table-row {
          background-color: rgb(240, 243, 250, 0.34);
          //background-color: #F0F3FA;
          //opacity: 0.34;

          .amount-column {
            display: flex;
            align-items: center;
            padding-left: 12px;

            .icon {
              transform: scale(0.6);
              color: #22d598;
            }
          }

          .link-column {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link {
              display: flex;
              align-items: center;
              cursor: pointer;

              .icon {
                transform: scale(0.6);
                color: #22d598;
              }

              .link-text {
                color: #22d598;
                text-decoration: underline;
              }
            }

            .image {
              transform: scale(0.5);
            }
          }

          .table-cell {
            cursor: default;
            //padding: 10px;
            text-align: start;
            font-size: 12px;
            color: #212128;
            opacity: 1;
          }
        }

        .table-row-bottom {
          height: 2px;
          border-top: 1px solid #c1c1c1;
        }
      }
    }
  }

  .cancel-subscription {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 20px;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #0a287c;
    }

    .content {
      font-size: 13px;
      color: #000000;
      letter-spacing: 0px;
      opacity: 1;
    }

    .cancel-button {
      width: fit-content;
      height: 40px;
      padding: 0 15px;
      cursor: pointer;
      color: #8a8c98;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8em;
      border-radius: 10px;
      opacity: 0.75;
      text-decoration: underline;
      background: #f6f6f7 0% 0% no-repeat padding-box;
    }
  }
}

.billing-and-plans-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0 0 0;

  .save-button {
    width: 100px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    background: #0a287c 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
  }
}
// mobile
@media (max-width: 776px) {
  .billing-and-plans {
    padding: 5px;
    .invoice-history {
      padding-bottom: 80%;
    }
  }
}
