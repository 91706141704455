.logo-container {
  width: 96px; /* Adjust the width and height as per your requirements */
  height: 96px;
  border-radius: 50%; /* Make the container a circle */
  overflow: hidden; /* Hide any content that exceeds the container boundaries */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */
  //box-shadow: #00000020 0px 2px 25px;
}

.logo-image {
  max-width: 96px; /* Adjust the maximum width as per your requirements */
  height: auto; /* Maintain the aspect ratio of the logo image */
  object-fit: contain; /* Preserve the aspect ratio of the image */
}

.funds-list-item {
  cursor: pointer;
  padding: 10px 10px 40px 10px;
  border: 1px solid #809fb89d;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  box-shadow: 0px 1px 4.5px 1px rgba(0, 0, 0, 0.125);

  &.read {
    background-color: #00000009;
  }

  &__logo {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .logo {
      width: 64px;
      height: 64px;
    }

    .logo-placeholder {
      cursor: default;
      width: 96px;
      height: 96px;
      font-size: 14px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      //box-shadow: #00000020 0px 2px 25px;

      .text {
        font-size: 12px;
      }

      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .fund-type-icon {
          height: unset;
          width: unset;
          font-size: 42px;
        }
      }

      .logo-image {
        max-width: 38%;
        max-height: 38%;
        //opacity: 50%;
      }
    }
  }

  &__top-actions {
    top: 10px;
    right: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .score {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      width: 78px;
      border-radius: 8px;
      height: 30px;
      color: white;
      justify-content: center;

      .icon {
        font-size: 14px;
        height: 14px;
        width: 14px;
        color: white;
      }

      .text {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .more-details {
      cursor: pointer;
      font-size: 18px;
      height: 18px;
      width: 18px;
      color: #809fb8;
    }
  }

  &__bottom-actions {
    bottom: 10px;
    right: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      font: normal normal 600 12px/12px OpenSans;
      font-family: 'OpenSans', sans-serif;
      color: #ffffff;
      padding: 5px 10px;
      border-radius: 5px;
      background: #0a287c;
      border: solid #0a287c;
    }

    .add-to-favourites-button {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 8px;
      text-align: center;
      font-size: 12px;
      min-inline-size: max-content;
      color: #0a287c;
      background: #ffffff;
      border: 1px solid #0a287c;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .text {
        font-size: 14px;
        line-height: 14px;
      }

      .icon {
        height: 18px;
        width: 18px;
        font-size: 18px;
      }
    }

    .add-to-workplan-button {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 8px;
      text-align: center;
      color: white;
      background: #0a287c;
      border: solid #0a287c;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .text {
        font-size: 14px;
        line-height: 14px;
      }

      .icon {
        height: 18px;
        width: 18px;
        font-size: 18px;
      }
    }

    .share-button {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 8px;
      text-align: center;
      color: #0a287c;
      background: #fecb2e;
      border: solid #fecb2e;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .text {
        font-size: 14px;
        line-height: 14px;
      }

      .icon {
        height: 18px;
        width: 18px;
        font-size: 18px;
      }
    }
  }

  .toolbar-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__content {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .fund-name {
      font-size: 14px;
      color: #000000;
      font-weight: bold;

      &.read {
        color: #681da8;
      }
    }

    .fund-type {
      cursor: default;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 10px;
    }

    .subtitle {
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      height: 32px;
      padding-bottom: 2%;

      .summary {
        font-size: 12px;
        color: #000000;
        font-weight: bold;
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .separator {
        font-size: 10px;
        color: #7d7d7d;
      }

      .location {
        font-size: 12px;
        color: #7d7d7d;
      }
    }

    .description {
      font-size: 12px;
      color: #7d7d7d;
      font-weight: bold;
      width: 520px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .interests {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;

      .interest-item {
        cursor: default;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #0095ff14;
        border: 1px solid #0095ff;
        display: flex;

        .text {
          font-size: 10px;
          color: #0095ff;
          font-weight: 600;
          letter-spacing: 0.12ch;
        }
      }
    }
  }
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 1;
}

html[dir='rtl'] {
  .funds-list-item {
    &__top-actions {
      right: unset;
      left: 10px;
    }

    &__bottom-actions {
      right: unset;
      left: 10px;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .funds-list-item {
    flex-direction: column;
    &__content {
      .description {
        width: 100%;
      }
    }
    &__logo {
      align-items: center;
    }
    &__bottom-actions {
      .text {
        display: none;
      }
    }
  }
}
