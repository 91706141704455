.smart-agent-table {
  width: 100%;
  position: relative;
  min-height: 200px;
  height: 100%;
  overflow: auto;

  tbody {
    display: block;
    max-height: 384px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #0a287c 0 0 no-repeat padding-box;
      border-radius: 3px;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }

  .table-container {
    width: 100%;
    min-width: 800px;

    tr.mat-header-row {
      font-family: 'OpenSans', sans-serif;
      font-weight: 900;
      color: #8a8c98;
    }

    .mat-column-funds.mat-cell {
      color: #809fb8;
      font-weight: 400;
    }

    .mat-column-date.mat-cell {
      color: #212128;
      font-weight: 400;
      font-size: 12px;
    }

    .mat-column-action.mat-cell {
      text-decoration: underline;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 0.06px;
      color: #809fb8;
    }

    .status-container {
      color: #fff;
      font-size: 13px;
      padding: 0.2rem 1rem;
      line-height: 25px;
      border-radius: 16px;
      background-color: var(--color-approved);
    }

    .type-indicator {
      display: flex;
      font-size: 13px;
      max-width: 90px;
      border-radius: 16px;
      justify-content: center;
    }

    .type-color-blue {
      color: rgba(15, 87, 245, 1);
      background-color: rgba(15, 87, 245, 0.29);
    }

    .type-color-orange {
      color: rgba(254, 174, 46, 1);
      background-color: rgba(254, 174, 46, 0.29);
    }

    .deadline-container {
    }

    .actions-icons-column {
      //justify-content: center;
      display: flex;
      gap: 1rem;
    }

    .mat-icon {
      color: #809fb8;
    }

    .mat-row .mat-cell {
      border: none;
      width: 30%;
      text-align: left;
      font: normal normal medium OpenSans;
      font-family: OpenSans, sans-serif;
      font-size: 13px;
    }

    th.mat-header-cell {
      border: none;
      //box-shadow: 0px 33px 56px #00000021;
      opacity: 1;
      background: transparent;
    }

    th.mat-column-deadline,
    th.mat-column-status {
      align-content: center;
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 20px;
    }

    th.mat-header-cell.mat-column-status {
      padding-left: 10px;
    }

    td.mat-cell:last-of-type,
    th.mat-header-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      //width: 20px;
      cursor: pointer;
    }

    .mat-column-date,
    .mat-column-status,
    .mat-column-lastUpdated,
    .mat-column-type {
      min-width: 120px;
    }

    .mat-row:nth-child(odd) {
      background-color: #f6f9ff;
    }

    .more-btn {
      padding-left: 10px;
      width: 23px;
      height: 23px;
      padding-top: 9px;
    }
  }
}

// rtl
html[dir='rtl'] {
  .table-container {
    .mat-row .mat-cell {
      border: none;
      width: 30%;
      text-align: right;
      font: normal normal medium OpenSans;
      font-family: OpenSans, sans-serif;
      font-size: 13px;
    }
  }
}
