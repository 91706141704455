@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import 'src/assets/scss/utils/vars';

html {
  height: 100dvh;
  direction: ltr;
  font-family: $font-site;
}
html[dir='ltr'] {
  .mobile-navbar-toggle {
    left: unset;
    right: 0;
  }
}

html[dir='rtl'] {
  direction: rtl;
  .mobile-navbar-toggle {
    right: unset;
    left: 0;
  }
  .custom-popover-search-bar {
    direction: rtl;
  }

  .account-settings-overlay-panel-class {
    direction: rtl;
  }

  .cdk-global-overlay-wrapper {
    direction: rtl;
  }
}

body {
  height: 100%;
  // overflow: scroll;
  margin: 0;
}

//google drop down auto complete
.pac-container {
  z-index: 10000;
}

// input
input,
textarea,
button,
select {
  border: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// remove mat dialog white background
// .mat-dialog-container {
//   box-shadow: unset;
//   background: unset;
//   color: unset;
//   overflow: unset !important;
// }

// checkbox
@mixin checkbox($size: 14px) {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: $size;
  min-height: $size;
  width: $size;
  height: $size;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;

  &:checked {
    border: 1px solid #0a287c;
    border-radius: 5px;
    background-color: #0a287c;
  }

  &:before {
    content: '';
    display: block;
    transition: transform 200ms;
  }

  &:checked:before {
    width: calc(#{$size / 3.5});
    height: calc(#{$size / 1.5});
    margin: 0 calc(#{$size / 3.5});
    border-bottom: calc(#{$size / 7}) solid #ffffff;
    border-right: calc(#{$size / 7}) solid #ffffff;
    transform: rotate(45deg);
  }
}

input[type='checkbox'] {
  @include checkbox(14px);
}

// global loader
.global-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(10, 40, 124, 0.33);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

iframe {
  width: 100% !important;
  height: 100% !important;
  border: none;
}

//// scroll
::-webkit-scrollbar {
  width: 0;
}

// google geo chart
.google-chart path {
  stroke-width: 0.5; /* control the countries borders width */
  stroke: #ffffff; /* choose a color for the border */
}

.custom-popover-search-bar {
  direction: ltr;
}

.account-settings-overlay-panel-class {
  direction: ltr;
}

.cdk-global-overlay-wrapper {
  direction: ltr;
}
@media (max-width: 776px) {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.prevent-copy {
  -webkit-touch-callout: none; /* Disable iOS touch events */
  -webkit-user-select: none; /* Disable text selection */
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// mobile
@media (max-width: 776px) {
  .custom-popover-search-bar {
    overflow-y: scroll;
  }
}
