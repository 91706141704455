.report-dialog {
  width: 100%;
  background: white;
  border-radius: 5px;
  min-width: 470px;
  min-height: 420px;
  display: flex;
  flex-direction: column;

  .close-icon {
    background-color: transparent;
    align-self: end;
    cursor: pointer;
  }

  .wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .text {
      text-align: left;
      font: normal normal normal 13px/17px Helvetica Neue;
    }

    .link {
      text-decoration: underline;
      font: normal normal normal 13px/17px;
      font-family: 'OpenSans', sans-serif;
      letter-spacing: 0;
      color: #4b9dcc;
    }

    .title {
      font: normal normal bold 23px Helvetica Neue;
      padding-bottom: 10px;
      font-family: 'Helvetica Neue', sans-serif;
    }

    .sub-title {
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
      font: normal normal 600 14px/17px;
      font-family: 'OpenSans', sans-serif;
    }

    .share-section {
      display: flex;
      gap: 5px;
    }

    .coin-image {
      width: 15px;
      height: 15px;
    }
  }

  .textarea {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    min-height: 4.5rem;
    max-width: 100%;
  }

  .row-buttons {
    display: flex;
    gap: 20px;
    position: relative;
    justify-content: flex-end;

    .button {
      font-size: 14px;
      border-radius: 5px;
      text-align: center;
      padding: 5px;
      width: 110px;
      font-family: 'Poppins', sans-serif;
      cursor: pointer;
    }

    .cancel {
      color: #0a287c;
      background: white;
      border: 1px solid #0a287c;
    }

    .send {
      color: #0a287c;
      background: #fecb2e 0 0 no-repeat padding-box;
      border: 1px solid #fecb2e;
      opacity: 1;
    }
  }

  .select {
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
  }

  .input-wrapper {
    width: 70%;

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding: 0;
    }

    > label {
      letter-spacing: 0px;
      opacity: 1;
      font: normal normal normal 13px/17px 'Helvetica Neue';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }

  .relevant-select-wrapper {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 8px;
    opacity: 1;
    height: 20px;
    min-width: 75px;
    cursor: pointer;

    .arrow-down {
      padding-right: 10px;
      font-size: 30px;
      height: 30px;
      width: 30px;
    }

    .placeholder {
      color: #5f5f5f;
      font-family: OpenSans;
      font-size: 12px;
    }
  }

  .small-text {
    font-size: 14px;
  }

  .textarea-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;

    > label {
      font-size: 11px;
      letter-spacing: 0px;
      opacity: 1;
      font-family: 'Helvetica Neue', sans-serif;
    }

    > textarea {
      border-radius: 0.5rem;
      padding-left: 0.5rem;
      min-height: 3.5rem;
      max-width: 100%;
      resize: none;
      color: #5f5f5f;
      font-size: 12px;
      font-family: OpenSans;
      border: 1px solid #707070;
    }
  }
}

app-report {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    height: 30px;
    display: flex;
    border-width: 0;
    align-items: center;
  }

  mat-form-field {
    width: 100%;
    overflow: auto;
  }

  .area::-webkit-input-placeholder {
    padding: 5px;
  }

  .area::-ms-input-placeholder {
    padding: 5px;
  }
}

.relevant-popover {
  z-index: 10000;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: auto;
  max-height: 300px;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  opacity: 1;
  width: 100%;

  .relevant-option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;

    &:hover {
      background-color: #70707038;
    }
  }
}
