.calendar-main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 3px;
  width: 94%;
  height: 100%;

  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 20%;
    align-items: center;

    .time-line-group-buttons {
      display: flex;
      align-items: center;

      .icon-left {
        cursor: pointer;
        border: 1px solid #d9e1e7;
        border-radius: 8px;
        margin-inline: 5px;
        font-size: 14px;
        display: flex;
        padding: 2px;
      }

      .icon-right {
        cursor: pointer;
        border: 1px solid #d9e1e7;
        border-radius: 8px;
        margin-inline: 5px;
        font-size: 14px;
        display: flex;
        padding: 2px;
        transform: rotate(180deg);
      }

      .time-line-value {
        font-size: 14px;
        color: #06152b;
        /*font-family: 'ProximaNova';*/
        padding-inline: 10px;
      }
    }

    .date-time-selector {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 10px;

      .show-by {
        text-align: left;
        letter-spacing: 0px;
        font-size: 14px;
        color: #06152b;
        /*font-family: 'ProximaNova';*/
      }

      .sorting-date-time {
        font-size: 14px;
        color: #06152b;
        /*font-family: 'ProximaNova';*/
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #d9e1e7;
        border-radius: 10px;

        .sorting-button {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding-inline: 10px;
        }
      }
    }
  }

  .body-wrapper {
    width: 95%;
    min-height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .down {
      gap: 1%;
      display: flex;
      width: 100%;
      height: 15%;
      flex-direction: row;
      justify-content: space-between;
    }

    .down-hours {
      display: flex;
      width: 95%;
      height: 5%;
      flex-direction: row;
      justify-content: space-between;
    }

    .upper {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
    }

    .upper-hours {
      display: flex;
      width: 95%;
      height: 100%;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
    }

    .events {
      background: transparent;
      position: absolute;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      z-index: 1;
      height: 100%;
      row-gap: 5px;

      .event {
        cursor: pointer;
        display: flex;
        min-width: calc(100% + 100px);
        background: rgba(0, 144, 255, 0.168627451) 0% 0% no-repeat padding-box;
        border-radius: 16px;
        padding-left: 10px;
        max-height: 50px;

        .content-wrapper {
          display: flex;
          width: 100%;
          height: 100%;
          gap: 5px;
          align-items: center;
          justify-content: flex-start;

          .content-line {
            width: 5px;
            height: 65%;
            letter-spacing: 0px;
            background: #0090ff 0% 0% no-repeat padding-box;
            border-radius: 10px;
          }

          .content-title {
            text-align: left;
            letter-spacing: 0px;
            color: #0090ff;
            font-size: 12px;
          }
        }
      }
    }

    #grid-days {
      display: flex;
      grid-gap: 2px;
      width: 100%;
      height: 88%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 73%;
    }

    #grid-hours {
      display: flex;
      grid-gap: 2px;
      width: 100%;
      height: 88%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .head,
    .cell {
      padding-block: 4px;
    }

    .head {
      //width: 1%;
      cursor: pointer;
      text-align: left;
      /*font: normal 12px/16px ProximaNova;*/
      letter-spacing: 0.36px;
      color: #809fb8;
      font-size: $font-small;
    }

    .head-hours {
      cursor: pointer;
      text-align: left;
      /*font: normal 9px/16px ProximaNova;*/
      letter-spacing: 0.36px;
      color: #809fb8;
      width: 1%;
    }

    .cell {
      cursor: pointer;
      background: #f8ffde;
      border: 1px solid #d9e1e7cc;
    }

    .today-cell {
      cursor: pointer;
      background: #f8ffde;
      width: 0;
      border: 1px dashed #d9e1e7cc;
      height: 100%;
      bottom: 10px;
      position: relative;
    }

    .today-banner {
      position: relative;
      width: 30px;
      height: 15px !important;
      display: flex;
      align-items: center;
      bottom: 40px;
      right: 24px;
      padding: 8px;
      border-radius: 10px;
      background: #d9e1e8 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 10px rgb(0 0 0 / 36%);
      opacity: 0.4;
      justify-content: center;

      .header {
        text-align: center;
        letter-spacing: 0px;
        color: #0a287c;
        opacity: 1;
        font-size: 12px;
      }
    }
  }
}

// tablet
@media (max-width: 1199px) {
  .calendar-main-wrapper {
    width: 90%;
    height: 95%;
    padding-block: 12px;

    .header-wrapper {
      .time-line-group-buttons {
        .icon-left {
          width: 18px;
        }

        .icon-right {
          width: 18px;
        }

        .time-line-value {
          font-size: 10px;
        }
      }

      .date-time-selector {
        .show-by {
          font-size: 10px;
        }

        .sorting-date-time {
          font-size: 10px;
        }
      }
    }

    .body-wrapper {
      #grid-days {
        height: 80%;
      }
    }
  }
}

// rtl
html[dir='rtl'] {
  .calendar-main-wrapper {
    .header-wrapper {
      .time-line-group-buttons {
        .icon-left {
          transform: rotate(180deg);
        }

        .icon-right {
          transform: unset;
        }
      }
    }

    .body-wrapper {
      .events {
        .event {
          padding-left: unset;
          padding-right: 10px;
        }
      }
    }
  }
}
// mobile
@media (max-width: 776px) {
  .calendar-main-wrapper {
    .header-wrapper {
      height: 10%;
    }
    .body-wrapper {
      min-height: 50%;
    }
  }
}
