.success {
  width: 100%;
  height: 100%;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  .box-shadow {
    width: clamp(600px, 42%, 720px);
    height: clamp(535px, 55%, 700px);
    border-radius: 20px;
    background: white;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;

    .success-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .atlas-icon {
        width: 163px;
        height: 44px;
        margin-top: 2%;
      }

      .calendar-icon {
        width: 148px;
        height: 141px;
        background: white;
        margin-top: 8%;
      }

      .success-text {
        width: 45%;
        text-align: center;
        font: bold 28px arial;
        color: #0a287c;
        margin-top: 3%;
      }

      .start-button {
        cursor: pointer;
        border-radius: 8px;
        width: 40%;
        text-align: center;
        font: normal normal bold 19px/55px Heebo;
        color: #0a287c;
        background: #fecb2e 0 0 no-repeat padding-box;
        box-shadow: 0 3px 13px #c6e5ff;
        margin: 8% 0 2% 0;
      }

      .login-loading {
        margin: 48px auto;
        padding-bottom: 0px;
      }
    }
  }
}

// for rtl styles
html[dir='rtl'] {
  // desktop
  .success {
  }
}

// mobile
@media (max-width: 776px) {
  .success {
    .box-shadow {
      margin: 10px;
    }
  }
}
