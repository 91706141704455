.management-panel-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  padding-top: 3%;

  .management-panel-container {
    width: 100%;
    height: 100%;
    padding-inline: 2rem;
    box-sizing: border-box;

    .row-one {
      display: flex;
      width: 100%;
      padding-bottom: 2rem;
      box-sizing: border-box;
      padding-inline-end: 1rem;

      .row-one-column-one {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        align-items: flex-start;

        .row-one-column-one-header-one {
          text-align: left;
          font-size: 13px;
          letter-spacing: 0;
          color: #809fb8;
        }
        .row-one-column-one-header-two {
          width: auto;
          font-size: 20px;
          color: #06152b;
        }
        .row-one-column-one-header-three {
          font-size: 15px;
          color: black;
        }
        .row-one-column-one-button {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          text-align: left;
          letter-spacing: 0;
          color: #fefefe;
          background: #0a287c;
          border-radius: 8px;
          height: 45px;
          width: 70%;

          .icon {
            color: #fecb2f;
            padding-inline-end: 0.5rem;
          }
        }
      }

      .row-one-column-two {
        gap: 2.5rem;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        .overview-box {
          cursor: pointer;
          display: flex;
          height: fit-content;
          justify-content: space-between;
          align-items: center;
          width: clamp(200px, 26%, 270px);
          background-color: rgba(233, 238, 248, 0.6784313725);
          border-radius: 16px;
          border: 1px solid #70707038;
          overflow: hidden;

          .header {
            gap: 0.6rem;
            text-align: start;
            font: normal normal 600 13px/15px Heebo;
            color: #809fb8;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 4%;

            .head-line {
              //width: 104px;
              //line-height: 5;
            }

            .number {
              color: black;
              font-size: 1.75rem;
              line-height: 1;
            }

            .wrapper {
              display: flex;
              justify-content: space-between;
              width: 80%;
              flex-wrap: wrap;
            }

            .button-link {
              background-color: rgba(128, 159, 184, 0.22);
              padding: 5px 8px;
              border-radius: 1rem;
              color: black;
              line-height: 1.5;
              font-size: 12px;
            }
          }

          .hidden-box-image {
            margin-inline-end: -50px;
          }
        }
        .overview-box:hover {
          overflow: visible;
        }

        .agent-button {
          cursor: pointer;
          display: flex;
          height: 80%;
          width: 33%;
          background: #e9eef8ad 0% 0% no-repeat padding-box;
          border-radius: 16px;
          overflow: hidden;
          .header {
            font-size: 10px;
            text-align: left;
            letter-spacing: 1.3px;
            color: #809fb8;
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 4%;
            .head-line {
              width: 140px;
              line-height: 5;
              padding-right: 50px;
            }
            .wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              .button-link {
                text-align: center;
                font-size: 10px;
                letter-spacing: 0px;
                color: #000000;
                border-radius: 15px;
                width: 85px;
                display: flex;
                height: 20px;
                align-items: center;
                justify-content: center;
                background: #d7e1;
              }
              .number {
                text-align: left;
                letter-spacing: 0px;
                color: #000000;
                font-size: 20px;
              }
            }
          }
        }
        .agent-button:hover {
          overflow: visible;
        }

        .funds-button {
          cursor: pointer;
          display: flex;
          height: 80%;
          width: 28%;
          background: #e9eef8ad 0% 0% no-repeat padding-box;
          border-radius: 16px;
          overflow: hidden;
          .header {
            font-size: 10px;
            text-align: left;
            letter-spacing: 1.3px;
            color: #809fb8;
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 4%;
            .head-line {
              width: 150px;
              line-height: 5;
            }
            .number {
              text-align: left;
              letter-spacing: 0px;
              color: #000000;
              font-size: 20px;
            }
          }
        }
        .funds-button:hover {
          overflow: visible;
        }
      }
    }

    .row-two {
      display: flex;
      width: 100%;
      height: 60%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d9e1e7cc;
      border-radius: 16px;
      .row-two-column-one {
        width: 65%;
        height: 100%;
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
      }
      .divider {
        height: 100%;
        border: solid 0.5px #d9e1e7cc;
      }
      .row-two-column-two {
        width: 35%;
        height: 100%;
        background: #f7f8f9;
        border-bottom-right-radius: 16px;
        border-top-right-radius: 16px;
      }
    }
  }
}

.expert-main-wrapper {
  background: #fff;
  width: 60%;
  height: 70%;
  z-index: 500000000000;
  position: fixed;
  top: 15%;
  bottom: 0;
  left: 20%;
  right: 0;
  .expert-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: #1c1c1c 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 0.52;
    right: 0;
    z-index: 10000;
  }
}
.custom-modalbox {
  mat-dialog-container {
    padding: 0;
  }
}
.back-drop-overview {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}
