.funding-experts-schedule-dialog {
  max-width: 600px;
  padding: 30px 20px 20px 20px;
  background: white;
  display: flex;
  border-radius: 0 10px 10px 0;
  border-left: 5px solid #fecb2e;
  flex-direction: column;
  position: relative;
  gap: 10px;

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__header {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    .details {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .description {
        font-size: 12px;
      }
    }

    .avatars {
      margin: 5px 0;
      display: flex;

      .avatar {
        height: 48px;
        width: 48px;
        box-sizing: border-box;
        border: 2px solid #fecb2e;
        border-radius: 50%;
      }

      .avatar:not(:first-child) {
        margin-left: -10px;
      }
    }
  }

  &__content {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .calendly-schedule {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
      border: 1px solid #707070;

      .calendly-iframe {
        width: 100%;
        height: 300px;
        max-height: 400px;
      }

      .schedule-button {
        padding: 10px 20px;
        margin: 10px;
        cursor: pointer;
        background-color: #0a287c;
        color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

// rtl
html[dir='rtl'] {
  .funding-experts-schedule-dialog {
  }
}
