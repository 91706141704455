.funding-expert-payment-dialog {
  width: 500px;
  padding: 30px 20px 20px 20px;
  background: white;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  gap: 10px;

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .card-error {
      color: red;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .expert-payment-form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .form-row {
        .credit-card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;

          .title {
            text-align: left;
            font: normal normal normal 18px/21px OpenSans;
            font-family: OpenSans, sans-serif;
            letter-spacing: 0;
            color: #536777;
            opacity: 1;
          }

          .card-element {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #b9b9b9;
            border-radius: 7px;
            opacity: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
          }

          &__number {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 10px;
          }

          &__extras {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .date {
              display: flex;
              flex-direction: column;
              width: 120px;
              gap: 10px;
            }

            .cvc {
              display: flex;
              flex-direction: column;
              width: 100px;
              gap: 10px;
            }
          }
        }

        .form-control {
          width: 100%;
        }

        .credit-card-type-form-control {
          min-width: 200px;
        }

        .expiry-date-form-control {
          max-width: 120px;
        }

        .cvv-form-control {
          max-width: 120px;
        }
      }

      .summary-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .terms-and-conditions {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          input[type='checkbox'] {
            @include checkbox(14px);
          }

          .text {
            color: #94a5b2;
          }
        }

        .total-amount {
          display: flex;
          align-items: center;
          gap: 5px;

          .label {
            font-size: 16px;
            color: #463e65;
          }

          .amount {
            font-size: 16px;
            font-weight: bold;
            color: #0a287c;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .pay-now-button {
      cursor: pointer;
      padding: 10px;
      background-color: #fecb2e;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .icon {
        height: 16px;
      }

      .text {
        font-size: 14px;
        color: #0a287c;
      }
    }

    .disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

// input
.funding-expert-payment-dialog-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    text-align: left;
    font: 18px/21px OpenSans;
    letter-spacing: 0;
    color: #536777;
    opacity: 1;

    .error-dot {
      color: red;
    }
  }

  .input-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b9b9b9;
    border-radius: 7px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    &__input {
      color: #144167;
      font-size: 18px;
      font-family: OpenSans, sans-serif;

      &::placeholder {
        color: #8c98a2;
        font-size: 15px;
        font-family: OpenSans, sans-serif;
      }
    }
  }

  .input-error {
    color: red;
    font-size: 12px;
    line-height: 12px;
  }
}

// select component overrides
.funding-expert-payment-dialog-select {
  min-width: 200px;
  width: 100%;

  .custom-select {
    .select-label {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: start;
      font-size: 14px;
      line-height: 14px;
      color: #536777;
    }

    .select-button {
      border-radius: 10px;
      border: 1px solid #b9b9b9;

      .credit-card {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          height: 18px;
          font-size: 14px;
        }

        &__text {
          color: #144167;
          font-size: 14px;
        }
      }

      .text {
        color: #536777;
        font-size: 14px;
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.funding-expert-payment-dialog-select-panel {
  max-height: 250px;
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;

  .select-option-wrapper {
    .select-option {
      background-color: white;
      color: #144167;
      gap: 10px;

      .credit-card {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          height: 18px;
          font-size: 14px;
        }

        &__text {
          color: #144167;
          font-size: 14px;
        }
      }

      .text {
      }
    }

    .select-option.focus {
      background-color: #d9e1e7;
      color: #809fb8;
    }

    .select-option:hover {
      background-color: #d9e1e7;
      color: #809fb8;
    }

    .divider {
      width: 100%;
      height: 2px;
      background-color: #d9e1e7;
    }
  }
}

// rtl
html[dir='rtl'] {
  .funding-expert-payment-dialog {
  }
}

// mobile
@media (max-width: 776px) {
  .funding-expert-payment-dialog {
    width: auto;
    &__content .expert-payment-form .form-row {
      .credit-card {
        flex-direction: column;
        &__extras {
          .cvc {
            width: 30%;
          }
          .date {
            width: 70%;
          }
        }
      }
    }
  }
}
