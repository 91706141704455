// desktop
.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  font-family: OpenSans, sans-serif;

  &__welcome {
    height: 100%;
    max-height: 100%;
    width: 60%;
    position: relative;
    font-size: 32px;
    display: flex;
    flex-wrap: wrap;

    .theme-picture {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
      opacity: 1;
      display: flex;
    }

    &__content {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .welcome-image {
        height: 190px;
        width: 190px;
        margin-left: 50px;
        margin-top: 50px;
        position: relative;
        display: flex;
      }

      .welcome-text-headline {
        text-align: left;
        font: normal normal bold 67px/79px OpenSans;
        font-family: OpenSans, sans-serif;
        letter-spacing: 1.05px;
        color: #ffffff;
        opacity: 1;
        width: 40%;
        display: flex;
        position: relative;
        margin-left: 160px;
        margin-top: -10px;
      }

      .welcome-text-sub-headline {
        text-align: left;
        font: normal normal 500 33px/39px OpenSans;
        font-family: OpenSans, sans-serif;
        letter-spacing: 0.52px;
        color: white;
        opacity: 1;
        display: flex;
        width: 40%;
        position: relative;
        margin-top: 20px;
        margin-left: 160px;
      }
    }
  }

  &__form {
    height: 100%;
    max-height: 100%;
    width: 40%;
    display: flex;
    max-width: 40%;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      box-sizing: border-box;
      margin: 0 auto;
      justify-content: center;

      .title {
        width: 100%;
        margin: 10vh 0 6vh 0;
        color: #000000;
        font: normal normal 600 32px/39px OpenSans;
        text-align: center;
      }

      .join-button-wrapper {
        height: 42px;
        width: 100%;
        margin-bottom: 8%;
        display: flex;
        justify-content: center;

        .join-button {
          width: 100%;
          display: flex;
          cursor: pointer;
          line-height: 14px;
          border-radius: 5px;
          text-align: center;
          font-weight: bold;
          flex-wrap: nowrap;
          color: #090e3a;
          font-size: 14px;
          background-color: #f5f5f5;
          border: 1px solid #c9c9c9;
          text-decoration: solid;
          box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
          align-items: center;
          justify-content: space-evenly;

          .join-button-content {
            display: flex;
            gap: 10px;
            flex-direction: row;
            align-items: center;

            .text {
            }

            .logo {
              height: 32px;
              width: auto;
              margin: 10px auto;
            }
          }
        }
      }

      .sub-title-user-login {
        width: 100%;
        margin-bottom: 10%;
        font-size: 18px;
        color: #000000;
        display: flex;
        align-items: center;

        .span {
          display: flex;
          text-align: center;
          align-items: center;
          width: 100%;
        }

        .span:before,
        .span:after {
          content: '';
          height: 2px;
          flex-grow: 1;
          margin: 0 12px;
          background: #333;
        }

        .span:before {
          background: -webkit-gradient(linear, left top, right top, from(#eeeeee), color-stop(#eeeeee), to(#eeeeee));
          background: linear-gradient(to right, #eeeeee, #eeeeee);
        }

        .span:after {
          background: -webkit-gradient(linear, left top, right top, from(#eeeeee), color-stop(#eeeeee), to(#eeeeee));
          background: linear-gradient(to right, #eeeeee, #eeeeee);
        }
      }

      .sep-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 10%;

        .divider {
          width: 100%;
          height: 1px;
          background-color: #0000001a;
          position: absolute;
          top: 50%;
        }

        .sep-content {
          font-size: 15px;
          font-weight: 300;
          background-color: white;
          padding-inline: 8px;
          text-align: center;
          display: flex;
          z-index: 2;
        }
      }

      .login-input-wrapper {
        width: 100%;
        margin: 10px;

        .login-input-container {
          height: 42px;
          border-radius: 5px;
          margin: 8px 0 1px;
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #ffffff 0 0 no-repeat padding-box;
          direction: ltr;
          border: 1px solid #c3c3c3;

          .icon {
            color: #999898;
            overflow: hidden;
          }

          .visibility-icon {
            cursor: pointer;
          }

          .separator {
            height: 24px;
            width: 1px;
            background-color: #1667c7;
            margin-inline-end: 30px;
          }

          .input {
            width: calc(100% - 24px);
            background-color: transparent;
            cursor: pointer;
            font-size: 15px;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
          }

          .input::placeholder {
            color: #9a9a9a;
          }
        }

        .login-input-error {
          color: red;
          font-size: 16px;
          display: block;
          text-align: center;
        }

        .forgot-password-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .forgot-password {
            cursor: pointer;
            font-weight: 400;
            font-size: 13px;
            text-align: right;
            text-decoration: underline;
            color: #0266d6;
            display: block;
          }
        }
      }

      .login-loading {
        margin: 42px auto;
        padding-bottom: 0;
      }

      .dont-have-account-wrapper {
        width: 100%;
        font-weight: 400;
        margin: 10px;
        text-align: center;
      }

      .have-old-account-wrapper {
        width: 100%;
        font-weight: 400;
        margin: 10px;
        text-align: center;
        color: #1270d9;
        text-decoration: underline;
        cursor: pointer;
      }

      .login-button {
        height: 48px;
        min-height: 48px;
        width: 100%;
        margin-top: 4%;
        margin-bottom: 6%;
        cursor: pointer;
        border-radius: 8px;
        text-align: center;
        color: #0a287c;
        background-color: #fecb2f;
        border: 3px solid #fecb2f;
        box-shadow: -1px 1px 5px #00000027;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 18px;
        font-weight: bold;
      }

      .language-select {
        margin: 10px;
      }

      .lang-select-input {
        margin: 1rem;

        mat-select {
          width: fit-content;
        }

        .fi {
          padding-left: 20px;
          height: 18px;
          width: 18px;
        }

        .country-placeholder {
          padding-left: 60px;
        }

        .country-flag-icon {
          margin-left: 10px;
          width: 35px;
        }

        .lang-arrow-down {
          padding-right: 10px;
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: initial;
      }
    }

    .spacer {
      flex: 1 1 auto;
    }

    .bottom-wrapper {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-inline: 5%;
      font-size: 13px;
      box-sizing: border-box;
      font-weight: 400;
      margin: auto auto;

      .rout-link {
        color: #1270d9;
      }

      .atlas-line {
        padding-right: 4%;
        color: #b1b1b1;
      }
    }
  }

  a:-webkit-any-link {
    color: #1270d9;
    text-decoration: underline;
    cursor: pointer;
  }
}

app-login {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 18px !important;
    // height: 42px;
    display: flex;
    border-width: 0;
    align-items: center;
    width: fit-content;
  }

  mat-form-field {
    // width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: black;
    //padding-left: 20px;
  }
}

// for rtl styles
html[dir='rtl'] {
  // desktop
  .login {
    &__welcome {
      &__content {
        .welcome-image {
          margin-right: 50px;
          margin-left: 0;
          transform: scaleX(-1);
        }

        .welcome-text-headline {
          margin-right: 145px;
          width: 50%;
          text-align: right;
        }

        .welcome-text-sub-headline {
          margin-right: 145px;
          margin-left: 0;
          text-align: right;
          width: 39%;
        }
      }
    }

    &__form {
      &__content {
        .login-input-wrapper {
          .forgot-password-wrapper {
            .forgot-password {
              text-align: left;
            }
          }

          .login-input-container {
            direction: rtl;

            .input {
              direction: rtl;
            }
          }
        }
      }
    }
  }
}

// mobile
@media (max-width: 776px) {
  .login {
    flex-direction: column;

    &__welcome {
      height: 50%;
      width: 100%;
      &__content {
        .welcome-text-headline {
          margin-left: 110px;
          margin-top: -30px;
          font: normal normal bold 30px/31px OpenSans;
        }
        .welcome-text-sub-headline {
          margin-top: 20px;
          margin-left: 110px;
          font: normal normal 500 16px/21px OpenSans;
        }
        .welcome-image {
          height: 45px;
          width: 45px;
        }
      }
    }

    &__form {
      width: 100%;
      max-width: 100%;
      &__content {
        .title {
          margin: 3vh 0 6vh 0;
        }
      }
    }
  }
}
