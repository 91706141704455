.cards {
  min-width: 100px;
  display: flex;
  flex-direction: row;
  height: 5.6rem;
  margin-bottom: 1rem;

  .card {
    cursor: pointer;
    padding: 10px 10px;
    margin-right: $gap-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: #f0f3f9;
    border-radius: 10px;
    border: solid 2px #e9ebf1;
    width: fit-content;

    &:hover {
      background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
    }

    .title-container {
      display: flex;
      flex-direction: row;
      gap: $gap-medium;

      .title {
        font-size: $font-medium;
        color: #8da9c0;
      }

      .pill {
        display: inline-block;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 1;
        border-radius: 25px;
        background-color: #3498db;
        color: #ffffff;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        transition: background-color 0.3s;
        width: fit-content;
      }

      .pill:hover {
        background-color: #2980b9;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0px;

      .value {
        font-size: 2.2rem;
        font-weight: 300;
        color: #000000;
      }

      .customize-button {
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #d7e1eb;
        color: #141516;
        font-size: $font-small;
        font-weight: bold;
      }
    }
  }

  .overview-card-search {
    cursor: pointer;
    padding: 5px 60px 5px 10px;
    margin-right: $gap-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: #f0f3f9;
    //border: 1px solid #dfe1e6;
    border-radius: 10px;
    border: solid 2px #e9ebf1;
    //gap: 10px;
    width: 9.5rem;

    &:hover {
      background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
      /*.icon {
            display: none;
            width: 85px;
            position: absolute;
            top: 0px;
            bottom: 0;
            left: 135px;
            right: 0px;
            margin: auto;
          }

          .icon-active {
            display: unset;
            width: 85px;
            position: absolute;
            top: 0px;
            bottom: 0;
            left: 135px;
            right: 0px;
            margin: auto;
          }

          .customize-button {
            visibility: visible;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 10px;
            background-color: #d7e1eb;
            color: #141516;
            font-size: $font-small;
            font-weight: bold;
          }*/
    }

    &__title {
      font-size: $font-medium;
      color: #8da9c0;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .value {
        font-size: 2.2rem;
        font-weight: 300;
        color: #000000;
      }

      .customize-button {
        visibility: hidden;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #d7e1eb;
        color: #141516;
        font-size: $font-small;
        font-weight: bold;
      }
    }

    &__icon {
      width: 60px;
      position: absolute;
      top: 4px;
      bottom: 0;
      left: 176px;
      right: 0px;
      margin: auto;
      opacity: 1;
      transform: scaleX(-1);
    }
    /*.icon-active {
          display: none;
          width: 85px;
          position: absolute;
          top: 0px;
          bottom: 0;
          left: 135px;
          right: 0px;
          margin: auto;
        }*/
  }

  .overview-card-funds {
    cursor: pointer;
    padding: 5px 60px 5px 10px;
    margin-right: $gap-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: #f0f3f9;
    //border: 1px solid #dfe1e6;
    border-radius: 10px;
    //gap: 10px;
    font-family: $font-site;
    border: solid 2px #e9ebf1;
    width: 9.8rem;

    &:hover {
      background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
    }

    &__title {
      font-size: $font-medium;
      color: #8da9c0;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .value {
        font-size: 2.2rem;
        font-weight: 300;
        color: #000000;
      }

      .customize-button {
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #d7e1eb;
        color: #141516;
        font-size: $font-small;
        font-weight: bold;
      }
    }

    &__icon {
      width: 52px;
      position: absolute;
      top: 0px;
      bottom: 4px;
      left: 119px;
      right: 0px;
      margin: auto;
      opacity: 1;
    }
  }
}

.fund-details {
  height: calc(100vh - 96px);
  flex: 1;
  overflow: scroll;
  // header
  &__header {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    gap: $gap-small;
    background-color: white;
    position: initial;
    top: 0;
    border-bottom: 2px solid #8a8c9888;
    z-index: 1;

    .toolbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .back-button {
        cursor: pointer;
        height: 20px;
      }

      .fund-type {
        cursor: default;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 10px;
      }

      .fund-name {
        font-size: 18px;
        color: #171725;
        font-weight: bold;
        font-family: $font-site;
      }

      .separator {
        font-size: 14px;
        color: #8a8c98;
      }

      .organization-number {
        font-size: 14px;
        color: #8a8c98;
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .add-to-workplan-button {
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 8px;
          text-align: center;
          font-size: 12px;
          min-inline-size: max-content;
          color: #0a287c;
          background: #ffffff;
          border: 1px solid #0a287c;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .text {
            //font: normal normal bold $font-medium $font-site;
            font-family: $font-site;
            font-size: $font-small;
          }

          .icon {
            height: 18px;
            width: 18px;
            font-size: 18px;
          }
        }

        .financial-reports-button {
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 8px;
          text-align: center;
          font-size: 12px;
          min-inline-size: max-content;
          color: white;
          background: #0a287c;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .text {
            //font: normal normal bold $font-medium $font-site;
            font-family: $font-site;
            font-size: $font-small;
          }

          .icon {
            height: 18px;
            width: 18px;
            font-size: 18px;
          }
        }

        .apply-online-button {
          opacity: 0.5;
          pointer-events: none;
          cursor: none;
          padding: 5px 10px;
          border-radius: 8px;
          text-align: center;
          color: #0a287c;
          background: #fecb2e;
          border: 1px solid #fecb2e;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .text {
            //font: normal normal bold 14px/17px ProximaNova;
            font-family: $font-site;
            font-size: $font-small;
          }

          .icon {
            font-size: 20px;
            height: 20px;
            width: 20px;
          }
        }
        .update-data-button {
          padding: 5px 10px;
          border-radius: 8px;
          text-align: center;
          color: #0a287c;
          background: #fecb2e;
          border: 1px solid #fecb2e;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .text {
            //font: normal normal bold 14px/17px ProximaNova;
            font-family: $font-site;
            font-size: $font-small;
          }

          .icon {
            font-size: 20px;
            height: 20px;
            width: 20px;
          }
        }
        .favourites-button {
          cursor: pointer;
          height: 24px;
          width: 24px;
        }

        .more-options-button {
          cursor: pointer;
          font-size: 18px;
          height: 18px;
          width: 18px;
          color: #809fb8;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      .score {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        min-width: 70px;
        max-width: 340px;

        .icon {
          font-size: 20px;
          height: 20px;
          min-width: 25px;
          max-width: 40px;
          color: #22d598;
        }

        .text {
          font-size: 12px;
          font-weight: bold;
        }
      }

      .address {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .icon {
          height: 20px;
          width: 20px;
        }

        .text {
          font-size: 12px;
          font-weight: bold;
        }
      }

      .website {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .icon {
          height: 20px;
          width: 20px;
        }

        .text {
          font-size: 12px;
          font-weight: bold;
          color: #4b9dcc;
          width: 125px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .contact {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .icon {
          height: 20px;
          width: 20px;
        }

        .text {
          font-size: 12px;
          font-weight: bold;
          color: #4b9dcc;
        }
      }

      .last-updated {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        color: #809fb8;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;

      .tabs {
        display: flex;
        flex-direction: row;
        gap: 5px;

        .tab-button {
          cursor: pointer;
          padding: 10px 20px;
          background-color: #f5f5f6;
          color: #8a8c9888;
          border-radius: 10px 10px 0 0;
          border: 1px solid #f5f5f6;
          font-weight: bold;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .icon {
            height: 18px;
            width: auto;
          }

          .text {
            font-size: 14px;
          }

          &__active {
            background-color: #ffffff;
            color: #000000;
            //box-shadow: 0 13px 19px #0000001A;
            border: 1px solid #8a8c9888;
            border-bottom: 3px solid #fecb2e;
            margin-bottom: -3px;
          }
        }
        //.actions-buttons {
        //  display: flex;
        //  flex-direction: row;
        //  align-items: center;
        //  gap: 10px;
        //
        //  &__history {
        //    cursor: pointer;
        //    display: flex;
        //    flex-direction: row;
        //    align-items: center;
        //    gap: 5px;
        //    color: #8A8C9888;
        //
        //    .icon {
        //      height: 18px;
        //      width: auto;
        //    }
        //
        //    .text {
        //      font-size: 14px;
        //    }
        //  }
        //
        //  .divider {
        //    height: 24px;
        //    width: 1px;
        //    background-color: #8A8C9888;
        //  }
        //
        //  &__comments {
        //    cursor: pointer;
        //    display: flex;
        //    flex-direction: row;
        //    align-items: center;
        //    gap: 5px;
        //    color: #8A8C9888;
        //
        //    .icon {
        //      height: 18px;
        //      width: auto;
        //    }
        //
        //    .text {
        //      font-size: 14px;
        //    }
        //  }
        //
        //  .flag-icon {
        //    cursor: pointer;
        //    height: 24px;
        //    width: 24px;
        //  }
        //
        //  .bug-icon {
        //    cursor: pointer;
        //    height: 24px;
        //    width: 24px;
        //  }
        //  &__active {
        //    background-color: #FFFFFF;
        //    color: #000000;
        //    box-shadow: 0 13px 19px #0000001A;
        //    border: 1px solid #8A8C9888;
        //    border-bottom: 3px solid #FECB2E;
        //    margin-bottom: -3px;
        //  }
        //}
      }

      .actions-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        &__history {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          color: #8a8c9888;

          .icon {
            height: 18px;
            width: auto;
          }

          .text {
            font-size: 14px;
          }

          &__active {
            color: black;
          }

          &__green {
            color: forestgreen;
          }
        }

        .divider {
          height: 24px;
          width: 1px;
          background-color: #8a8c9888;
        }

        &__comments {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          color: #8a8c9888;

          .icon {
            height: 18px;
            width: auto;
          }

          .text {
            font-size: 14px;
          }

          &__active {
            color: black;
          }

          &__green {
            color: forestgreen;
          }
        }

        .flag-icon {
          cursor: pointer;
          height: 24px;
          width: 24px;
        }

        .bug-icon {
          cursor: pointer;
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
}

// for rtl styles
html[dir='rtl'] {
  .fund-details {
    &__header {
      .toolbar {
        .back-button {
          transform: rotate(180deg);
        }
      }
    }
  }
}
// mobile
@media (max-width: 776px) {
  .fund-details {
    .details {
      flex-direction: column;

      .address {
        .text {
          text-align: center;
        }
      }
    }

    &__header {
      .actions {
        flex-direction: column;
        align-items: center;

        .actions-buttons {
          padding: 5%;
        }

        .tabs {
          //flex-direction: column;
          .tab-button .text {
            display: none;
          }
        }
      }

      .toolbar {
        flex-direction: column;

        &__buttons {
          .text {
            display: none;
          }
        }
      }
    }
  }
}
