// desktop
.forgot-password {
  size: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  font-family: OpenSans, serif;

  &__welcome {
    height: 100%;
    max-height: 100%;
    width: 60%;
    position: relative;
    font-size: 32px;
    display: flex;
    flex-wrap: wrap;

    .theme-picture {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
      opacity: 1;
      display: flex;
    }

    &__content {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .welcome-image {
        height: 190px;
        width: 190px;
        margin-left: 50px;
        margin-top: 50px;
        position: relative;
        display: flex;
      }

      .welcome-text-headline {
        text-align: left;
        font: normal normal bold 67px/79px;
        font-family: OpenSans, sans-serif;
        letter-spacing: 1.05px;
        color: #ffffff;
        opacity: 1;
        width: 40%;
        display: flex;
        position: relative;
        margin-left: 160px;
        margin-top: -10px;
      }

      .welcome-text-sub-headline {
        text-align: left;
        font: normal normal 500 33px/39px;
        font-family: OpenSans, sans-serif;
        letter-spacing: 0.52px;
        color: white;
        opacity: 1;
        display: flex;
        width: 40%;
        position: relative;
        margin-top: 20px;
        margin-left: 160px;
      }
    }
  }

  &__form {
    height: 100%;
    max-height: 100%;
    width: 40%;
    display: flex;
    max-width: 40%;
    flex-direction: column;
    justify-content: center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      box-sizing: border-box;
      margin: auto;
      justify-content: center;

      .title {
        width: 100%;
        color: #000000;
        font-size: 32px;
        font-family: OpenSans, sans-serif;
        text-align: center;
      }

      .forgot-password-input-wrapper {
        width: 100%;
        margin: 10px;
        padding-top: 10px;

        .input-title {
          font-weight: bold;
        }

        .forgot-password-input-container {
          .input {
            width: calc(100% - 24px);
            cursor: pointer;
            font-size: 15px;
            font-family: OpenSans, sans-serif;
            height: 42px;
            border-radius: 5px;
            margin: 8px 0 1px;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff 0 0 no-repeat padding-box;
            direction: ltr;
            border: 1px solid #c3c3c3;
          }

          input[type='email']:focus {
            border: 1px solid #fecb2e;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
          }

          .input::placeholder {
            color: #9a9a9a;
          }
        }

        .forgot-password-input-error {
          color: red;
          font-size: 16px;
          display: block;
          text-align: center;
        }
      }

      .forgot-password-loading {
        margin: 42px auto;
        padding-bottom: 0;
      }

      .have-account-wrapper {
        width: 100%;
        font-weight: 400;
        margin: 10px;
        text-align: center;
      }

      .forgot-password-button {
        height: 48px;
        min-height: 48px;
        width: 100%;
        margin-top: 4%;
        margin-bottom: 6%;
        cursor: pointer;
        border-radius: 8px;
        text-align: center;
        color: #0a287c;
        background-color: #fecb2f;
        border: 3px solid #fecb2f;
        box-shadow: -1px 1px 5px #00000027;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 18px;
        font-weight: bold;
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding: 0;
      }
    }

    .bottom-wrapper {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-inline: 5%;
      font-size: 13px;
      box-sizing: border-box;
      font-weight: 400;
      margin: 0 auto 20px;

      .rout-link {
        color: #1270d9;
      }

      .atlas-line {
        padding-right: 4%;
        color: #b1b1b1;
      }
    }
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  .line {
    flex: 1;
  }

  .text {
    padding-left: 10px;
    padding-right: 10px;
  }

  a:-webkit-any-link {
    color: #1270d9;
    text-decoration: underline;
    cursor: pointer;
  }
}

app-forgot-password {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 18px !important;
    // height: 42px;
    display: flex;
    border-width: 0;
    align-items: center;
    width: fit-content;
  }

  mat-form-field {
    // width: 100%;
    overflow: auto;
  }
}
// mobile
@media (max-width: 776px) {
  .forgot-password {
    flex-direction: column;

    &__welcome {
      height: 50%;
      width: 100%;
      &__content {
        .welcome-text-headline {
          margin-left: 110px;
          margin-top: -30px;
          font: normal normal bold 30px/31px OpenSans;
        }
        .welcome-text-sub-headline {
          margin-top: 20px;
          margin-left: 110px;
          font: normal normal 500 16px/21px OpenSans;
        }
        .welcome-image {
          height: 45px;
          width: 45px;
        }
      }
    }

    &__form {
      width: 100%;
      max-width: 100%;
      &__content {
        .title {
          margin: 3vh 0 6vh 0;
        }
      }
    }
  }
}
