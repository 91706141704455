// desktop
.visitors-plan-options {
  cursor: pointer;
  display: flex;
  border-radius: 25px;
  flex-direction: column;
  background: white;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  max-height: 28rem;
  position: relative;
  padding-bottom: 6%;
  box-shadow: 0 3px 6px #00000000;
  border: 1px solid #ececf1;
  font-family: 'Mulish', sans-serif;

  &__plan-uper {
    border-radius: 25px 25px 0 0;
  }

  &__p-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    justify-content: flex-start;

    .plan-title {
      font-size: 19px;
      color: #121d2a;
      letter-spacing: -0.38px;
      height: 11%;
      display: flex;
      align-items: flex-end;
    }

    .visitors-plan-options__p-form__content-yearly-custom {
      height: 65%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      align-items: center;
      height: 87%;
      width: 85%;

      .description-wrapper {
        width: 70%; //67%;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        align-items: center;
        color: #677177;
        letter-spacing: -0.24px;
        font-size: 12px;
      }

      .feature-container {
        display: flex;
        gap: 0.1rem;
        flex-direction: column;

        .feature-row {
          display: flex;
          gap: 0.5rem;
          font-size: 13px;
          font-weight: 600;
          align-items: center;
          justify-content: flex-start;

          .check-icon {
            color: #fecb2e;
          }

          .feature-description {
            display: flex;

            &__text {
              text-align: start;
            }
          }
        }
      }

      .feature-container-custom {
        display: none;
      }

      .logo {
        height: 64px;
        width: auto;
        margin: 10px auto;
      }

      .custom-title {
        font: normal normal 900 31px OpenSans;
        letter-spacing: -0.62px;
      }

      &__titles-low-div {
        align-self: center;
        display: flex;
        gap: 5px;
        align-items: center;
        flex-direction: column;

        .title-low {
          text-align: center;
          font: normal normal 600 31px Mulish;
          letter-spacing: -0.62px;
          color: #121d2a;
          width: 69%;
          overflow: hidden;
        }

        .per-month-label {
          font-size: 14px;
          letter-spacing: -0.28px;
          line-height: 29px;
          color: #96a5ae;
          width: 3.4rem;
        }

        .sub-title-low {
          margin: 10px auto;
          display: inline;
          font-size: 21px;
          font-weight: bold;
          color: #4c9dcb;
        }
      }

      .plan-input-container {
        width: 247px;
        height: 48px;

        margin: 10px auto;
        padding: 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff 0 0 no-repeat padding-box;
        box-shadow: inset 0 3px 6px #00000029, 0 3px 16px #00000029;
      }

      .icon {
        height: 30px;
        width: 30px;
      }

      .star-icon {
        height: 20px;
        width: 20px;
        margin-right: -55px;
        padding-left: 60px;
        margin-bottom: 12px;
      }

      .visibility-icon {
        cursor: pointer;
      }

      .separator {
        height: 24px;
        width: 1px;
        background-color: #1667c7;
        margin-inline-end: 30px;
      }

      .input {
        font-size: 18px;
        width: 100%;
        background-color: transparent;
      }

      .plan-button {
        border: 1px solid #fecb2e;
        text-align: center;
        color: #fecb2e;
        background: white;
        padding: 0;
        border-radius: 10px;
        margin: 0;
        font-size: 17px;
        font-family: 'OpenSans', sans-serif;
        font-weight: 900;
        letter-spacing: -0.36px;
        height: 43px;
        width: 80%;
        cursor: pointer;
      }

      .save-more-header {
        text-align: center;
        letter-spacing: -0.26px;
        color: #809fb8;
      }

      .save-more-under-header {
        text-align: center;
        letter-spacing: -0.26px;
        color: #000000;
      }

      .choose-plan-button {
        border: none;
        text-align: center;
        color: #0a287c;
        background: #fecb2e 0% 0% no-repeat padding-box;
        padding: 0;
        border-radius: 10px;
        margin: 0;
        font-size: 17px;
        font-family: 'OpenSans', sans-serif;
        font-weight: 900;
        letter-spacing: -0.36px;
        height: 43px;
        width: 80%;
        cursor: pointer;
      }

      .separator {
        height: 2px;
        width: 10px;
        background-color: #4c9dcb;
        margin-inline-end: 30px;
      }
    }

    &__content-yearly {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      align-items: center;
      height: 100%;
      width: 85%;
      padding: 0 17px;

      .description-wrapper {
        width: 70%; //67%;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        align-items: center;
        color: #677177;
        letter-spacing: -0.24px;
        font-size: 12px;
      }

      .feature-container {
        display: flex;
        gap: 0.1rem;
        flex-direction: column;

        .feature-row {
          display: flex;
          gap: 0.5rem;
          font-size: 13px;
          font-weight: 600;
          align-items: center;
          justify-content: flex-start;

          .check-icon {
            color: #fecb2e;
          }

          .feature-description {
            display: flex;

            &__text {
              text-align: start;
            }
          }
        }
      }

      .feature-container-custom {
        display: none;
      }

      .logo {
        height: 64px;
        width: auto;
        margin: 10px auto;
      }

      .custom-title {
        font: normal normal 900 31px OpenSans;
        letter-spacing: -0.62px;
      }

      &__titles-low-div {
        align-self: center;
        display: flex;
        gap: 5px;
        align-items: center;
        flex-direction: column;

        .title-low {
          text-align: center;
          font: normal normal 600 31px Mulish;
          letter-spacing: -0.62px;
          color: #121d2a;
          width: 69%;
          overflow: hidden;
        }

        .per-month-label {
          font-size: 14px;
          letter-spacing: -0.28px;
          line-height: 29px;
          color: #96a5ae;
          width: 3.4rem;
        }

        .sub-title-low {
          margin: 10px auto;
          display: inline;
          font-size: 21px;
          font-weight: bold;
          color: #4c9dcb;
        }
      }

      .plan-input-container {
        width: 247px;
        height: 48px;

        margin: 10px auto;
        padding: 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff 0 0 no-repeat padding-box;
        box-shadow: inset 0 3px 6px #00000029, 0 3px 16px #00000029;
      }

      .icon {
        height: 30px;
        width: 30px;
      }

      .star-icon {
        height: 20px;
        width: 20px;
        margin-right: -55px;
        padding-left: 60px;
        margin-bottom: 12px;
      }

      .visibility-icon {
        cursor: pointer;
      }

      .separator {
        height: 24px;
        width: 1px;
        background-color: #1667c7;
        margin-inline-end: 30px;
      }

      .input {
        font-size: 18px;
        width: 100%;
        background-color: transparent;
      }

      .plan-button {
        border: 1px solid #fecb2e;
        text-align: center;
        color: #fecb2e;
        background: white;
        padding: 0;
        border-radius: 10px;
        margin: 0;
        font-size: 17px;
        font-family: 'OpenSans', sans-serif;
        font-weight: 900;
        letter-spacing: -0.36px;
        height: 43px;
        width: 80%;
        cursor: pointer;
      }

      .choose-plan-button {
        border: none;
        text-align: center;
        color: #0a287c;
        background: #fecb2e 0% 0% no-repeat padding-box;
        padding: 0;
        border-radius: 10px;
        margin: 0;
        font-size: 17px;
        font-family: 'OpenSans', sans-serif;
        font-weight: 900;
        letter-spacing: -0.36px;
        height: 43px;
        width: 80%;
        cursor: pointer;
      }

      .separator {
        height: 2px;
        width: 10px;
        background-color: #4c9dcb;
        margin-inline-end: 30px;
      }
    }
  }
}

.visitors-plan-options:hover {
  box-shadow: 0 3px 6px #00000029, 0 3px 16px #00000029;
  max-height: 28rem;

  .feature-container-custom {
    display: flex;
    gap: 0.1rem;
    flex-direction: column;

    .feature-row {
      display: flex;
      gap: 0.5rem;
      font-size: 13px;
      font-weight: 600;
      align-items: center;
      justify-content: flex-start;

      .check-icon {
        color: #fecb2e;
      }

      .feature-description {
        display: flex;

        &__text {
          text-align: start;
        }
      }
    }
  }

  .visitors-plan-options__p-form {
    .visitors-plan-options__p-form__content-yearly-custom {
      height: 87%;
    }
  }
}

.opacity {
  opacity: 0.35;
}

// mobile
@media (max-width: 776px) {
  .visitors-plan-options {
  }
}

// for rtl styles
html[dir='ltr'] {
  // desktop

  // mobile
  @media (max-width: 776px) {
    .visitors-plan-options {
    }
  }
}
