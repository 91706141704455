.fund-comments-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 98%;

  .header-wrapper {
    display: flex;

    .icon {
      cursor: pointer;
      width: 2rem;
    }

    .header {
      text-align: center;
      //font: normal normal 600 18px/36px $font-site;
      font-family: $font-site;
      font-size: $font-medium;
      letter-spacing: 0.07px;
      color: #171725;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #cfcfcf;
    margin-bottom: 25px;
  }

  .text-area {
    width: 97.5%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    min-height: 180px;
    padding-inline: 10px;
    padding-block: 10px;
    resize: none;
    text-align: initial;
    //font: normal normal medium 15px/36px $font-site !important;
    font-family: $font-site;
    font-size: $font-medium;
    letter-spacing: 0.06px;
    color: #000000;
  }

  .spacer {
    height: 50px;
  }

  .button {
    display: flex;
    width: 100%;

    .spacer {
      flex: 1 1 auto;
    }

    .save-comment {
      background: #fecb2e 0% 0% no-repeat padding-box;
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;

      .header {
        text-align: center;
        //font: normal normal bold 14px/17px $font-site;
        font-family: $font-site;
        font-size: $font-medium;
        letter-spacing: 0px;
        color: #0a287c;
        padding: 10px;
      }
    }
  }
}

html[dir='rtl'] {
  .fund-comments-main {
    .header-wrapper {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
}
@media (max-width: 776px) {
  .fund-comments-main .text-area {
    width: 90%;
  }
}
