.work-plan-dialog {
  //width: 30%;
  min-width: 270px;
  max-height: 80vh;
  background-color: white;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px #00000014;
  //position: relative;
  overflow: auto;
  //display: flex;
  flex-direction: column;
  border-radius: unset;

  .workplan-container {
    .form-container {
      .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .close-icon {
          width: 18px;
          height: 18px;
          //position: sticky;
          cursor: pointer;
          top: unset;
          left: unset;
          right: unset;
          position: unset;
        }

        .header-icon {
          width: 18px;
          height: 18px;
          //position: sticky;
          cursor: pointer;
        }
      }
    }

    .finish-btn-wrapper {
      padding-left: unset;
      width: 500px;
      display: flex;
      justify-content: center;
    }
  }

  img.close-icon {
    width: 18px;
    height: 18px;
    //position: sticky;
    cursor: pointer;
    //left: calc(100% - 16px - 18px);
    //float: right;
    //padding: 5px;
  }

  // scroll
  &::-webkit-scrollbar {
    width: 5px;
    height: 90%;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #0a287c 0 0 no-repeat padding-box;
    border-radius: 20px;
  }
}

// select
.work-plan-dialog-select {
  width: 100%;

  .custom-select {
    gap: 0px;

    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      height: 20px;
      min-width: 150px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.work-plan-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    .select-option {
      padding: 10px 5px;
      background-color: white;
      font-size: 14px;

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #000000;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #000000;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}

// for rtl styles
html[dir='rtl'] {
  .work-plan-dialog {
    //width: 30%;
    min-width: 270px;
    max-height: 80vh;
    background-color: white;
    //border-top-left-radius: 16px;
    //border-top-right-radius: unset;
    //border-bottom-left-radius: 16px;
    //border-bottom-right-radius: unset;
    border: 1px solid #d0d0d0;
    border-right: 8px solid #fecb2e;
    border-left: unset;
    box-shadow: 0 13px 36px rgb(0 0 0 / 8%);
    overflow: auto;

    img.close-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
      //float: left;
      //padding: 10px;
      //left: 35%;
      //top: 11%;
    }

    .workplan-container {
      .finish-btn-wrapper {
        width: 500px;
        display: flex;
        justify-content: center;
        padding-left: unset;
      }
    }
  }
}
@media (max-width: 776px) {
  .work-plan-dialog {
    width: 100%;
    min-width: unset;
  }
  .work-plan-dialog .workplan-container .finish-btn-wrapper {
    width: unset !important;
  }
}
