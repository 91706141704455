.confirm-dialog {
  width: 500px;
  height: 100%;
  background: white;
  border: 1px solid gray;
  border-radius: 10px;
  gap: 10px;
  padding: 20px 40px;

  &__header {
    padding: 10px;
    display: flex;
    justify-content: center;
    margin: 0;

    .title {
      color: #1c2d57;
      font-size: 24px;
      text-align: center;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    margin: 10px !important;
    flex-direction: column;
    align-items: center;

    .message {
      color: #636c84;
      font-size: 14px;
      text-align: center;
    }

    .image {
      width: 250px;
      height: auto;
    }
  }

  &__footer {
    .buttons {
      display: flex;
      justify-content: space-around;

      .btn {
        width: 40%;
        padding: 2px 0;
        box-shadow: 0 1px 3px #00000026;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        color: #0a287c;
        display: flex;
        justify-content: center;
      }

      .save-button {
        background: #fecb2e 0 0 no-repeat padding-box;
        box-shadow: 0 1px 3px #00000026;
      }

      .cancel-button {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #0a287c;
      }
    }
  }
}

@media (max-width: 776px) {
  .confirm-dialog {
    width: unset;
    height: unset;
    border: none;
  }
}
