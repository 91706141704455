.smart-agent-dialog {
  font-family: OpenSans, sans-serif;
  //width: 30%;
  max-height: 85vh;
  min-width: 700px;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px #00000014;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;

  img.close-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .smart-agent-container {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
    gap: 14px;
    display: flex;
    flex-direction: column;

    // scroll
    &::-webkit-scrollbar {
      cursor: pointer;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      cursor: pointer;
      background-color: #e4e4e4;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background: #0a287c 0 0 no-repeat padding-box;
      border-radius: 3px;
    }

    img.close-icon {
      //top: 16px;
      width: 18px;
      height: 18px;
      position: unset;
      right: unset;
      top: unset;
      //position: sticky;
      cursor: pointer;
      //left: calc(100% - 16px - 18px);
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      //> img {
      //  width: 2rem;
      //  height: 2rem;
      //}

      > span {
        font-size: 1rem;
        font-weight: 600;
        margin-left: 1rem;
        color: #171725;
      }

      .input-error {
        color: red;
        font-size: 11px;
        display: block;
        padding: 5px;
      }

      .fill-color {
        filter: brightness(0) saturate(100%) invert(79%) sepia(7%) saturate(4327%) hue-rotate(351deg) brightness(109%)
          contrast(99%);
      }
    }

    .form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .form-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;

        .input-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          //gap: 10px;

          .input-currency {
            //position: absolute;
            //left: 10px;
            //top: 67%;
            line-height: 1em;
            font-size: 14px;
            justify-content: center;
            align-items: baseline;
          }

          .input-wrapper > input {
            padding-left: 16px;
            font-size: 1em;
          }

          > label {
            font-family: OpenSans, sans-serif;
            padding-left: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: -0.26px;
          }

          > input {
            margin: 0px 0 10px;
            padding-block: 9px;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #c1c1c1;
            font-size: 14px;
            padding: 15px 5px;
          }

          > placeholder {
            color: rgba(0, 0, 0, 0.3490196078);
            font-size: 14px;
          }

          input[type='text']:focus {
            border: 1px solid #fecb2e;
          }
        }

        .input-error {
          color: red;
          font-size: 13px;
          display: block;
          padding-left: 5px;
        }

        .fund-grant-range {
          flex: 1;
          min-width: 200px;
          display: flex;
        }

        .textarea-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          > label {
            font-family: OpenSans, sans-serif;
            padding-left: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: -0.26px;
            color: rgba(0, 0, 0, 0.3490196078);
          }

          > textarea {
            border-radius: 0.5rem;
            min-height: 4.5rem;
            max-width: 100%;
            resize: none;
            color: #5f5f5f;
            font-size: 14px;
            font-family: OpenSans, sans-serif;
            border: 1px solid black;
            color: rgba(0, 0, 0, 1.34902);
            padding: 10px;
          }
        }
      }
    }

    .actions-row {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;

      .btn {
        border-radius: 8px;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        line-height: 21px;
        font-weight: 600;
        letter-spacing: -0.26px;
        cursor: pointer;
      }

      .btn-primary {
        background-color: #fecb2e;
        color: #171725;
      }

      .btn-secondary {
        background-color: white;
        color: #0a287c;
        border: 2px solid #0a287c;
      }
    }
  }
}

// select component
.smart-agent-dialog-select {
  width: 100%;

  .custom-select {
    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      padding: 10px 5px;

      .select-input {
        color: rgba(0, 0, 0, 0.3490196078);
        font-size: 14px;
        padding: 0;
      }

      ::placeholder {
        color: rgba(0, 0, 0, 0.3490196078);
        font-size: 14px;
      }

      .text {
        color: rgba(0, 0, 0, 0.3490196078);
        font-size: 14px;
      }

      .label {
        color: rgba(0, 0, 0, 0.3490196078);
        font-size: 14px;
      }

      .icon {
        color: #0a287c;
        font-size: 24px;
      }
    }

    .selected-options {
      max-height: 70px;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #0a287c 0 0 no-repeat padding-box;
        border-radius: 3px;
      }
    }
  }
}

.smart-agent-dialog-select-panel {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    display: flex;
    flex-direction: column;

    .select-option {
      padding: 5px;
      background-color: white;
      color: #5f5f5f;
      gap: 10px;

      .checkbox {
      }

      .text {
        color: rgba(0, 0, 0, 0.3490196078);
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #5f5f5f;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #5f5f5f;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}

.select-label {
  font-family: OpenSans, sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
}

// slider component
.smart-agent-slider {
  width: 100%;
}

html[dir='rtl'] {
  img.close-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    left: 16px;
    top: 16px;
    right: unset;
  }

  & .ngx-slider .ngx-slider-tick-legend {
    left: 5px;
  }
}

.smart-agent-select {
  width: 100%;

  .custom-select {
    .select-label {
      font-family: OpenSans, sans-serif;
      padding-left: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: -0.26px;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      padding: 10px 5px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
      max-height: 70px;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #0a287c 0 0 no-repeat padding-box;
        border-radius: 3px;
      }
    }
  }
}

.smart-agent-select-error {
  width: 100%;

  .custom-select {
    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid red;
      font-size: 14px;
      padding: 10px 5px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}
// mobile
@media (max-width: 776px) {
  .smart-agent-dialog {
    max-height: 500px;
    min-width: unset;
    .slider {
      padding: 0 !important;
    }
    .smart-agent-container {
      .form-container {
        .form-row {
          flex-direction: column;
          align-items: unset;
        }
      }
    }
  }
}
