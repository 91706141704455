.signup-old-fb-account-main {
  height: 55vh;
  width: 55vw;
  background: white;
  border-radius: 10px;
  display: flex;

  .decoration {
    height: 100%;
    background-color: #fecb2f;
    width: 3%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  .content {
    width: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .close-wrapper {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 20px;

      .close-icon {
        background-color: transparent;
        float: right;
        padding: 10px;
        cursor: pointer;
      }
    }

    .content-wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      height: 90%;
      gap: 15%;

      .header {
        text-align: center;
        font: normal normal bold 24px/17px 'OpenSans';
        letter-spacing: 0.1px;
        color: #44444f;
      }

      .input-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;

        .old-input {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 45%;
          height: 100%;
          justify-content: space-between;

          .input-header {
            font-size: 12px;
          }

          .input {
            height: 42px;
            border-radius: 5px;
            padding: 0 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff 0 0 no-repeat padding-box;
            border: 1px solid #c3c3c3;
            width: 100%;
          }
        }

        .new-input {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 45%;
          height: 100%;
          justify-content: space-between;

          .header-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;

            .input-error {
              font-size: 11px;
              color: red;
            }

            .input-header {
              font-size: 12px;
            }
          }

          .input {
            height: 42px;
            border-radius: 5px;
            padding: 0 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff 0 0 no-repeat padding-box;
            border: 1px solid #c3c3c3;
            width: 100%;
          }
        }
      }

      .submit {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2%;

        .login-button-submit {
          height: 35px;
          min-height: 35px;
          width: 30%;
          cursor: pointer;
          border-radius: 8px;
          text-align: center;
          color: #0a287c;
          background-color: #fecb2f;
          border: 3px solid #fecb2f;
          box-shadow: -1px 1px 5px rgb(0 0 0 / 15%);
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 18px;
          font-weight: bold;

          &__disabled {
            background: #dfdfdf;
            border: 1px solid #dfdfdf;
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
}

@media (max-width: 776px) {
  .signup-old-fb-account-main {
    height: 75vh;
    width: 75vw;
    .content {
      .content-wrapper {
        gap: 7%;
        .input-wrapper {
          flex-direction: column;
          .old-input {
            width: auto;
          }
          .new-input {
            width: auto;
          }
        }
        .header {
          padding-top: 5%;
          font: normal normal bold 24px/27px 'OpenSans';
        }
      }
    }
  }
}
