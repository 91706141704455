.prizes {
  .main {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;
    width: fit-content;
    background: white;
    margin: 20px auto;
    border: 1px solid #d0d0d0;
    border-radius: 10px;

    // scroll
    &::-webkit-scrollbar {
      cursor: pointer;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      cursor: pointer;
      background-color: #e4e4e4;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background: #0a287c 0 0 no-repeat padding-box;
      border-radius: 3px;
    }
  }

  .wrapper {
    padding: 30px;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .text {
    font-size: 11px;
    text-align: left;
    font-family: 'Helvetica Neue';
  }

  .title {
    font-size: 21px;
    font-family: 'Helvetica Neue';
  }

  .sub-title {
    font-size: 25px;
    color: #070707;
    opacity: 1;
    font-weight: bold;
    margin-top: 10px;
    font-family: 'Helvetica Neue';
  }

  .icon {
    color: #4b9dcc;
    margin-right: 5px;
  }

  .close-icon {
    position: relative;
    display: flex;
    background-color: transparent;
    float: right;
    padding: 5px;
    cursor: pointer;
  }

  .sub-text {
    font-family: 'OpenSans';
    font-size: 11px;
  }

  .coin-section {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: flex-start;
  }

  .item-title {
    font-family: 'OpenSans';
    font-weight: bold;
    font-size: 13px;
    color: #4b9dcc;
    margin: 0;
  }

  .divider {
    margin-bottom: 15px;
  }

  ul {
    padding-left: 1em;
  }
}
