.notifications-dialog {
  width: 360px;
  padding: 20px 20px 0 20px;
  background: white;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  border-radius: 10px;
  direction: rtl;

  .cards-list-section {
    overflow: auto;
    width: 100%;
    padding-inline-end: 20px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
      background: #0a287c 0 0 no-repeat padding-box;
    }
  }

  .dialog-header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
    font-size: 15px;
    font-family: 'OpenSans', sans-serif;
    justify-content: space-between;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .close-icon {
    top: 16px;
    width: 18px;
    height: 18px;
    //position: sticky;
    cursor: pointer;
    //left: calc(100% - 16px - 18px);
    //position: relative;
    display: flex;
    background-color: transparent;
    float: right;
    padding: 5px;
  }

  .card {
    cursor: pointer;
    height: 80px;
    border: solid 1px #fecb2e;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;

    .icon {
      color: #fecb2e;
      transform: scale(0.7);
    }

    .icon-read {
      color: gray;
      transform: scale(0.7);
    }

    .header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header {
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 12px;
      }

      .date-section {
        color: #1e1f22;
        font-size: 9px;
        padding-top: 10px;
      }
    }

    .body-section {
      color: #1e1f22;
      font-weight: bold;
      padding: 10px;
      font-size: 10px;
    }
  }
}

html[dir='ltr'] {
  .notifications-dialog {
    direction: ltr;
  }
}
