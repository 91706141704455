.funding-experts-dialog {
  max-width: 900px;
  padding: 30px 20px 0 20px;
  background: white;
  display: flex;
  border-radius: 0 10px 10px 0;
  border-left: 5px solid #fecb2e;
  flex-direction: column;
  position: relative;
  gap: 10px;
  max-height: 500px;
  direction: ltr;
  overflow: auto;
  min-width: 800px;
  min-height: 460px;

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__header {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .details {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .avatar {
        height: 48px;
        width: 48px;
        box-sizing: border-box;
        border: 1px solid black;
        border-radius: 50%;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .search-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;
      border: 2px solid #809fb8;
      border-radius: 10px;
      gap: 10px;

      .icon {
        height: 14px;
      }

      .input {
        color: gray;
        flex: 1;

        ::placeholder {
          color: #809fb8;
        }
      }
    }
  }

  &__content {
    padding: 0 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .line-sep {
      background: #dbdbdf;
      display: flex;
      flex-direction: column;
      min-width: 2px;
      position: relative;
      right: 1.5%;
      top: 35px;
      min-height: 146px;
      max-height: 355px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    margin-block: 20px;

    .filters {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: scroll;
      overflow-x: hidden;
      flex: 1;

      .filter {
        .expandable {
          &__header {
            .title {
              font-size: 14px;
              font-weight: bold;
              color: #9f9fa9;
            }

            .expand-icon {
              margin: unset;
              color: #9f9fa9;
            }
          }

          &__content {
            .filter-option {
              cursor: pointer;
              padding: 5px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 5px;

              input[type='checkbox'] {
                @include checkbox(16px);
              }

              .text {
                color: #44444f;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .tabs {
      display: flex;
      flex-direction: row;
      gap: 5px;
      z-index: 10;
      background: white;

      .tab-button {
        cursor: pointer;
        padding: 10px 20px;
        background-color: #f5f5f6;
        color: #8a8c9888;
        border-radius: 10px 10px 0 0;
        border: 1px solid #f5f5f6;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .text {
          font-size: 14px;
        }

        &__active {
          background-color: #ffffff;
          color: #000000;
          box-shadow: 0 13px 19px #0000001a;
          border: 1px solid #8a8c9888;
          border-bottom: 3px solid #fecb2e;
          margin-bottom: -3px;
        }
      }
    }

    .results {
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 100%;
      max-height: 360px;

      .line {
        width: 98%;
        background: #dbdbdf;
        height: 2px;
        margin-bottom: -10px;
        margin-top: -4px;
        z-index: 9;
      }

      .loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(233, 238, 248, 0.6784313725);
        border-bottom-right-radius: 10px;

        .loader {
        }
      }

      .selected-funding-expert {
        padding: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__white-blur {
          z-index: 1;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(3px);
        }

        &__card {
          padding: 25px;
          z-index: 2;
          width: 288px;
        }
      }

      .funding-experts-list {
        max-height: 400px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: scroll;

        &__title {
          padding: 15px;
          font-size: 14px;
          font-weight: bold;
        }

        &__list {
          gap: 20px;
          padding: 40px;
          justify-content: space-between;
          overflow-y: scroll;
          overflow-x: hidden;
          display: grid;
          grid-template-columns: 1fr auto;
          min-width: 620px;
          //min-height: 475px;

          // scroll
          &::-webkit-scrollbar {
            cursor: pointer;
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            cursor: pointer;
            background-color: #e4e4e4;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: #0a287c 0 0 no-repeat padding-box;
            border-radius: 3px;
          }
        }
      }

      .funding-expert-card {
        max-width: 288px;
        display: flex;
        flex-direction: column;
        flex-basis: calc(50% - 10px);
        background: white;
        box-shadow: 0px 6px 27px #00000012;
        border: 1px solid #809fb89d;
        border-radius: 10px;
        max-height: 270px;

        &__selected {
        }

        &__list-item {
          cursor: pointer;
        }

        &__invisible {
          visibility: hidden;
        }

        &__header {
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 2px solid #fecb2e;
          gap: 10px;

          .avatar {
            height: 24px;
            width: 24px;
            box-sizing: border-box;
            border: 1px solid black;
            border-radius: 50%;
          }

          .name {
            font-size: $font-medium;
            font-weight: bold;
          }
        }

        &__content {
          padding: 10px;
          display: flex;
          flex-direction: column;

          .funding-expert-properties {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .property {
              font-size: $font-small;
            }
          }
        }

        &__footer {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;

          .price {
            font-size: 12px;
            font-weight: bold;
          }

          .learn-more-button {
            font-size: 12px;
            cursor: pointer;
            text-decoration: underline;
            color: #3b5396;
          }
        }

        &__payment {
          padding: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          .hours-input {
            padding: 5px;
            border: 2px solid #d9e1e7;
            border-radius: 10px;
            height: 18px;
            width: 18px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
          }

          .hours-text {
            font-size: 10px;
          }

          .pay-and-schedule-button {
            cursor: pointer;
            padding: 10px 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: #fecb2e;
            color: #0a287c;
            border-radius: 10px;
            font-size: 10px;
          }

          .hours-input-error {
            padding: 5px;
            border: 2px solid red;
            border-radius: 10px;
            height: 18px;
            width: 18px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .expandable__content {
    overflow: auto;
    max-height: 300px;

    // scroll
    &::-webkit-scrollbar {
      cursor: pointer;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      cursor: pointer;
      background-color: #e4e4e4;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background: #0a287c 0 0 no-repeat padding-box;
      border-radius: 3px;
    }
  }
}

// rtl
html[dir='rtl'] {
  .funding-experts-dialog {
    direction: rtl;
  }
}

@media (max-width: 776px) {
  .funding-experts-dialog {
    min-width: unset;
    &__results {
      .results .funding-experts-list__list {
        padding: 5px;
        grid-template-columns: unset;
        min-width: unset;
      }
      .tabs {
        flex-direction: column;
      }
    }
    &__header {
      flex-direction: column;
    }
    &__content {
      flex-direction: column;
      .line-sep {
        display: none;
      }
    }
  }
}
