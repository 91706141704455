.workplan-container {
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;

  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;

    .header-title {
      font-size: $font-large;
      font-weight: 600;
      display: flex;
      gap: 10px;
      color: #171725;
      align-items: center;

      .header-icon {
        height: 32px;
      }
    }

    .form-row {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .owner-select-input {
        border: 1px solid #707070;
        border-radius: 8px;

        .owner-placeholder {
          padding-left: 16px;
        }

        .arrow-down {
          padding-right: 10px;
          font-size: 30px;
          height: 30px;
          width: 30px;
        }
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding: 0;
      }

      .title {
        font-size: $font-medium;
        font-weight: 600;
      }

      .input-wrapper {
        width: 100%;
        border: 1px solid #707070;
        border-radius: 8px;
        height: 32px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &:focus-within {
          border: 1px solid #fecb2e;
        }

        .input-icon {
          opacity: 1;
          color: #707070;
          padding-right: 3px;
        }

        input {
          padding-left: 16px;
          width: 100%;
          background-color: transparent;
        }

        ::placeholder {
          color: #5f5f5f;
          letter-spacing: 0.24px;
          font-size: $font-medium;
        }
      }

      .toggle-wrapper {
        height: 32px;
        display: flex;
        width: fit-content;

        .status-btn {
          color: #707070;
          display: flex;
          font-size: 13px;
          padding: 0 1rem;
          background-color: white;
          line-height: 32px;
          border-radius: 6px;
          border: 1px solid #707070;
          z-index: 1;
          cursor: pointer;
        }

        .btn-overlap {
          margin-right: -8px;
        }

        .selected-btn {
          background-color: #0a287c;
          color: white;
          z-index: 2;
        }
      }

      .input-error {
        color: red;
        font-size: 13px;
        display: block;
        padding-left: 5px;
      }
    }
  }

  .finish-btn-wrapper {
    width: 88%;
    display: flex;
    padding-left: 6%;
    justify-content: flex-end;

    .finish-btn {
      height: 32px;
      color: #0a287c;
      font-size: 12px;
      letter-spacing: -0.24;
      margin-top: 20px;
      cursor: pointer;
      font-weight: 600;
      border-radius: 8px;
      align-self: center;
      margin-bottom: 20px;
      padding-inline: 1rem;
      background-color: #fecb2e;
      border: 1px solid #fecb2e;
      font-family: OpenSans, sans-serif;
    }

    .disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

app-work-plan-dialog {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    height: 30px;
    display: flex;
    border-width: 0;
    align-items: center;
  }

  mat-form-field {
    width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: #00000059;
    padding-left: 20px;
  }

  .red {
    color: red;
  }
}

html[dir='rtl'] {
  .overlay {
    .create-work-plan-wrapper {
      img.close-icon {
        left: calc(16px);
      }

      .form-container {
        .header-title {
          .header-icon {
          }
        }

        .form-row {
          .title {
          }

          .input-wrapper {
            input {
              padding-left: 0;
              padding-right: 8px;
            }

            ::placeholder {
            }
          }

          .toggle-wrapper {
            .status-btn {
              cursor: pointer;
            }

            .selected-btn {
            }

            .btn-overlap {
              margin-right: 0;
              margin-left: -8px;
            }
          }
        }
      }

      .finish-btn-wrapper {
        .finish-btn {
        }
      }
    }
  }
}
