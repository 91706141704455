.main-table {
  width: 100%;

  .table-container {
    width: 100%;
  }

  .main-wrapper {
    min-width: 270px;
  }

  .mat-row {
    min-height: 56px !important;
  }

  .icon-download {
    padding: 5px;
    background-color: #fecb2e;
    border-radius: 100px;
  }

  .mat-cell {
    border-bottom: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
    cursor: pointer !important;
    padding: 0 !important;
  }

  .mat-cell:hover {
    background-color: lightblue !important;
  }

  .row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .img-pdf-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 300px;

      .img {
        padding: 0 10px 0 0;
        width: 26px;
      }

      .pdf-name {
        text-align: left;
        font: normal normal normal 15px/20px OpenSans;
        letter-spacing: 0.06px;
        color: #171725;
        opacity: 1;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
