.main-toolbar {
  height: 100%;
  width: 100vw;
  box-shadow: 15px 0px 15px rgb(10 40 124 / 20%);
  display: flex;
  flex-direction: row;
  align-items: center;
  //transition-duration: 1000ms;

  .img-wrappper {
    min-width: 264px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      cursor: pointer;
      height: 40px;
      width: auto;
      margin: 10px 30px;
    }
  }

  .img-wrappper-mobile {
    display: none;
  }
  .search-bar-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-left: 0;

    .search-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;

      &__hidden {
        visibility: hidden;
      }

      .search-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid #262460;
        border-radius: 4px;

        .icon {
          width: 36px;
          text-align: inherit;
          letter-spacing: 0px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .search-input {
          min-width: 550px;
          flex: 1;
          text-align: inherit;
          letter-spacing: 0px;
          color: #809fb8;
          font-size: $font-large;
          font-family: $font-site;
        }

        .search-input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #809fb8;
        }

        .search-input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #809fb8;
          opacity: 1;
        }

        .search-input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #809fb8;
          opacity: 1;
        }

        .search-input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #809fb8;
        }

        .find-button {
          cursor: pointer;
          // padding: 5px 20px;
          background: #262460 0% 0% no-repeat padding-box;
          border-radius: 5px;
          text-align: left;
          letter-spacing: 0px;
          color: #fefefe;
          font-size: $font-large;
          min-height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0.25rem;
        }
      }

      .advanced-filter-button {
        height: 48px;
        min-width: 124px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .icon {
          height: 18px;
          width: 18px;
          color: #809fb8;
        }

        .text {
          font-size: $font-medium;
          color: #809fb8;
        }
      }
    }
  }

  .toolbar-icons {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    gap: 10px;

    .language-icon {
      height: 24px;
      width: 24px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-wrapper {
      width: 24px;
      // border: 2px solid #D9E1E7;
      // border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 1;
}

.back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.16;
}

.notifications-popover {
  border: 1px solid #d9e1e7;
  border-radius: 10px;
  //position: relative;
  //right: 355px;
  margin-top: 3px;
  //direction: ltr;
  position: absolute;
  right: 20px;
}

.languages-popover {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px #16347729;
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  margin-top: 5px;
  overflow: auto;

  .language-option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
    gap: 6px;
    height: 2rem;
    width: 2rem;
    align-items: center;
    padding-bottom: 17px;

    &:hover {
      background-color: #70707038;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .admin {
  }
}

html[dir='rtl'] {
  //desktop
  .main-toolbar {
    .search-bar-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      margin: auto 0;
      margin-left: unset;
      margin-right: 0;
      width: 90%;
    }
  }

  .notifications-popover {
    position: absolute;
    left: 20px;
    right: unset;
  }

  // mobile
  @media (max-width: 776px) {
    .admin {
    }
  }
}

.admin__toolbar__mobile {
  display: none;
}

// mobile
@media (max-width: 776px) {
  .notifications-popover {
    position: absolute;
    transform: translate(-8%, -121%);
  }
  .admin__toolbar__mobile {
    display: block;
    .main-toolbar {
      flex-direction: column;
      .img-wrappper,
      .img-wrappper-mobile,
      .search-bar-wrapper {
        display: none !important;
      }
      .toolbar-icons {
        display: flex;
        padding: 5px;
      }
    }
  }
  .main-toolbar {
    .toolbar-icons {
      display: none;
    }
    .search-bar-wrapper {
      width: 90%;
      margin-right: -3%;
      .search-bar {
        width: 90%;
        .search-box {
          width: 90%;
          .find-button {
            padding: 2px;
            font-size: 16px;
            min-height: 25px;
          }
          .search-input {
            min-width: 30%;
            font-size: 10px;
          }
        }
        .advanced-filter-button {
          display: flex;
          min-width: 25px;
          .text {
            display: none;
          }
        }
      }
    }
    .img-wrappper {
      display: none;
    }
    .img-wrappper-mobile {
      display: flex;
      .logo {
        cursor: pointer;
        height: 25px;
        width: auto;
        margin: 5px 5px;
      }
    }
  }
}
