.grant-main {
  width: 75%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .grant-main-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .grant-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .header-divider {
        .header {
          text-align: initial;
          font-size: $font-large;
          font-family: $font-site;
          letter-spacing: 0.07px;
          color: #171725;
        }

        .divider {
          height: 1px;
          width: 100%;
          background-color: #d3dde8;
        }
      }

      .content {
        text-align: initial;
        font-size: $font-medium;
        font-family: $font-site;
        letter-spacing: 0.06px;
        color: #171725;
        line-break: auto;
        display: flex;
        width: 100%;
        max-width: 670px;
        white-space: pre-line;
      }
    }
  }
}
