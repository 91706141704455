.fund-about {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: $gap-medium;

  .charts {
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
    justify-content: space-between;
    width: 100%;

    .chart-card {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 20px 25px 20px 25px;
      box-shadow: 0px 10px 100px #8aa1cb26;
      border: 1px solid #e9f1ff;
      border-radius: 15px;
      gap: 10px;
      box-sizing: border-box;

      &__header {
        .title {
          font-size: 14px;
          font-weight: bold;
          color: #141414;
        }
      }

      &__content {
        .chart-canvas {
          direction: ltr;
          height: 220px;
        }
      }
    }

    .chart-card-doughnut {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 20px 25px 20px 25px;
      box-shadow: 0px 10px 100px rgba(138, 161, 203, 0.1490196078);
      border: 1px solid #e9f1ff;
      border-radius: 15px;
      gap: 10px;
      align-items: center;
      box-sizing: border-box;

      &__header {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
          font-size: 14px;
          font-weight: bold;
          color: #141414;
        }
      }

      &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .chart-canvas {
          min-height: 220px;
        }
      }
    }

    .chart-card-fullwidth {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 20px 25px 20px 25px;
      box-shadow: 0px 10px 100px #8aa1cb26;
      border: 1px solid #e9f1ff;
      border-radius: 15px;
      gap: 10px;
      box-sizing: border-box;

      &__header {
        .title {
          font-size: 14px;
          font-weight: bold;
          color: #141414;
        }
      }

      &__content {
        .chart-canvas {
          height: 220px;
        }
      }
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .text-box {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .title {
        text-align: initial;
        font: normal normal 600 18px/36px;
        letter-spacing: 0.07px;
        color: #171725;
        font-family: $font-site;
        font-weight: 600;
        background: linear-gradient(to left, #fff7df, transparent);
        padding: 5px 7px;
      }

      .divider {
        height: 1px;
        background-color: #dddddd;
        width: 100%;
      }

      .description {
        text-align: initial;
        font: normal normal normal 15px/20px;
        letter-spacing: 0.06px;
        color: #171725;
        font-family: $font-site;
        white-space: pre-line;
      }
    }
  }
}

.about-columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 40px;
}

.about-text {
  width: 66%;
}

.about-charts {
  width: 34%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

// for rtl styles
html[dir='ltr'] {
}

// tablet
@media (max-width: 1400px) {
  .about-text {
    width: 100%;
  }
  .about-charts {
    width: 100%;
  }
  .fund-about {
    .charts {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .chart-card {
        width: 100%;
        min-width: 250px;
      }

      .chart-card-doughnut {
        min-width: 250px;
      }
    }
  }
}

// mobile
@media (max-width: 776px) {
  .about-columns {
    flex-direction: column-reverse;
  }
}
