@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: unset;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px unset;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px unset;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px unset;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px unset;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px unset;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px unset;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px unset;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px unset;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px unset;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px unset;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px unset;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px unset;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px unset;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px unset;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: unset;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: unset;
}

.mat-card {
  font-family: unset;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: unset;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: unset;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: unset;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px unset;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: unset;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px unset;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: unset;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: unset;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: unset;
  font-size: 12px;
}

.mat-radio-button {
  font-family: unset;
}

.mat-select {
  font-family: unset;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: unset;
}

.mat-slider-thumb-label-text {
  font-family: unset;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: unset;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: unset;
}

.mat-tab-label, .mat-tab-link {
  font-family: unset;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px unset;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: unset;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: unset;
}

.mat-list-option {
  font-family: unset;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: unset;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: unset;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: unset;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px unset;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: unset;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: unset;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #cbd0e9;
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

/*-------------- fonts -----------------*/
/* Heebo */
@font-face {
  font-family: "Heebo";
  font-display: swap;
  src: local("Heebo"), local("Heebo-VariableFont"), url("../../fonts/Heebo/Heebo-VariableFont.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans";
  font-display: swap;
  src: local("OpenSans"), local("OpenSans-VariableFont_wdth,wght"), url("../../fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-display: swap;
  src: local("Inter"), local("Inter-VariableFont_slnt,wght"), url("../../fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  font-display: swap;
  src: local("Inter-SemiBold"), local("Inter-Semi-Bold"), url("../../fonts/Inter/Inter-Semi-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Mulish";
  font-display: swap;
  src: local("Mulish"), local("Mulish-VariableFont_wght"), url("../../fonts/Mulish/Mulish-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"), local("Poppins-Regular"), url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova";
  font-display: swap;
  src: local("ProximaNova"), local("Proxima-Nova-Font"), url("../../fonts/ProximaNova/Proxima-Nova-Font.otf") format("truetype");
}
@font-face {
  font-family: "Helvetica Neue";
  font-display: swap;
  src: local("Helvetica Neue"), local("Helvetica-Neue-Font"), url("../../fonts/HelveticaNeue/Helvetica-Neue-Font.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito";
  font-display: swap;
  src: local("Nunito"), local("Nunito-VariableFont_wght"), url("../../fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
}
:root {
  --color-score-high: #00aa69;
  --color-score-medium: #fecb2f;
  --color-score-low: #ed4059;
  --color-foundation: #3abec1;
  --color-business: #9d6dbc;
  --color-rfp: #f4a671;
  --color-workplan: #0a287c;
  --color-submission: #568bd8;
  --color-pending: #fecb2f;
  --color-approved: #00aa69;
  --color-closed: #eee;
  --color-blue-atlas: #0a287c;
  --color-yellow-atlas: #fecb2f;
}

.outline-none {
  outline: none !important;
}

:root {
  --color-score-high: #00aa69;
  --color-score-medium: #fecb2f;
  --color-score-low: #ed4059;
  --color-foundation: #3abec1;
  --color-business: #9d6dbc;
  --color-rfp: #f4a671;
  --color-workplan: #0a287c;
  --color-submission: #568bd8;
  --color-pending: #fecb2f;
  --color-approved: #00aa69;
  --color-closed: #eee;
  --color-blue-atlas: #0a287c;
  --color-yellow-atlas: #fecb2f;
}

html {
  height: 100dvh;
  direction: ltr;
  font-family: "Open Sans";
}

html[dir=ltr] .mobile-navbar-toggle {
  left: unset;
  right: 0;
}

html[dir=rtl] {
  direction: rtl;
}
html[dir=rtl] .mobile-navbar-toggle {
  right: unset;
  left: 0;
}
html[dir=rtl] .custom-popover-search-bar {
  direction: rtl;
}
html[dir=rtl] .account-settings-overlay-panel-class {
  direction: rtl;
}
html[dir=rtl] .cdk-global-overlay-wrapper {
  direction: rtl;
}

body {
  height: 100%;
  margin: 0;
}

.pac-container {
  z-index: 10000;
}

input,
textarea,
button,
select {
  border: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
input[type=checkbox]:checked:before {
  width: calc(4px);
  height: calc(9.3333333333px);
  margin: 0 calc(4px);
  border-bottom: calc(2px) solid #ffffff;
  border-right: calc(2px) solid #ffffff;
  transform: rotate(45deg);
}

.global-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(10, 40, 124, 0.33);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

iframe {
  width: 100% !important;
  height: 100% !important;
  border: none;
}

::-webkit-scrollbar {
  width: 0;
}

.google-chart path {
  stroke-width: 0.5; /* control the countries borders width */
  stroke: #ffffff; /* choose a color for the border */
}

.custom-popover-search-bar {
  direction: ltr;
}

.account-settings-overlay-panel-class {
  direction: ltr;
}

.cdk-global-overlay-wrapper {
  direction: ltr;
}

@media (max-width: 776px) {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
.prevent-copy {
  -webkit-touch-callout: none; /* Disable iOS touch events */
  -webkit-user-select: none; /* Disable text selection */
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 776px) {
  .custom-popover-search-bar {
    overflow-y: scroll;
  }
}
.custom-select {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.custom-select .select-button {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3490196078);
}
.custom-select .select-button .select-input {
  width: 100%;
  color: black;
  font-size: 14px;
  padding: 0;
}
.custom-select .select-button ::placeholder {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.custom-select .select-button .text {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: auto;
  height: 18px;
  width: 290px;
}
.custom-select .select-button .icon {
  color: #0a287c;
}
.custom-select .selected-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.custom-select .selected-options .selected-chip {
  cursor: pointer;
  padding: 6px 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ecebfb;
  border-radius: 16px;
}
.custom-select .selected-options .selected-chip .text {
  font-size: 12px;
  line-height: 12px;
  color: #3a36db;
  transition: all 400ms;
}
.custom-select .selected-options .selected-chip .close {
  display: none;
  cursor: pointer;
  height: 12px;
  width: 12px;
  font-size: 12px;
  color: #3a36db;
  transition: all 400ms;
}
.custom-select .selected-options .selected-chip:hover {
  padding: 6px 12px;
}
.custom-select .selected-options .selected-chip:hover .text {
  font-size: 12px;
  line-height: 12px;
  color: #c6c4f5;
  margin-left: -6px;
  margin-right: 6px;
  transition: all 400ms;
}
.custom-select .selected-options .selected-chip:hover .close {
  display: block;
}
.custom-select .selected-options .selected-chip:hover .close:hover {
  color: #c6c4f5;
  transition: all 400ms;
}
.custom-select .select-error {
  color: red;
  font-size: 12px;
  line-height: 12px;
}

.custom-select-panel {
  width: 100%;
  max-height: 250px;
  margin-top: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3490196078);
}
.custom-select-panel::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}
.custom-select-panel::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 3px;
}
.custom-select-panel::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.custom-select-panel .select-option-wrapper {
  display: flex;
  flex-direction: column;
}
.custom-select-panel .select-option-wrapper .select-option {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: rgba(0, 0, 0, 0.3490196078);
  gap: 10px;
}
.custom-select-panel .select-option-wrapper .select-option input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.custom-select-panel .select-option-wrapper .select-option input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.custom-select-panel .select-option-wrapper .select-option input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.custom-select-panel .select-option-wrapper .select-option input[type=checkbox]:checked:before {
  width: calc(5.1428571429px);
  height: calc(12px);
  margin: 0 calc(5.1428571429px);
  border-bottom: calc(2.5714285714px) solid #ffffff;
  border-right: calc(2.5714285714px) solid #ffffff;
  transform: rotate(45deg);
}
.custom-select-panel .select-option-wrapper .select-option.focus {
  background-color: #d9e1e7;
  color: rgba(0, 0, 0, 0.3490196078);
}
.custom-select-panel .select-option-wrapper .select-option:hover {
  background-color: #d9e1e7;
  color: rgba(0, 0, 0, 0.3490196078);
}
.custom-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3490196078);
}

html[dir=rtl] .custom-select .selected-options .selected-chip:hover .text {
  margin-left: 6px;
  margin-right: -6px;
}

.expandable {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.expandable__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.expandable__header .expand-icon {
  margin-left: auto;
  color: black;
}
.expandable__content {
  overflow: hidden;
}

.spa-expandable {
  height: 1px;
  width: 100%;
  background-color: #d3dde8;
}

.favourites-main-wrapper .favourites-main {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.favourites-main-wrapper .favourites-main .favourites-main-header {
  text-align: left;
  font: normal normal medium 20px/36px "OpenSans";
  font-family: "OpenSans", sans-serif;
  letter-spacing: 0.08px;
  color: #171725;
  min-height: 20px;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort {
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  min-height: 150px;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .search {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 8px;
  min-width: 343px;
  min-height: 28px;
  color: #99b2c6;
  height: 28px;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .search .search-input {
  cursor: pointer;
  text-align: initial;
  letter-spacing: 0px;
  color: #809fb8;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .select-manu {
  font-size: 14px;
  letter-spacing: 0.08px;
  color: #92929d;
  display: flex;
  align-items: center;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .select-manu .mat-select {
  display: inline-block;
  width: fit-content;
  outline: none;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .select-manu .mat-select-value-text {
  margin-right: 0.5vh;
  font-weight: 600;
  padding-left: 0.5vh;
  color: #44444f;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-section {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-section .sort-icon {
  height: 24px;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-section .sort-icon .icon {
  display: flex;
  margin: 0;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-section .sort-selected-text {
  font-size: 13px;
  cursor: pointer;
  color: black;
  padding: 5px;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-section .icon-wrapper {
  height: 24px;
  width: 24px;
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-popover {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px rgba(22, 52, 119, 0.1607843137);
  border: 2px solid #d9e1e7;
  border-radius: 10px;
}
.favourites-main-wrapper .favourites-main .favourites-search-sort .sort-popover .popover-text {
  font-size: 12px;
  cursor: pointer;
  padding: 10px;
  color: black;
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
}
.favourites-main-wrapper .favourites-main .favourites-table {
  width: 96%;
  position: relative;
  min-height: 200px;
  max-height: 100%;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container {
  width: 100%;
  box-shadow: none;
  min-width: 800px;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container .white {
  background-color: white !important;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container .mat-row .mat-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  text-align: initial;
  font: normal normal medium 12px/36px Roboto;
  letter-spacing: 0.05px;
  color: #000000;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container th.mat-column-deadline,
.favourites-main-wrapper .favourites-main .favourites-table .table-container th.mat-column-status {
  text-align: initial;
  align-content: center;
  min-width: 90px;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container .mat-column-Preview {
  cursor: pointer;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container th.mat-header-cell:first-of-type,
.favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-cell:first-of-type,
.favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-footer-cell:first-of-type {
  padding-left: 8px;
  width: 15%;
  text-align: initial;
  font: normal normal medium 12px/14px Roboto;
  letter-spacing: 0px;
  color: #809fb8;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-cell.mat-column-Preview {
  color: #809fb8;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-cell:last-of-type,
.favourites-main-wrapper .favourites-main .favourites-table .table-container th.mat-header-cell:last-of-type,
.favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-footer-cell:last-of-type {
  width: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  font: normal normal medium 12px/36px Roboto;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.05px;
  color: #809fb8;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container .action-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container .mat-row:nth-child(odd) {
  background-color: #f0f3fa;
}
.favourites-main-wrapper .favourites-main .favourites-table .table-container th.mat-header-cell:not(.mat-column-action, .mat-column-moreOptions) {
  font-size: 14px;
  font-family: OpenSans, sans-serif;
  color: #8a8c98;
  font-weight: 100;
  text-align: initial;
}

html[dir=rtl] .favourites-main-wrapper .favourites-main .favourites-table .table-container th.mat-header-cell:first-of-type,
html[dir=rtl] .favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-cell:first-of-type,
html[dir=rtl] .favourites-main-wrapper .favourites-main .favourites-table .table-container td.mat-footer-cell:first-of-type {
  padding-left: unset;
  padding-right: 8px;
}
html[dir=rtl] .favourites-main-wrapper .favourites-main .favourites-table .table-container .action-icon {
  margin-right: unset;
  margin-left: 0.5rem;
}

@media (max-width: 776px) {
  .favourites-main-wrapper .favourites-main .favourites-search-sort {
    min-height: unset;
    flex-direction: column;
    width: unset;
  }
}
.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  font-family: OpenSans, sans-serif;
}
.login__welcome {
  height: 100%;
  max-height: 100%;
  width: 60%;
  position: relative;
  font-size: 32px;
  display: flex;
  flex-wrap: wrap;
}
.login__welcome .theme-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
  opacity: 1;
  display: flex;
}
.login__welcome__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.login__welcome__content .welcome-image {
  height: 190px;
  width: 190px;
  margin-left: 50px;
  margin-top: 50px;
  position: relative;
  display: flex;
}
.login__welcome__content .welcome-text-headline {
  text-align: left;
  font: normal normal bold 67px/79px OpenSans;
  font-family: OpenSans, sans-serif;
  letter-spacing: 1.05px;
  color: #ffffff;
  opacity: 1;
  width: 40%;
  display: flex;
  position: relative;
  margin-left: 160px;
  margin-top: -10px;
}
.login__welcome__content .welcome-text-sub-headline {
  text-align: left;
  font: normal normal 500 33px/39px OpenSans;
  font-family: OpenSans, sans-serif;
  letter-spacing: 0.52px;
  color: white;
  opacity: 1;
  display: flex;
  width: 40%;
  position: relative;
  margin-top: 20px;
  margin-left: 160px;
}
.login__form {
  height: 100%;
  max-height: 100%;
  width: 40%;
  display: flex;
  max-width: 40%;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
}
.login__form__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  box-sizing: border-box;
  margin: 0 auto;
  justify-content: center;
}
.login__form__content .title {
  width: 100%;
  margin: 10vh 0 6vh 0;
  color: #000000;
  font: normal normal 600 32px/39px OpenSans;
  text-align: center;
}
.login__form__content .join-button-wrapper {
  height: 42px;
  width: 100%;
  margin-bottom: 8%;
  display: flex;
  justify-content: center;
}
.login__form__content .join-button-wrapper .join-button {
  width: 100%;
  display: flex;
  cursor: pointer;
  line-height: 14px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  flex-wrap: nowrap;
  color: #090e3a;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #c9c9c9;
  text-decoration: solid;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: space-evenly;
}
.login__form__content .join-button-wrapper .join-button .join-button-content {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
.login__form__content .join-button-wrapper .join-button .join-button-content .logo {
  height: 32px;
  width: auto;
  margin: 10px auto;
}
.login__form__content .sub-title-user-login {
  width: 100%;
  margin-bottom: 10%;
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
}
.login__form__content .sub-title-user-login .span {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}
.login__form__content .sub-title-user-login .span:before,
.login__form__content .sub-title-user-login .span:after {
  content: "";
  height: 2px;
  flex-grow: 1;
  margin: 0 12px;
  background: #333;
}
.login__form__content .sub-title-user-login .span:before {
  background: -webkit-gradient(linear, left top, right top, from(#eeeeee), color-stop(#eeeeee), to(#eeeeee));
  background: linear-gradient(to right, #eeeeee, #eeeeee);
}
.login__form__content .sub-title-user-login .span:after {
  background: -webkit-gradient(linear, left top, right top, from(#eeeeee), color-stop(#eeeeee), to(#eeeeee));
  background: linear-gradient(to right, #eeeeee, #eeeeee);
}
.login__form__content .sep-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
}
.login__form__content .sep-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1019607843);
  position: absolute;
  top: 50%;
}
.login__form__content .sep-wrapper .sep-content {
  font-size: 15px;
  font-weight: 300;
  background-color: white;
  padding-inline: 8px;
  text-align: center;
  display: flex;
  z-index: 2;
}
.login__form__content .login-input-wrapper {
  width: 100%;
  margin: 10px;
}
.login__form__content .login-input-wrapper .login-input-container {
  height: 42px;
  border-radius: 5px;
  margin: 8px 0 1px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  direction: ltr;
  border: 1px solid #c3c3c3;
}
.login__form__content .login-input-wrapper .login-input-container .icon {
  color: #999898;
  overflow: hidden;
}
.login__form__content .login-input-wrapper .login-input-container .visibility-icon {
  cursor: pointer;
}
.login__form__content .login-input-wrapper .login-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.login__form__content .login-input-wrapper .login-input-container .input {
  width: calc(100% - 24px);
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
}
.login__form__content .login-input-wrapper .login-input-container input:-webkit-autofill,
.login__form__content .login-input-wrapper .login-input-container input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.login__form__content .login-input-wrapper .login-input-container .input::placeholder {
  color: #9a9a9a;
}
.login__form__content .login-input-wrapper .login-input-error {
  color: red;
  font-size: 16px;
  display: block;
  text-align: center;
}
.login__form__content .login-input-wrapper .forgot-password-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login__form__content .login-input-wrapper .forgot-password-wrapper .forgot-password {
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  text-align: right;
  text-decoration: underline;
  color: #0266d6;
  display: block;
}
.login__form__content .login-loading {
  margin: 42px auto;
  padding-bottom: 0;
}
.login__form__content .dont-have-account-wrapper {
  width: 100%;
  font-weight: 400;
  margin: 10px;
  text-align: center;
}
.login__form__content .have-old-account-wrapper {
  width: 100%;
  font-weight: 400;
  margin: 10px;
  text-align: center;
  color: #1270d9;
  text-decoration: underline;
  cursor: pointer;
}
.login__form__content .login-button {
  height: 48px;
  min-height: 48px;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 6%;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background-color: #fecb2f;
  border: 3px solid #fecb2f;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: bold;
}
.login__form__content .language-select {
  margin: 10px;
}
.login__form__content .lang-select-input {
  margin: 1rem;
}
.login__form__content .lang-select-input mat-select {
  width: fit-content;
}
.login__form__content .lang-select-input .fi {
  padding-left: 20px;
  height: 18px;
  width: 18px;
}
.login__form__content .lang-select-input .country-placeholder {
  padding-left: 60px;
}
.login__form__content .lang-select-input .country-flag-icon {
  margin-left: 10px;
  width: 35px;
}
.login__form__content .lang-select-input .lang-arrow-down {
  padding-right: 10px;
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.login__form__content .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: initial;
}
.login__form .spacer {
  flex: 1 1 auto;
}
.login__form .bottom-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: 5%;
  font-size: 13px;
  box-sizing: border-box;
  font-weight: 400;
  margin: auto auto;
}
.login__form .bottom-wrapper .rout-link {
  color: #1270d9;
}
.login__form .bottom-wrapper .atlas-line {
  padding-right: 4%;
  color: #b1b1b1;
}
.login a:-webkit-any-link {
  color: #1270d9;
  text-decoration: underline;
  cursor: pointer;
}

app-login .mat-form-field-underline {
  display: none;
}
app-login .mat-select-arrow-wrapper {
  display: none !important;
}
app-login .mat-form-field-infix {
  padding: 0 !important;
  line-height: 18px !important;
  display: flex;
  border-width: 0;
  align-items: center;
  width: fit-content;
}
app-login mat-form-field {
  overflow: auto;
}
app-login .mat-select-value-text {
  color: black;
}

html[dir=rtl] .login__welcome__content .welcome-image {
  margin-right: 50px;
  margin-left: 0;
  transform: scaleX(-1);
}
html[dir=rtl] .login__welcome__content .welcome-text-headline {
  margin-right: 145px;
  width: 50%;
  text-align: right;
}
html[dir=rtl] .login__welcome__content .welcome-text-sub-headline {
  margin-right: 145px;
  margin-left: 0;
  text-align: right;
  width: 39%;
}
html[dir=rtl] .login__form__content .login-input-wrapper .forgot-password-wrapper .forgot-password {
  text-align: left;
}
html[dir=rtl] .login__form__content .login-input-wrapper .login-input-container {
  direction: rtl;
}
html[dir=rtl] .login__form__content .login-input-wrapper .login-input-container .input {
  direction: rtl;
}

@media (max-width: 776px) {
  .login {
    flex-direction: column;
  }
  .login__welcome {
    height: 50%;
    width: 100%;
  }
  .login__welcome__content .welcome-text-headline {
    margin-left: 110px;
    margin-top: -30px;
    font: normal normal bold 30px/31px OpenSans;
  }
  .login__welcome__content .welcome-text-sub-headline {
    margin-top: 20px;
    margin-left: 110px;
    font: normal normal 500 16px/21px OpenSans;
  }
  .login__welcome__content .welcome-image {
    height: 45px;
    width: 45px;
  }
  .login__form {
    width: 100%;
    max-width: 100%;
  }
  .login__form__content .title {
    margin: 3vh 0 6vh 0;
  }
}
.signup-email-verification {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
}
.signup-email-verification__card {
  width: 400px;
  padding: 40px 60px;
  max-height: calc(80vh - 80px);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #707070;
  overflow: scroll;
}
.signup-email-verification__card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.signup-email-verification__card .content .title {
  text-align: center;
  font: 26px Arial;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #0a287c;
}
.signup-email-verification__card .content .subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 8%;
  text-align: center;
  font: normal normal normal 2vh Heebo;
  font-size: 14px;
  color: #636c84;
}
.signup-email-verification__card .content .image {
  width: auto;
  height: 150px;
}
.signup-email-verification__card .content .expiration {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.signup-email-verification__card .content .expiration .text {
  font-size: 12px;
}
.signup-email-verification__card .content .expiration .timer {
  font-size: 12px;
  color: #fecb2e;
}
.signup-email-verification__card .content .resend-mail {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.signup-email-verification__card .content .resend-mail .text {
  font-size: 12px;
}
.signup-email-verification__card .content .resend-mail .link {
  cursor: pointer;
  font-size: 12px;
  color: #fecb2e;
}
.signup-email-verification__card .content .verify-button {
  width: 100%;
  padding: 10px 0;
  color: #0a287c;
  margin-top: 6%;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
  background-color: #fecb2f;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-email-verification__card .content .verify-loading {
  margin: 4vh;
}

@media (max-width: 776px) {
  .signup-email-verification__card {
    width: unset;
    margin: 10px;
  }
}
.create-organization {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
}
.create-organization__card {
  width: 650px;
  padding: 80px 40px;
  max-height: calc(80vh - 60px);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #707070;
}
.create-organization__card .sign-out {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: #a7a7a7;
  font: normal normal bold 16px Lato;
  padding: 20px;
}
.create-organization__card .sign-out .go-back {
  color: #707070;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding-left: 15px;
  position: relative;
}
.create-organization__card .sign-out .go-back::before,
.create-organization__card .sign-out .go-back::after {
  content: "";
  display: block;
  left: 0px;
  position: absolute;
  top: 50%;
}
.create-organization__card .sign-out .go-back::before {
  border-color: #707070;
  border-style: solid;
  border-width: 0 0 2px 2px;
  height: 6px;
  margin-top: -4px;
  width: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.create-organization__card .sign-out .go-back::after {
  background: #707070;
  height: 2px;
  width: 18px;
  margin-top: -1px;
}
.create-organization__card .logo-box {
  top: 34px;
  left: 34px;
  width: 110px;
  height: 110px;
  border-radius: 8px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(112, 112, 112, 0.1843137255);
  box-shadow: 0 13px 20px rgba(0, 0, 0, 0.0784313725);
}
.create-organization__card .logo-box .logo {
  width: 60px;
  height: auto;
}
.create-organization__card .organization-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.create-organization__card .organization-form .title {
  width: 100%;
  color: #1c2d57;
  font: bold 25px arial;
  text-align: start;
  margin-bottom: 20px;
}
.create-organization__card .organization-form .form-control {
  width: 100%;
}
.create-organization__card .organization-form .next-button {
  width: 100%;
  min-width: 200px;
  margin-top: 20px;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-organization__card .organization-form .next-button__disabled {
  background: #dfdfdf;
}

.create-organization-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.create-organization-input .input-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: black;
}
.create-organization-input .input-label .error-dot {
  color: red;
}
.create-organization-input .input-box {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3490196078);
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.create-organization-input .input-box__input {
  width: 100%;
  height: 24px;
  padding: 0;
}
.create-organization-input .input-error {
  color: red;
  font-size: 12px;
  line-height: 12px;
}

.create-organization-select {
  width: 100%;
}
.create-organization-select .select-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.create-organization-select .select-wrapper .select-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 14px;
  line-height: 14px;
  color: black;
}
.create-organization-select .select-wrapper .select-label .error-dot {
  color: red;
}
.create-organization-select .select-wrapper .select-button {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.create-organization-select .select-wrapper .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.create-organization-select .select-wrapper .select-button .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.create-organization-select .select-wrapper .select-button .country__icon {
  font-size: 14px;
}
.create-organization-select .select-wrapper .select-button .country__text {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.create-organization-select .select-wrapper .select-button .icon {
  color: #0a287c;
}
.create-organization-select .select-wrapper .select-selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.create-organization-select .select-wrapper .select-selected .selected-chip {
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background: #ecebfb;
  border-radius: 16px;
}
.create-organization-select .select-wrapper .select-selected .selected-chip .text {
  font-size: 12px;
  color: #3a36db;
}
.create-organization-select .select-wrapper .select-selected .selected-chip .text:hover {
  color: #c6c4f5;
}
.create-organization-select .select-wrapper .select-selected .selected-chip .close {
  display: none;
  cursor: pointer;
  height: 12px;
  width: 12px;
  font-size: 12px;
  color: #3a36db;
}
.create-organization-select .select-wrapper .select-selected .selected-chip:hover .close {
  display: block;
}
.create-organization-select .select-wrapper .select-error {
  color: red;
  font-size: 12px;
  line-height: 12px;
}

.create-organization-select-panel {
  width: 100%;
  max-height: 250px;
  margin-top: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.create-organization-select-panel .select-option-wrapper {
  display: flex;
  flex-direction: column;
}
.create-organization-select-panel .select-option-wrapper .select-option {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  font-size: 14px;
}
.create-organization-select-panel .select-option-wrapper .select-option .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.create-organization-select-panel .select-option-wrapper .select-option .country__icon {
  font-size: 14px;
}
.create-organization-select-panel .select-option-wrapper .select-option .country__text {
  color: black;
  font-size: 14px;
}
.create-organization-select-panel .select-option-wrapper .select-option input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.create-organization-select-panel .select-option-wrapper .select-option input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.create-organization-select-panel .select-option-wrapper .select-option input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.create-organization-select-panel .select-option-wrapper .select-option input[type=checkbox]:checked:before {
  width: calc(5.1428571429px);
  height: calc(12px);
  margin: 0 calc(5.1428571429px);
  border-bottom: calc(2.5714285714px) solid #ffffff;
  border-right: calc(2.5714285714px) solid #ffffff;
  transform: rotate(45deg);
}
.create-organization-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.create-organization-select-panel .select-option-wrapper .select-option.focus {
  background-color: #d9e1e7;
  color: #809fb8;
}
.create-organization-select-panel .select-option-wrapper .select-option:hover {
  background-color: #d9e1e7;
  color: #809fb8;
}
.create-organization-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 2px;
  background-color: #d9e1e7;
}

.organization {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
}
.organization .container {
  width: clamp(650px, 50%, 100%);
  display: flex;
  position: relative;
  border-radius: 20px;
  justify-content: center;
  background-color: white;
  border: 1px solid #707070;
}
.organization .container .title-organization {
  /*height: 46px;
  width: 100%;
  text-align: left;
  color: #1C2D57;
  font: bold 25px arial;
  margin-top: 27px;
  padding-bottom: 50px;*/
  color: #1c2d57;
  font: bold 25px arial;
  padding-bottom: 45px;
}
.organization .container .box-shadow {
  top: 0;
  left: 0;
  width: 110px;
  height: 110px;
  display: flex;
  margin: 34px 0 0 34px;
  position: absolute;
  border-radius: 3vh;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(112, 112, 112, 0.1843137255);
  box-shadow: 0 13px 20px rgba(0, 0, 0, 0.0784313725);
}
.organization .container .box-shadow .corner-sunshine-icon {
  width: 60%;
}
.organization .organization-form {
  z-index: 1;
  margin: 11% 0 8% 0;
  width: 50%;
}
.organization__form {
  width: 50%;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.organization__form__content {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.organization__form__content__cotent-coponnent {
  width: 50%;
  padding-bottom: 12%;
}
.organization__form__content .name-surname-wrapper {
  display: flex;
}
.organization__form__content .name-surname-wrapper .input-wrapper .input-headline {
  margin-top: 12px;
  margin-left: 12px;
}
.organization__form__content .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.organization__form__content .input-wrapper .organization-select-input {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  margin-top: 8px;
}
.organization__form__content .input-wrapper .organization-select-input .fi {
  padding-left: 20px;
  height: 25px;
  width: 40px;
}
.organization__form__content .input-wrapper .organization-select-input .country-placeholder {
  padding-left: 60px;
}
.organization__form__content .input-wrapper .organization-select-input .country-flag-icon {
  margin-left: 10px;
  width: 35px;
}
.organization__form__content .input-wrapper .organization-select-input .organization-arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.organization__form__content .input-wrapper .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.organization__form__content .input-wrapper .input_title {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.organization__form__content .input-wrapper .input-headline {
  font: bold 14px arial;
}
.organization__form__content .input-wrapper .organization-input-container {
  height: 42px;
  display: flex;
  margin: 8px 0 0 0;
  border-radius: 8px;
  align-items: center;
  padding: 0 15px 0 12px;
  border: 1px solid #e3e3e3;
  justify-content: flex-start;
  background: #ffffff 0 0 no-repeat padding-box;
}
.organization__form__content .input-wrapper .organization-input-container .icon {
  margin: auto 10px;
  color: #1667c7;
}
.organization__form__content .input-wrapper .organization-input-container .visibility-icon {
  cursor: pointer;
}
.organization__form__content .input-wrapper .organization-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.organization__form__content .input-wrapper .organization-input-container .input {
  font-size: 13px;
  width: 100%;
  background-color: transparent;
}
.organization__form__content .input-wrapper .organization-input-container .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.organization__form__content .input-wrapper .organization-input-container .input::placeholder {
  color: #9a9a9a;
}
.organization__form__content .checkbox-wrapper {
  display: flex;
  margin-top: 2%;
  margin-bottom: 3%;
  width: 94%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.organization__form__content .checkbox-wrapper .input-headline {
  margin-left: 12px;
}
.organization__form__content .checkbox-wrapper .organization-input-container {
  width: 415px;
  height: 40px;
  border-radius: 8px;
  margin: 0 10px 10px 10px;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #e3e3e3;
  background: #ffffff 0 0 no-repeat padding-box;
}
.organization__form__content .checkbox-wrapper .organization-input-container .icon {
  margin: auto 10px;
  color: #1667c7;
}
.organization__form__content .checkbox-wrapper .organization-input-container .visibility-icon {
  cursor: pointer;
}
.organization__form__content .checkbox-wrapper .organization-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.organization__form__content .checkbox-wrapper .organization-input-container .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.organization__form__content .checkbox-wrapper .organization-input-container .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.organization__form__content .checkbox-wrapper .organization-input-container .input::placeholder {
  color: #9a9a9a;
}
.organization__form__content .organization-input-error-checkbox {
  color: red;
  font-size: 12px;
  width: 81%;
  margin: 9px 8px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: flex-end;
  margin-top: -2%;
}
.organization__form__content .organization-input-error {
  color: red;
  font-size: 12px;
  line-height: 16px;
}
.organization__form__content .organization-loading {
  margin: 10px auto;
}
.organization__form__content .login-loading {
  margin: 0px auto;
  margin-top: 35px;
  margin-bottom: 0px;
  padding-bottom: 2px;
}
.organization__form__content .organization-button {
  cursor: pointer;
  height: 42px;
  border-radius: 8px;
  width: 100%;
  margin-top: 16px;
  font-weight: bold;
  font-size: 17px;
  color: #0a287c;
  background-color: #fecb31;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
}

app-organization .mat-form-field-underline {
  display: none;
}
app-organization .mat-select-arrow-wrapper {
  display: none !important;
}
app-organization .mat-form-field-infix {
  padding: 0 !important;
  line-height: 30px !important;
  height: 42px;
  display: flex;
  border-width: 0;
  align-items: center;
}
app-organization mat-form-field {
  width: 100%;
  overflow: auto;
}
app-organization .mat-select-value-text {
  color: rgba(0, 0, 0, 0.3490196078);
}

mat-option .organization-country-selection-wrapper {
  display: flex;
  gap: 20px;
}

html[dir=rtl] .organization .container .box-shadow {
  position: absolute;
  top: 0;
  margin: 34px 34px 0 0;
  right: 0;
}
html[dir=rtl] .organization__form__content .input-wrapper .organization-select-input .country-placeholder {
  padding-left: 0;
  padding-right: 60px;
}
html[dir=rtl] .organization__form__content .input-wrapper .organization-select-input .country-flag-icon {
  margin-left: 0;
  padding-right: 0;
  margin-right: 10px;
  padding-left: 10px;
}
html[dir=rtl] .organization__form__content .input-wrapper .organization-select-input .organization-arrow-down {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 776px) {
  .create-organization__card {
    width: unset;
    margin: 10px;
  }
  .create-organization__card .logo-box {
    position: unset;
    width: 50px;
    padding: 10px;
    height: 40px;
  }
  .create-organization__card .logo-box .logo {
    width: 35px;
  }
  .create-organization__card .organization-form {
    width: unset;
  }
}
.payment-container {
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: safe center;
  align-items: safe center;
}
.payment-container .payment-form-container {
  height: 80%;
  width: 50%;
  min-width: 600px;
  background-color: #ffffff;
  border: 1px solid #707070;
  border-radius: 2.3vh;
}
.payment-container .payment-form-container .form-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10% 12%;
}
.payment-container .payment-form-container .form-container .form-class {
  width: 100%;
  height: 100%;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  height: 100%;
  width: 100%;
  margin: auto;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card .title {
  text-align: left;
  font: normal normal normal 18px/21px OpenSans;
  font-family: OpenSans, sans-serif;
  letter-spacing: 0;
  color: #536777;
  opacity: 1;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card .card-element {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #b9b9b9;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card__number {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card__extras {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card__extras .date {
  display: flex;
  flex-direction: column;
  width: 120px;
  gap: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .credit-card__extras .cvc {
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .title-row {
  display: flex;
  flex-direction: column;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .title-row .card-error {
  color: red;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .title-row .payment-title {
  color: #0a287c;
  letter-spacing: 0;
  font-size: 26px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .title-row .sub-title {
  font-size: 18px;
  letter-spacing: 0;
  color: #a5a5a5;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .card-type {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .owner-name-row {
  flex-direction: column;
  display: flex;
  gap: 10px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .owner-name-row .title {
  text-align: left;
  font: normal normal normal 18px/21px OpenSans;
  font-family: OpenSans, sans-serif;
  letter-spacing: 0;
  color: #536777;
  opacity: 1;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .owner-name-row .payment-input {
  color: #144167;
  font-weight: 500;
  font-family: OpenSans, sans-serif;
  font-size: 18px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .t-c-container {
  display: flex;
  align-items: center;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .t-c-container label {
  padding: 0 0 0 1vh;
  font-size: 15px;
  color: #94a5b2;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .t-c-container input[type=checkbox] {
  accent-color: #0a287c;
  height: unset;
  width: unset;
  box-sizing: unset;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .payment-total {
  display: flex;
  align-items: center;
  color: #463e65;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .payment-total .total-label {
  font: normal normal bold 21px/25px Heebo;
  color: #463e65;
  padding-bottom: 0;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .payment-total .total-amount-label {
  font: normal normal 600 21px/25px Heebo;
  letter-spacing: 0;
  color: #0a287c;
  padding-bottom: 0;
  margin-left: 2vh;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.5px 0;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row .back-container .payment-back-icon {
  cursor: pointer;
  height: 14px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row .back-container label {
  cursor: pointer;
  padding: 0 0.35vh;
  color: #a7a7a7;
  font: normal normal bold 18px/20px Heebo;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row .pay-now-btn {
  cursor: pointer;
  gap: 1.5vh;
  width: max(35%, 200px);
  display: flex;
  justify-content: center;
  background: #fecb2e 0 0 no-repeat padding-box;
  font: normal normal bold 21px Heebo;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 1.1vh;
  padding: 0.9vh 0.7vh;
  align-items: center;
  max-width: 208px;
  color: #0a287c;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row .pay-now-btn .credit-icon {
  cursor: pointer;
  width: 34px;
  height: 34px;
  transform: rotate(-15deg);
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bebebe;
  width: 110%;
  margin-top: -61.44px;
  margin-left: -5%;
  padding: 6px 5%;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1411764706);
  animation-name: show;
  animation-duration: 400ms;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion .contact-me-btn {
  width: max(35%, 200px);
  display: flex;
  justify-content: center;
  background: #fecb2e;
  font: normal normal bold 21px Heebo;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 1.1vh;
  padding: 0.9vh 0.7vh;
  align-items: center;
  color: #0a287c;
  max-width: 208px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion .t-c-container {
  display: flex;
  align-items: center;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion .t-c-container label {
  padding: 0 0 0 1vh;
  font-size: 15px;
  color: #94a5b2;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion .t-c-container input[type=checkbox] {
  accent-color: #0a287c;
  height: 17px;
  width: 17px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-card {
  display: flex;
  margin-top: -30px;
  justify-content: safe flex-end;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-card span {
  color: #a7a7a7;
  cursor: pointer;
  max-width: 208px;
  text-align: center;
  width: max(35%, 200px);
  text-decoration: underline;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper input,
.payment-container .payment-form-container .form-container .form-class .form_wrapper select {
  box-sizing: border-box;
  border: 1px solid #b9b9b9;
  border-radius: 7px;
  height: 51px;
  margin: 0;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper label {
  color: #536777;
  padding-bottom: 1.1px;
  font-size: 18px;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper select {
  color: #0a287c;
  padding-left: 5vh;
  background-size: 3vh;
  background-position: 1vh;
  background-repeat: no-repeat;
  font: normal normal 18px/21px Heebo;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper input:focus {
  border: 3px solid rgba(15, 87, 245, 0.24);
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper input.ng-invalid.ng-touched {
  border: 2px solid rgba(255, 0, 0, 0.64);
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper input::placeholder {
  color: #8c98a2;
  font-size: 15px;
  font-family: OpenSans, sans-serif;
}
.payment-container .payment-form-container .form-container .form-class .form_wrapper input[type=text],
.payment-container .payment-form-container .form-container .form-class .form_wrapper input[type=number] {
  color: #144167;
  font-size: 18px;
  font-family: OpenSans, sans-serif;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}

html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .t-c-container label {
  font-size: 15px;
  padding: 0 10px 0 0;
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper .agree-row .payment-total .total-amount-label {
  margin-right: 2vh;
  margin-left: 0;
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row .back-container .payment-back-icon {
  transform: rotate(180deg);
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper .payment-row .back-container label {
  padding: 0 0.35vh;
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion {
  margin-left: 0;
  margin-right: -5%;
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper .no-credit-expansion .t-c-container label {
  padding: 0 1vh 0 0;
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper select {
  padding-right: 5vh;
  padding-left: 0;
  background-position: 96%;
}
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper input[type=text],
html[dir=rtl] .payment-container .payment-form-container .form-container .form-class .form_wrapper input[type=number] {
  padding-right: 20px;
  padding-left: 0;
}

@media (max-width: 776px) {
  .payment-container .payment-form-container {
    height: unset;
    width: unset;
    min-width: unset;
    margin: 10px;
  }
  .payment-container .form-container .form-class .form_wrapper .agree-row {
    flex-direction: column;
  }
  .payment-container .form-container .form-class .form_wrapper .credit-card {
    flex-direction: column !important;
  }
}
.main-plan-wrapper {
  width: 100%;
  height: 100%;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-plan-wrapper input[type=checkbox] {
  display: none;
}
.main-plan-wrapper .blur-wrapper {
  position: fixed;
  top: 10%;
  height: 80%;
  border-radius: 20px;
  z-index: 10000;
  background: white;
  opacity: 0.5;
  width: clamp(900px, 70%, 1200px);
}
.main-plan-wrapper .blur-wrapper-after {
  position: fixed;
  z-index: 100000;
  left: 44.5%;
  top: 43.5%;
  width: 12%;
  height: 14%;
  border-radius: 8%;
  background-color: white;
}
.main-plan-wrapper .blur-wrapper-after .login-loading {
  position: fixed;
  left: 50%;
  top: 50%;
}
.main-plan-wrapper .sub-plan-wrapper {
  height: 95%;
  display: flex;
  overflow: hidden;
  background: white;
  margin-top: -1%;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  width: clamp(900px, 70%, 1200px);
}
.main-plan-wrapper .sub-plan-wrapper .plans-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .ellipse {
  position: absolute;
  width: 140%;
  right: -20%;
  height: 75%;
  top: 34%;
  border-radius: 50% 50% 0 0/100% 100% 0 0;
  background: transparent linear-gradient(180deg, #d8e3fd 0, #ffffff 75%) 0 0 no-repeat padding-box;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .plan-wrapper {
  padding: 0rem 3rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  position: relative;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .plan-wrapper .move-up {
  margin-top: -20px;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .plan-wrapper ul:first-child {
  padding-inline: 0;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .plan-wrapper ul {
  width: 100%;
  height: 95%;
  max-width: 280px;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .plan-wrapper .custom-ul {
  padding-inline-start: 0;
  height: 100%;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper {
  display: flex;
  bottom: 0;
  height: 40%;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-top: 4%;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline .main-header {
  font-size: 26px;
  font-weight: 900;
  color: #0a287c;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline .back-button {
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  font: normal normal normal 19px/21px Heebo;
  letter-spacing: 0;
  color: #636c84;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline .right-div {
  width: 10%;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-header {
  text-align: center;
  font: normal normal normal 14px/21px Heebo;
  letter-spacing: 0;
  color: #636c84;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  margin-top: 20px;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .sep {
  flex: 1 1 auto;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper {
  display: flex;
  align-items: center;
  padding-left: calc(46% - 103px);
  margin-right: 25px;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .bill-slider {
  direction: rtl;
  text-align: center;
  white-space: nowrap;
  font: normal normal normal 11px/41px Heebo;
  letter-spacing: -0.22px;
  color: #8a8c98;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle-content {
  color: #8a8c98;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle {
  color: #4c9dcb;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle .mat-slide-toggle-bar {
  height: 22px;
  border-radius: 30px;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle .mat-slide-toggle-bar .mat-slide-toggle-thumb {
  background: #fecb2e;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fecb2e;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle-thumb-container {
  color: #fecb2e;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle .mat-slide-toggle-bar {
  height: 36px !important;
  width: 80px !important;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 12px 26px rgba(197, 191, 214, 0.2);
  border: 1px solid #bfbfbf;
  border-radius: 20px;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
  background-color: #fecb2e;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  display: none;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-right: 15vh;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper .save-header {
  text-align: center;
  white-space: nowrap;
  font: normal normal normal 12px/20px Heebo;
  letter-spacing: -0.24px;
  color: #fecb2e;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper .underline {
  width: 56px;
  height: 0;
  border-radius: 100% 85% 0 0;
  border: 4px none #fecb2e;
  border-top-style: solid;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .currency-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.main-plan-wrapper .sub-plan-wrapper .plans-container .logo-wrapper {
  margin-bottom: 2%;
  z-index: 1;
  display: flex;
  justify-content: center;
}
app-plan .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(36px, 0, 0) !important;
}
app-plan .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle-thumb-container {
  color: #fecb2e;
  margin: 6px !important;
  height: fit-content !important;
  width: fit-content !important;
}
app-plan .mat-slide-toggle-thumb {
  width: 32px;
  height: 30px;
  display: block;
  border-radius: 50%;
  box-shadow: none;
}

html[dir=rtl] .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper {
  margin-right: 0;
  margin-left: 15vh;
}
html[dir=rtl] .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper {
  padding-left: 0;
  padding-right: calc(50% - 117px);
  margin-right: 0;
  margin-left: 25px;
}
html[dir=rtl] app-plan .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(-36px, 0, 0) !important;
}
html[dir=rtl] app-plan .mat-slide-toggle-label {
  flex-direction: row-reverse;
}
html[dir=rtl] app-plan .mat-slide-toggle-bar {
  margin-left: 8px;
  margin-right: 8px;
}

@media (max-width: 776px) {
  .main-plan-wrapper .sub-plan-wrapper {
    margin: 10px;
  }
  .main-plan-wrapper .sub-plan-wrapper .plans-container .logo-wrapper {
    margin-bottom: 10%;
  }
  .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper {
    flex-direction: column;
  }
  .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper {
    margin: 0 !important;
  }
  .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper {
    padding: 0 !important;
  }
  .main-plan-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline {
    width: unset;
    display: unset;
  }
  .main-plan-wrapper .sub-plan-wrapper .plans-container .plan-wrapper {
    padding: 0rem 0rem 0rem 1rem;
    padding: 0rem 1rem 2rem 1rem;
    width: unset;
  }
}
.success {
  width: 100%;
  height: 100%;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.success .box-shadow {
  width: clamp(600px, 42%, 720px);
  height: clamp(535px, 55%, 700px);
  border-radius: 20px;
  background: white;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
}
.success .box-shadow .success-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.success .box-shadow .success-container .atlas-icon {
  width: 163px;
  height: 44px;
  margin-top: 2%;
}
.success .box-shadow .success-container .calendar-icon {
  width: 148px;
  height: 141px;
  background: white;
  margin-top: 8%;
}
.success .box-shadow .success-container .success-text {
  width: 45%;
  text-align: center;
  font: bold 28px arial;
  color: #0a287c;
  margin-top: 3%;
}
.success .box-shadow .success-container .start-button {
  cursor: pointer;
  border-radius: 8px;
  width: 40%;
  text-align: center;
  font: normal normal bold 19px/55px Heebo;
  color: #0a287c;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 3px 13px #c6e5ff;
  margin: 8% 0 2% 0;
}
.success .box-shadow .success-container .login-loading {
  margin: 48px auto;
  padding-bottom: 0px;
}

@media (max-width: 776px) {
  .success .box-shadow {
    margin: 10px;
  }
}
.signup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 15px OpenSans;
}
.signup__welcome {
  height: 100%;
  max-height: 100%;
  width: 60%;
  position: relative;
  font-size: 32px;
  display: flex;
  flex-wrap: wrap;
}
.signup__welcome .theme-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
  opacity: 1;
  display: flex;
}
.signup__welcome__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.signup__welcome__content .welcome-image {
  height: 190px;
  width: 190px;
  margin-left: 50px;
  margin-top: 50px;
  position: relative;
  display: flex;
}
.signup__welcome__content .welcome-text-headline {
  text-align: left;
  font: normal normal bold 67px/79px OpenSans;
  letter-spacing: 1.05px;
  color: #ffffff;
  opacity: 1;
  width: 40%;
  display: flex;
  position: relative;
  margin-left: 160px;
  margin-top: -10px;
}
.signup__welcome__content .welcome-text-sub-headline {
  font: normal normal 500 33px/39px OpenSans;
  letter-spacing: 0.52px;
  color: white;
  opacity: 1;
  display: flex;
  width: 40%;
  position: relative;
  margin-top: 20px;
  margin-left: 160px;
}
.signup__comp-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.signup__form {
  height: 100%;
  width: 40%;
  display: flex;
  max-width: 40%;
  justify-content: space-around;
  z-index: 3;
  overflow-y: scroll;
}
.signup__form .logo {
  height: 12%;
}
.signup__form .bottom-wrapper {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 13px;
  margin: auto auto;
}
.signup__form .bottom-wrapper .atlas-line {
  padding-right: 4%;
  color: #b1b1b1;
}
.signup__form__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.signup__form__content .login-loading {
  margin: 13.5px auto;
  padding-bottom: 0px;
}
.signup__form__content .google-button {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
.signup__form__content .google-button .logo {
  height: 4vh;
  width: auto;
  margin: 10px auto;
}
.signup__form__content .join-button-wrapper {
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.signup__form__content .sep-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.signup__form__content .sep-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1019607843);
  position: absolute;
  top: 50%;
}
.signup__form__content .sep-wrapper .sep-content {
  font-size: 15px;
  font-weight: 300;
  background-color: white;
  padding-inline: 8px;
  text-align: center;
  display: flex;
  z-index: 2;
}
.signup__form__content .join-button {
  width: 100%;
  display: flex;
  cursor: pointer;
  line-height: 14px;
  border-radius: 5px;
  text-align: center;
  color: #090e3a;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #c9c9c9;
  text-decoration: solid;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
  align-items: center;
  justify-content: space-evenly;
}
.signup__form .title {
  margin: 10px auto;
  font-size: 32px;
  font-weight: bold;
  color: #2e4765;
}
.signup__form__cotent-coponnent {
  width: 50%;
  padding-bottom: 12%;
}
.signup__form .mat-checkbox {
  padding-right: 4%;
}
.signup__form .mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #4c9dcb !important;
}
.signup__form .mat-checkbox .mat-ripple-element {
  background-color: #4c9dcb !important;
}
.signup__form .approval-block {
  overflow: hidden;
  background: #f0faff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1019607843);
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 430px;
  width: 700px;
}
.signup__form .approval-block .titles-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5%;
}
.signup__form .approval-block .titles-block .approval-title {
  font-size: 35px;
  font-weight: bold;
  color: #2e4765;
  padding-bottom: 28px;
}
.signup__form .approval-block .titles-block .approval-title-bold {
  font-size: 40px;
  font-weight: bold;
  color: black;
  padding-bottom: 28px;
}
.signup__form .approval-block .titles-block .approval-sub-title {
  font-size: 20px;
  font-weight: bold;
  color: #2e4765;
  padding-bottom: 28px;
}
.signup__form .radio-group {
  display: flex;
  flex-direction: column;
}
.signup__form .radio-group .radio-button {
  border-radius: 20px;
  background-color: #f0faff;
  padding: 25px;
}
.signup__form .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4c9dcb;
}
.signup__form .mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #4c9dcb;
  background-color: #4c9dcb;
}
.signup__form .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}
.signup__form .signup-form {
  width: 80%;
  max-width: 80%;
}
.signup__form .signup-form strong {
  color: red;
}
.signup__form .title {
  font-size: 32px;
  font-weight: 600;
  color: black;
  margin: 1.2rem;
  display: flex;
  align-items: center;
}
.signup__form .span {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}
.signup__form .name-surname-wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}
.signup__form .name-surname-wrapper .input-wrapper {
  direction: ltr;
  width: 100%;
}
.signup__form .name-surname-wrapper .input-wrapper .input-headline {
  margin-top: 12px;
  font-size: 13px;
  display: inline-block;
}
.signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname {
  font: 15px OpenSans;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;
  padding-left: 8px;
  margin-top: 6px;
  display: flex;
  border: 1px solid #e3e3e3;
}
.signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .icon {
  margin: auto 10px;
  color: #1667c7;
}
.signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .visibility-icon {
  cursor: pointer;
}
.signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .input {
  font: 15px OpenSans;
  width: 100%;
  background-color: transparent;
  direction: ltr;
}
.signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .input::placeholder {
  color: #9a9a9a;
}
.signup__form .phone-area-code-wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}
.signup__form .phone-area-code-wrapper .input-wrapper {
  direction: ltr;
  width: 100%;
}
.signup__form .phone-area-code-wrapper .input-wrapper .input-headline {
  font-size: 13px;
  margin-top: 12px;
  display: inline-block;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field {
  display: flex;
  align-items: center;
  width: 100%;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-wrapper {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: initial;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-select-arrow {
  border: none;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-underline {
  display: none;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-select-panel-wrap {
  width: 45%;
}
.signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-infix {
  padding: 0;
  width: 100%;
  border-top: 0;
  /* border-top: 0.84375em solid transparent; */
}
.signup__form .phone-area-code-wrapper .shrink {
  width: 30%;
  max-width: 100px;
}
.signup__form .input-wrapper {
  direction: ltr;
  width: 100%;
  margin-bottom: 15px;
}
.signup__form .input-wrapper .input-headline {
  width: 100%;
  font-size: 13px;
  display: inline-block;
}
.signup__form .input-wrapper .signup-input-container {
  font: 15px OpenSans;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;
  padding-left: 8px;
  margin-top: 6px;
  display: flex;
  border: 1px solid #e3e3e3;
}
.signup__form .input-wrapper .signup-input-container .icon {
  margin: auto 10px;
  color: #999898;
  overflow: hidden;
}
.signup__form .input-wrapper .signup-input-container .visibility-icon {
  cursor: pointer;
}
.signup__form .input-wrapper .signup-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.signup__form .input-wrapper .signup-input-container .input {
  font: 15px OpenSans;
  width: 85%;
  background-color: transparent;
  direction: ltr;
}
.signup__form .input-wrapper .signup-input-container .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.signup__form .input-wrapper .signup-input-container .input::placeholder {
  color: #9a9a9a;
}
.signup__form .checkbox-wrapper {
  display: flex;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 3%;
  flex-direction: row;
  align-items: center;
  font: 15px/15px OpenSans;
}
.signup__form .checkbox-wrapper input[type=checkbox] {
  width: 15px;
  height: 15px;
  accent-color: #0a287c;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
}
.signup__form .checkbox-wrapper .input-headline {
  margin: 8px 0 8px 12px;
  font: 13px OpenSans;
}
.signup__form .checkbox-wrapper .signup-input-container {
  width: 415px;
  height: 40px;
  border-radius: 5px;
  margin: 0 10px;
  margin-bottom: 10px;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #e3e3e3;
  background: #ffffff 0 0 no-repeat padding-box;
}
.signup__form .checkbox-wrapper .signup-input-container .icon {
  margin: auto 10px;
  color: #999898;
  overflow: hidden;
}
.signup__form .checkbox-wrapper .signup-input-container .visibility-icon {
  cursor: pointer;
}
.signup__form .checkbox-wrapper .signup-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.signup__form .checkbox-wrapper .signup-input-container .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
  direction: ltr;
}
.signup__form .checkbox-wrapper .signup-input-container .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.signup__form .checkbox-wrapper .signup-input-container .input::placeholder {
  color: #9a9a9a;
}
.signup__form .signup-input-error-checkbox {
  color: red;
  font-size: 12px;
  width: 81%;
  margin: 9px 8px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: flex-end;
  margin-top: -2%;
}
.signup__form .signup-input-error {
  color: red;
  font-size: 12px;
  width: 350px;
}
.signup__form .signup-loading {
  margin: 10px auto;
}
.signup__form .signup-button {
  width: 100%;
  height: 48px;
  display: flex;
  color: #0a287c;
  cursor: pointer;
  font: normal normal bold 16px/21px OpenSans;
  line-height: 24px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  background-color: #fecb2f;
  justify-content: space-evenly;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
}
.signup__form .dont-have-account-wrapper {
  width: 100%;
  text-align: center;
  font: normal normal normal 13px OpenSans;
}
.signup__form .lang-select-input {
  margin: 1rem;
}
.signup__form .lang-select-input mat-select {
  width: fit-content;
}
.signup__form .lang-select-input .fi {
  padding-left: 20px;
  height: 18px;
  width: 18px;
}
.signup__form .lang-select-input .country-placeholder {
  padding-left: 60px;
}
.signup__form .lang-select-input .country-flag-icon {
  margin-left: 10px;
  width: 35px;
}
.signup__form .lang-select-input .lang-arrow-down {
  padding-right: 10px;
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.signup__form .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: initial;
}
.signup .spacer {
  flex: 1 1 auto;
}

a:-webkit-any-link {
  color: #0266d6;
  text-decoration: underline;
  cursor: pointer;
}

app-singup .mat-form-field-underline {
  display: none;
}
app-singup .mat-select-arrow-wrapper {
  display: none !important;
}
app-singup .mat-form-field-infix {
  padding: 0 !important;
  line-height: 18px !important;
  display: flex;
  border-width: 0;
  align-items: center;
  width: fit-content;
}
app-singup mat-form-field {
  overflow: auto;
}
app-singup .mat-select-value-text {
  color: black;
}

html[dir=rtl] .signup__welcome__content .welcome-image {
  margin-right: 50px;
  margin-left: 0;
  transform: scaleX(-1);
}
html[dir=rtl] .signup__welcome__content .welcome-text-headline {
  margin-right: 181px;
  margin-left: 123px;
  text-align: right;
}
html[dir=rtl] .signup__welcome__content .welcome-text-sub-headline {
  margin-right: 200px;
}
html[dir=rtl] .signup__form__content .input-wrapper {
  direction: rtl;
}
html[dir=rtl] .signup__form__content .input-wrapper .signup-input-container .input {
  padding-right: 8px;
  padding-left: unset;
  direction: rtl;
}
html[dir=rtl] .signup__form__content .spacer {
  flex: 1 1 auto;
}
html[dir=rtl] .signup__form__content .name-surname-wrapper .input-wrapper {
  direction: rtl;
}
html[dir=rtl] .signup__form__content .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .input {
  padding-right: 8px;
  direction: rtl;
}
html[dir=rtl] .signup__form__content .checkbox-wrapper .input-headline {
  margin-left: 0;
  margin-right: 12px;
}
@media (max-width: 776px) {
  .signup {
    flex-direction: column;
  }
  .signup__welcome {
    height: 50%;
    width: 100%;
  }
  .signup__welcome__content .welcome-text-headline {
    margin-left: 110px;
    margin-top: -30px;
    font: normal normal bold 30px/31px OpenSans;
  }
  .signup__welcome__content .welcome-text-sub-headline {
    margin-top: 20px;
    margin-left: 110px;
    font: normal normal 500 16px/21px OpenSans;
  }
  .signup__welcome__content .welcome-image {
    height: 45px;
    width: 45px;
  }
  .signup__form {
    width: 100%;
    max-width: 100%;
  }
  .signup__form__content .title {
    margin: 3vh 0 6vh 0;
  }
}
.plan {
  cursor: pointer;
  display: flex;
  border-radius: 25px;
  flex-direction: column;
  background: white;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  max-height: 28rem;
  position: relative;
  padding-bottom: 6%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0);
  border: 1px solid #ececf1;
  font-family: "Mulish", sans-serif;
}
.plan__plan-uper {
  border-radius: 25px 25px 0 0;
}
.plan__p-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  justify-content: flex-start;
}
.plan__p-form .plan-title {
  font-size: 19px;
  color: #121d2a;
  letter-spacing: -0.38px;
  height: 11%;
  display: flex;
  align-items: flex-end;
}
.plan__p-form .plan__p-form__content-yearly-custom {
  height: 65%;
}
.plan__p-form__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 87%;
}
.plan__p-form__content .description-wrapper {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  color: #677177;
  letter-spacing: -0.24px;
  font-size: 12px;
}
.plan__p-form__content .feature-container {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}
.plan__p-form__content .feature-container .feature-row {
  display: flex;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
}
.plan__p-form__content .feature-container .feature-row .check-icon {
  color: #fecb2e;
}
.plan__p-form__content .feature-container .feature-row .feature-description {
  display: flex;
}
.plan__p-form__content .feature-container .feature-row .feature-description__text {
  text-align: start;
}
.plan__p-form__content .feature-container-custom {
  display: none;
}
.plan__p-form__content .logo {
  height: 64px;
  width: auto;
  margin: 10px auto;
}
.plan__p-form__content .custom-title {
  font: normal normal 900 31px OpenSans;
  letter-spacing: -0.62px;
}
.plan__p-form__content__titles-low-div {
  align-self: center;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.plan__p-form__content__titles-low-div .title-low {
  text-align: center;
  font: normal normal 600 31px Mulish;
  letter-spacing: -0.62px;
  color: #121d2a;
  width: 69%;
  overflow: hidden;
}
.plan__p-form__content__titles-low-div .per-month-label {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 29px;
  color: #96a5ae;
  width: 3.4rem;
}
.plan__p-form__content__titles-low-div .sub-title-low {
  margin: 10px auto;
  display: inline;
  font-size: 21px;
  font-weight: bold;
  color: #4c9dcb;
}
.plan__p-form__content__titles-low-div-1 {
  align-self: center;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.plan__p-form__content__titles-low-div-1 .title-low {
  text-align: center;
  font: normal normal 600 31px Mulish;
  letter-spacing: -0.62px;
  color: #121d2a;
  overflow: hidden;
}
.plan__p-form__content__titles-low-div-1 .per-month-label {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 29px;
  color: #96a5ae;
  width: 3.4rem;
}
.plan__p-form__content__titles-low-div-1 .sub-title-low {
  margin: 10px auto;
  display: inline;
  font-size: 21px;
  font-weight: bold;
  color: #4c9dcb;
}
.plan__p-form__content .plan-input-container {
  width: 247px;
  height: 48px;
  margin: 10px auto;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1607843137), 0 3px 16px rgba(0, 0, 0, 0.1607843137);
}
.plan__p-form__content .icon {
  height: 30px;
  width: 30px;
}
.plan__p-form__content .star-icon {
  height: 20px;
  width: 20px;
  margin-right: -55px;
  padding-left: 60px;
  margin-bottom: 12px;
}
.plan__p-form__content .visibility-icon {
  cursor: pointer;
}
.plan__p-form__content .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.plan__p-form__content .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.plan__p-form__content .plan-button {
  border: 1px solid #fecb2e;
  text-align: center;
  color: #fecb2e;
  background: white;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: OpenSans, sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.plan__p-form__content .save-more-header {
  text-align: center;
  letter-spacing: -0.26px;
  color: #809fb8;
}
.plan__p-form__content .save-more-under-header {
  text-align: center;
  letter-spacing: -0.26px;
  color: #000000;
}
.plan__p-form__content .choose-plan-button {
  border: none;
  text-align: center;
  color: #0a287c;
  background: #fecb2e 0% 0% no-repeat padding-box;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: OpenSans, sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.plan__p-form__content .separator {
  height: 2px;
  width: 10px;
  background-color: #4c9dcb;
  margin-inline-end: 30px;
}
.plan__p-form__content-yearly {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 85%;
}
.plan__p-form__content-yearly .description-wrapper {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  color: #677177;
  letter-spacing: -0.24px;
  font-size: 12px;
}
.plan__p-form__content-yearly .feature-container {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}
.plan__p-form__content-yearly .feature-container .feature-row {
  display: flex;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
}
.plan__p-form__content-yearly .feature-container .feature-row .check-icon {
  color: #fecb2e;
}
.plan__p-form__content-yearly .feature-container .feature-row .feature-description {
  display: flex;
}
.plan__p-form__content-yearly .feature-container .feature-row .feature-description__text {
  text-align: start;
}
.plan__p-form__content-yearly .feature-container-custom {
  display: none;
}
.plan__p-form__content-yearly .logo {
  height: 64px;
  width: auto;
  margin: 10px auto;
}
.plan__p-form__content-yearly .custom-title {
  font: normal normal 900 31px OpenSans;
  letter-spacing: -0.62px;
}
.plan__p-form__content-yearly__titles-low-div {
  align-self: center;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.plan__p-form__content-yearly__titles-low-div .title-low {
  text-align: center;
  font: normal normal 600 31px Mulish;
  letter-spacing: -0.62px;
  color: #121d2a;
  width: 69%;
  overflow: hidden;
}
.plan__p-form__content-yearly__titles-low-div .per-month-label {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 29px;
  color: #96a5ae;
  width: 3.4rem;
}
.plan__p-form__content-yearly__titles-low-div .sub-title-low {
  margin: 10px auto;
  display: inline;
  font-size: 21px;
  font-weight: bold;
  color: #4c9dcb;
}
.plan__p-form__content-yearly__titles-low-div-1 {
  align-self: center;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.plan__p-form__content-yearly__titles-low-div-1 .title-low {
  text-align: center;
  font: normal normal 600 31px Mulish;
  letter-spacing: -0.62px;
  color: #121d2a;
  overflow: hidden;
}
.plan__p-form__content-yearly__titles-low-div-1 .per-month-label {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 29px;
  color: #96a5ae;
  width: 3.4rem;
}
.plan__p-form__content-yearly__titles-low-div-1 .sub-title-low {
  margin: 10px auto;
  display: inline;
  font-size: 21px;
  font-weight: bold;
  color: #4c9dcb;
}
.plan__p-form__content-yearly .plan-input-container {
  width: 247px;
  height: 48px;
  margin: 10px auto;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1607843137), 0 3px 16px rgba(0, 0, 0, 0.1607843137);
}
.plan__p-form__content-yearly .icon {
  height: 30px;
  width: 30px;
}
.plan__p-form__content-yearly .star-icon {
  height: 20px;
  width: 20px;
  margin-right: -55px;
  padding-left: 60px;
  margin-bottom: 12px;
}
.plan__p-form__content-yearly .visibility-icon {
  cursor: pointer;
}
.plan__p-form__content-yearly .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.plan__p-form__content-yearly .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.plan__p-form__content-yearly .plan-button {
  border: 1px solid #fecb2e;
  text-align: center;
  color: #fecb2e;
  background: white;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: OpenSans, sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.plan__p-form__content-yearly .choose-plan-button {
  border: none;
  text-align: center;
  color: #0a287c;
  background: #fecb2e 0% 0% no-repeat padding-box;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: OpenSans, sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.plan__p-form__content-yearly .separator {
  height: 2px;
  width: 10px;
  background-color: #4c9dcb;
  margin-inline-end: 30px;
}

.plan:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137), 0 3px 16px rgba(0, 0, 0, 0.1607843137);
  max-height: 28rem;
}
.plan:hover .feature-container-custom {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}
.plan:hover .feature-container-custom .feature-row {
  display: flex;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
}
.plan:hover .feature-container-custom .feature-row .check-icon {
  color: #fecb2e;
}
.plan:hover .feature-container-custom .feature-row .feature-description {
  display: flex;
}
.plan:hover .feature-container-custom .feature-row .feature-description__text {
  text-align: start;
}
.plan:hover .plan__p-form .plan__p-form__content-yearly-custom {
  height: 87%;
}

.opacity {
  opacity: 0.35;
}

.email-verified {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100000;
}
.email-verified__card {
  width: 400px;
  padding: 40px 60px;
  max-height: calc(80vh - 80px);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #707070;
  overflow: scroll;
}
.email-verified__card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.email-verified__card .content .title {
  text-align: center;
  font: 26px Arial;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #0a287c;
}
.email-verified__card .content .subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 8%;
  text-align: center;
  font: normal normal normal 2vh Heebo;
  font-size: 14px;
  color: #636c84;
}
.email-verified__card .content .image {
  width: auto;
  height: 150px;
}
.email-verified__card .content .verify-button {
  width: 100%;
  padding: 10px 0;
  color: #0a287c;
  margin-top: 6%;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
  background-color: #fecb2f;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password {
  size: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  font-family: OpenSans, serif;
}
.forgot-password__welcome {
  height: 100%;
  max-height: 100%;
  width: 60%;
  position: relative;
  font-size: 32px;
  display: flex;
  flex-wrap: wrap;
}
.forgot-password__welcome .theme-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
  opacity: 1;
  display: flex;
}
.forgot-password__welcome__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.forgot-password__welcome__content .welcome-image {
  height: 190px;
  width: 190px;
  margin-left: 50px;
  margin-top: 50px;
  position: relative;
  display: flex;
}
.forgot-password__welcome__content .welcome-text-headline {
  text-align: left;
  font: normal normal bold 67px/79px;
  font-family: OpenSans, sans-serif;
  letter-spacing: 1.05px;
  color: #ffffff;
  opacity: 1;
  width: 40%;
  display: flex;
  position: relative;
  margin-left: 160px;
  margin-top: -10px;
}
.forgot-password__welcome__content .welcome-text-sub-headline {
  text-align: left;
  font: normal normal 500 33px/39px;
  font-family: OpenSans, sans-serif;
  letter-spacing: 0.52px;
  color: white;
  opacity: 1;
  display: flex;
  width: 40%;
  position: relative;
  margin-top: 20px;
  margin-left: 160px;
}
.forgot-password__form {
  height: 100%;
  max-height: 100%;
  width: 40%;
  display: flex;
  max-width: 40%;
  flex-direction: column;
  justify-content: center;
}
.forgot-password__form__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  box-sizing: border-box;
  margin: auto;
  justify-content: center;
}
.forgot-password__form__content .title {
  width: 100%;
  color: #000000;
  font-size: 32px;
  font-family: OpenSans, sans-serif;
  text-align: center;
}
.forgot-password__form__content .forgot-password-input-wrapper {
  width: 100%;
  margin: 10px;
  padding-top: 10px;
}
.forgot-password__form__content .forgot-password-input-wrapper .input-title {
  font-weight: bold;
}
.forgot-password__form__content .forgot-password-input-wrapper .forgot-password-input-container .input {
  width: calc(100% - 24px);
  cursor: pointer;
  font-size: 15px;
  font-family: OpenSans, sans-serif;
  height: 42px;
  border-radius: 5px;
  margin: 8px 0 1px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  direction: ltr;
  border: 1px solid #c3c3c3;
}
.forgot-password__form__content .forgot-password-input-wrapper .forgot-password-input-container input[type=email]:focus {
  border: 1px solid #fecb2e;
}
.forgot-password__form__content .forgot-password-input-wrapper .forgot-password-input-container input:-webkit-autofill,
.forgot-password__form__content .forgot-password-input-wrapper .forgot-password-input-container input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.forgot-password__form__content .forgot-password-input-wrapper .forgot-password-input-container .input::placeholder {
  color: #9a9a9a;
}
.forgot-password__form__content .forgot-password-input-wrapper .forgot-password-input-error {
  color: red;
  font-size: 16px;
  display: block;
  text-align: center;
}
.forgot-password__form__content .forgot-password-loading {
  margin: 42px auto;
  padding-bottom: 0;
}
.forgot-password__form__content .have-account-wrapper {
  width: 100%;
  font-weight: 400;
  margin: 10px;
  text-align: center;
}
.forgot-password__form__content .forgot-password-button {
  height: 48px;
  min-height: 48px;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 6%;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background-color: #fecb2f;
  border: 3px solid #fecb2f;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: bold;
}
.forgot-password__form__content .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.forgot-password__form .bottom-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: 5%;
  font-size: 13px;
  box-sizing: border-box;
  font-weight: 400;
  margin: 0 auto 20px;
}
.forgot-password__form .bottom-wrapper .rout-link {
  color: #1270d9;
}
.forgot-password__form .bottom-wrapper .atlas-line {
  padding-right: 4%;
  color: #b1b1b1;
}
.forgot-password .container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
.forgot-password .line {
  flex: 1;
}
.forgot-password .text {
  padding-left: 10px;
  padding-right: 10px;
}
.forgot-password a:-webkit-any-link {
  color: #1270d9;
  text-decoration: underline;
  cursor: pointer;
}

app-forgot-password .mat-form-field-underline {
  display: none;
}
app-forgot-password .mat-form-field-infix {
  padding: 0 !important;
  line-height: 18px !important;
  display: flex;
  border-width: 0;
  align-items: center;
  width: fit-content;
}
app-forgot-password mat-form-field {
  overflow: auto;
}

@media (max-width: 776px) {
  .forgot-password {
    flex-direction: column;
  }
  .forgot-password__welcome {
    height: 50%;
    width: 100%;
  }
  .forgot-password__welcome__content .welcome-text-headline {
    margin-left: 110px;
    margin-top: -30px;
    font: normal normal bold 30px/31px OpenSans;
  }
  .forgot-password__welcome__content .welcome-text-sub-headline {
    margin-top: 20px;
    margin-left: 110px;
    font: normal normal 500 16px/21px OpenSans;
  }
  .forgot-password__welcome__content .welcome-image {
    height: 45px;
    width: 45px;
  }
  .forgot-password__form {
    width: 100%;
    max-width: 100%;
  }
  .forgot-password__form__content .title {
    margin: 3vh 0 6vh 0;
  }
}
.reset-password {
  size: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  font-family: OpenSans, sans-serif;
}
.reset-password__welcome {
  height: 100%;
  max-height: 100%;
  width: 60%;
  position: relative;
  font-size: 32px;
  display: flex;
  flex-wrap: wrap;
}
.reset-password__welcome .theme-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: none;
  background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
  opacity: 1;
  display: flex;
}
.reset-password__welcome__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.reset-password__welcome__content .welcome-image {
  height: 190px;
  width: 190px;
  margin-left: 50px;
  margin-top: 50px;
  position: relative;
  display: flex;
}
.reset-password__welcome__content .welcome-text-headline {
  text-align: left;
  font: normal normal bold 67px/79px OpenSans;
  letter-spacing: 1.05px;
  color: #ffffff;
  opacity: 1;
  width: 40%;
  display: flex;
  position: relative;
  margin-left: 160px;
  margin-top: -10px;
}
.reset-password__welcome__content .welcome-text-sub-headline {
  text-align: left;
  font: normal normal 500 33px/39px OpenSans;
  letter-spacing: 0.52px;
  color: white;
  opacity: 1;
  display: flex;
  width: 40%;
  position: relative;
  margin-top: 20px;
  margin-left: 160px;
}
.reset-password__form {
  height: 100%;
  max-height: 100%;
  width: 40%;
  display: flex;
  max-width: 40%;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
}
.reset-password__form__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  box-sizing: border-box;
  margin: 0 auto;
  justify-content: center;
}
.reset-password__form__content .title {
  width: 100%;
  margin: 20vh 0 3vh 0;
  color: #000000;
  font: normal normal 600 32px/39px OpenSans;
  text-align: center;
}
.reset-password__form__content .reset-password-input-wrapper {
  width: 100%;
  margin: 10px;
  padding-top: 10px;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container {
  height: 42px;
  border-radius: 5px;
  margin: 8px 0 1px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  direction: ltr;
  border: 1px solid #c3c3c3;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container .icon {
  color: gray;
  overflow: hidden;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container .visibility-icon {
  cursor: pointer;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container .input {
  width: calc(100% - 24px);
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  font-family: OpenSans;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container input:-webkit-autofill,
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-container .input::placeholder {
  color: #9a9a9a;
}
.reset-password__form__content .reset-password-input-wrapper .reset-password-input-error {
  color: red;
  font-size: 16px;
  display: block;
  text-align: center;
}
.reset-password__form__content .reset-password-loading {
  margin: 42px auto;
  padding-bottom: 0;
}
.reset-password__form__content .reset-password-button {
  height: 48px;
  min-height: 48px;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 6%;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background-color: #fecb2f;
  border: 3px solid #fecb2f;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: bold;
}
.reset-password__form__content .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.reset-password__form .bottom-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: 5%;
  font-size: 13px;
  box-sizing: border-box;
  font-weight: 400;
  margin: 0 auto 20px;
}
.reset-password__form .bottom-wrapper .rout-link {
  color: #1270d9;
}
.reset-password__form .bottom-wrapper .atlas-line {
  padding-right: 4%;
  color: #b1b1b1;
}
.reset-password a:-webkit-any-link {
  color: #1270d9;
  text-decoration: underline;
  cursor: pointer;
}

app-reset-password .mat-form-field-underline {
  display: none;
}
app-reset-password .mat-form-field-infix {
  padding: 0 !important;
  line-height: 18px !important;
  display: flex;
  border-width: 0;
  align-items: center;
  width: fit-content;
}
app-reset-password mat-form-field {
  overflow: auto;
}

@media (max-width: 776px) {
  .reset-password {
    flex-direction: column;
  }
  .reset-password__welcome {
    height: 50%;
    width: 100%;
  }
  .reset-password__welcome__content .welcome-text-headline {
    margin-left: 110px;
    margin-top: -30px;
    font: normal normal bold 30px/31px OpenSans;
  }
  .reset-password__welcome__content .welcome-text-sub-headline {
    margin-top: 20px;
    margin-left: 110px;
    font: normal normal 500 16px/21px OpenSans;
  }
  .reset-password__welcome__content .welcome-image {
    height: 45px;
    width: 45px;
  }
  .reset-password__form {
    width: 100%;
    max-width: 100%;
  }
  .reset-password__form__content .title {
    margin: 3vh 0 6vh 0;
  }
}
.signup-old-fb-account-main {
  height: 55vh;
  width: 55vw;
  background: white;
  border-radius: 10px;
  display: flex;
}
.signup-old-fb-account-main .decoration {
  height: 100%;
  background-color: #fecb2f;
  width: 3%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.signup-old-fb-account-main .content {
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-old-fb-account-main .content .close-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 20px;
}
.signup-old-fb-account-main .content .close-wrapper .close-icon {
  background-color: transparent;
  float: right;
  padding: 10px;
  cursor: pointer;
}
.signup-old-fb-account-main .content .content-wrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  height: 90%;
  gap: 15%;
}
.signup-old-fb-account-main .content .content-wrapper .header {
  text-align: center;
  font: normal normal bold 24px/17px "OpenSans";
  letter-spacing: 0.1px;
  color: #44444f;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .old-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;
  height: 100%;
  justify-content: space-between;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .old-input .input-header {
  font-size: 12px;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .old-input .input {
  height: 42px;
  border-radius: 5px;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #c3c3c3;
  width: 100%;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .new-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;
  height: 100%;
  justify-content: space-between;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .new-input .header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .new-input .header-wrapper .input-error {
  font-size: 11px;
  color: red;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .new-input .header-wrapper .input-header {
  font-size: 12px;
}
.signup-old-fb-account-main .content .content-wrapper .input-wrapper .new-input .input {
  height: 42px;
  border-radius: 5px;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #c3c3c3;
  width: 100%;
}
.signup-old-fb-account-main .content .content-wrapper .submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}
.signup-old-fb-account-main .content .content-wrapper .submit .login-button-submit {
  height: 35px;
  min-height: 35px;
  width: 30%;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background-color: #fecb2f;
  border: 3px solid #fecb2f;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: bold;
}
.signup-old-fb-account-main .content .content-wrapper .submit .login-button-submit__disabled {
  background: #dfdfdf;
  border: 1px solid #dfdfdf;
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 776px) {
  .signup-old-fb-account-main {
    height: 75vh;
    width: 75vw;
  }
  .signup-old-fb-account-main .content .content-wrapper {
    gap: 7%;
  }
  .signup-old-fb-account-main .content .content-wrapper .input-wrapper {
    flex-direction: column;
  }
  .signup-old-fb-account-main .content .content-wrapper .input-wrapper .old-input {
    width: auto;
  }
  .signup-old-fb-account-main .content .content-wrapper .input-wrapper .new-input {
    width: auto;
  }
  .signup-old-fb-account-main .content .content-wrapper .header {
    padding-top: 5%;
    font: normal normal bold 24px/27px "OpenSans";
  }
}
.admin {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.admin__mobile {
  display: none;
}
.admin__toolbar {
  height: 90px;
  width: 100vw;
  z-index: 2;
}
.admin__content {
  width: 100vw;
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: row;
}
.admin__content__sidenav {
  min-width: 264px;
}
.admin__content__main-content {
  width: 100%;
  height: calc(103vh - 96px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.mat-drawer-backdrop {
  position: fixed !important;
  background: transparent;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}

mat-drawer-transition .mat-drawer-backdrop {
  transition-duration: 0ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: background-color, visibility;
}

@media (max-width: 776px) {
  .admin__mobile {
    display: inline;
  }
  .admin__content {
    flex-direction: column;
  }
  .admin__content__sidenav {
    display: none;
  }
  .admin__content__sidenav__open {
    display: inline;
  }
}
.main-bar {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}
.main-bar .mat-toolbar-single-row {
  padding: 0;
  z-index: 5;
}

.main-side-bar {
  display: flex;
  width: 100%;
  height: 90%;
}

.rout-div {
  width: min(85%, 100% - 250px);
  margin-right: 0;
  background-color: #ffffff;
  box-shadow: inset 0 5vh 4vh -7vh;
}
.rout-div .blur-wrapper {
  position: fixed;
  top: 15%;
  height: 76%;
  border-radius: 0;
  z-index: 10020;
  background: white;
  opacity: 0.5;
  width: 100%;
}
.rout-div .blur-wrapper-after {
  position: fixed;
  z-index: 100000;
  left: 50%;
  top: 40%;
  width: 20%;
  height: 25%;
  border-radius: 8%;
  background-color: white;
}
.rout-div .blur-wrapper-after .funds-loading {
  height: 100%;
  position: absolute;
  z-index: 1000000;
  top: 45%;
  left: 50%;
}

.icon-wrapper {
  height: 3rem;
  width: 3rem;
  border-radius: 1rem;
}
.icon-wrapper .icon-span {
  color: #0a287c;
  cursor: pointer;
  background: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-wrapper .icon-span .icon {
  height: 2rem;
  width: 2rem;
  color: #0a287c;
}
.icon-wrapper .icon-smart {
  height: 1.6rem;
  color: #0a287c;
}
.icon-wrapper .icon-writing {
  height: 1.75rem;
  margin-bottom: 2px;
  color: #0a287c;
}

.icon-wrapper-alert {
  width: 24px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.icon-wrapper-alert .icon-span {
  color: #81a0b9;
  cursor: pointer;
  background: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.icon-wrapper-alert .icon-span .icon {
  height: 2rem;
  width: 2rem;
  color: #809fb8;
}
.icon-wrapper-alert .red-alert {
  position: relative;
  border: 5px solid #dd3737;
  border-radius: 15px;
  background: #dd3737;
  bottom: 22px;
  left: 6px;
}
.icon-wrapper-alert .icon-smart {
  height: 2rem;
  width: 2rem;
  color: #809fb8;
}

.tool-bar {
  display: flex;
  width: calc(100% - max(175px, 15%));
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 0;
}
.tool-bar .filter-search-icons-div-left {
  flex-grow: 0;
  display: flex;
  align-items: center;
  width: 65%;
  margin-left: 3.5vw;
}
.tool-bar .filter-search-icons-div-left .search-input .search-input-and-icon .search-headline {
  font-size: 2vh;
}
.tool-bar .filter-search-icons-div-left-open {
  flex-grow: 0;
  display: flex;
  align-items: center;
  width: 65%;
}
.tool-bar .filter-search-icons-div-left-open .search-input .search-input-and-icon .search-headline {
  font-size: 18px;
  font-weight: 600;
}
.tool-bar .filter-search-icons-div-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.4rem;
  flex-grow: 1;
  padding-right: 1rem;
}
.tool-bar .filter-search-icons-div-right .mat-select-trigger:first-child {
  width: 1px;
}
.tool-bar .filter-search-icons-div-right .label {
  font-size: clamp(3px, 1.5vh, 1.5vh);
  color: #92929d;
}
.tool-bar .filter-search-icons-div-right .select {
  color: #92929d;
  font-size: 2vh;
}
.tool-bar .filter-search-icons-div-right .mat-select {
  display: inline-block;
  outline: none;
}
.tool-bar .filter-search-icons-div-right .mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 7.5px solid rgba(0, 0, 0, 0);
  border-right: 7.5px solid rgba(0, 0, 0, 0);
  border-top: 7.5px solid;
  margin: 0 7px;
}
.tool-bar .filter-search-icons-div-right .mat-select-value-text {
  margin-right: 0.5vh;
  font-weight: 600;
  padding-left: 0.5vh;
  color: #44444f;
}
.tool-bar .separator {
  padding-left: 5%;
}
.tool-bar .input {
  font-size: 1em;
  background-color: transparent;
  border: 2px solid #d9e1e7;
  max-width: 500px;
  border-radius: 1rem;
  flex-grow: 1;
  color: #d9e1e7;
  display: flex;
  cursor: pointer;
  height: 6vh;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.tool-bar .input .input-and-icon {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.tool-bar .input .input-and-icon .icon {
  color: #809fb8;
  font-size: 24px;
  height: 24px;
  margin-left: 0.4rem;
  width: 26px;
}
.tool-bar .input .input-and-icon ::placeholder {
  color: #809fb8;
}
.tool-bar .input .input-and-icon .input-adjust {
  font: normal normal normal 15px/15px Heebo;
  color: #809fb8;
  cursor: pointer;
  margin-left: 0.4rem;
  width: 100%;
}
.tool-bar .input .search-button {
  font-size: 14px;
  color: #ffffff;
  background: #0a287c;
  border-radius: 10px;
  height: 88%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  margin-right: 0.3rem;
}
.tool-bar .small-fillter-options {
  background-color: transparent;
  color: #809fb8;
  display: flex;
  align-items: center;
}
.tool-bar .small-fillter-options .reset-input {
  background-color: white;
  font: normal normal 600 13px Heebo;
  text-decoration: underline;
  color: #809fb8;
}
.tool-bar .small-fillter-options .input-close {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0;
}
.tool-bar .small-fillter-options .input-close .icon-input-close {
  font-size: 20px;
  height: 20px;
  width: 18px;
}
.tool-bar .small-fillter-options .input {
  padding-inline: 1vw;
  font: normal normal normal 14px Heebo;
  color: #809fb8;
  border: none;
  padding: 0;
}
.tool-bar .small-fillter-options .tune {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.mat-form-field {
  text-align: right;
  display: flex;
  flex-direction: row;
}
.mat-form-field .mat-form-field-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  right: 100%;
}

.mat-toolbar-single-row {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  width: 100%;
  height: 10%;
  position: fixed;
  padding: 0 2vw;
}

.logo-div {
  width: 15%;
  min-width: 175px;
}
.logo-div .logo {
  display: flex;
  padding-left: 1.5rem;
  width: 60%;
  height: 75%;
  flex-direction: row;
  justify-content: flex-start;
}

.lang-panel span.fi {
  background-size: contain;
  background-position: center;
  width: 100%;
  line-height: 20px;
}
.lang-panel mat-option {
  height: 3rem !important;
  padding: 0;
}

.search-hover-container {
  position: fixed;
  top: 12vh;
  right: 20.6vw;
  z-index: 150;
}

.filter-funds-page-wrapper {
  position: fixed;
  top: 10%;
  left: max(15% + 2rem, 207px);
  width: 50vw;
  box-shadow: inset 0 1.05vh 0.7vh -0.58vh #fecb2f;
  background: #ffffff;
  z-index: 100;
}

html[dir=ltr] .admin .tool-bar .filter-search-icons-div-left {
  flex-grow: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 60%;
  margin-left: 2rem;
}
html[dir=ltr] .admin .tool-bar .filter-search-icons-div-left-open {
  flex-grow: 0;
  display: flex;
  align-items: center;
  width: 60%;
}
html[dir=ltr] .admin .tool-bar .separator {
  padding-left: 0;
  padding-right: 5%;
}
html[dir=ltr] .search-hover-container {
  position: fixed;
  top: 12vh;
  left: 20.6vw;
  z-index: 150;
}
.main-toolbar {
  height: 100%;
  width: 100vw;
  box-shadow: 15px 0px 15px rgba(10, 40, 124, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-toolbar .img-wrappper {
  min-width: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-toolbar .img-wrappper .logo {
  cursor: pointer;
  height: 40px;
  width: auto;
  margin: 10px 30px;
}
.main-toolbar .img-wrappper-mobile {
  display: none;
}
.main-toolbar .search-bar-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-left: 0;
}
.main-toolbar .search-bar-wrapper .search-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.main-toolbar .search-bar-wrapper .search-bar__hidden {
  visibility: hidden;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #262460;
  border-radius: 4px;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .icon {
  width: 36px;
  text-align: inherit;
  letter-spacing: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .search-input {
  min-width: 550px;
  flex: 1;
  text-align: inherit;
  letter-spacing: 0px;
  color: #809fb8;
  font-size: 16px;
  font-family: "Open Sans";
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .search-input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #809fb8;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .search-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #809fb8;
  opacity: 1;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .search-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #809fb8;
  opacity: 1;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #809fb8;
}
.main-toolbar .search-bar-wrapper .search-bar .search-box .find-button {
  cursor: pointer;
  background: #262460 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: #fefefe;
  font-size: 16px;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25rem;
}
.main-toolbar .search-bar-wrapper .search-bar .advanced-filter-button {
  height: 48px;
  min-width: 124px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.main-toolbar .search-bar-wrapper .search-bar .advanced-filter-button .icon {
  height: 18px;
  width: 18px;
  color: #809fb8;
}
.main-toolbar .search-bar-wrapper .search-bar .advanced-filter-button .text {
  font-size: 14px;
  color: #809fb8;
}
.main-toolbar .toolbar-icons {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  gap: 10px;
}
.main-toolbar .toolbar-icons .language-icon {
  height: 24px;
  width: 24px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-toolbar .toolbar-icons .icon-wrapper {
  width: 24px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 1;
}

.back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.16;
}

.notifications-popover {
  border: 1px solid #d9e1e7;
  border-radius: 10px;
  margin-top: 3px;
  position: absolute;
  right: 20px;
}

.languages-popover {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px rgba(22, 52, 119, 0.1607843137);
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  margin-top: 5px;
  overflow: auto;
}
.languages-popover .language-option {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  gap: 6px;
  height: 2rem;
  width: 2rem;
  align-items: center;
  padding-bottom: 17px;
}
.languages-popover .language-option:hover {
  background-color: rgba(112, 112, 112, 0.2196078431);
}

html[dir=rtl] .main-toolbar .search-bar-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto 0;
  margin-left: unset;
  margin-right: 0;
  width: 90%;
}
html[dir=rtl] .notifications-popover {
  position: absolute;
  left: 20px;
  right: unset;
}
.admin__toolbar__mobile {
  display: none;
}

@media (max-width: 776px) {
  .notifications-popover {
    position: absolute;
    transform: translate(-8%, -121%);
  }
  .admin__toolbar__mobile {
    display: block;
  }
  .admin__toolbar__mobile .main-toolbar {
    flex-direction: column;
  }
  .admin__toolbar__mobile .main-toolbar .img-wrappper,
.admin__toolbar__mobile .main-toolbar .img-wrappper-mobile,
.admin__toolbar__mobile .main-toolbar .search-bar-wrapper {
    display: none !important;
  }
  .admin__toolbar__mobile .main-toolbar .toolbar-icons {
    display: flex;
    padding: 5px;
  }
  .main-toolbar .toolbar-icons {
    display: none;
  }
  .main-toolbar .search-bar-wrapper {
    width: 90%;
    margin-right: -3%;
  }
  .main-toolbar .search-bar-wrapper .search-bar {
    width: 90%;
  }
  .main-toolbar .search-bar-wrapper .search-bar .search-box {
    width: 90%;
  }
  .main-toolbar .search-bar-wrapper .search-bar .search-box .find-button {
    padding: 2px;
    font-size: 16px;
    min-height: 25px;
  }
  .main-toolbar .search-bar-wrapper .search-bar .search-box .search-input {
    min-width: 30%;
    font-size: 10px;
  }
  .main-toolbar .search-bar-wrapper .search-bar .advanced-filter-button {
    display: flex;
    min-width: 25px;
  }
  .main-toolbar .search-bar-wrapper .search-bar .advanced-filter-button .text {
    display: none;
  }
  .main-toolbar .img-wrappper {
    display: none;
  }
  .main-toolbar .img-wrappper-mobile {
    display: flex;
  }
  .main-toolbar .img-wrappper-mobile .logo {
    cursor: pointer;
    height: 25px;
    width: auto;
    margin: 5px 5px;
  }
}
.main-sidenav {
  background: #f1f4fa;
  color: #8b8c98;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(103vh - 96px);
  align-items: flex-start;
}
.main-sidenav .automatic-search-section {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
}
.main-sidenav__navigation-buttons {
  display: flex;
  padding: 12px 10px;
  height: 56%;
  width: 92%;
  flex-direction: column;
}
.main-sidenav__navigation-buttons .navigation-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #262460;
  padding: 15px 10px;
  border-radius: 10px;
  margin: 2px 0;
  text-decoration: none;
  width: 90%;
}
.main-sidenav__navigation-buttons .navigation-button .title {
  margin: 0 10px;
}
.main-sidenav__navigation-buttons .navigation-button .icon {
  margin: 0 10px;
  filter: invert(58%) sepia(8%) saturate(400%) hue-rotate(194deg) brightness(94%) contrast(87%);
  height: 1.25rem;
  color: #262460;
}
.main-sidenav__navigation-buttons .navigation-button .spacer {
  flex: 1 1 auto;
}
.main-sidenav__navigation-buttons .navigation-button .new-funds {
  background: #fecb2e 0% 0% no-repeat padding-box;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 12px/12px OpenSans;
  font-family: "OpenSans", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 5px 10px;
  line-height: 12px;
}
.main-sidenav__navigation-buttons .navigation-button__active {
  background-color: #fecb2f;
  color: #262460;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.0784313725);
  font-weight: 700;
}
.main-sidenav__navigation-buttons .navigation-button__active .icon {
  margin: 0 10px;
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(3%) hue-rotate(197deg) brightness(95%) contrast(96%);
}
.main-sidenav__navigation-buttons .navigation-button__active .new-funds {
  background: #262460 0% 0% no-repeat padding-box;
  border-radius: 25px;
}
.main-sidenav__navigation-buttons .navigation-button:hover {
  background-color: #fecb2f;
  color: #262460;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.0784313725);
  font-weight: 700;
}
.main-sidenav__navigation-buttons .navigation-button:hover .icon {
  margin: 0 10px;
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(3%) hue-rotate(197deg) brightness(95%) contrast(96%);
}
.main-sidenav__navigation-buttons .navigation-button:hover .new-funds {
  background: #262460 0% 0% no-repeat padding-box;
  border-radius: 25px;
}
.main-sidenav__navigation-buttons .sub-navigation-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8a8c98;
  padding: 15px 10px;
  border-radius: 10px;
  text-decoration: none;
}
.main-sidenav__navigation-buttons .sub-navigation-button .title {
  margin: 0 10px;
  font: normal normal 600 16px/22px OpenSans;
}
.main-sidenav__navigation-buttons .sub-navigation-button .count {
  background: #262460 0% 0% no-repeat padding-box;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 12px/12px OpenSans;
  font-family: "OpenSans", sans-serif;
  letter-spacing: 0px;
  color: #0a287c;
  padding: 5px 10px;
  line-height: 12px;
}
.main-sidenav__navigation-buttons .sub-navigation-button__active {
  color: #080808;
}
.main-sidenav__navigation-buttons .sub-navigation-button__active .count {
  background: #262460 0% 0% no-repeat padding-box;
}
.main-sidenav__navigation-buttons .sub-navigation-button:hover {
  color: #080808;
}
.main-sidenav__footer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
}
.main-sidenav__footer .banner {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #e2e8f5;
  border-radius: 10px;
  padding: 25px 30px;
  overflow: hidden;
  gap: 10px;
}
.main-sidenav__footer .banner__text-box {
  position: relative;
}
.main-sidenav__footer .banner__text-box .icon {
  transform: rotate(-10deg);
  height: 28px;
  width: 40px;
  position: absolute;
  top: -15px;
  left: -20px;
}
.main-sidenav__footer .banner__text-box .text {
  color: black;
  width: 100px;
  font-size: 15px;
}
.main-sidenav__footer .banner__image {
  height: 96px;
  width: auto;
  bottom: -32px;
  right: 12px;
  position: absolute;
}
.main-sidenav__footer .banner .more-details-icon {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  height: 12px;
}
.main-sidenav__footer .banner .learn-more-button {
  cursor: pointer;
  background: #fecb2e;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 0.7rem;
  width: fit-content;
}
.main-sidenav__footer .profile-options {
  display: flex;
  justify-content: center;
  background: white;
  padding: 5px 10px;
  gap: 10px;
}
.main-sidenav__footer .profile-options .avatar {
  box-sizing: border-box;
  width: 32px;
  border: 2px solid #fecb2e;
  border-radius: 50%;
  height: 2rem;
}
.main-sidenav__footer .profile-options .text-box {
  font: normal normal normal 1em/1em Heebo;
  color: #8b8c98;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-sidenav__footer .profile-options .text-box .icon {
  font-size: 24px;
}
.main-sidenav__footer .profile-options .text-box .name {
  font-size: 14px;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 1;
}

.profile-options-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px rgba(22, 52, 119, 0.1607843137);
  border: 2px solid #d9e1e7;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: -5px;
}
.profile-options-menu .menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
}
.profile-options-menu .menu-item .text {
  color: #8a8c98;
}
.profile-options-menu .menu-item .icon {
  color: #8a8c98;
}
.profile-options-menu .logout-menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
  font-size: 14px;
  align-items: center;
}
.profile-options-menu .logout-menu-item .text {
  color: #8a8c98;
}
.profile-options-menu .logout-menu-item .icon {
  color: #8a8c98;
}
.profile-options-menu .upgrade-notification {
  cursor: pointer;
  background-color: #faf0cd;
  display: flex;
  border-radius: 10px;
  margin: 12px 5px;
}
.profile-options-menu .upgrade-notification .text {
  margin: 10px;
  color: #fecb2e;
}
.profile-options-menu .upgrade-notification .image {
  margin: 10px;
}

html[dir=rtl] .main-sidenav__footer .banner__image {
  right: unset;
  left: 12px;
}
@media (max-width: 776px) {
  html[dir=rtl] .main-sidenav .main-sidenav__footer .profile-options {
    min-width: 300px;
  }
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
  z-index: 100;
  width: 85%;
  height: 8vh;
  position: fixed;
  bottom: 0;
  opacity: 1;
  box-shadow: 0 -7px 26px rgba(0, 0, 0, 0.0431372549);
  background: white linear-gradient(91deg, rgba(255, 255, 255, 0.0784313725) 0, rgba(10, 40, 124, 0.3529411765) 100%) 0 0 no-repeat padding-box;
}
.footer-wrapper .footer-left-container {
  display: flex;
  align-items: center;
  width: 40vw;
  margin-left: 1vh;
  gap: 1vh;
}
.footer-wrapper .footer-left-container .footer-left-header-wrapper {
  display: flex;
  align-items: center;
}
.footer-wrapper .footer-left-container .footer-left-header-wrapper .month-and-header {
  width: max-content;
  font-size: clamp(5px, 2.5vh, 2.5vh);
}
.footer-wrapper .footer-left-container .up-icon {
  width: 3.5vw;
  min-width: 3.5vw;
}
.footer-wrapper .footer-right-container {
  width: 75%;
}
.footer-wrapper .footer-right-container .carousel {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.footer-wrapper .footer-right-container .carousel .slick-prev {
  display: none !important;
}
.footer-wrapper .footer-right-container .carousel .slider-item {
  gap: 1vw;
  padding-inline: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vh;
  min-height: 4vh;
  opacity: 0.82;
  color: #8a8c98;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
  border-radius: 6vh;
  background: #ffffff 0 0 no-repeat padding-box;
}
.footer-wrapper .footer-right-container .carousel .slider-item .left-slider-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  letter-spacing: 0.08px;
  padding-right: 6px;
  font-size: clamp(2px, 2vh, 2vh);
  padding-left: 6px;
}

html[dir=rtl] .footer-wrapper {
  background: white linear-gradient(91deg, rgba(10, 40, 124, 0.3529411765) 0, rgba(255, 255, 255, 0.0784313725) 100%) 0 0 no-repeat padding-box;
}
html[dir=rtl] .footer-wrapper .footer-left-container {
  margin-left: 0;
  margin-right: 1vh;
  width: fit-content;
}
html[dir=rtl] .footer-wrapper .footer-right-container {
  flex-grow: 1;
}
html[dir=rtl] .footer-wrapper .footer-right-container .carousel .slider-item {
  flex-direction: row-reverse;
}
html[dir=rtl] .footer-wrapper .footer-right-container .carousel .slider-item .left-slider-item {
  padding-left: 6px;
}

.hover-search-container {
  background-color: white;
  border: 2px solid #d9e1e7;
  border-radius: 9px;
  width: 40vw;
  font: normal normal 600 13px/55px Heebo;
  color: #8a8c98;
  min-width: 450px;
  z-index: 100;
}
.hover-search-container .hover-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(138, 140, 152, 0.27);
}
.hover-search-container .hover-item .left-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1vh;
  flex-grow: 1;
  gap: 1.5vh;
}
.hover-search-container .hover-item .left-item-container .hover-profile-image {
  border: 1px solid rgba(112, 112, 112, 0.2901960784);
  object-fit: contain;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.hover-search-container .hover-item .left-item-container .hover-search-result {
  font-size: 14px;
  line-height: 55px;
  font-weight: 100;
}
.hover-search-container .hover-item .left-item-container .tags-county {
  opacity: 0.51;
}
.hover-search-container .hover-item .left-item-container .spacer-hover-item {
  margin-right: auto;
}
.hover-search-container .hover-item .hover-enter {
  text-decoration: underline;
  margin-right: 1vh;
}

html[dir=rtl] .hover-enter {
  margin-left: 1.5vh;
  margin-right: 0 !important;
}
html[dir=rtl] .left-item-container {
  margin-right: 1.5vh;
  margin-left: 0 !important;
}
html[dir=rtl] .spacer-hover-item {
  margin-left: auto;
  margin-right: 0 !important;
}

.onboarding-welcome {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 30px 120px;
}
.onboarding-welcome .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.onboarding-welcome__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 350px;
  margin: auto;
  gap: 10px;
}
.onboarding-welcome__content .title {
  color: #1c2d57;
  font-size: 24px;
  text-align: center;
}
.onboarding-welcome__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.onboarding-welcome__content .image {
  width: 250px;
  height: auto;
}
.onboarding-welcome__content .note {
  color: #cccdd6;
  font-size: 12px;
  text-align: center;
}
.onboarding-welcome__content .lets-start-button {
  width: 100%;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
  display: flex;
  justify-content: center;
}
.onboarding-welcome__content .skip-button {
  font-size: 14px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 776px) {
  .onboarding-welcome {
    padding: 10% 10%;
  }
}
.onboarding-base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/images/screen-background.png");
}
.onboarding-base__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(10, 40, 124, 0.63);
  backdrop-filter: blur(4px);
}
.onboarding-base__content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboarding-base__content .onboarding-card {
  margin: auto;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #707070;
  max-height: 80vh;
  overflow: scroll;
}

.onboarding-success {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 50px 120px 30px 120px;
}
.onboarding-success .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.onboarding-success .success-title {
  padding: 15px;
  position: absolute;
  font-weight: 600;
  font-size: 18px;
  color: #3a36db;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
}
.onboarding-success__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 350px;
  margin: auto;
  gap: 10px;
}
.onboarding-success__content .title {
  color: #1c2d57;
  font-size: 24px;
}
.onboarding-success__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.onboarding-success__content .image {
  width: 250px;
  height: auto;
}
.onboarding-success__content .note {
  color: #cccdd6;
  font-size: 12px;
  text-align: center;
}
.onboarding-success__content .lets-dive-in-button {
  width: 100%;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
  display: flex;
  justify-content: center;
}
.onboarding-success__content .skip-button {
  font-size: 14px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 776px) {
  .onboarding-success {
    padding: 20% 0% 10% 0%;
  }
  .onboarding-success__content {
    padding: 10px;
  }
}
.areas-of-interests {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 70px 100px 30px 100px;
}
.areas-of-interests .back-button {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 15px;
  left: 15px;
  height: 24px;
  width: auto;
}
.areas-of-interests .back-button .text {
  font-size: 18px;
  color: black;
}
.areas-of-interests .back-button .icon {
  height: 24px;
  width: 24px;
  color: black;
}
.areas-of-interests .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.areas-of-interests .step-title {
  padding: 15px;
  position: absolute;
  font-weight: 600;
  font-size: 18px;
  color: #3a36db;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
}
.areas-of-interests__content {
  width: 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  gap: 10px;
}
.areas-of-interests__content .title {
  color: #1c2d57;
  font-size: 24px;
}
.areas-of-interests__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.areas-of-interests__content .interests-select {
  width: 100%;
  margin-bottom: 20px;
}
.areas-of-interests__content .next-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
}
.areas-of-interests__content .skip-button {
  font-size: 14px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

.areas-of-interests-select {
  width: 100%;
}
.areas-of-interests-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.areas-of-interests-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  padding: 10px 5px;
}
.areas-of-interests-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.areas-of-interests-select .custom-select .select-button .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.areas-of-interests-select .custom-select .select-button .country__icon {
  font-size: 14px;
}
.areas-of-interests-select .custom-select .select-button .country__text {
  color: #000000;
  font-size: 14px;
}
.areas-of-interests-select .custom-select .select-button .icon {
  color: #0a287c;
}
.areas-of-interests-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.areas-of-interests-select-panel .disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
  color: gray;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option .country__icon {
  font-size: 14px;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option .country__text {
  color: #000000;
  font-size: 14px;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.areas-of-interests-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.areas-of-interests-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

@media (max-width: 776px) {
  .areas-of-interests {
    padding: 20% 0% 10% 0%;
  }
  .areas-of-interests__content {
    padding: 10px;
  }
}
.areas-of-activities {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 70px 100px 30px 100px;
}
.areas-of-activities .back-button {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 15px;
  left: 15px;
  height: 24px;
  width: auto;
}
.areas-of-activities .back-button .text {
  font-size: 18px;
  color: black;
}
.areas-of-activities .back-button .icon {
  height: 24px;
  width: 24px;
  color: black;
}
.areas-of-activities .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.areas-of-activities .step-title {
  padding: 15px;
  position: absolute;
  font-weight: 600;
  font-size: 18px;
  color: #3a36db;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
}
.areas-of-activities__content {
  width: 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  gap: 10px;
}
.areas-of-activities__content .title {
  color: #1c2d57;
  font-size: 24px;
}
.areas-of-activities__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.areas-of-activities__content .activities-select {
  width: 100%;
  margin-bottom: 20px;
}
.areas-of-activities__content .next-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
}
.areas-of-activities__content .skip-button {
  font-size: 14px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

.areas-of-activities-select {
  width: 100%;
}
.areas-of-activities-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.areas-of-activities-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  padding: 10px 5px;
}
.areas-of-activities-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.areas-of-activities-select .custom-select .select-button .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.areas-of-activities-select .custom-select .select-button .country__icon {
  font-size: 14px;
}
.areas-of-activities-select .custom-select .select-button .country__text {
  color: #000000;
  font-size: 14px;
}
.areas-of-activities-select .custom-select .select-button .icon {
  color: #0a287c;
}
.areas-of-activities-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option .country__icon {
  font-size: 14px;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option .country__text {
  color: #000000;
  font-size: 14px;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.areas-of-activities-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.areas-of-activities-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

@media (max-width: 776px) {
  .areas-of-activities {
    padding: 20% 0% 10% 0%;
  }
  .areas-of-activities__content {
    padding: 10px;
  }
}
.funding-sums {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 70px 100px 30px 100px;
}
.funding-sums .back-button {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 15px;
  left: 15px;
  height: 24px;
  width: auto;
}
.funding-sums .back-button .text {
  font-size: 18px;
  color: black;
}
.funding-sums .back-button .icon {
  height: 24px;
  width: 24px;
  color: black;
}
.funding-sums .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.funding-sums .step-title {
  padding: 15px;
  position: absolute;
  font-weight: 600;
  font-size: 18px;
  color: #3a36db;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
}
.funding-sums__content {
  width: 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  gap: 10px;
}
.funding-sums__content .title {
  color: #1c2d57;
  font-size: 24px;
}
.funding-sums__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.funding-sums__content .funding-sums-slider-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.funding-sums__content .funding-sums-slider-wrapper .funding-sums-value {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.funding-sums__content .funding-sums-slider-wrapper .funding-sums-slider {
  width: 100%;
  padding: 10px;
  display: flex;
}
.funding-sums__content .funding-sums-slider-wrapper .funding-sums-slider .slider-wrapper .slider-header {
  display: none;
}
.funding-sums__content .next-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
}
.funding-sums__content .skip-button {
  font-size: 14px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 776px) {
  .funding-sums {
    padding: 20% 0% 10% 0%;
  }
  .funding-sums__content {
    padding: 10px;
  }
}
.skip-onboarding {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 30px 120px;
  border-radius: 20px;
  background-color: white;
  max-height: 80vh;
  overflow: scroll;
}
.skip-onboarding .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.skip-onboarding__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 350px;
  margin: auto;
  gap: 10px;
}
.skip-onboarding__content .title {
  color: #1c2d57;
  font-size: 24px;
  text-align: center;
}
.skip-onboarding__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.skip-onboarding__content .image {
  width: 250px;
  height: auto;
}
.skip-onboarding__content .back-button {
  width: 100%;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
  display: flex;
  justify-content: center;
}
.skip-onboarding__content .skip-button {
  font-size: 14px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 776px) {
  .skip-onboarding {
    padding: 30px 30px;
  }
  .skip-onboarding__content {
    width: 100%;
  }
}
.funds-search {
  height: calc(100vh - 96px);
  width: 100%;
  display: flex;
  flex-direction: row;
}
.funds-search .filters-sidebar {
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
  width: 25%;
}
.funds-search .filters-sidebar__header {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: -3%;
  margin-top: 2%;
}
.funds-search .filters-sidebar__header .icon-btn {
  background-color: #f1f4fa;
  box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  width: 30px;
  height: 30px;
}
.funds-search .filters-sidebar__header .icon-btn:hover {
  background-color: #fecb2e;
}
.funds-search .filters-sidebar__header .expand-icon {
  color: black;
  margin-top: -12px;
}
.funds-search .filters-sidebar__content {
  min-width: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow-y: scroll;
}
.funds-search .filters-sidebar__content .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.funds-search .filters-sidebar__content .header .title {
  color: #06152b;
  font-size: 16px;
}
.funds-search .filters-sidebar__content .header .reset-filters {
  cursor: pointer;
  color: #809fb8;
  font-size: 14px;
  text-decoration: underline;
}
.funds-search .filters-sidebar__content .divider {
  height: 1px;
  width: 100%;
  background-color: #d3dde8;
}
.funds-search .filters-sidebar__content .filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  overflow-x: hidden;
  flex: 1;
  padding-inline-end: 1vw;
}
.funds-search .filters-sidebar__content .filters .filter {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__header .title {
  font-size: 14px;
  font-weight: bold;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__header .expand-icon {
  color: black;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option input[type=checkbox]:checked:before {
  width: calc(4.5714285714px);
  height: calc(10.6666666667px);
  margin: 0 calc(4.5714285714px);
  border-bottom: calc(2.2857142857px) solid #ffffff;
  border-right: calc(2.2857142857px) solid #ffffff;
  transform: rotate(45deg);
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option .text {
  color: #44444f;
  font-size: 14px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-option .count {
  color: #44444f;
  font-size: 12px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options {
  cursor: pointer;
  padding-inline: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-block: 5px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options input[type=checkbox]:checked:before {
  width: calc(4.5714285714px);
  height: calc(10.6666666667px);
  margin: 0 calc(4.5714285714px);
  border-bottom: calc(2.2857142857px) solid #ffffff;
  border-right: calc(2.2857142857px) solid #ffffff;
  transform: rotate(45deg);
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options .text {
  color: #44444f;
  font-size: 14px;
}
.funds-search .filters-sidebar__content .filters .filter .expandable__content .filter-options .count {
  color: #44444f;
  font-size: 12px;
}
.funds-search .filters-sidebar__content .filters-parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  flex: 1;
  padding-inline-end: 1vw;
}
.funds-search .filters-sidebar__content .filters-parent .filter {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__header .title {
  font-size: 14px;
  font-weight: bold;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__header .expand-icon {
  color: black;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option input[type=checkbox]:checked:before {
  width: calc(4.5714285714px);
  height: calc(10.6666666667px);
  margin: 0 calc(4.5714285714px);
  border-bottom: calc(2.2857142857px) solid #ffffff;
  border-right: calc(2.2857142857px) solid #ffffff;
  transform: rotate(45deg);
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option .text {
  color: #44444f;
  font-size: 14px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-option .count {
  color: #44444f;
  font-size: 12px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options {
  cursor: pointer;
  padding-inline: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-block: 5px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options input[type=checkbox]:checked:before {
  width: calc(4.5714285714px);
  height: calc(10.6666666667px);
  margin: 0 calc(4.5714285714px);
  border-bottom: calc(2.2857142857px) solid #ffffff;
  border-right: calc(2.2857142857px) solid #ffffff;
  transform: rotate(45deg);
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options .text {
  color: #44444f;
  font-size: 14px;
}
.funds-search .filters-sidebar__content .filters-parent .filter .expandable__content .filter-options .count {
  color: #44444f;
  font-size: 12px;
}
.funds-search .filters-sidebar__content ::-webkit-scrollbar {
  width: 0.1vw;
  margin-left: 1vw;
}
.funds-search .filters-sidebar__content ::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 4px;
}
.funds-search .filters-sidebar__content ::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 4px;
}
.funds-search__content {
  height: calc(100vh - 96px);
  flex: 1;
  display: flex;
  flex-direction: column;
}
.funds-search__content .toolbar {
  height: 50px;
  max-height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  gap: 15px;
  background-color: white;
  top: 0;
  z-index: 1;
  box-shadow: 15px 0px 15px rgba(10, 40, 124, 0.2);
}
.funds-search__content .toolbar .title {
  text-align: start;
  font-size: 18px;
  color: #171725;
  display: flex;
  align-items: center;
}
.funds-search__content .toolbar .active-automatic-search-button {
  padding: 5px 15px;
  background: #fecb2e 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #0a287c;
  text-align: center;
  letter-spacing: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.funds-search__content .toolbar .save-search-button {
  padding: 5px 15px;
  background: #b98f0f 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #0a287c;
  text-align: center;
  letter-spacing: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}
.funds-search__content .toolbar .alignment-toggle .button-group {
  border-radius: 8px;
  border: none;
}
.funds-search__content .toolbar .alignment-toggle .button-group mat-button-toggle {
  background-color: #ffffff;
}
.funds-search__content .toolbar .alignment-toggle .button-group .mat-button-toggle {
  border: none;
}
.funds-search__content .toolbar .alignment-toggle .button-group .mat-button-toggle-disabled {
  background-color: #e1dde5;
  color: #90abc1;
}
.funds-search__content .toolbar .alignment-toggle .button-group .mat-button-toggle-checked {
  background-color: #0a287c;
  color: #ffffff;
}
.funds-search__content .toolbar .alignment-toggle .button-group .mat-button-toggle-label-content {
  padding: 6px;
  line-height: 24px;
}
.funds-search__content .toolbar .sorting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.funds-search__content .toolbar .sorting .title {
  font-size: 14px;
  color: #92929d;
}
.funds-search__content .toolbar .sorting .sorting-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.funds-search__content .toolbar .sorting .sorting-button .value {
  font-size: 14px;
  color: black;
}
.funds-search__content .results {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  padding: 10px 10px 10px 10px;
  overflow: auto;
  overflow-x: hidden;
}
.funds-search__content .results::-webkit-scrollbar {
  width: 8px;
}
.funds-search__content .results::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.funds-search__content .results::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.funds-search__content .results .funds-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.funds-search__content .results .load-more {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.funds-search__content .results .load-more__button {
  cursor: pointer;
  padding: 7px 15px;
  border: none;
  width: 16%;
  border-radius: 8px;
  border: 1px solid #3e96dc;
  color: #3e96dc;
  text-align: center;
  font-size: 14px;
}
.funds-search__content .results .load-more__loading {
  zoom: 0.5;
}
.funds-search__content .results .search-history-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.funds-search__content .results .search-history-wrapper .search-history-header {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0.08px;
  color: #171725;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper {
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  gap: 20px;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .search-history-category-header {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.08px;
  color: #0a287c;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container {
  width: 100%;
  text-align: left;
  letter-spacing: 0.08px;
  color: #0a287c;
  gap: 13px;
  display: flex;
  flex-direction: column;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  cursor: pointer;
  height: 40px;
  align-items: center;
  padding-inline: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: solid 1px #707070;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body:hover {
  box-shadow: 0px 10px 100px rgba(138, 161, 203, 0.1490196078);
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .date-td {
  width: 18%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .date-td .date {
  text-align: initial;
  letter-spacing: 0.06px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .result-td {
  width: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .result-td .sap {
  border: solid 0.1px;
  background: gray;
  height: 10px;
  margin-inline: 10%;
  align-items: center;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .result-td .result {
  text-align: initial;
  font-size: 12px;
  letter-spacing: 0.06px;
  color: #809fb8;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .filters-td {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .filters-td .sap {
  border: solid 0.1px;
  background: gray;
  height: 10px;
  margin-inline: 4%;
  align-items: center;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .filters-td .filters {
  text-align: initial;
  font-size: 12px;
  letter-spacing: 0.06px;
  color: #809fb8;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .open-search-td {
  width: 18%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .open-search-td .sap {
  border: solid 0.1px;
  background: gray;
  height: 10px;
  margin-inline: 10%;
  align-items: center;
}
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .open-search-td .open-search {
  text-align: initial;
  text-decoration: underline;
  font-size: 12px;
  letter-spacing: 0.06px;
  color: #0a287c;
}

.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 1;
}

.sorting-popover {
  width: 100%;
  background-color: white;
  border: 1px solid #92929d;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
}
.sorting-popover .sorting-option {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
}
.sorting-popover .sorting-option .text {
  color: black;
  font-size: 12px;
}
.sorting-popover .sorting-option:hover {
  background-color: #92929d;
}

html[dir=rtl] .funds-search .toolbar {
  height: 50px;
  max-height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  gap: 15px;
  background-color: white;
  top: 0;
  z-index: 1;
  box-shadow: -15px 0px 15px rgba(10, 40, 124, 0.2);
}

@media (max-width: 776px) {
  .funds-search__content {
    width: 100%;
  }
  .funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .search-history-category-header {
    text-align: center;
  }
  .funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body {
    padding-inline: 0px;
  }
  .funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .open-search-td,
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .filters-td,
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .result-td,
.funds-search__content .results .search-history-wrapper .search-history-categories-wrapper .search-history-category-wrapper .table-container .table-body .date-td {
    width: 100%;
  }
}
.logo-container {
  width: 96px; /* Adjust the width and height as per your requirements */
  height: 96px;
  border-radius: 50%; /* Make the container a circle */
  overflow: hidden; /* Hide any content that exceeds the container boundaries */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */
}

.logo-image {
  max-width: 96px; /* Adjust the maximum width as per your requirements */
  height: auto; /* Maintain the aspect ratio of the logo image */
  object-fit: contain; /* Preserve the aspect ratio of the image */
}

.funds-list-item {
  cursor: pointer;
  padding: 10px 10px 40px 10px;
  border: 1px solid rgba(128, 159, 184, 0.6156862745);
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  box-shadow: 0px 1px 4.5px 1px rgba(0, 0, 0, 0.125);
}
.funds-list-item.read {
  background-color: rgba(0, 0, 0, 0.0352941176);
}
.funds-list-item__logo {
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.funds-list-item__logo .logo {
  width: 64px;
  height: 64px;
}
.funds-list-item__logo .logo-placeholder {
  cursor: default;
  width: 96px;
  height: 96px;
  font-size: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.funds-list-item__logo .logo-placeholder .text {
  font-size: 12px;
}
.funds-list-item__logo .logo-placeholder .logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.funds-list-item__logo .logo-placeholder .logo-wrapper .fund-type-icon {
  height: unset;
  width: unset;
  font-size: 42px;
}
.funds-list-item__logo .logo-placeholder .logo-image {
  max-width: 38%;
  max-height: 38%;
}
.funds-list-item__top-actions {
  top: 10px;
  right: 10px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.funds-list-item__top-actions .score {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 78px;
  border-radius: 8px;
  height: 30px;
  color: white;
  justify-content: center;
}
.funds-list-item__top-actions .score .icon {
  font-size: 14px;
  height: 14px;
  width: 14px;
  color: white;
}
.funds-list-item__top-actions .score .text {
  font-size: 12px;
  font-weight: bold;
}
.funds-list-item__top-actions .more-details {
  cursor: pointer;
  font-size: 18px;
  height: 18px;
  width: 18px;
  color: #809fb8;
}
.funds-list-item__bottom-actions {
  bottom: 10px;
  right: 10px;
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.funds-list-item__bottom-actions .count {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 12px/12px OpenSans;
  font-family: "OpenSans", sans-serif;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  background: #0a287c;
  border: solid #0a287c;
}
.funds-list-item__bottom-actions .add-to-favourites-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  min-inline-size: max-content;
  color: #0a287c;
  background: #ffffff;
  border: 1px solid #0a287c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.funds-list-item__bottom-actions .add-to-favourites-button .text {
  font-size: 14px;
  line-height: 14px;
}
.funds-list-item__bottom-actions .add-to-favourites-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.funds-list-item__bottom-actions .add-to-workplan-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  color: white;
  background: #0a287c;
  border: solid #0a287c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.funds-list-item__bottom-actions .add-to-workplan-button .text {
  font-size: 14px;
  line-height: 14px;
}
.funds-list-item__bottom-actions .add-to-workplan-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.funds-list-item__bottom-actions .share-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background: #fecb2e;
  border: solid #fecb2e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.funds-list-item__bottom-actions .share-button .text {
  font-size: 14px;
  line-height: 14px;
}
.funds-list-item__bottom-actions .share-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.funds-list-item .toolbar-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.funds-list-item__content {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.funds-list-item__content .fund-name {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}
.funds-list-item__content .fund-name.read {
  color: #681da8;
}
.funds-list-item__content .fund-type {
  cursor: default;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 10px;
}
.funds-list-item__content .subtitle {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  height: 32px;
  padding-bottom: 2%;
}
.funds-list-item__content .subtitle .summary {
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.funds-list-item__content .subtitle .separator {
  font-size: 10px;
  color: #7d7d7d;
}
.funds-list-item__content .subtitle .location {
  font-size: 12px;
  color: #7d7d7d;
}
.funds-list-item__content .description {
  font-size: 12px;
  color: #7d7d7d;
  font-weight: bold;
  width: 520px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.funds-list-item__content .interests {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.funds-list-item__content .interests .interest-item {
  cursor: default;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: rgba(0, 149, 255, 0.0784313725);
  border: 1px solid #0095ff;
  display: flex;
}
.funds-list-item__content .interests .interest-item .text {
  font-size: 10px;
  color: #0095ff;
  font-weight: 600;
  letter-spacing: 0.12ch;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 1;
}

html[dir=rtl] .funds-list-item__top-actions {
  right: unset;
  left: 10px;
}
html[dir=rtl] .funds-list-item__bottom-actions {
  right: unset;
  left: 10px;
}

@media (max-width: 776px) {
  .funds-list-item {
    flex-direction: column;
  }
  .funds-list-item__content .description {
    width: 100%;
  }
  .funds-list-item__logo {
    align-items: center;
  }
  .funds-list-item__bottom-actions .text {
    display: none;
  }
}
.cards {
  min-width: 100px;
  display: flex;
  flex-direction: row;
  height: 5.6rem;
  margin-bottom: 1rem;
}
.cards .card {
  cursor: pointer;
  padding: 10px 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #f0f3f9;
  border-radius: 10px;
  border: solid 2px #e9ebf1;
  width: fit-content;
}
.cards .card:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
}
.cards .card .title-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.cards .card .title-container .title {
  font-size: 14px;
  color: #8da9c0;
}
.cards .card .title-container .pill {
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1;
  border-radius: 25px;
  background-color: #3498db;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
}
.cards .card .title-container .pill:hover {
  background-color: #2980b9;
}
.cards .card .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
}
.cards .card .content .value {
  font-size: 2.2rem;
  font-weight: 300;
  color: #000000;
}
.cards .card .content .customize-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
.cards .overview-card-search {
  cursor: pointer;
  padding: 5px 60px 5px 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #f0f3f9;
  border-radius: 10px;
  border: solid 2px #e9ebf1;
  width: 9.5rem;
  /*.icon-active {
        display: none;
        width: 85px;
        position: absolute;
        top: 0px;
        bottom: 0;
        left: 135px;
        right: 0px;
        margin: auto;
      }*/
}
.cards .overview-card-search:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
  /*.icon {
        display: none;
        width: 85px;
        position: absolute;
        top: 0px;
        bottom: 0;
        left: 135px;
        right: 0px;
        margin: auto;
      }

      .icon-active {
        display: unset;
        width: 85px;
        position: absolute;
        top: 0px;
        bottom: 0;
        left: 135px;
        right: 0px;
        margin: auto;
      }

      .customize-button {
        visibility: visible;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #d7e1eb;
        color: #141516;
        font-size: $font-small;
        font-weight: bold;
      }*/
}
.cards .overview-card-search__title {
  font-size: 14px;
  color: #8da9c0;
}
.cards .overview-card-search__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.cards .overview-card-search__content .value {
  font-size: 2.2rem;
  font-weight: 300;
  color: #000000;
}
.cards .overview-card-search__content .customize-button {
  visibility: hidden;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
.cards .overview-card-search__icon {
  width: 60px;
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 176px;
  right: 0px;
  margin: auto;
  opacity: 1;
  transform: scaleX(-1);
}
.cards .overview-card-funds {
  cursor: pointer;
  padding: 5px 60px 5px 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #f0f3f9;
  border-radius: 10px;
  font-family: "Open Sans";
  border: solid 2px #e9ebf1;
  width: 9.8rem;
}
.cards .overview-card-funds:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
}
.cards .overview-card-funds__title {
  font-size: 14px;
  color: #8da9c0;
}
.cards .overview-card-funds__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.cards .overview-card-funds__content .value {
  font-size: 2.2rem;
  font-weight: 300;
  color: #000000;
}
.cards .overview-card-funds__content .customize-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
.cards .overview-card-funds__icon {
  width: 52px;
  position: absolute;
  top: 0px;
  bottom: 4px;
  left: 119px;
  right: 0px;
  margin: auto;
  opacity: 1;
}

.fund-details {
  height: calc(100vh - 96px);
  flex: 1;
  overflow: scroll;
}
.fund-details__header {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  gap: 5px;
  background-color: white;
  position: initial;
  top: 0;
  border-bottom: 2px solid rgba(138, 140, 152, 0.5333333333);
  z-index: 1;
}
.fund-details__header .toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.fund-details__header .toolbar .back-button {
  cursor: pointer;
  height: 20px;
}
.fund-details__header .toolbar .fund-type {
  cursor: default;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 10px;
}
.fund-details__header .toolbar .fund-name {
  font-size: 18px;
  color: #171725;
  font-weight: bold;
  font-family: "Open Sans";
}
.fund-details__header .toolbar .separator {
  font-size: 14px;
  color: #8a8c98;
}
.fund-details__header .toolbar .organization-number {
  font-size: 14px;
  color: #8a8c98;
}
.fund-details__header .toolbar__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.fund-details__header .toolbar__buttons .add-to-workplan-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  min-inline-size: max-content;
  color: #0a287c;
  background: #ffffff;
  border: 1px solid #0a287c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.fund-details__header .toolbar__buttons .add-to-workplan-button .text {
  font-family: "Open Sans";
  font-size: 12px;
}
.fund-details__header .toolbar__buttons .add-to-workplan-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.fund-details__header .toolbar__buttons .financial-reports-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  min-inline-size: max-content;
  color: white;
  background: #0a287c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.fund-details__header .toolbar__buttons .financial-reports-button .text {
  font-family: "Open Sans";
  font-size: 12px;
}
.fund-details__header .toolbar__buttons .financial-reports-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.fund-details__header .toolbar__buttons .apply-online-button {
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background: #fecb2e;
  border: 1px solid #fecb2e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.fund-details__header .toolbar__buttons .apply-online-button .text {
  font-family: "Open Sans";
  font-size: 12px;
}
.fund-details__header .toolbar__buttons .apply-online-button .icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.fund-details__header .toolbar__buttons .update-data-button {
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background: #fecb2e;
  border: 1px solid #fecb2e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.fund-details__header .toolbar__buttons .update-data-button .text {
  font-family: "Open Sans";
  font-size: 12px;
}
.fund-details__header .toolbar__buttons .update-data-button .icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.fund-details__header .toolbar__buttons .favourites-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.fund-details__header .toolbar__buttons .more-options-button {
  cursor: pointer;
  font-size: 18px;
  height: 18px;
  width: 18px;
  color: #809fb8;
}
.fund-details__header .details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.fund-details__header .details .score {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  min-width: 70px;
  max-width: 340px;
}
.fund-details__header .details .score .icon {
  font-size: 20px;
  height: 20px;
  min-width: 25px;
  max-width: 40px;
  color: #22d598;
}
.fund-details__header .details .score .text {
  font-size: 12px;
  font-weight: bold;
}
.fund-details__header .details .address {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.fund-details__header .details .address .icon {
  height: 20px;
  width: 20px;
}
.fund-details__header .details .address .text {
  font-size: 12px;
  font-weight: bold;
}
.fund-details__header .details .website {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.fund-details__header .details .website .icon {
  height: 20px;
  width: 20px;
}
.fund-details__header .details .website .text {
  font-size: 12px;
  font-weight: bold;
  color: #4b9dcc;
  width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fund-details__header .details .contact {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.fund-details__header .details .contact .icon {
  height: 20px;
  width: 20px;
}
.fund-details__header .details .contact .text {
  font-size: 12px;
  font-weight: bold;
  color: #4b9dcc;
}
.fund-details__header .details .last-updated {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  color: #809fb8;
}
.fund-details__header .actions {
  display: flex;
  flex-direction: row;
}
.fund-details__header .actions .tabs {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.fund-details__header .actions .tabs .tab-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f5f5f6;
  color: rgba(138, 140, 152, 0.5333333333);
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f6;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.fund-details__header .actions .tabs .tab-button .icon {
  height: 18px;
  width: auto;
}
.fund-details__header .actions .tabs .tab-button .text {
  font-size: 14px;
}
.fund-details__header .actions .tabs .tab-button__active {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid rgba(138, 140, 152, 0.5333333333);
  border-bottom: 3px solid #fecb2e;
  margin-bottom: -3px;
}
.fund-details__header .actions .actions-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.fund-details__header .actions .actions-buttons__history {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: rgba(138, 140, 152, 0.5333333333);
}
.fund-details__header .actions .actions-buttons__history .icon {
  height: 18px;
  width: auto;
}
.fund-details__header .actions .actions-buttons__history .text {
  font-size: 14px;
}
.fund-details__header .actions .actions-buttons__history__active {
  color: black;
}
.fund-details__header .actions .actions-buttons__history__green {
  color: forestgreen;
}
.fund-details__header .actions .actions-buttons .divider {
  height: 24px;
  width: 1px;
  background-color: rgba(138, 140, 152, 0.5333333333);
}
.fund-details__header .actions .actions-buttons__comments {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: rgba(138, 140, 152, 0.5333333333);
}
.fund-details__header .actions .actions-buttons__comments .icon {
  height: 18px;
  width: auto;
}
.fund-details__header .actions .actions-buttons__comments .text {
  font-size: 14px;
}
.fund-details__header .actions .actions-buttons__comments__active {
  color: black;
}
.fund-details__header .actions .actions-buttons__comments__green {
  color: forestgreen;
}
.fund-details__header .actions .actions-buttons .flag-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.fund-details__header .actions .actions-buttons .bug-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.fund-details__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

html[dir=rtl] .fund-details__header .toolbar .back-button {
  transform: rotate(180deg);
}

@media (max-width: 776px) {
  .fund-details .details {
    flex-direction: column;
  }
  .fund-details .details .address .text {
    text-align: center;
  }
  .fund-details__header .actions {
    flex-direction: column;
    align-items: center;
  }
  .fund-details__header .actions .actions-buttons {
    padding: 5%;
  }
  .fund-details__header .actions .tabs .tab-button .text {
    display: none;
  }
  .fund-details__header .toolbar {
    flex-direction: column;
  }
  .fund-details__header .toolbar__buttons .text {
    display: none;
  }
}
.fund-about {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.fund-about .charts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}
.fund-about .charts .chart-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 25px 20px 25px;
  box-shadow: 0px 10px 100px rgba(138, 161, 203, 0.1490196078);
  border: 1px solid #e9f1ff;
  border-radius: 15px;
  gap: 10px;
  box-sizing: border-box;
}
.fund-about .charts .chart-card__header .title {
  font-size: 14px;
  font-weight: bold;
  color: #141414;
}
.fund-about .charts .chart-card__content .chart-canvas {
  direction: ltr;
  height: 220px;
}
.fund-about .charts .chart-card-doughnut {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 25px 20px 25px;
  box-shadow: 0px 10px 100px rgba(138, 161, 203, 0.1490196078);
  border: 1px solid #e9f1ff;
  border-radius: 15px;
  gap: 10px;
  align-items: center;
  box-sizing: border-box;
}
.fund-about .charts .chart-card-doughnut__header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fund-about .charts .chart-card-doughnut__header .title {
  font-size: 14px;
  font-weight: bold;
  color: #141414;
}
.fund-about .charts .chart-card-doughnut__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fund-about .charts .chart-card-doughnut__content .chart-canvas {
  min-height: 220px;
}
.fund-about .charts .chart-card-fullwidth {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 25px 20px 25px;
  box-shadow: 0px 10px 100px rgba(138, 161, 203, 0.1490196078);
  border: 1px solid #e9f1ff;
  border-radius: 15px;
  gap: 10px;
  box-sizing: border-box;
}
.fund-about .charts .chart-card-fullwidth__header .title {
  font-size: 14px;
  font-weight: bold;
  color: #141414;
}
.fund-about .charts .chart-card-fullwidth__content .chart-canvas {
  height: 220px;
}
.fund-about .information {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.fund-about .information .text-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.fund-about .information .text-box .title {
  text-align: initial;
  font: normal normal 600 18px/36px;
  letter-spacing: 0.07px;
  color: #171725;
  font-family: "Open Sans";
  font-weight: 600;
  background: linear-gradient(to left, #fff7df, transparent);
  padding: 5px 7px;
}
.fund-about .information .text-box .divider {
  height: 1px;
  background-color: #dddddd;
  width: 100%;
}
.fund-about .information .text-box .description {
  text-align: initial;
  font: normal normal normal 15px/20px;
  letter-spacing: 0.06px;
  color: #171725;
  font-family: "Open Sans";
  white-space: pre-line;
}

.about-columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 40px;
}

.about-text {
  width: 66%;
}

.about-charts {
  width: 34%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 1400px) {
  .about-text {
    width: 100%;
  }
  .about-charts {
    width: 100%;
  }
  .fund-about .charts {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .fund-about .charts .chart-card {
    width: 100%;
    min-width: 250px;
  }
  .fund-about .charts .chart-card-doughnut {
    min-width: 250px;
  }
}
@media (max-width: 776px) {
  .about-columns {
    flex-direction: column-reverse;
  }
}
.financial-charts-container {
  padding: 40px;
}

.financial-charts-rows {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  justify-content: space-between;
}

.financial-charts-cols {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
}

.chart-container {
  width: 50%;
  padding: 20px 40px;
  box-shadow: 0px 0px 75px rgba(138, 161, 203, 0.15);
  border: 1px solid #e9f1ff;
  border-radius: 15px;
}

.chart-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-header-value {
  font-weight: bold;
  font-size: larger;
  margin-right: 0.25rem;
}

.chart-header-year {
  color: #949292;
}

.financial-reports-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 65%;
}
.financial-reports-main .financial-reports-main-header {
  text-align: initial;
  font: normal normal 600 18px/36px "Open Sans";
  letter-spacing: 0.07px;
  color: #171725;
}
.financial-reports-main .divider {
  height: 1px;
  background-color: #dddddd;
  max-width: 520px;
}
.financial-reports-main .financial-reports-list-wrapper {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-inline: 10px;
}
.financial-reports-main .financial-reports-list-wrapper .financial-report-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
}
.financial-reports-main .financial-reports-list-wrapper .financial-report-wrapper .img-pdf-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
}
.financial-reports-main .financial-reports-list-wrapper .financial-report-wrapper .img-pdf-name .img {
  padding: 10px;
}
.financial-reports-main .financial-reports-list-wrapper .financial-report-wrapper .img-pdf-name .pdf-name {
  text-align: left;
  font: normal normal normal 15px/20px OpenSans;
  letter-spacing: 0.06px;
  color: #171725;
  opacity: 1;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
}
.financial-reports-main .financial-reports-list-wrapper .financial-report-wrapper .clickable {
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: underline;
  color: #4b9dcc;
}

.grant-main {
  width: 75%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.grant-main .grant-main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.grant-main .grant-main-wrapper .grant-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.grant-main .grant-main-wrapper .grant-wrapper .header-divider .header {
  text-align: initial;
  font-size: 16px;
  font-family: "Open Sans";
  letter-spacing: 0.07px;
  color: #171725;
}
.grant-main .grant-main-wrapper .grant-wrapper .header-divider .divider {
  height: 1px;
  width: 100%;
  background-color: #d3dde8;
}
.grant-main .grant-main-wrapper .grant-wrapper .content {
  text-align: initial;
  font-size: 14px;
  font-family: "Open Sans";
  letter-spacing: 0.06px;
  color: #171725;
  line-break: auto;
  display: flex;
  width: 100%;
  max-width: 670px;
  white-space: pre-line;
}

.people-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.people-main .people-main-header {
  text-align: initial;
  font: normal normal 600 18px/36px "Open Sans";
  letter-spacing: 0.07px;
  color: #171725;
}
.people-main .people-main-table {
  width: 100%;
  position: relative;
  min-height: 200px;
  max-height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.people-main .people-main-table .table-container {
  width: 100%;
  min-width: 800px;
}
.people-main .divider {
  height: 1px;
  background-color: #dddddd;
  width: 100%;
}

.fund-comments-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 98%;
}
.fund-comments-main .header-wrapper {
  display: flex;
}
.fund-comments-main .header-wrapper .icon {
  cursor: pointer;
  width: 2rem;
}
.fund-comments-main .header-wrapper .header {
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.07px;
  color: #171725;
}
.fund-comments-main .divider {
  height: 1px;
  width: 100%;
  background-color: #cfcfcf;
  margin-bottom: 25px;
}
.fund-comments-main .text-area {
  width: 97.5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  min-height: 180px;
  padding-inline: 10px;
  padding-block: 10px;
  resize: none;
  text-align: initial;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.06px;
  color: #000000;
}
.fund-comments-main .spacer {
  height: 50px;
}
.fund-comments-main .button {
  display: flex;
  width: 100%;
}
.fund-comments-main .button .spacer {
  flex: 1 1 auto;
}
.fund-comments-main .button .save-comment {
  background: #fecb2e 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.fund-comments-main .button .save-comment .header {
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0px;
  color: #0a287c;
  padding: 10px;
}

html[dir=rtl] .fund-comments-main .header-wrapper .icon {
  transform: rotate(180deg);
}

@media (max-width: 776px) {
  .fund-comments-main .text-area {
    width: 90%;
  }
}
.funds-history {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 65%;
}
.funds-history__header {
  text-align: initial;
  font: normal normal 600 18px/36px "Open Sans";
  letter-spacing: 0.07px;
  color: #171725;
}
.funds-history .divider {
  height: 1px;
  background-color: #dddddd;
  width: 100%;
}
.funds-history__content {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-inline: 10px;
}
.funds-history__content .history-row {
  display: flex;
  gap: 2em;
  align-items: center;
}
.funds-history__content .history-row .date {
  font: normal normal medium 15px/36px "Open Sans";
  letter-spacing: 0.06px;
  color: #000000;
  opacity: 1;
}
.funds-history__content .history-row .description {
  width: 40%;
  text-align: center;
  font: normal normal medium 14px/36px "Open Sans";
  letter-spacing: 0.06px;
  color: #809fb8;
  opacity: 1;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.funds-history__content .history-row .status-container {
  display: flex;
  color: #22d598;
  font-size: 13px;
  line-height: 32px;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  width: clamp(80px, 80%, 120px);
  background-color: rgba(34, 213, 152, 0.15);
}
.funds-history__content .history-row .clickable {
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: underline;
  color: #4b9dcc;
}

@media (max-width: 776px) {
  .funds-history {
    width: 100%;
  }
}
.popup-wrapper {
  width: 685px;
  height: 363px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 36px rgba(0, 0, 0, 0.08);
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup-wrapper .spacer {
  flex: 1 1 auto;
  height: 10px;
}
.popup-wrapper .close-icon {
  cursor: pointer;
  padding: 14px;
  width: 93%;
  height: 1%;
}
.popup-wrapper .contact-details-wrapper {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.popup-wrapper .contact-details-wrapper .header-wrapper {
  width: 85%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  gap: 15px;
}
.popup-wrapper .contact-details-wrapper .header-wrapper .header {
  text-align: initial;
  font: normal normal 600 22px/36px "OpenSans";
  letter-spacing: 0.09px;
  color: #171725;
}
.popup-wrapper .contact-details-wrapper .header-wrapper .fund-type {
  cursor: default;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 10px;
}
.popup-wrapper .contact-details-wrapper .header-wrapper .last-updated {
  font-size: 12px;
  color: #809fb8;
}
.popup-wrapper .contact-details-wrapper .divider {
  width: 85%;
  background-color: #707070;
  border: 1px solid #707070;
  opacity: 0.4;
}
.popup-wrapper .contact-details-wrapper .content-wrapper {
  direction: initial;
  width: 85%;
  height: 65%;
  display: flex;
  gap: 20px;
  padding: 20px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .wrapper-obj {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .wrapper-obj .icon {
  height: 20px;
  width: 20px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .wrapper-obj .text {
  font-size: 12px;
  font-weight: bold;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .wrapper .icon {
  height: 20px;
  width: 20px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .wrapper .text {
  font-size: 12px;
  font-weight: bold;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .phone-wrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .phone-wrapper .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .phone-wrapper .wrapper .icon {
  height: 20px;
  width: 20px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .phone-wrapper .wrapper .text {
  font-size: 12px;
  font-weight: bold;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .social-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 25%;
  justify-content: space-between;
  width: 100%;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .social-wrapper .text {
  font-size: 12px;
  font-weight: bold;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .social-wrapper .social-icons {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .social-wrapper .social-icons .icon-obj {
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .icon {
  height: 20px;
  width: 40px;
  color: #fecb2d;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .text {
  font-size: 12px;
  font-weight: bold;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .wrapper .details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .wrapper .details .text-header {
  font-size: 16px;
  color: #cca435;
  text-decoration: underline;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .wrapper .icon {
  height: 20px;
  width: 40px;
}
.popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col .delegation-wrapper .wrapper .text {
  font-size: 12px;
  font-weight: bold;
}

@media (max-width: 776px) {
  .popup-wrapper {
    width: unset;
    height: unset;
  }
  .popup-wrapper .contact-details-wrapper .header-wrapper {
    flex-direction: column;
  }
  .popup-wrapper .contact-details-wrapper .content-wrapper {
    width: 100%;
    flex-direction: column;
  }
  .popup-wrapper .contact-details-wrapper .content-wrapper .content-wrapper-col {
    gap: 15px;
  }
}
.comments-list-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.comments-list-main .table-container-wrapper .table-container {
  width: 100%;
  box-shadow: none;
  min-width: 800px;
}
.comments-list-main .table-container-wrapper .table-container .white {
  background-color: white !important;
}
.comments-list-main .table-container-wrapper .table-container tr.mat-header-row {
  height: 56px;
  display: flex;
  width: 100%;
  align-items: center;
}
.comments-list-main .table-container-wrapper .table-container .mat-row .mat-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  text-align: initial;
  font: normal normal medium 12px/36px Roboto;
  letter-spacing: 0.05px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
.comments-list-main .table-container-wrapper .table-container .mat-row .mat-cell .user-name {
  display: flex;
  align-items: center;
}
.comments-list-main .table-container-wrapper .table-container .mat-row .mat-cell .user-name .avatar {
  width: 2rem;
}
.comments-list-main .table-container-wrapper .table-container td.mat-column-DateAdded {
  text-align: initial;
  align-content: center;
}
.comments-list-main .table-container-wrapper .table-container td.mat-column-UserName {
  text-align: initial;
  align-content: center;
}
.comments-list-main .table-container-wrapper .table-container td.mat-column-Comment {
  text-align: initial;
  align-content: center;
  margin-right: 5%;
  cursor: pointer;
}
.comments-list-main .table-container-wrapper .table-container td.mat-column-DeleteNote {
  text-align: initial;
  align-content: center;
  padding-left: 1%;
}
.comments-list-main .table-container-wrapper .table-container th.mat-header-cell:first-of-type,
.comments-list-main .table-container-wrapper .table-container td.mat-cell:first-of-type,
.comments-list-main .table-container-wrapper .table-container td.mat-footer-cell:first-of-type {
  padding-left: 8px;
  width: 20%;
  text-align: initial;
  font: normal normal medium 12px/14px "Open Sans";
  letter-spacing: 0px;
  color: #809fb8;
}
.comments-list-main .table-container-wrapper .table-container td.mat-cell:last-of-type,
.comments-list-main .table-container-wrapper .table-container th.mat-header-cell:last-of-type,
.comments-list-main .table-container-wrapper .table-container td.mat-footer-cell:last-of-type {
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  font: normal normal medium 12px/36px "Open Sans";
  letter-spacing: 0.05px;
  color: #809fb8;
  display: flex;
  justify-content: flex-end;
}
.comments-list-main .table-container-wrapper .table-container .mat-row:nth-child(odd) {
  display: flex;
  background-color: #f0f3fa;
}
.comments-list-main .table-container-wrapper .table-container .mat-row:nth-child(even) {
  display: flex;
}
.comments-list-main .table-container-wrapper .table-container th.mat-header-cell,
.comments-list-main .table-container-wrapper .table-container td.mat-cell,
.comments-list-main .table-container-wrapper .table-container td.mat-footer-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: none;
  width: 20%;
}
.comments-list-main .bottom-wrapper {
  width: 100%;
  display: flex;
  padding-top: 5%;
}
.comments-list-main .bottom-wrapper .spacer {
  flex: 1 1 auto;
}
.comments-list-main .bottom-wrapper .write-comment-button {
  cursor: pointer;
  background: #fecb2e 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;
  width: 150px;
  justify-content: center;
}
.comments-list-main .bottom-wrapper .write-comment-button .icon {
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0px;
  color: #0a287c;
}
.comments-list-main .bottom-wrapper .write-comment-button .button-header {
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0px;
  color: #0a287c;
}

.disabled-write-comment-button {
  pointer-events: none;
  cursor: none;
  opacity: 0.6;
  background: #7d7d7d;
}

.mat-tooltip-show {
  height: max-content !important;
  width: fit-content !important;
  line-break: auto !important;
  background: grey 0 !important;
  font-size: 12px;
}

.main-table {
  width: 100%;
}
.main-table .table-container {
  width: 100%;
}
.main-table .main-wrapper {
  min-width: 270px;
}
.main-table .mat-row {
  min-height: 56px !important;
}
.main-table .icon-download {
  padding: 5px;
  background-color: #fecb2e;
  border-radius: 100px;
}
.main-table .mat-cell {
  border-bottom: 1px solid transparent !important;
  border-top: 1px solid transparent !important;
  cursor: pointer !important;
  padding: 0 !important;
}
.main-table .mat-cell:hover {
  background-color: lightblue !important;
}
.main-table .row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.main-table .row-wrapper .img-pdf-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
}
.main-table .row-wrapper .img-pdf-name .img {
  padding: 0 10px 0 0;
  width: 26px;
}
.main-table .row-wrapper .img-pdf-name .pdf-name {
  text-align: left;
  font: normal normal normal 15px/20px OpenSans;
  letter-spacing: 0.06px;
  color: #171725;
  opacity: 1;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
}

.mat-grid-tile-content {
  align-items: start !important;
}

.outlined-btn {
  display: flex;
  gap: 10px;
  height: 35px;
  cursor: pointer;
  color: #0a287c;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  background-color: white;
  justify-content: center;
  border: 1px solid #0a287c;
  font-family: "Open Sans";
  margin-bottom: 15px;
  padding: 0px 15px;
}
.outlined-btn > img {
  height: 18px;
  width: 18px;
}

.add-work-plan-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fecb2e;
  border-radius: 10px;
  cursor: pointer;
  color: #0a287c;
  gap: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
}
.add-work-plan-btn > img {
  height: 22px;
  width: 22px;
}

.overview-page {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  justify-content: space-between;
}
.overview-page .funds-list-item {
  padding: 10px 10px 0px 10px;
}
.overview-page .funds-list-item {
  flex-direction: unset;
}
.overview-page .funds-list-item__content {
  padding: 27px 5px;
  max-width: 65%;
}
.overview-page .mat-grid-tile-content {
  display: block;
}
.overview-page .mat-tab-body-wrapper {
  height: 673px;
}
.overview-page__header {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20%;
}
.overview-page__header .information {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.overview-page__header .information .details {
  display: flex;
  flex-direction: row;
}
.overview-page__header .information .details .text {
  color: #809fb8;
  font-size: 14px;
}
.overview-page__header .information .welcome {
  display: flex;
  flex-direction: column;
  /*gap: 5px;*/
}
.overview-page__header .information .welcome .title {
  color: #06152b;
  font-size: 16px;
  font-weight: 800;
}
.overview-page__header .information .welcome .text {
  color: #000000;
  font-size: 14px;
}
.overview-page__header .information .talk-to-expert-button {
  cursor: pointer;
  min-width: 170px;
  padding: 5px 15px 7px 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
  letter-spacing: 0;
  background: #0a287c;
  color: white;
  border-radius: 100px;
  gap: 10px;
}
.overview-page__header .information .talk-to-expert-button .text {
  font-size: 14px;
  font-weight: 500;
}
.overview-page__header .information .talk-to-expert-button .icon {
  display: block;
  height: 24px;
  width: auto;
}
.overview-page__header .information .talk-to-expert-button .icon-hover {
  height: 24px;
  width: auto;
  display: none;
}
.overview-page__header .information .talk-to-expert-button:hover {
  background-color: #fecb2e;
  color: #0a287c;
}
.overview-page__header .information .talk-to-expert-button:hover .icon {
  display: none;
}
.overview-page__header .information .talk-to-expert-button:hover .icon-hover {
  display: block;
}
.overview-page__header .cards {
  display: flex;
  flex-direction: row;
  height: 5.6rem;
  justify-content: space-between;
  width: 100%;
}
.overview-page__header .cards .overview-card-work-plan {
  cursor: pointer;
  padding: 5px 60px 5px 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #f0f3f9;
  border-radius: 10px;
  border: solid 2px #e9ebf1;
  width: unset;
  /*.icon-active {
    display: none;
    width: 85px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 135px;
    right: 0px;
    margin: auto;
  }*/
}
.overview-page__header .cards .overview-card-work-plan:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
  /*.icon {
    display: none;
    width: 85px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 135px;
    right: 0px;
    margin: auto;
  }

  .icon-active {
    display: unset;
    width: 85px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 135px;
    right: 0px;
    margin: auto;
  }*/
}
.overview-page__header .cards .overview-card-work-plan__title {
  font-size: 14px;
  color: #8da9c0;
}
.overview-page__header .cards .overview-card-work-plan__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
}
.overview-page__header .cards .overview-card-work-plan__content .value {
  font-size: 2.2rem;
  font-weight: 300;
  color: #000000;
}
.overview-page__header .cards .overview-card-work-plan__content .customize-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
.overview-page__header .cards .overview-card-work-plan__icon {
  width: 57px;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 140px;
  right: 0px;
  margin: auto;
  opacity: 1;
  transform: scaleX(-1);
}
.overview-page__header .cards .overview-card-search {
  cursor: pointer;
  padding: 5px 60px 5px 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #f0f3f9;
  border-radius: 10px;
  border: solid 2px #e9ebf1;
  width: unset;
  /*.icon-active {
    display: none;
    width: 85px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 135px;
    right: 0px;
    margin: auto;
  }*/
}
.overview-page__header .cards .overview-card-search:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
  /*.icon {
    display: none;
    width: 85px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 135px;
    right: 0px;
    margin: auto;
  }

  .icon-active {
    display: unset;
    width: 85px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 135px;
    right: 0px;
    margin: auto;
  }

  .customize-button {
    visibility: visible;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #d7e1eb;
    color: #141516;
    font-size: $font-small;
    font-weight: bold;
  }*/
}
.overview-page__header .cards .overview-card-search__title {
  font-size: 14px;
  color: #8da9c0;
}
.overview-page__header .cards .overview-card-search__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.overview-page__header .cards .overview-card-search__content .value {
  font-size: 2.2rem;
  font-weight: 300;
  color: #000000;
}
.overview-page__header .cards .overview-card-search__content .customize-button {
  visibility: hidden;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
.overview-page__header .cards .overview-card-search__icon {
  width: 60px;
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 176px;
  right: 0px;
  margin: auto;
  opacity: 1;
  transform: scaleX(-1);
}
.overview-page__header .cards .overview-card-funds {
  cursor: pointer;
  padding: 5px 60px 5px 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #f0f3f9;
  border-radius: 10px;
  font-family: "Open Sans";
  border: solid 2px #e9ebf1;
  width: unset;
}
.overview-page__header .cards .overview-card-funds:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #fff8de);
}
.overview-page__header .cards .overview-card-funds__title {
  font-size: 14px;
  color: #8da9c0;
}
.overview-page__header .cards .overview-card-funds__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.overview-page__header .cards .overview-card-funds__content .value {
  font-size: 2.2rem;
  font-weight: 300;
  color: #000000;
}
.overview-page__header .cards .overview-card-funds__content .customize-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
.overview-page__header .cards .overview-card-funds__icon {
  width: 52px;
  position: absolute;
  top: 0px;
  bottom: 4px;
  left: 119px;
  right: 0px;
  margin: auto;
  opacity: 1;
}
.overview-page__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.overview-page__content .calendar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 10px;
  border: 1px solid rgba(217, 225, 231, 0.8);
  align-items: center;
  height: 100%;
}
.overview-page__content .calendar__timeline {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
}
.overview-page__content .calendar__timeline__header {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
.overview-page__content .calendar__timeline__header .title {
  font-size: 16px;
  font-weight: 800;
  color: #06152b;
}
.overview-page__content .calendar__timeline__header .more-options {
  font-size: 18px;
  line-height: 18px;
  color: #809fb8;
  height: 30px;
}
.overview-page__content .calendar .accent-btn {
  background-color: #0a287c;
  border-radius: 8px;
  color: white;
  font-size: 14px;
}
.overview-page__content .calendar .btn-title-section {
  display: flex;
  align-items: center;
}
.overview-page__content .calendar .new-event-btn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 41px;
  box-sizing: border-box;
  cursor: pointer;
}
.overview-page__content .calendar .new-event-btn > img {
  width: 18px;
  height: 18px;
}
.overview-page__content .calendar .divider {
  height: 100%;
  width: 1px;
  background-color: rgba(217, 225, 231, 0.8);
}
.overview-page__content .calendar__events {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.overview-page__content .calendar__events__header {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
.overview-page__content .calendar__events__header .title {
  font-size: 14px;
  color: #06152b;
  font-weight: 800;
}
.overview-page__content .calendar__events__header .sub-title {
  font-size: 12px;
  color: #06152b;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  padding: 5px;
  background: #f1f1f1;
  padding-inline: 12px;
}
.overview-page__content .calendar__events__header .sub-title .logo {
  width: 20px;
  height: 20px;
  background: transparent;
}
.overview-page__content .calendar__events__header .more-options {
  font-size: 18px;
  line-height: 18px;
  color: #809fb8;
  height: 30px;
}
.overview-page__content .calendar__events__content {
  overflow: auto;
  height: 90%;
}
.overview-page__content .calendar__events__content::-webkit-scrollbar {
  width: 5px;
}
.overview-page__content .calendar__events__content::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.overview-page__content .calendar__events__content::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.overview-page__content .calendar__events__content .calendar-events-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .tag {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .tag .text {
  font-size: 12px;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .event-title {
  cursor: pointer;
  font-size: 14px;
  font: normal normal medium 12px/52px Roboto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .date-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .date-tag__text {
  font-size: 12px;
  color: #06152b;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .date-tag__icon {
  font-size: 12px;
  line-height: 12px;
}
.overview-page__content .calendar__events__content .calendar-events-list .calendar-event-list-item .more-options {
  color: #99b2c6;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
}
.overview-page__footer {
  z-index: 1;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 -7px 26px rgba(0, 0, 0, 0.0431372549);
  background: white linear-gradient(91deg, rgba(255, 255, 255, 0.0784313725) 0, rgba(10, 40, 124, 0.3529411765) 100%) 0 0 no-repeat padding-box;
  width: 100%;
}
.overview-page__footer .details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.overview-page__footer .details .icon {
  height: 24px;
  width: 24px;
}
.overview-page__footer .details .date-text {
  font-size: 12px;
  color: #171725;
}
.overview-page__footer .details .title {
  font-size: 12px;
  font-weight: bold;
  color: #171725;
  min-width: 170px;
}
.overview-page__footer .carousel {
  display: flex;
  flex-direction: row;
}
.overview-page__footer .carousel .slick-prev {
  display: none !important;
}
.overview-page__footer .carousel .carousel-item {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8c98;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto 5px;
  border-radius: 12px;
  background: #ffffff 0 0 no-repeat padding-box;
  gap: 5px;
}
.overview-page__footer .carousel .carousel-item .title {
  font-size: 12px;
  font-weight: bold;
}
.overview-page__footer .carousel .carousel-item .text {
  font-size: 12px;
}

@media (max-width: 1300px) {
  .overview-page__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 50%;
  }
}
html[dir=rtl] .overview-page__header .cards .overview-card-work-plan {
  padding: 5px 10px 5px 60px;
  margin-right: 0;
  margin-left: 1rem;
  border: solid 2px #e9ebf1;
}
html[dir=rtl] .overview-page__header .cards .overview-card-work-plan__icon {
  left: 0px;
  right: 137px;
  transform: scaleX(1);
}
html[dir=rtl] .overview-page__header .overview-card-search {
  padding: 5px 10px 5px 60px;
  margin-right: 0;
  margin-left: 1rem;
  border: solid 2px #e9ebf1;
}
html[dir=rtl] .overview-page__header .overview-card-search__icon {
  left: 0px;
  right: 142px;
  transform: scaleX(1);
}
html[dir=rtl] .overview-page__header .overview-card-funds {
  padding: 5px 10px 5px 60px;
  margin-right: 0;
  margin-left: 0rem;
  border: solid 2px #e9ebf1;
}
html[dir=rtl] .overview-page__header .overview-card-funds .customize-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #d7e1eb;
  color: #141516;
  font-size: 12px;
  font-weight: bold;
}
html[dir=rtl] .overview-page__header .overview-card-funds__icon {
  left: 0px;
  right: 155px;
}

@media (max-width: 776px) {
  .overview-page .mat-grid-tile-content {
    padding-right: 5%;
    padding-left: 5%;
  }
  .overview-page__content {
    height: 100%;
  }
  .overview-page__content .calendar {
    flex-direction: column;
    justify-content: unset;
  }
  .overview-page__content .calendar__timeline {
    width: 100%;
  }
  .overview-page__content .calendar__events {
    width: 100%;
  }
  .overview-page__header {
    display: block;
    height: 100%;
  }
  .overview-page .overview-page__header .cards {
    flex-direction: column;
    height: 100%;
  }
  .overview-page .overview-page__header .cards .overview-card-work-plan {
    padding: 5px 40px 5px 40px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: unset !important;
    margin-left: unset !important;
  }
  .overview-page .overview-page__header .cards .overview-card-search,
.overview-page .overview-page__header .cards .overview-card-funds {
    margin-bottom: 20px;
    margin-right: unset;
    margin-left: 0;
    padding: 5px 40px 5px 40px !important;
  }
}
.fund-table-cell {
  padding: 15px 8px;
}

.funds-table {
  width: 100%;
}

.even-row {
  background-color: rgba(244, 243, 253, 0.5843137255);
}

.fund-name {
  font-size: 14px;
  color: #171725;
  font-weight: 700;
  font-family: "Open Sans";
}

.fund-summary {
  font-size: 14px;
  color: #171725;
  font-weight: 400;
  font-family: "Open Sans";
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.toolbar .fund-type {
  cursor: default;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 10px;
}
.toolbar .separator {
  font-size: 14px;
  color: #8a8c98;
}
.toolbar .organization-number {
  font-size: 14px;
  color: #8a8c98;
}
.toolbar .score {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  border-radius: 10px;
  color: white;
  justify-content: center;
}
.toolbar .icon {
  height: 14px;
  width: 14px;
  font-size: 14px;
}

.mat-tab-label-content {
  font-size: 16px;
  font-weight: 700;
  color: #0a287c;
}

.management-panel-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  padding-top: 3%;
}
.management-panel-main .management-panel-container {
  width: 100%;
  height: 100%;
  padding-inline: 2rem;
  box-sizing: border-box;
}
.management-panel-main .management-panel-container .row-one {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
  box-sizing: border-box;
  padding-inline-end: 1rem;
}
.management-panel-main .management-panel-container .row-one .row-one-column-one {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: flex-start;
}
.management-panel-main .management-panel-container .row-one .row-one-column-one .row-one-column-one-header-one {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #809fb8;
}
.management-panel-main .management-panel-container .row-one .row-one-column-one .row-one-column-one-header-two {
  width: auto;
  font-size: 20px;
  color: #06152b;
}
.management-panel-main .management-panel-container .row-one .row-one-column-one .row-one-column-one-header-three {
  font-size: 15px;
  color: black;
}
.management-panel-main .management-panel-container .row-one .row-one-column-one .row-one-column-one-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
  letter-spacing: 0;
  color: #fefefe;
  background: #0a287c;
  border-radius: 8px;
  height: 45px;
  width: 70%;
}
.management-panel-main .management-panel-container .row-one .row-one-column-one .row-one-column-one-button .icon {
  color: #fecb2f;
  padding-inline-end: 0.5rem;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two {
  gap: 2.5rem;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box {
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  width: clamp(200px, 26%, 270px);
  background-color: rgba(233, 238, 248, 0.6784313725);
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.2196078431);
  overflow: hidden;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box .header {
  gap: 0.6rem;
  text-align: start;
  font: normal normal 600 13px/15px Heebo;
  color: #809fb8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4%;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box .header .number {
  color: black;
  font-size: 1.75rem;
  line-height: 1;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box .header .wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box .header .button-link {
  background-color: rgba(128, 159, 184, 0.22);
  padding: 5px 8px;
  border-radius: 1rem;
  color: black;
  line-height: 1.5;
  font-size: 12px;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box .hidden-box-image {
  margin-inline-end: -50px;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .overview-box:hover {
  overflow: visible;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button {
  cursor: pointer;
  display: flex;
  height: 80%;
  width: 33%;
  background: rgba(233, 238, 248, 0.6784313725) 0% 0% no-repeat padding-box;
  border-radius: 16px;
  overflow: hidden;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button .header {
  font-size: 10px;
  text-align: left;
  letter-spacing: 1.3px;
  color: #809fb8;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 4%;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button .header .head-line {
  width: 140px;
  line-height: 5;
  padding-right: 50px;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button .header .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button .header .wrapper .button-link {
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #000000;
  border-radius: 15px;
  width: 85px;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  background: rgba(221, 119, 238, 0.0666666667);
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button .header .wrapper .number {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 20px;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .agent-button:hover {
  overflow: visible;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .funds-button {
  cursor: pointer;
  display: flex;
  height: 80%;
  width: 28%;
  background: rgba(233, 238, 248, 0.6784313725) 0% 0% no-repeat padding-box;
  border-radius: 16px;
  overflow: hidden;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .funds-button .header {
  font-size: 10px;
  text-align: left;
  letter-spacing: 1.3px;
  color: #809fb8;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 4%;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .funds-button .header .head-line {
  width: 150px;
  line-height: 5;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .funds-button .header .number {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 20px;
}
.management-panel-main .management-panel-container .row-one .row-one-column-two .funds-button:hover {
  overflow: visible;
}
.management-panel-main .management-panel-container .row-two {
  display: flex;
  width: 100%;
  height: 60%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid rgba(217, 225, 231, 0.8);
  border-radius: 16px;
}
.management-panel-main .management-panel-container .row-two .row-two-column-one {
  width: 65%;
  height: 100%;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}
.management-panel-main .management-panel-container .row-two .divider {
  height: 100%;
  border: solid 0.5px rgba(217, 225, 231, 0.8);
}
.management-panel-main .management-panel-container .row-two .row-two-column-two {
  width: 35%;
  height: 100%;
  background: #f7f8f9;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

.expert-main-wrapper {
  background: #fff;
  width: 60%;
  height: 70%;
  z-index: 500000000000;
  position: fixed;
  top: 15%;
  bottom: 0;
  left: 20%;
  right: 0;
}
.expert-main-wrapper .expert-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.52;
  right: 0;
  z-index: 10000;
}

.custom-modalbox mat-dialog-container {
  padding: 0;
}

.back-drop-overview {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}

.calendar-board-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-board-main .calendar-board-main-wrapper {
  width: 95%;
  height: 95%;
}
.calendar-board-main .calendar-board-main-wrapper .calendar-board-row-one {
  width: 100%;
  height: 22%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.calendar-board-main .calendar-board-main-wrapper .calendar-board-row-one .row-one-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 35%;
}
.calendar-board-main .calendar-board-main-wrapper .calendar-board-row-one .row-one-date .row-one-date-button-wrapper {
  border: solid 1px #d9e1e7;
  background: #fff;
  border-radius: 5px;
}
.calendar-board-main .calendar-board-main-wrapper .calendar-board-row-two {
  width: 100%;
  height: 78%;
}

.events-panels {
  height: 100%;
  padding: 25px;
  background-color: rgba(244, 243, 253, 0.5843137255);
}
.events-panels .events-previous-dates {
  border-left: 4px solid var(--color-score-low);
}
.events-panels .events-this-week {
  border-left: 4px solid var(--color-score-medium);
}
.events-panels .events-future {
  border-left: 4px solid var(--color-score-high);
}
.events-panels .mat-expansion-panel-header.mat-expanded {
  background-color: whitesmoke !important;
}
.events-panels .mat-expansion-panel-header.mat-expanded:hover {
  background-color: whitesmoke !important;
}
.events-panels .mat-expanded .mat-expansion-panel-content {
  padding-top: 14px !important;
}

.calendar-events-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}
.calendar-events-main .calendar-events-wrapper {
  width: 95%;
  height: 100%;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30%;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-one .header {
  text-align: left;
  font: normal normal medium 16px/32px "OpenSans";
  letter-spacing: 0px;
  color: #06152b;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-one .buttons-wrapper {
  display: flex;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-one .buttons-wrapper .menu-wrapper {
  box-shadow: none;
  background: transparent;
  border: none;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-one .buttons-wrapper .row-one-button {
  display: flex;
  align-items: center;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-two {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-two .event-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-two .event-wrapper .type-button {
  height: 25px;
  width: 50px;
  border-radius: 20px;
  background: #d4ebff;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-two .event-wrapper .summary {
  overflow: hidden;
  height: 20px;
  width: 105px;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-two .event-wrapper .clock-icon {
  width: 50px;
  font-size: 14px;
  height: 15px;
}
.calendar-events-main .calendar-events-wrapper .calendar-events-row-two .event-wrapper .menu-wrapper-event {
  background: transparent;
  border: none;
}

html[dir=rtl] .events-panels .events-previous-dates {
  border-right: 4px solid var(--color-score-low);
  border-left: unset;
}
html[dir=rtl] .events-panels .events-this-week {
  border-right: 4px solid var(--color-score-medium);
  border-left: unset;
}
html[dir=rtl] .events-panels .events-future {
  border-right: 4px solid var(--color-score-high);
  border-left: unset;
}

.calendar-main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 3px;
  width: 94%;
  height: 100%;
}
.calendar-main-wrapper .header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 20%;
  align-items: center;
}
.calendar-main-wrapper .header-wrapper .time-line-group-buttons {
  display: flex;
  align-items: center;
}
.calendar-main-wrapper .header-wrapper .time-line-group-buttons .icon-left {
  cursor: pointer;
  border: 1px solid #d9e1e7;
  border-radius: 8px;
  margin-inline: 5px;
  font-size: 14px;
  display: flex;
  padding: 2px;
}
.calendar-main-wrapper .header-wrapper .time-line-group-buttons .icon-right {
  cursor: pointer;
  border: 1px solid #d9e1e7;
  border-radius: 8px;
  margin-inline: 5px;
  font-size: 14px;
  display: flex;
  padding: 2px;
  transform: rotate(180deg);
}
.calendar-main-wrapper .header-wrapper .time-line-group-buttons .time-line-value {
  font-size: 14px;
  color: #06152b;
  /*font-family: 'ProximaNova';*/
  padding-inline: 10px;
}
.calendar-main-wrapper .header-wrapper .date-time-selector {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}
.calendar-main-wrapper .header-wrapper .date-time-selector .show-by {
  text-align: left;
  letter-spacing: 0px;
  font-size: 14px;
  color: #06152b;
  /*font-family: 'ProximaNova';*/
}
.calendar-main-wrapper .header-wrapper .date-time-selector .sorting-date-time {
  font-size: 14px;
  color: #06152b;
  /*font-family: 'ProximaNova';*/
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9e1e7;
  border-radius: 10px;
}
.calendar-main-wrapper .header-wrapper .date-time-selector .sorting-date-time .sorting-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline: 10px;
}
.calendar-main-wrapper .body-wrapper {
  width: 95%;
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.calendar-main-wrapper .body-wrapper .down {
  gap: 1%;
  display: flex;
  width: 100%;
  height: 15%;
  flex-direction: row;
  justify-content: space-between;
}
.calendar-main-wrapper .body-wrapper .down-hours {
  display: flex;
  width: 95%;
  height: 5%;
  flex-direction: row;
  justify-content: space-between;
}
.calendar-main-wrapper .body-wrapper .upper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.calendar-main-wrapper .body-wrapper .upper-hours {
  display: flex;
  width: 95%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.calendar-main-wrapper .body-wrapper .events {
  background: transparent;
  position: absolute;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  z-index: 1;
  height: 100%;
  row-gap: 5px;
}
.calendar-main-wrapper .body-wrapper .events .event {
  cursor: pointer;
  display: flex;
  min-width: calc(100% + 100px);
  background: rgba(0, 144, 255, 0.168627451) 0% 0% no-repeat padding-box;
  border-radius: 16px;
  padding-left: 10px;
  max-height: 50px;
}
.calendar-main-wrapper .body-wrapper .events .event .content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
}
.calendar-main-wrapper .body-wrapper .events .event .content-wrapper .content-line {
  width: 5px;
  height: 65%;
  letter-spacing: 0px;
  background: #0090ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.calendar-main-wrapper .body-wrapper .events .event .content-wrapper .content-title {
  text-align: left;
  letter-spacing: 0px;
  color: #0090ff;
  font-size: 12px;
}
.calendar-main-wrapper .body-wrapper #grid-days {
  display: flex;
  grid-gap: 2px;
  width: 100%;
  height: 88%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 73%;
}
.calendar-main-wrapper .body-wrapper #grid-hours {
  display: flex;
  grid-gap: 2px;
  width: 100%;
  height: 88%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.calendar-main-wrapper .body-wrapper .head,
.calendar-main-wrapper .body-wrapper .cell {
  padding-block: 4px;
}
.calendar-main-wrapper .body-wrapper .head {
  cursor: pointer;
  text-align: left;
  /*font: normal 12px/16px ProximaNova;*/
  letter-spacing: 0.36px;
  color: #809fb8;
  font-size: 12px;
}
.calendar-main-wrapper .body-wrapper .head-hours {
  cursor: pointer;
  text-align: left;
  /*font: normal 9px/16px ProximaNova;*/
  letter-spacing: 0.36px;
  color: #809fb8;
  width: 1%;
}
.calendar-main-wrapper .body-wrapper .cell {
  cursor: pointer;
  background: #f8ffde;
  border: 1px solid rgba(217, 225, 231, 0.8);
}
.calendar-main-wrapper .body-wrapper .today-cell {
  cursor: pointer;
  background: #f8ffde;
  width: 0;
  border: 1px dashed rgba(217, 225, 231, 0.8);
  height: 100%;
  bottom: 10px;
  position: relative;
}
.calendar-main-wrapper .body-wrapper .today-banner {
  position: relative;
  width: 30px;
  height: 15px !important;
  display: flex;
  align-items: center;
  bottom: 40px;
  right: 24px;
  padding: 8px;
  border-radius: 10px;
  background: #d9e1e8 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.36);
  opacity: 0.4;
  justify-content: center;
}
.calendar-main-wrapper .body-wrapper .today-banner .header {
  text-align: center;
  letter-spacing: 0px;
  color: #0a287c;
  opacity: 1;
  font-size: 12px;
}

@media (max-width: 1199px) {
  .calendar-main-wrapper {
    width: 90%;
    height: 95%;
    padding-block: 12px;
  }
  .calendar-main-wrapper .header-wrapper .time-line-group-buttons .icon-left {
    width: 18px;
  }
  .calendar-main-wrapper .header-wrapper .time-line-group-buttons .icon-right {
    width: 18px;
  }
  .calendar-main-wrapper .header-wrapper .time-line-group-buttons .time-line-value {
    font-size: 10px;
  }
  .calendar-main-wrapper .header-wrapper .date-time-selector .show-by {
    font-size: 10px;
  }
  .calendar-main-wrapper .header-wrapper .date-time-selector .sorting-date-time {
    font-size: 10px;
  }
  .calendar-main-wrapper .body-wrapper #grid-days {
    height: 80%;
  }
}
html[dir=rtl] .calendar-main-wrapper .header-wrapper .time-line-group-buttons .icon-left {
  transform: rotate(180deg);
}
html[dir=rtl] .calendar-main-wrapper .header-wrapper .time-line-group-buttons .icon-right {
  transform: unset;
}
html[dir=rtl] .calendar-main-wrapper .body-wrapper .events .event {
  padding-left: unset;
  padding-right: 10px;
}

@media (max-width: 776px) {
  .calendar-main-wrapper .header-wrapper {
    height: 10%;
  }
  .calendar-main-wrapper .body-wrapper {
    min-height: 50%;
  }
}
.funding-experts-dialog {
  max-width: 900px;
  padding: 30px 20px 0 20px;
  background: white;
  display: flex;
  border-radius: 0 10px 10px 0;
  border-left: 5px solid #fecb2e;
  flex-direction: column;
  position: relative;
  gap: 10px;
  max-height: 500px;
  direction: ltr;
  overflow: auto;
  min-width: 800px;
  min-height: 460px;
}
.funding-experts-dialog .close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.funding-experts-dialog__header {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.funding-experts-dialog__header .details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.funding-experts-dialog__header .details .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 50%;
}
.funding-experts-dialog__header .details .title {
  font-size: 16px;
  font-weight: bold;
}
.funding-experts-dialog__header .search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border: 2px solid #809fb8;
  border-radius: 10px;
  gap: 10px;
}
.funding-experts-dialog__header .search-box .icon {
  height: 14px;
}
.funding-experts-dialog__header .search-box .input {
  color: gray;
  flex: 1;
}
.funding-experts-dialog__header .search-box .input ::placeholder {
  color: #809fb8;
}
.funding-experts-dialog__content {
  padding: 0 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.funding-experts-dialog__content .line-sep {
  background: #dbdbdf;
  display: flex;
  flex-direction: column;
  min-width: 2px;
  position: relative;
  right: 1.5%;
  top: 35px;
  min-height: 146px;
  max-height: 355px;
}
.funding-experts-dialog__sidebar {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  margin-block: 20px;
}
.funding-experts-dialog__sidebar .filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  overflow-x: hidden;
  flex: 1;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__header .title {
  font-size: 14px;
  font-weight: bold;
  color: #9f9fa9;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__header .expand-icon {
  margin: unset;
  color: #9f9fa9;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__content .filter-option {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__content .filter-option input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__content .filter-option input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__content .filter-option input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.funding-experts-dialog__sidebar .filters .filter .expandable__content .filter-option input[type=checkbox]:checked:before {
  width: calc(4.5714285714px);
  height: calc(10.6666666667px);
  margin: 0 calc(4.5714285714px);
  border-bottom: calc(2.2857142857px) solid #ffffff;
  border-right: calc(2.2857142857px) solid #ffffff;
  transform: rotate(45deg);
}
.funding-experts-dialog__sidebar .filters .filter .expandable__content .filter-option .text {
  color: #44444f;
  font-size: 14px;
}
.funding-experts-dialog__results {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.funding-experts-dialog__results .tabs {
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 10;
  background: white;
}
.funding-experts-dialog__results .tabs .tab-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f5f5f6;
  color: rgba(138, 140, 152, 0.5333333333);
  border-radius: 10px 10px 0 0;
  border: 1px solid #f5f5f6;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.funding-experts-dialog__results .tabs .tab-button .text {
  font-size: 14px;
}
.funding-experts-dialog__results .tabs .tab-button__active {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 13px 19px rgba(0, 0, 0, 0.1019607843);
  border: 1px solid rgba(138, 140, 152, 0.5333333333);
  border-bottom: 3px solid #fecb2e;
  margin-bottom: -3px;
}
.funding-experts-dialog__results .results {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  max-height: 360px;
}
.funding-experts-dialog__results .results .line {
  width: 98%;
  background: #dbdbdf;
  height: 2px;
  margin-bottom: -10px;
  margin-top: -4px;
  z-index: 9;
}
.funding-experts-dialog__results .results .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(233, 238, 248, 0.6784313725);
  border-bottom-right-radius: 10px;
}
.funding-experts-dialog__results .results .selected-funding-expert {
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.funding-experts-dialog__results .results .selected-funding-expert__white-blur {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
}
.funding-experts-dialog__results .results .selected-funding-expert__card {
  padding: 25px;
  z-index: 2;
  width: 288px;
}
.funding-experts-dialog__results .results .funding-experts-list {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
}
.funding-experts-dialog__results .results .funding-experts-list__title {
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
}
.funding-experts-dialog__results .results .funding-experts-list__list {
  gap: 20px;
  padding: 40px;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr auto;
  min-width: 620px;
}
.funding-experts-dialog__results .results .funding-experts-list__list::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}
.funding-experts-dialog__results .results .funding-experts-list__list::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 3px;
}
.funding-experts-dialog__results .results .funding-experts-list__list::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.funding-experts-dialog__results .results .funding-expert-card {
  max-width: 288px;
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 10px);
  background: white;
  box-shadow: 0px 6px 27px rgba(0, 0, 0, 0.0705882353);
  border: 1px solid rgba(128, 159, 184, 0.6156862745);
  border-radius: 10px;
  max-height: 270px;
}
.funding-experts-dialog__results .results .funding-expert-card__list-item {
  cursor: pointer;
}
.funding-experts-dialog__results .results .funding-expert-card__invisible {
  visibility: hidden;
}
.funding-experts-dialog__results .results .funding-expert-card__header {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #fecb2e;
  gap: 10px;
}
.funding-experts-dialog__results .results .funding-expert-card__header .avatar {
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 50%;
}
.funding-experts-dialog__results .results .funding-expert-card__header .name {
  font-size: 14px;
  font-weight: bold;
}
.funding-experts-dialog__results .results .funding-expert-card__content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.funding-experts-dialog__results .results .funding-expert-card__content .funding-expert-properties {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.funding-experts-dialog__results .results .funding-expert-card__content .funding-expert-properties .property {
  font-size: 12px;
}
.funding-experts-dialog__results .results .funding-expert-card__footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.funding-experts-dialog__results .results .funding-expert-card__footer .price {
  font-size: 12px;
  font-weight: bold;
}
.funding-experts-dialog__results .results .funding-expert-card__footer .learn-more-button {
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: #3b5396;
}
.funding-experts-dialog__results .results .funding-expert-card__payment {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.funding-experts-dialog__results .results .funding-expert-card__payment .hours-input {
  padding: 5px;
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  height: 18px;
  width: 18px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.funding-experts-dialog__results .results .funding-expert-card__payment .hours-text {
  font-size: 10px;
}
.funding-experts-dialog__results .results .funding-expert-card__payment .pay-and-schedule-button {
  cursor: pointer;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fecb2e;
  color: #0a287c;
  border-radius: 10px;
  font-size: 10px;
}
.funding-experts-dialog__results .results .funding-expert-card__payment .hours-input-error {
  padding: 5px;
  border: 2px solid red;
  border-radius: 10px;
  height: 18px;
  width: 18px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.funding-experts-dialog .expandable__content {
  overflow: auto;
  max-height: 300px;
}
.funding-experts-dialog .expandable__content::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}
.funding-experts-dialog .expandable__content::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 3px;
}
.funding-experts-dialog .expandable__content::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}

html[dir=rtl] .funding-experts-dialog {
  direction: rtl;
}

@media (max-width: 776px) {
  .funding-experts-dialog {
    min-width: unset;
  }
  .funding-experts-dialog__results .results .funding-experts-list__list {
    padding: 5px;
    grid-template-columns: unset;
    min-width: unset;
  }
  .funding-experts-dialog__results .tabs {
    flex-direction: column;
  }
  .funding-experts-dialog__header {
    flex-direction: column;
  }
  .funding-experts-dialog__content {
    flex-direction: column;
  }
  .funding-experts-dialog__content .line-sep {
    display: none;
  }
}
.funding-expert-payment-dialog {
  width: 500px;
  padding: 30px 20px 20px 20px;
  background: white;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  gap: 10px;
}
.funding-expert-payment-dialog .close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.funding-expert-payment-dialog__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.funding-expert-payment-dialog__header .title {
  font-size: 16px;
  font-weight: bold;
}
.funding-expert-payment-dialog__header .card-error {
  color: red;
}
.funding-expert-payment-dialog__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card .title {
  text-align: left;
  font: normal normal normal 18px/21px OpenSans;
  font-family: OpenSans, sans-serif;
  letter-spacing: 0;
  color: #536777;
  opacity: 1;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card .card-element {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b9b9b9;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card__number {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card__extras {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card__extras .date {
  display: flex;
  flex-direction: column;
  width: 120px;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card__extras .cvc {
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .form-control {
  width: 100%;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card-type-form-control {
  min-width: 200px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .expiry-date-form-control {
  max-width: 120px;
}
.funding-expert-payment-dialog__content .expert-payment-form .form-row .cvv-form-control {
  max-width: 120px;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .terms-and-conditions {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .terms-and-conditions input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .terms-and-conditions input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .terms-and-conditions input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .terms-and-conditions input[type=checkbox]:checked:before {
  width: calc(4px);
  height: calc(9.3333333333px);
  margin: 0 calc(4px);
  border-bottom: calc(2px) solid #ffffff;
  border-right: calc(2px) solid #ffffff;
  transform: rotate(45deg);
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .terms-and-conditions .text {
  color: #94a5b2;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .total-amount {
  display: flex;
  align-items: center;
  gap: 5px;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .total-amount .label {
  font-size: 16px;
  color: #463e65;
}
.funding-expert-payment-dialog__content .expert-payment-form .summary-row .total-amount .amount {
  font-size: 16px;
  font-weight: bold;
  color: #0a287c;
}
.funding-expert-payment-dialog__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.funding-expert-payment-dialog__footer .pay-now-button {
  cursor: pointer;
  padding: 10px;
  background-color: #fecb2e;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.funding-expert-payment-dialog__footer .pay-now-button .icon {
  height: 16px;
}
.funding-expert-payment-dialog__footer .pay-now-button .text {
  font-size: 14px;
  color: #0a287c;
}
.funding-expert-payment-dialog__footer .disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}

.funding-expert-payment-dialog-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.funding-expert-payment-dialog-input .input-label {
  text-align: left;
  font: 18px/21px OpenSans;
  letter-spacing: 0;
  color: #536777;
  opacity: 1;
}
.funding-expert-payment-dialog-input .input-label .error-dot {
  color: red;
}
.funding-expert-payment-dialog-input .input-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b9b9b9;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.funding-expert-payment-dialog-input .input-box__input {
  color: #144167;
  font-size: 18px;
  font-family: OpenSans, sans-serif;
}
.funding-expert-payment-dialog-input .input-box__input::placeholder {
  color: #8c98a2;
  font-size: 15px;
  font-family: OpenSans, sans-serif;
}
.funding-expert-payment-dialog-input .input-error {
  color: red;
  font-size: 12px;
  line-height: 12px;
}

.funding-expert-payment-dialog-select {
  min-width: 200px;
  width: 100%;
}
.funding-expert-payment-dialog-select .custom-select .select-label {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 14px;
  line-height: 14px;
  color: #536777;
}
.funding-expert-payment-dialog-select .custom-select .select-button {
  border-radius: 10px;
  border: 1px solid #b9b9b9;
}
.funding-expert-payment-dialog-select .custom-select .select-button .credit-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.funding-expert-payment-dialog-select .custom-select .select-button .credit-card__icon {
  height: 18px;
  font-size: 14px;
}
.funding-expert-payment-dialog-select .custom-select .select-button .credit-card__text {
  color: #144167;
  font-size: 14px;
}
.funding-expert-payment-dialog-select .custom-select .select-button .text {
  color: #536777;
  font-size: 14px;
}
.funding-expert-payment-dialog-select .custom-select .select-button .icon {
  color: #0a287c;
}
.funding-expert-payment-dialog-select-panel {
  max-height: 250px;
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .select-option {
  background-color: white;
  color: #144167;
  gap: 10px;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .select-option .credit-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .select-option .credit-card__icon {
  height: 18px;
  font-size: 14px;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .select-option .credit-card__text {
  color: #144167;
  font-size: 14px;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .select-option.focus {
  background-color: #d9e1e7;
  color: #809fb8;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .select-option:hover {
  background-color: #d9e1e7;
  color: #809fb8;
}
.funding-expert-payment-dialog-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 2px;
  background-color: #d9e1e7;
}

@media (max-width: 776px) {
  .funding-expert-payment-dialog {
    width: auto;
  }
  .funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card {
    flex-direction: column;
  }
  .funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card__extras .cvc {
    width: 30%;
  }
  .funding-expert-payment-dialog__content .expert-payment-form .form-row .credit-card__extras .date {
    width: 70%;
  }
}
.funding-experts-schedule-dialog {
  max-width: 600px;
  padding: 30px 20px 20px 20px;
  background: white;
  display: flex;
  border-radius: 0 10px 10px 0;
  border-left: 5px solid #fecb2e;
  flex-direction: column;
  position: relative;
  gap: 10px;
}
.funding-experts-schedule-dialog .close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.funding-experts-schedule-dialog__header {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}
.funding-experts-schedule-dialog__header .details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.funding-experts-schedule-dialog__header .details .title {
  font-size: 16px;
  font-weight: bold;
}
.funding-experts-schedule-dialog__header .details .description {
  font-size: 12px;
}
.funding-experts-schedule-dialog__header .avatars {
  margin: 5px 0;
  display: flex;
}
.funding-experts-schedule-dialog__header .avatars .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 2px solid #fecb2e;
  border-radius: 50%;
}
.funding-experts-schedule-dialog__header .avatars .avatar:not(:first-child) {
  margin-left: -10px;
}
.funding-experts-schedule-dialog__content {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.funding-experts-schedule-dialog__content .calendly-schedule {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  border: 1px solid #707070;
}
.funding-experts-schedule-dialog__content .calendly-schedule .calendly-iframe {
  width: 100%;
  height: 300px;
  max-height: 400px;
}
.funding-experts-schedule-dialog__content .calendly-schedule .schedule-button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  background-color: #0a287c;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.funding-experts-success-dialog {
  min-width: 500px;
  min-height: 400px;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 13px 36px rgba(0, 0, 0, 0.08);
  border: 1px solid #d0d0d0;
  background: transparent linear-gradient(180deg, #ffffff 0%, #ffefbc 100%) 0% 0% no-repeat padding-box;
  display: flex;
  border-radius: 0 10px 10px 0;
  border-left: 5px solid #fecb2e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;
}
.funding-experts-success-dialog .close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.funding-experts-success-dialog__content {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.funding-experts-success-dialog__content .logo {
  height: 48px;
}
.funding-experts-success-dialog__content .avatars {
  margin: 5px 0;
  display: flex;
}
.funding-experts-success-dialog__content .avatars .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 2px solid #fecb2e;
  border-radius: 50%;
}
.funding-experts-success-dialog__content .avatars .avatar:not(:first-child) {
  margin-left: -10px;
}
.funding-experts-success-dialog__content .message {
  text-align: center;
  color: #3b3b3b;
  font-size: 14px;
}
.funding-experts-success-dialog__content .resources-center-button {
  cursor: pointer;
  padding: 15px 20px;
  background-color: #fecb2e;
  color: #0a287c;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.funding-experts-success-dialog__content .resources-center-button .icon {
  height: 24px;
}
.funding-experts-success-dialog__content .resources-center-button .text {
  font-size: 12px;
  font-weight: bold;
}

.advanced-search-bar {
  position: relative;
  background-color: white;
}
.advanced-search-bar .loading-background {
  position: absolute;
  background: white;
  opacity: 0.5;
}
.advanced-search-bar .loading-background .loader {
  height: 100%;
  position: absolute;
  z-index: 1000000;
  top: 55%;
  left: 45%;
}
.advanced-search-bar__header {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding-right: 20px;
}
.advanced-search-bar__header .advanced-search-bar-headline {
  width: 550px;
  text-align: start;
  margin: 0 auto;
  margin-left: 5px;
  font-size: 16px;
}
.advanced-search-bar__header .reset-filters-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  min-width: 100px;
}
.advanced-search-bar__header .reset-filters-button .text {
  font-size: 14px;
  color: #809fb8;
  text-decoration: underline;
}
.advanced-search-bar__header .close-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.advanced-search-bar__header .close-button .icon {
  color: #3e4041;
}
.advanced-search-bar__header .close-button .text {
  color: #3e4041;
  font-size: 14px;
}
.advanced-search-bar__form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 13px 26px rgba(10, 40, 124, 0.1215686275);
  border-top: 4px solid #fecb2e;
  gap: 5px;
}
.advanced-search-bar__form .form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.advanced-search-bar__form .fund-types {
  width: 100%;
  min-width: 200px;
  display: flex;
}
.advanced-search-bar__form .area-of-interest {
  width: 100%;
  min-width: 200px;
  display: flex;
}
.advanced-search-bar__form .location {
  width: 100%;
  min-width: 200px;
  display: flex;
}
.advanced-search-bar__form .area-of-activity {
  width: 100%;
  min-width: 200px;
  display: flex;
}
.advanced-search-bar__form .fund-grant-range {
  width: 100%;
  min-width: 200px;
  display: flex;
}
.advanced-search-bar__form .search-query {
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  min-height: 60px;
}
.advanced-search-bar__form .search-query .search-box {
  width: 100%;
  border: 2px solid #d9e1e7;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 65%;
}
.advanced-search-bar__form .search-query .search-box .icon {
  height: 24px;
  width: 24px;
  color: #809fb8;
}
.advanced-search-bar__form .search-query .search-box .search-input {
  min-width: 300px;
  flex: 1;
  font-size: 16px;
  font-family: "Open Sans";
}
.advanced-search-bar__form .search-query .search-box .search-input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #809fb8;
}
.advanced-search-bar__form .search-query .search-box .search-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #809fb8;
  opacity: 1;
}
.advanced-search-bar__form .search-query .search-box .search-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #809fb8;
  opacity: 1;
}
.advanced-search-bar__form .search-query .search-box .search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #809fb8;
}
.advanced-search-bar__form .search-query .find-button {
  cursor: pointer;
  background-color: #262460;
  border-radius: 10px;
  color: white;
  padding: 7px 20px;
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advanced-search-bar-slider {
  width: 100%;
}

.advanced-search-bar-select {
  width: 100%;
}
.advanced-search-bar-select .custom-select .select-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 14px;
  line-height: 14px;
  color: #809fb8;
}
.advanced-search-bar-select .custom-select .select-button {
  border-radius: 16px;
  border: 2px solid #d9e1e7;
  font-family: "Open Sans";
}
.advanced-search-bar-select .custom-select .select-button .select-input {
  width: 100%;
  color: #809fb8;
  font-size: 16px;
  font-family: "Open Sans";
}
.advanced-search-bar-select .custom-select .select-button ::placeholder {
  color: #809fb8;
  font-size: 16px;
}
.advanced-search-bar-select .custom-select .select-button .text {
  color: #809fb8;
  font-size: 16px;
}
.advanced-search-bar-select .custom-select .select-button .icon {
  color: #0a287c;
}
.advanced-search-bar-select .custom-select .selected-options {
  max-height: 70px;
  overflow: auto;
  overflow-x: hidden;
}
.advanced-search-bar-select .custom-select .selected-options::-webkit-scrollbar {
  width: 5px;
}
.advanced-search-bar-select .custom-select .selected-options::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.advanced-search-bar-select .custom-select .selected-options::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}

.advanced-search-bar-select-panel {
  max-height: 250px;
  margin-top: 5px;
  border-radius: 10px;
  border: 2px solid #d9e1e7;
}
.advanced-search-bar-select-panel .select-option-wrapper .select-option {
  background-color: white;
  color: #809fb8;
  gap: 10px;
}
.advanced-search-bar-select-panel .select-option-wrapper .select-option.focus {
  background-color: #d9e1e7;
  color: #809fb8;
}
.advanced-search-bar-select-panel .select-option-wrapper .select-option:hover {
  background-color: #d9e1e7;
  color: #809fb8;
}
.advanced-search-bar-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 2px;
  background-color: #d9e1e7;
}

html[dir=rtl] .advanced-search-bar__header {
  padding-right: unset;
  padding-left: 20px;
}
html[dir=rtl] .advanced-search-bar__header .advanced-search-bar {
  position: relative;
  background-color: white;
}
html[dir=rtl] .advanced-search-bar__header .advanced-search-bar .loading-background {
  position: absolute;
  background: white;
  opacity: 0.5;
}
html[dir=rtl] .advanced-search-bar__header .advanced-search-bar .loading-background .loader {
  height: 100%;
  position: absolute;
  z-index: 1000000;
  top: 55%;
  left: 45%;
}
html[dir=rtl] .advanced-search-bar__header .advanced-search-bar-headline {
  margin: 0 auto;
  margin-right: 5px;
}
html[dir=rtl] .advanced-search-bar__form .form-row {
  flex-direction: row-reverse;
}

@media (max-width: 776px) and (orientation: landscape) {
  .advanced-search-bar {
    width: 100% !important;
  }
}
@media (max-width: 776px) {
  .advanced-search-bar {
    width: 40vh;
  }
  .advanced-search-bar__header {
    flex-direction: column-reverse;
  }
  .advanced-search-bar__header .close-button {
    align-items: center;
    margin: -5px;
  }
  .advanced-search-bar__header .reset-filters-button {
    min-width: unset;
  }
  .advanced-search-bar__header .advanced-search-bar-headline {
    text-align: center;
    margin: 0 !important;
  }
  .advanced-search-bar__form .fund-grant-range {
    padding: 15px 0px 15px 0px;
  }
  .advanced-search-bar__form .area-of-activity {
    width: 100%;
  }
  .advanced-search-bar__form .search-query {
    flex-direction: column;
  }
  .advanced-search-bar__form .search-query .search-box .search-input {
    min-width: 190px;
  }
  .advanced-search-bar__form .form-row {
    flex-direction: column;
  }
}
@media (max-width: 776px) {
  html[dir=rtl] .advanced-search-bar__form .form-row {
    flex-direction: column-reverse;
  }
}
.work-plan-container {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 2rem;
  padding-top: 32px;
  padding-bottom: 3%;
}
.work-plan-container .data-cards-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: clamp(110px, 16%, 140px);
  gap: 2%;
}
.work-plan-container .data-cards-row .card-container {
  width: 100%;
  height: 6rem;
}
.work-plan-container .separator {
  height: 1px;
  width: 100%;
  background-color: #707070;
  opacity: 0.25;
}
.work-plan-container .buttons-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.work-plan-container .buttons-row .buttons-row-left {
  display: flex;
  align-items: center;
  width: 50%;
  gap: 80px;
}
.work-plan-container .buttons-row .buttons-row-left .search-wrapper {
  display: flex;
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  flex-grow: 1;
  max-width: 300px;
}
.work-plan-container .buttons-row .buttons-row-left .search-wrapper > input {
  height: 30px;
  padding-left: 8px;
  background-color: transparent;
  width: 100%;
  max-width: 300px;
  font-family: "Open Sans";
  font-size: 14px;
}
.work-plan-container .buttons-row .buttons-row-left .search-wrapper ::placeholder {
  color: #809fb8;
}
.work-plan-container .buttons-row .buttons-row-left .search-wrapper > img {
  padding-left: 8px;
}
.work-plan-container .buttons-row .buttons-row-left .select-menu {
  font-size: 14px;
  letter-spacing: 0.08px;
  color: #92929d;
  display: flex;
  align-items: center;
}
.work-plan-container .buttons-row .buttons-row-left .select-menu .mat-select {
  display: inline-block;
  width: fit-content;
  outline: none;
}
.work-plan-container .buttons-row .buttons-row-left .select-menu .mat-select-value-text {
  margin-right: 0.5vh;
  font-weight: 600;
  padding-left: 0.5vh;
  color: #44444f;
}
.work-plan-container .buttons-row .buttons-row-left .sort-section {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-plan-container .buttons-row .buttons-row-left .sort-section .sort-icon {
  height: 24px;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-plan-container .buttons-row .buttons-row-left .sort-section .sort-selected-text {
  font-size: 14px;
  cursor: pointer;
  color: black;
  padding: 5px;
}
.work-plan-container .buttons-row .buttons-row-left .sort-section .icon-wrapper {
  height: 24px;
  width: 24px;
  border: 2px solid #d9e1e7;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-plan-container .buttons-row .buttons-row-right {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-grow: 1;
}
.work-plan-container .buttons-row .buttons-row-right .xml-download-btn {
  display: flex;
  gap: 5px;
  height: 35px;
  cursor: pointer;
  color: #0a287c;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  background-color: white;
  justify-content: center;
  border: 1px solid #0a287c;
  width: clamp(150px, 50%, 160px);
  font-family: "Open Sans";
}
.work-plan-container .buttons-row .buttons-row-right .xml-download-btn > img {
  height: 18px;
  width: 18px;
}
.work-plan-container .buttons-row .buttons-row-right .add-work-plan-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fecb2e;
  border-radius: 10px;
  cursor: pointer;
  color: #0a287c;
  gap: 5px;
  font-size: 14px;
  font-weight: bold;
  width: clamp(225px, 50%, 190px);
  font-family: "Open Sans";
}
.work-plan-container .buttons-row .buttons-row-right .add-work-plan-btn > img {
  height: 22px;
  width: 22px;
}
.work-plan-container .table-row {
  width: 100%;
}

.sort-popover {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px rgba(22, 52, 119, 0.1607843137);
  border: 2px solid #d9e1e7;
  border-radius: 10px;
}
.sort-popover .popover-text {
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
  color: black;
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
}

@media (max-width: 776px) {
  .work-plan-container .table-row {
    margin-bottom: 15%;
  }
  .work-plan-container .hidden-phone {
    display: none;
  }
  .work-plan-container .buttons-row {
    flex-direction: column;
    gap: 10px;
  }
  .work-plan-container .buttons-row .buttons-row-left {
    gap: 10px;
    flex-direction: column;
    width: 100%;
  }
  .work-plan-container .data-card {
    max-width: unset;
  }
  .work-plan-container .data-card .card-title {
    text-align: center;
  }
  .work-plan-container .data-card .card-data {
    text-align: center;
  }
  .work-plan-container .data-cards-row {
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  .work-plan-container .data-cards-row .card-container {
    max-width: unset;
  }
}
.work-plan-table .mat-header-cell {
  text-align: center !important;
  padding: 0 10px;
}
.work-plan-table .mat-cell {
  text-align: center !important;
  padding: 0 10px;
}
.work-plan-table td.no-center-text {
  text-align: start !important;
}
.work-plan-table th.mat-header-cell:first-of-type,
.work-plan-table td.mat-cell:first-of-type,
.work-plan-table td.mat-footer-cell:first-of-type {
  padding-left: 0.6rem;
}
.work-plan-table th.mat-header-cell:last-of-type,
.work-plan-table td.mat-cell:last-of-type,
.work-plan-table td.mat-footer-cell:last-of-type {
  padding-right: 0.6rem;
}
.work-plan-table .main-table-container {
  border: 1px solid rgba(0, 0, 0, 0.1843137255);
  width: 100%;
  border-radius: 10px;
  overflow: hidden !important;
}
.work-plan-table .main-table-container .status-cell {
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1;
  border-radius: 25px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
}
.work-plan-table .main-table-container .message-no-subs {
  padding: 15px 10px;
  font-weight: bold;
}
.work-plan-table .main-table-container .main-table-header-row {
  height: 50px;
}
.work-plan-table .main-table-container .main-table-header-row th:first-child {
  border-left: 8px var(--color-workplan) solid;
}
.work-plan-table .main-table-container tr.main-table-element-row:not(.main-table-expanded-row):hover {
  background: rgb(241, 244, 250);
}
.work-plan-table .main-table-container tr.main-table-expanded-row {
  background: rgb(241, 244, 250);
}
.work-plan-table .main-table-container .main-table-element-row td {
  border-bottom-width: 0;
}
.work-plan-table .main-table-container tr.main-table-detail-row {
  height: 0;
  background: rgb(241, 244, 250);
}
.work-plan-table .main-table-container tr.main-table-detail-row td:first-child {
  border-left: 1px var(--color-workplan) solid;
}
.work-plan-table .main-table-container .main-table-element-detail {
  overflow: hidden;
  display: flex;
  padding: 0 1rem;
}
.work-plan-table .main-table-container .main-table-element-row td:first-child {
  border-left: 8px var(--color-workplan) solid;
}
.work-plan-table .main-table-container .sub-table-container {
  border: 1px solid rgba(0, 0, 0, 0.1843137255);
  width: 100%;
  border-radius: 10px;
  overflow: hidden !important;
  margin-bottom: 10px;
}
.work-plan-table .main-table-container .sub-table-container .sub-table-header-row {
  height: 30px;
}
.work-plan-table .main-table-container .sub-table-container .sub-table-header-row th:first-child {
  border-left: 8px var(--color-submission) solid;
}
.work-plan-table .main-table-container .sub-table-container .sub-table-row {
  height: 40px;
}
.work-plan-table .main-table-container .sub-table-container .sub-table-row td:first-child {
  border-left: 8px var(--color-submission) solid;
}

.mat-icon-btn {
  cursor: pointer;
}

html[dir=rtl] .main-table-container th.mat-header-cell:first-of-type,
html[dir=rtl] .main-table-container td.mat-cell:first-of-type,
html[dir=rtl] .main-table-container td.mat-footer-cell:first-of-type {
  padding-right: 0.6rem;
  padding-left: unset;
}
html[dir=rtl] .main-table-container th.mat-header-cell:last-of-type,
html[dir=rtl] .main-table-container td.mat-cell:last-of-type,
html[dir=rtl] .main-table-container td.mat-footer-cell:last-of-type {
  padding-left: 0.6rem;
  padding-right: unset;
}
html[dir=rtl] .main-table-container .main-table-header-row th:first-child {
  border-right: 8px var(--color-workplan) solid;
  border-left: unset;
}
html[dir=rtl] .main-table-container tr.main-table-detail-row td:first-child {
  border-right: 1px var(--color-workplan) solid;
  border-left: unset;
}
html[dir=rtl] .main-table-container .main-table-element-row td:first-child {
  border-right: 8px var(--color-workplan) solid;
  border-left: unset;
}
html[dir=rtl] .main-table-container .sub-table-container .sub-table-header-row th:first-child {
  border-right: 8px var(--color-submission) solid;
  border-left: unset;
}
html[dir=rtl] .main-table-container .sub-table-container .sub-table-row td:first-child {
  border-right: 8px var(--color-submission) solid;
  border-left: unset;
}

.deadline-owner-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}

.task-dialog {
  max-height: 80vh;
  min-width: 300px;
  overflow: auto;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  flex-direction: column;
}
.task-dialog img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  float: right;
  padding: 5px;
}
.task-dialog .red {
  color: red;
}
.task-dialog .task-container {
  padding: 10px;
}
.task-dialog .task-container .form-container .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task-dialog .task-container .form-container .header-wrapper .close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  top: unset;
  left: unset;
  right: unset;
  position: unset;
}
.task-dialog .task-container .form-container .header-wrapper .header-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.task-dialog .task-container .form-container .form-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.task-dialog .task-container .form-container .form-row .owner-select-input {
  border: 1px solid #707070;
  border-radius: 8px;
}
.task-dialog .task-container .form-container .form-row .owner-select-input .owner-placeholder {
  padding-left: 16px;
}
.task-dialog .task-container .form-container .form-row .owner-select-input .arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.task-dialog .task-container .form-container .form-row .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.task-dialog .task-container .form-container .form-row .title {
  font-size: 13px;
  font-weight: 600;
}
.task-dialog .task-container .form-container .form-row .input-wrapper {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  height: 32px;
  display: flex;
  box-sizing: border-box;
}
.task-dialog .task-container .form-container .form-row .input-wrapper:focus-within {
  border: 1px solid #fecb2e;
}
.task-dialog .task-container .form-container .form-row .input-wrapper input {
  padding-left: 16px;
  width: 100%;
  background-color: transparent;
}
.task-dialog .task-container .form-container .form-row .input-wrapper ::placeholder {
  color: #5f5f5f;
  letter-spacing: 0.24px;
  font-size: 12px;
}
.task-dialog .task-container .form-container .form-row .toggle-wrapper {
  height: 32px;
  display: flex;
  width: fit-content;
}
.task-dialog .task-container .form-container .form-row .toggle-wrapper .status-btn {
  color: #707070;
  display: flex;
  font-size: 13px;
  padding: 0 1rem;
  background-color: white;
  line-height: 32px;
  border-radius: 6px;
  border: 1px solid #707070;
  z-index: 1;
}
.task-dialog .task-container .form-container .form-row .toggle-wrapper .btn-overlap {
  margin-right: -8px;
}
.task-dialog .task-container .form-container .form-row .toggle-wrapper .selected-btn {
  background-color: #0a287c;
  color: white;
  z-index: 2;
}
.task-dialog .task-container .form-container .form-row .input-error {
  color: red;
  font-size: 13px;
  display: block;
  padding-left: 5px;
}
.task-dialog .task-container .form-container .form-row .textarea-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.task-dialog .task-container .form-container .form-row .textarea-wrapper > label {
  font-family: OpenSans, sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
}
.task-dialog .task-container .form-container .form-row .textarea-wrapper > textarea {
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  min-height: 4.5rem;
  max-width: 100%;
  resize: none;
  color: #5f5f5f;
  font-size: 14px;
  font-family: OpenSans, sans-serif;
  border: 1px solid black;
}
.task-dialog .task-container .tasks-table {
  width: 95%;
  margin: 0 auto;
}
.task-dialog .task-container .tasks-table .columns-text {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  letter-spacing: -0.26px;
  opacity: 1;
  font: normal normal 600 14px/14px OpenSans;
  padding-right: 20px;
}
.task-dialog .task-container .tasks-table .line {
  border: 1px solid #e9e9e9;
  width: 96%;
  margin: 5px;
}
.task-dialog .task-container .tasks-table .open-tasks {
  margin-bottom: 16px;
  font-size: 17px;
  display: flex;
  gap: 3px;
  color: #171725;
  align-items: center;
}
.task-dialog .task-container .tasks-table .open-task-text {
  text-align: center;
  letter-spacing: -0.26px;
  color: #809fb8;
  opacity: 1;
  font: normal normal 600 13px/13px OpenSans;
}
.task-dialog .task-container .tasks-table .tasks-list {
  display: flex;
  padding-right: 17px;
  padding-left: 2px;
  justify-content: space-between;
}
.task-dialog .task-container .tasks-table .tasks-list .name-text {
  width: 200px;
  height: 20px;
  overflow: hidden;
}
.task-dialog .task-container .tasks-table .tasks-list .list-text {
  font: normal normal 600 13px/13px OpenSans;
}
.task-dialog .task-container .tasks-table .tasks-list .icons {
  flex-direction: row;
}
.task-dialog .task-container .tasks-table .tasks-list .icons .view-action {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.task-dialog .task-container .tasks-table .tasks-list .icons .delete-action {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.task-dialog .task-container .finish-btn-wrapper {
  width: 88%;
  display: flex;
  padding-left: 6%;
  justify-content: flex-end;
}
.task-dialog .task-container .finish-btn-wrapper .finish-btn {
  height: 32px;
  color: #0a287c;
  font-size: 12px;
  letter-spacing: -0.24;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 8px;
  align-self: center;
  margin-bottom: 20px;
  padding-inline: 1rem;
  background-color: #fecb2e;
  border: 1px solid #fecb2e;
  font-family: OpenSans, sans-serif;
}
.task-dialog .task-container .finish-btn-wrapper .disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}

.task-dialog-select {
  width: 100%;
}
.task-dialog-select .custom-select {
  gap: 0px;
  color: #5f5f5f;
}
.task-dialog-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.task-dialog-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  height: 12px;
  color: #5f5f5f;
}
.task-dialog-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.task-dialog-select .custom-select .select-button .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.task-dialog-select .custom-select .select-button .workplan__icon {
  font-size: 14px;
}
.task-dialog-select .custom-select .select-button .workplan__text {
  color: #000000;
  font-size: 14px;
}
.task-dialog-select .custom-select .select-button .icon {
  color: #0a287c;
}
.task-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.task-dialog-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.task-dialog-select-panel .select-option-wrapper .select-option .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.task-dialog-select-panel .select-option-wrapper .select-option .workplan__icon {
  font-size: 14px;
}
.task-dialog-select-panel .select-option-wrapper .select-option .workplan__text {
  color: #000000;
  font-size: 14px;
}
.task-dialog-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.task-dialog-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.task-dialog-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.task-dialog-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

@media (max-width: 776px) {
  .task-dialog {
    min-width: unset;
  }
}
.submission-dialog {
  min-width: 270px;
  max-height: 87vh;
  margin: 5px auto;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.submission-dialog img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  position: sticky;
  cursor: pointer;
  left: calc(100% - 16px - 18px);
  position: relative;
  display: flex;
  background-color: transparent;
  float: right;
  padding: 5px;
  cursor: pointer;
}
.submission-dialog .submission-container {
  flex-direction: row;
  display: flex;
  padding: 10px;
  gap: 12px;
  overflow: auto;
  overflow-x: hidden;
  /*align-items: center;
  margin: 10px;*/
}
.submission-dialog .submission-container::-webkit-scrollbar {
  width: 5px;
}
.submission-dialog .submission-container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.submission-dialog .submission-container::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.submission-dialog .submission-container .form-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
}
.submission-dialog .submission-container .form-container .header-title {
  font-size: 17px;
  display: flex;
  gap: 10px;
  color: #171725;
  align-items: center;
}
.submission-dialog .submission-container .form-container .header-title .header-icon {
  height: 32px;
}
.submission-dialog .submission-container .form-container .form-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.submission-dialog .submission-container .form-container .form-row .owner-select-input {
  border: 1px solid #707070;
  border-radius: 8px;
}
.submission-dialog .submission-container .form-container .form-row .owner-select-input .owner-placeholder {
  padding-left: 16px;
}
.submission-dialog .submission-container .form-container .form-row .owner-select-input .arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.submission-dialog .submission-container .form-container .form-row .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.submission-dialog .submission-container .form-container .form-row .title {
  font-size: 13px;
  font-weight: 600;
}
.submission-dialog .submission-container .form-container .form-row .file-input-wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  box-sizing: border-box;
  background: rgba(138, 140, 152, 0.0980392157) 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  border-radius: 6px;
  opacity: 1;
}
.submission-dialog .submission-container .form-container .form-row .file-input-wrapper input {
  padding-left: 8px;
  width: 100%;
  background-color: transparent;
}
.submission-dialog .submission-container .form-container .form-row .file-input-wrapper ::placeholder {
  color: #5f5f5f;
}
.submission-dialog .submission-container .form-container .form-row .input-wrapper {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  height: 32px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.submission-dialog .submission-container .form-container .form-row .input-wrapper:focus-within {
  border: 1px solid #fecb2e;
}
.submission-dialog .submission-container .form-container .form-row .input-wrapper .input-icon {
  opacity: 1;
  color: #707070;
  padding-right: 3px;
}
.submission-dialog .submission-container .form-container .form-row .input-wrapper input {
  padding-left: 16px;
  width: 100%;
  background-color: transparent;
}
.submission-dialog .submission-container .form-container .form-row .input-wrapper ::placeholder {
  color: #5f5f5f;
  letter-spacing: 0.24px;
  font-size: 12px;
}
.submission-dialog .submission-container .form-container .form-row .toggle-wrapper {
  height: 32px;
  display: flex;
  width: fit-content;
}
.submission-dialog .submission-container .form-container .form-row .toggle-wrapper .status-btn {
  color: #707070;
  display: flex;
  font-size: 13px;
  padding: 0 1rem;
  background-color: white;
  line-height: 32px;
  border-radius: 6px;
  border: 1px solid #707070;
  z-index: 1;
  cursor: pointer;
}
.submission-dialog .submission-container .form-container .form-row .toggle-wrapper .btn-overlap {
  margin-right: -8px;
}
.submission-dialog .submission-container .form-container .form-row .toggle-wrapper .selected-btn {
  background-color: #0a287c;
  color: white;
  z-index: 2;
}
.submission-dialog .submission-container .form-container .red {
  color: red;
}
.submission-dialog .submission-container .finish-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.submission-dialog .submission-container .finish-btn-wrapper .finish-btn {
  height: 32px;
  color: #0a287c;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.24;
  border-radius: 8px;
  align-self: center;
  margin-bottom: 20px;
  padding-inline: 1rem;
  background-color: #fecb2e;
  border: 1px solid #fecb2e;
  font-family: OpenSans, sans-serif;
}
.submission-dialog .submission-container .finish-btn-wrapper .disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}
.submission-dialog .submission-container .save-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
.submission-dialog .submission-container .save-btn-wrapper .save-btn {
  width: 100px;
  height: 32px;
  color: #0a287c;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.24;
  border-radius: 8px;
  align-self: center;
  padding-inline: 1rem;
  background-color: #fecb2e;
  border: 1px solid #fecb2e;
  font-family: OpenSans, sans-serif;
}
.submission-dialog .submission-container .save-btn-wrapper .disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}

.edit-submission .edit-container {
  flex-direction: column;
  min-width: 300px;
  justify-content: space-evenly;
  display: flex;
}
.edit-submission .scroll-section {
  overflow-y: auto;
  height: 70%;
}
.edit-submission div.vertical-line {
  height: auto;
  width: 1px;
  background-color: black;
  opacity: 0.43;
  margin-left: 0;
  margin-top: 10%;
}
.edit-submission .open-task-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.edit-submission .open-task-row .add-task-btn {
  color: white;
  background: #0a287c;
  border-radius: 8px;
  width: 110px;
  height: 32px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.24;
  align-self: center;
  padding-inline: 1rem;
  font-family: OpenSans, sans-serif;
}
.edit-submission .open-tasks {
  display: flex;
}
.edit-submission .open-task-text {
  text-align: center;
  font: normal normal 600 13px/18px OpenSans;
  letter-spacing: -0.26px;
  color: #809fb8;
  opacity: 1;
  font-size: 11px;
  font-weight: 900;
}
.edit-submission .tasks-table {
  width: 95%;
  margin: 0 auto;
  height: 40%;
  overflow-y: scroll;
}
.edit-submission .columns-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}
.edit-submission .table-header {
  font-size: 11px;
  letter-spacing: 0.05px;
  color: #809fb8;
  opacity: 1;
}
.edit-submission .line {
  border: 1px solid #e9e9e9;
  width: 96%;
}
.edit-submission .action-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.edit-submission .title-icon {
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-right: 3px;
}
.edit-submission .tasks-list {
  display: flex;
  padding-left: 2px;
  justify-content: space-between;
  align-items: center;
}
.edit-submission .tasks-list .name-text {
  font-size: 12px;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.edit-submission .tasks-list .icons {
  display: flex;
  flex-direction: row;
  margin-right: 14px;
}
.edit-submission .tasks-list .icons .view-action {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.edit-submission .tasks-list .icons .delete-action {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

div.scroll-section::-webkit-scrollbar {
  width: 5px;
}

/* Track */
div.scroll-section::-webkit-scrollbar-track {
  background: #d0d0d0;
}

div.scroll-section::-webkit-scrollbar-thumb {
  background: #0a287c;
  height: 3px;
}

html[dir=rtl] .overlay .create-work-plan-wrapper img.close-icon {
  left: 16px;
}
html[dir=rtl] .overlay .create-work-plan-wrapper .form-container .form-row .input-wrapper input {
  padding-left: 0;
  padding-right: 8px;
}
html[dir=rtl] .overlay .create-work-plan-wrapper .form-container .form-row .toggle-wrapper .btn-overlap {
  margin-right: 0;
  margin-left: -8px;
}
html[dir=rtl] .edit-submission .create-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 200;
}
html[dir=rtl] .edit-submission .create-overlay .create-wrapper {
  width: 30%;
  max-width: 350px;
  min-width: 270px;
  height: fit-content;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
html[dir=rtl] .edit-submission .create-overlay .create-wrapper img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  position: sticky;
  cursor: pointer;
  left: calc(100% - 16px - 18px);
}
html[dir=rtl] .submission-dialog {
  min-width: 270px;
  max-height: 87vh;
  margin: 5px auto;
  background-color: white;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: unset;
  border-right: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
html[dir=rtl] .submission-dialog img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  position: sticky;
  cursor: pointer;
  right: calc(100% - 16px - 18px);
  position: relative;
  display: flex;
  background-color: transparent;
  float: right;
  padding: 5px;
  cursor: pointer;
}

.submission-dialog-select {
  width: 100%;
}
.submission-dialog-select .custom-select {
  gap: 0px;
}
.submission-dialog-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.submission-dialog-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  height: 20px;
  min-width: 150px;
}
.submission-dialog-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.submission-dialog-select .custom-select .select-button .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.submission-dialog-select .custom-select .select-button .workplan__icon {
  font-size: 14px;
}
.submission-dialog-select .custom-select .select-button .workplan__text {
  color: #000000;
  font-size: 14px;
}
.submission-dialog-select .custom-select .select-button .icon {
  color: #0a287c;
}
.submission-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.submission-dialog-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.submission-dialog-select-panel .select-option-wrapper .select-option .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.submission-dialog-select-panel .select-option-wrapper .select-option .workplan__icon {
  font-size: 14px;
}
.submission-dialog-select-panel .select-option-wrapper .select-option .workplan__text {
  color: #000000;
  font-size: 14px;
}
.submission-dialog-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.submission-dialog-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.submission-dialog-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.submission-dialog-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

@media (max-width: 776px) {
  .submission-dialog {
    min-width: unset;
  }
  .submission-dialog .submission-container .form-container {
    min-width: unset;
    width: 100%;
  }
}
.workplan-container {
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.workplan-container .form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
}
.workplan-container .form-container .header-title {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  gap: 10px;
  color: #171725;
  align-items: center;
}
.workplan-container .form-container .header-title .header-icon {
  height: 32px;
}
.workplan-container .form-container .form-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.workplan-container .form-container .form-row .owner-select-input {
  border: 1px solid #707070;
  border-radius: 8px;
}
.workplan-container .form-container .form-row .owner-select-input .owner-placeholder {
  padding-left: 16px;
}
.workplan-container .form-container .form-row .owner-select-input .arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.workplan-container .form-container .form-row .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.workplan-container .form-container .form-row .title {
  font-size: 14px;
  font-weight: 600;
}
.workplan-container .form-container .form-row .input-wrapper {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  height: 32px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.workplan-container .form-container .form-row .input-wrapper:focus-within {
  border: 1px solid #fecb2e;
}
.workplan-container .form-container .form-row .input-wrapper .input-icon {
  opacity: 1;
  color: #707070;
  padding-right: 3px;
}
.workplan-container .form-container .form-row .input-wrapper input {
  padding-left: 16px;
  width: 100%;
  background-color: transparent;
}
.workplan-container .form-container .form-row .input-wrapper ::placeholder {
  color: #5f5f5f;
  letter-spacing: 0.24px;
  font-size: 14px;
}
.workplan-container .form-container .form-row .toggle-wrapper {
  height: 32px;
  display: flex;
  width: fit-content;
}
.workplan-container .form-container .form-row .toggle-wrapper .status-btn {
  color: #707070;
  display: flex;
  font-size: 13px;
  padding: 0 1rem;
  background-color: white;
  line-height: 32px;
  border-radius: 6px;
  border: 1px solid #707070;
  z-index: 1;
  cursor: pointer;
}
.workplan-container .form-container .form-row .toggle-wrapper .btn-overlap {
  margin-right: -8px;
}
.workplan-container .form-container .form-row .toggle-wrapper .selected-btn {
  background-color: #0a287c;
  color: white;
  z-index: 2;
}
.workplan-container .form-container .form-row .input-error {
  color: red;
  font-size: 13px;
  display: block;
  padding-left: 5px;
}
.workplan-container .finish-btn-wrapper {
  width: 88%;
  display: flex;
  padding-left: 6%;
  justify-content: flex-end;
}
.workplan-container .finish-btn-wrapper .finish-btn {
  height: 32px;
  color: #0a287c;
  font-size: 12px;
  letter-spacing: -0.24;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 8px;
  align-self: center;
  margin-bottom: 20px;
  padding-inline: 1rem;
  background-color: #fecb2e;
  border: 1px solid #fecb2e;
  font-family: OpenSans, sans-serif;
}
.workplan-container .finish-btn-wrapper .disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}

app-work-plan-dialog .mat-form-field-underline {
  display: none;
}
app-work-plan-dialog .mat-select-arrow-wrapper {
  display: none !important;
}
app-work-plan-dialog .mat-form-field-infix {
  padding: 0 !important;
  line-height: 20px !important;
  font-size: 13px;
  height: 30px;
  display: flex;
  border-width: 0;
  align-items: center;
}
app-work-plan-dialog mat-form-field {
  width: 100%;
  overflow: auto;
}
app-work-plan-dialog .mat-select-value-text {
  color: rgba(0, 0, 0, 0.3490196078);
  padding-left: 20px;
}
app-work-plan-dialog .red {
  color: red;
}

html[dir=rtl] .overlay .create-work-plan-wrapper img.close-icon {
  left: 16px;
}
html[dir=rtl] .overlay .create-work-plan-wrapper .form-container .form-row .input-wrapper input {
  padding-left: 0;
  padding-right: 8px;
}
html[dir=rtl] .overlay .create-work-plan-wrapper .form-container .form-row .toggle-wrapper .status-btn {
  cursor: pointer;
}
html[dir=rtl] .overlay .create-work-plan-wrapper .form-container .form-row .toggle-wrapper .btn-overlap {
  margin-right: 0;
  margin-left: -8px;
}
.add-to-workplan-wrapper {
  padding: 20px 30px;
  background-color: white;
  height: 100%;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #d0d0d0;
  border-radius: 0 10px 10px 0;
  border-left: 8px solid #fecb2e;
  position: relative;
}
.add-to-workplan-wrapper img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  position: absolute;
  cursor: pointer;
  left: calc(100% - 16px - 18px);
}
.add-to-workplan-wrapper .add-to-workplan {
  padding: 30px 16px 0 16px;
}
.add-to-workplan-wrapper .add-to-workplan__header {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  gap: 10px;
  color: #171725;
  align-items: center;
}
.add-to-workplan-wrapper .add-to-workplan__header .header-icon {
  height: 32px;
}
.add-to-workplan-wrapper .add-to-workplan .disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.add-to-workplan-wrapper .add-to-workplan__actions {
  display: flex;
  padding-top: 22px;
  flex-direction: column;
  gap: 18px;
}
.add-to-workplan-wrapper .add-to-workplan__actions .form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  min-width: 370px;
}
.add-to-workplan-wrapper .add-to-workplan__actions .form-row .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.add-to-workplan-wrapper .add-to-workplan__actions .form-row .chooser-btn {
  width: 100%;
  max-width: 150px;
  height: 32px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.add-to-workplan-wrapper .add-to-workplan__actions .form-row .create-new-btn {
  background-color: #0a287c;
  color: white;
}
.add-to-workplan-wrapper .add-to-workplan__actions .form-row .next-btn {
  background-color: #fecb2e;
  color: #0a287c;
}
.add-to-workplan-wrapper .add-to-workplan__actions .flex-end {
  display: flex;
  justify-content: flex-end;
}

app-add-to-workplan .mat-form-field-underline {
  display: none;
}
app-add-to-workplan .mat-select-arrow-wrapper {
  display: none !important;
}
app-add-to-workplan .mat-form-field-infix {
  padding: 0 !important;
  line-height: 20px !important;
  font-size: 13px;
  height: 30px;
  display: flex;
  border-width: 0;
  align-items: center;
}
app-add-to-workplan mat-form-field {
  width: 100%;
  overflow: auto;
}
app-add-to-workplan .mat-select-value-text {
  color: #5f5f5f;
  padding-left: 20px;
}

.add-to-workplan-select {
  width: 100%;
}
.add-to-workplan-select .custom-select {
  gap: 0px;
}
.add-to-workplan-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.add-to-workplan-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  height: 20px;
  min-width: 150px;
}
.add-to-workplan-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.add-to-workplan-select .custom-select .select-button .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.add-to-workplan-select .custom-select .select-button .workplan__icon {
  font-size: 14px;
}
.add-to-workplan-select .custom-select .select-button .workplan__text {
  color: #000000;
  font-size: 14px;
}
.add-to-workplan-select .custom-select .select-button .icon {
  color: #0a287c;
}
.add-to-workplan-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option .workplan__icon {
  font-size: 14px;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option .workplan__text {
  color: #000000;
  font-size: 14px;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.add-to-workplan-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.add-to-workplan-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

@media (max-width: 776px) {
  .add-to-workplan-wrapper .add-to-workplan__actions .form-row {
    min-width: unset;
    flex-direction: column;
  }
  .add-to-workplan-wrapper .add-to-workplan__actions .form-row .chooser-btn {
    height: unset;
    min-height: 32px;
    max-width: unset;
  }
}
.data-card {
  gap: 5px;
  max-width: 280px;
  display: flex;
  overflow-y: scroll;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  background: #e9eef8;
  box-shadow: 0 10px 30px rgba(10, 40, 124, 0.1019607843);
  border: 1px solid rgba(112, 112, 112, 0.2352941176);
  border-left: 1px solid transparent;
  border-radius: 10px;
}
.data-card .accent-border {
  height: 100%;
  width: 7px;
  position: absolute;
  top: 0;
  left: 0;
}
.data-card .accent-ellipse-border {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 2;
  opacity: 0.26;
  filter: blur(30px);
}
.data-card .card-title {
  font-size: 13px;
  font-family: "Open Sans";
  font-weight: bold;
  letter-spacing: 1.3px;
  color: #809fb8;
}
.data-card .card-data {
  font-size: 25px;
  font-weight: bold;
}

html[dir=rtl] .data-card {
  padding-right: 15px;
  padding-left: 0;
}
html[dir=rtl] .data-card .accent-border {
  left: unset;
  right: 0;
}

.work-plan-dialog {
  min-width: 270px;
  max-height: 80vh;
  background-color: white;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  overflow: auto;
  flex-direction: column;
  border-radius: unset;
}
.work-plan-dialog .workplan-container .form-container .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work-plan-dialog .workplan-container .form-container .header-wrapper .close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  top: unset;
  left: unset;
  right: unset;
  position: unset;
}
.work-plan-dialog .workplan-container .form-container .header-wrapper .header-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.work-plan-dialog .workplan-container .finish-btn-wrapper {
  padding-left: unset;
  width: 500px;
  display: flex;
  justify-content: center;
}
.work-plan-dialog img.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.work-plan-dialog::-webkit-scrollbar {
  width: 5px;
  height: 90%;
  border-radius: 20px;
}
.work-plan-dialog::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 20px;
}
.work-plan-dialog::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 20px;
}

.work-plan-dialog-select {
  width: 100%;
}
.work-plan-dialog-select .custom-select {
  gap: 0px;
}
.work-plan-dialog-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.work-plan-dialog-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  height: 20px;
  min-width: 150px;
}
.work-plan-dialog-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.work-plan-dialog-select .custom-select .select-button .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.work-plan-dialog-select .custom-select .select-button .workplan__icon {
  font-size: 14px;
}
.work-plan-dialog-select .custom-select .select-button .workplan__text {
  color: #000000;
  font-size: 14px;
}
.work-plan-dialog-select .custom-select .select-button .icon {
  color: #0a287c;
}
.work-plan-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .workplan__icon {
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .workplan__text {
  color: #000000;
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.work-plan-dialog-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

html[dir=rtl] .work-plan-dialog {
  min-width: 270px;
  max-height: 80vh;
  background-color: white;
  border: 1px solid #d0d0d0;
  border-right: 8px solid #fecb2e;
  border-left: unset;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.08);
  overflow: auto;
}
html[dir=rtl] .work-plan-dialog img.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
html[dir=rtl] .work-plan-dialog .workplan-container .finish-btn-wrapper {
  width: 500px;
  display: flex;
  justify-content: center;
  padding-left: unset;
}

@media (max-width: 776px) {
  .work-plan-dialog {
    width: 100%;
    min-width: unset;
  }
  .work-plan-dialog .workplan-container .finish-btn-wrapper {
    width: unset !important;
  }
}
.work-plan-dialog {
  min-width: 270px;
  max-height: 80vh;
  background-color: white;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  overflow: auto;
  flex-direction: column;
  border-radius: unset;
}
.work-plan-dialog .workplan-container .form-container .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.work-plan-dialog .workplan-container .form-container .header-wrapper .close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  top: unset;
  left: unset;
  right: unset;
  position: unset;
}
.work-plan-dialog .workplan-container .form-container .header-wrapper .header-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.work-plan-dialog .workplan-container .finish-btn-wrapper {
  padding-left: unset;
  width: 500px;
  display: flex;
  justify-content: center;
}
.work-plan-dialog img.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.work-plan-dialog::-webkit-scrollbar {
  width: 5px;
  height: 90%;
  border-radius: 20px;
}
.work-plan-dialog::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 20px;
}
.work-plan-dialog::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 20px;
}

.work-plan-dialog-select {
  width: 100%;
}
.work-plan-dialog-select .custom-select {
  gap: 0px;
}
.work-plan-dialog-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.work-plan-dialog-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  height: 20px;
  min-width: 150px;
}
.work-plan-dialog-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.work-plan-dialog-select .custom-select .select-button .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.work-plan-dialog-select .custom-select .select-button .workplan__icon {
  font-size: 14px;
}
.work-plan-dialog-select .custom-select .select-button .workplan__text {
  color: #000000;
  font-size: 14px;
}
.work-plan-dialog-select .custom-select .select-button .icon {
  color: #0a287c;
}
.work-plan-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .workplan {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .workplan__icon {
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .workplan__text {
  color: #000000;
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.work-plan-dialog-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.work-plan-dialog-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

html[dir=rtl] .work-plan-dialog {
  min-width: 270px;
  max-height: 80vh;
  background-color: white;
  border: 1px solid #d0d0d0;
  border-right: 8px solid #fecb2e;
  border-left: unset;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.08);
  overflow: auto;
}
html[dir=rtl] .work-plan-dialog img.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
html[dir=rtl] .work-plan-dialog .workplan-container .finish-btn-wrapper {
  width: 500px;
  display: flex;
  justify-content: center;
  padding-left: unset;
}

@media (max-width: 776px) {
  .work-plan-dialog {
    width: 100%;
    min-width: unset;
  }
  .work-plan-dialog .workplan-container .finish-btn-wrapper {
    width: unset !important;
  }
}
.document-dialog {
  min-width: 270px;
  max-width: 400px;
  max-height: 50vh;
  min-height: 290px;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  overflow: auto;
  flex-direction: column;
}
.document-dialog img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  float: right;
  padding: 5px;
}
.document-dialog .document-container {
  padding: 10px;
}
.document-dialog .document-container .header-title {
  font-family: OpenSans;
  font-size: 17px;
  display: flex;
  gap: 10px;
  color: #171725;
  align-items: center;
}
.document-dialog .document-container img.close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  position: sticky;
  cursor: pointer;
  left: 0px;
}
.document-dialog .document-container .documents-table .columns-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal 600 13px/13px OpenSans;
  opacity: 1;
}
.document-dialog .document-container .documents-table .line {
  border: 1px solid #e9e9e9;
  width: 96%;
}
.document-dialog .document-container .documents-table .open-documents {
  margin-bottom: 16px;
}
.document-dialog .document-container .documents-table .action-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.document-dialog .document-container .documents-table .open-documents-text {
  text-align: center;
  font: normal normal 600 13px/13px OpenSans;
  letter-spacing: -0.26px;
  color: #809fb8;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}
.document-dialog .document-container .documents-table .documents-list {
  display: flex;
  padding-left: 2px;
  justify-content: space-between;
  align-items: center;
}
.document-dialog .document-container .documents-table .documents-list .name-text {
  width: 200px;
  overflow: hidden;
  font: normal normal 600 12px/32px OpenSans;
}
.document-dialog .document-container .documents-table .documents-list .icons {
  display: flex;
}
.document-dialog .document-container .documents-table .documents-list .icons .view-action {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.document-dialog .document-container .documents-table .documents-list .icons .delete-action {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.account-settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 1002;
}
.account-settings__menu {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.account-settings__menu .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 1.5vh;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.15vw solid #d9e1e7;
}
.account-settings__menu .close-button .icon-close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
}
.account-settings__menu .top-menu-wrapper {
  display: flex;
  margin: 20px;
  justify-content: space-between;
}
.account-settings__menu .top-menu-wrapper .menu {
  display: flex;
  flex-direction: column;
  border: none;
}
.account-settings__menu .top-menu-wrapper .menu .menu-button {
  cursor: pointer;
  color: #9d9d9d;
  font: normal normal 600 17px/30px OpenSans;
  display: flex;
  align-items: center;
  height: 48px;
}
.account-settings__menu .top-menu-wrapper .menu .menu-button__content {
  margin: 5px 20px;
}
.account-settings__menu .top-menu-wrapper .menu .menu-button__line {
  height: 100%;
  position: relative;
  display: flex;
  width: 3px;
}
.account-settings__menu .top-menu-wrapper .menu .menu-button__line .line-background {
  height: 100%;
  width: 1px;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f3f3f3;
  z-index: 1;
}
.account-settings__menu .top-menu-wrapper .menu .menu-button__line .active-line {
  height: calc(100% - 15px);
  width: 3px;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fecb2e;
  z-index: 2;
}
.account-settings__menu .top-menu-wrapper .menu .menu-button__active {
  color: #0a287c;
  font-weight: bold;
}
.account-settings__menu .bottom-menu-wrapper {
  min-width: 250px;
  margin: 20px;
}
.account-settings__menu .bottom-menu-wrapper .banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fafbfc;
  border-radius: 10px;
  padding: 30px 20px;
}
.account-settings__menu .bottom-menu-wrapper .banner .upgrade-notification {
  background-color: #faf0cd;
  display: flex;
  border-radius: 10px;
  margin: 5px 0;
}
.account-settings__menu .bottom-menu-wrapper .banner .upgrade-notification__content {
  margin: 10px 20px;
  color: #fecb2e;
}
.account-settings__menu .bottom-menu-wrapper .banner .upgrade-notification__image {
  margin: 10px;
}
.account-settings__menu .bottom-menu-wrapper .banner__text-box {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}
.account-settings__menu .bottom-menu-wrapper .banner__title {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}
.account-settings__menu .bottom-menu-wrapper .banner__subtitle {
  color: #000000;
  font-weight: bold;
  font-size: 12px;
}
.account-settings__menu .bottom-menu-wrapper .banner__profiles {
  margin: 5px 0;
  display: flex;
}
.account-settings__menu .bottom-menu-wrapper .banner__profiles .avatar {
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 50%;
}
.account-settings__menu .bottom-menu-wrapper .banner__profiles .avatar:not(:first-child) {
  margin-left: -10px;
}
.account-settings__menu .bottom-menu-wrapper .banner__footer {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.account-settings__menu .bottom-menu-wrapper .banner__footer .contact-link {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
}
.account-settings__menu .bottom-menu-wrapper .banner__footer .upgrade-button {
  background-color: #fecb2f;
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  cursor: pointer;
  border-radius: 20px;
  color: #000000;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}
.account-settings__content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

html[dir=rtl] .account-settings__menu .close-button {
  right: unset;
  left: 20px;
}

@media (max-width: 776px) {
  .account-settings {
    flex-direction: column-reverse;
  }
  .account-settings .bottom-menu-wrapper {
    display: none;
  }
}
.profile-settings {
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 150px 40px 20px;
  gap: 30px;
}
.profile-settings__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.profile-settings__header .title {
  font-size: 18px;
  color: #171725;
}
.profile-settings__header .subtitle {
  font-size: 18px;
  color: #0a287c;
}
.profile-settings__organization-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.profile-settings__organization-form .form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.profile-settings__organization-form .form-row .form-control {
  width: 45%;
}
.profile-settings .divider {
  width: 100%;
  height: 1px;
  background-color: #d7d7d7;
  visibility: visible;
  margin-block: 30px;
}
.profile-settings__user-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.profile-settings__user-form .form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.profile-settings__user-form .form-row .form-control {
  width: 50%;
}
.profile-settings__user-form .form-row .user-permission {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-settings__user-form .form-row .user-permission__label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.profile-settings__user-form .form-row .user-permission__select-box {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
}
.profile-settings__user-form .form-row .user-permission__select-box .text {
  font-size: 14px;
  color: black;
}
.profile-settings__user-form .form-row .user-permission__select-box .icon {
  font-size: 24px;
  display: flex;
  color: #d7d7d7;
  justify-content: center;
  align-items: center;
}
.profile-settings__user-form .form-row .user-profile-picture {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.profile-settings__user-form .form-row .user-profile-picture .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 50%;
}
.profile-settings__user-form .form-row .user-profile-picture .change-photo-button {
  cursor: pointer;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a287c;
  color: #0a287c;
  border-radius: 20px;
}
.profile-settings__user-form .form-row .user-profile-picture .change-photo-button .text {
  font-size: 14px;
  color: #0a287c;
}
.profile-settings__preferences-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.profile-settings__preferences-form .form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.profile-settings__preferences-form .form-row .form-control {
  width: 100%;
}
.profile-settings__preferences-form .form-row .user-permission {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-settings__preferences-form .form-row .user-permission__label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.profile-settings__preferences-form .form-row .user-permission__select-box {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
}
.profile-settings__preferences-form .form-row .user-permission__select-box .text {
  font-size: 14px;
  color: black;
}
.profile-settings__preferences-form .form-row .user-permission__select-box .icon {
  font-size: 24px;
  display: flex;
  color: #d7d7d7;
  justify-content: center;
  align-items: center;
}
.profile-settings__preferences-form .form-row .user-profile-picture {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.profile-settings__preferences-form .form-row .user-profile-picture .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 50%;
}
.profile-settings__preferences-form .form-row .user-profile-picture .change-photo-button {
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a287c;
  color: #0a287c;
  border-radius: 20px;
}
.profile-settings__preferences-form .form-row .user-profile-picture .change-photo-button .text {
  font-size: 14px;
  color: #0a287c;
}
.profile-settings__preferences-form .form-row .funding-range-slider {
  padding: 10px;
  display: flex;
}
.profile-settings .preference {
  text-align: center;
  font: normal normal 300 20px/36px OpenSans;
  letter-spacing: 0.08px;
  color: #171725;
}

.profile-settings-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0 0 0;
}
.profile-settings-wrapper .save-button {
  width: 100px;
  height: 40px;
  line-height: 17px;
  padding: 0 15px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}

.profile-settings-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-settings-input .input-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.profile-settings-input .input-box {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #262460;
  color: #000000;
  font-size: 1rem;
}
.profile-settings-input .input-box__input {
  width: 100%;
  height: 24px;
  padding: 0;
  font-size: 0.95rem;
}

.profile-settings-select {
  width: 100%;
}
.profile-settings-select .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.profile-settings-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  padding: 10px 5px;
}
.profile-settings-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.profile-settings-select .custom-select .select-button .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.profile-settings-select .custom-select .select-button .country__icon {
  font-size: 14px;
}
.profile-settings-select .custom-select .select-button .country__text {
  color: #000000;
  font-size: 14px;
}
.profile-settings-select .custom-select .select-button .icon {
  color: #0a287c;
}
.profile-settings-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}
.profile-settings-select-panel .disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.profile-settings-select-panel .select-option-wrapper .select-option {
  padding: 10px 5px;
  background-color: white;
  font-size: 14px;
}
.profile-settings-select-panel .select-option-wrapper .select-option .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.profile-settings-select-panel .select-option-wrapper .select-option .country__icon {
  font-size: 14px;
}
.profile-settings-select-panel .select-option-wrapper .select-option .country__text {
  color: #000000;
  font-size: 14px;
}
.profile-settings-select-panel .select-option-wrapper .select-option .text {
  font-size: 14px;
}
.profile-settings-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #000000;
}
.profile-settings-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #000000;
}
.profile-settings-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

html[dir=rtl] .profile-settings {
  padding: 40px 20px 40px 150px;
}
html[dir=rtl] .profile-settings-wrapper .save-button {
  right: unset;
  left: 10px;
}

@media (max-width: 776px) {
  .profile-settings-wrapper .save-button {
    bottom: 191px !important;
    z-index: 10000;
  }
  .profile-settings {
    padding: 5px !important;
  }
  .profile-settings__organization-form .form-row, .profile-settings__user-form .form-row {
    align-items: center;
    flex-direction: column;
  }
  .profile-settings__organization-form .form-row .form-control, .profile-settings__user-form .form-row .form-control {
    width: 75%;
  }
  .profile-settings__preferences-form {
    padding-bottom: 70%;
  }
  .profile-settings__preferences-form .form-row {
    flex-direction: column;
  }
}
.billing-and-plans {
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 150px 40px 40px;
  gap: 30px;
}
.billing-and-plans__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.billing-and-plans__header .title {
  font-size: 18px;
  color: #171725;
}
.billing-and-plans__header .subtitle {
  font-size: 18px;
  color: #0a287c;
}
.billing-and-plans .current-plan {
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.billing-and-plans .current-plan .title {
  font-size: 14px;
  font-weight: bold;
  color: #0a287c;
}
.billing-and-plans .current-plan .card {
  height: 200px;
  background: #f6f6f7 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
}
.billing-and-plans .current-plan .card .card-row {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  width: 80%;
}
.billing-and-plans .current-plan .card .card-row .left-content {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.billing-and-plans .current-plan .card .card-row .right-content {
  display: flex;
  align-items: center;
  gap: 15px;
}
.billing-and-plans .current-plan .card .card-row .upgrade-text {
  color: #0a287c;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}
.billing-and-plans .current-plan .card .card-row .text {
  font-size: 14px;
}
.billing-and-plans .current-plan .card .card-row .grey {
  color: #9a9a9a;
}
.billing-and-plans .current-plan .card .card-row .left-amount {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 10px;
}
.billing-and-plans .current-plan .card .curr-plan {
  color: #0f57f5;
  background-color: rgba(15, 87, 245, 0.29);
}
.billing-and-plans .current-plan .card .green {
  color: #22d598;
  background-color: rgba(34, 213, 152, 0.15);
}
.billing-and-plans .current-plan .card .red {
  color: #f19494;
  background-color: rgb(251, 226, 226);
}
.billing-and-plans .current-plan .card .container {
  width: 60px;
  font-size: 13px;
  padding: 0.2rem 1rem;
  line-height: 25px;
  border-radius: 20px;
  text-align: center;
}
.billing-and-plans .invoice-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
}
.billing-and-plans .invoice-history .title {
  font-size: 14px;
  font-weight: bold;
  color: #0a287c;
}
.billing-and-plans .invoice-history .invoices-table {
  border-collapse: collapse;
  border-spacing: 0;
}
.billing-and-plans .invoice-history .invoices-table__header .header-row .header-cell {
  cursor: default;
  padding: 10px;
  text-align: start;
  font-size: 12px;
  color: #8a8c98;
  opacity: 1;
  width: 25%;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row {
  background-color: rgba(240, 243, 250, 0.34);
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .amount-column {
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .amount-column .icon {
  transform: scale(0.6);
  color: #22d598;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .link-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .link-column .link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .link-column .link .icon {
  transform: scale(0.6);
  color: #22d598;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .link-column .link .link-text {
  color: #22d598;
  text-decoration: underline;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .link-column .image {
  transform: scale(0.5);
}
.billing-and-plans .invoice-history .invoices-table__body .table-row .table-cell {
  cursor: default;
  text-align: start;
  font-size: 12px;
  color: #212128;
  opacity: 1;
}
.billing-and-plans .invoice-history .invoices-table__body .table-row-bottom {
  height: 2px;
  border-top: 1px solid #c1c1c1;
}
.billing-and-plans .cancel-subscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 20px;
}
.billing-and-plans .cancel-subscription .title {
  font-size: 14px;
  font-weight: bold;
  color: #0a287c;
}
.billing-and-plans .cancel-subscription .content {
  font-size: 13px;
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;
}
.billing-and-plans .cancel-subscription .cancel-button {
  width: fit-content;
  height: 40px;
  padding: 0 15px;
  cursor: pointer;
  color: #8a8c98;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  border-radius: 10px;
  opacity: 0.75;
  text-decoration: underline;
  background: #f6f6f7 0% 0% no-repeat padding-box;
}

.billing-and-plans-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0 0 0;
}
.billing-and-plans-wrapper .save-button {
  width: 100px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}

@media (max-width: 776px) {
  .billing-and-plans {
    padding: 5px;
  }
  .billing-and-plans .invoice-history {
    padding-bottom: 80%;
  }
}
.general-settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 150px 40px 20px;
  gap: 30px;
  overflow: scroll;
}
.general-settings__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.general-settings__header .title {
  font-size: 18px;
  color: #171725;
}
.general-settings__header .subtitle {
  font-size: 18px;
  color: #0a287c;
}
.general-settings .divider {
  width: 100%;
  height: 1px;
  background-color: #d7d7d7;
  visibility: visible;
}
.general-settings__password-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.general-settings__password-form .title {
  font-size: 14px;
  font-weight: bold;
  color: #0a287c;
}
.general-settings__password-form .reset-password-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
}
.general-settings__password-form .reset-password-input .input-box {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  flex: 1;
}
.general-settings__password-form .reset-password-input .input-box .text {
  font-size: 14px;
  color: #c1c1c1;
}
.general-settings__password-form .reset-password-input .link {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  color: #8a8c98;
}
.general-settings__users-and-permissions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.general-settings__users-and-permissions .disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.6;
}
.general-settings__users-and-permissions .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.general-settings__users-and-permissions .header .title {
  font-size: 14px;
  font-weight: bold;
  color: #0a287c;
}
.general-settings__users-and-permissions .header .add-user-button {
  width: 100px;
  height: 40px;
  padding: 0 15px;
  cursor: pointer;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0;
  font-family: "OpenSans", sans-serif;
}
.general-settings__users-and-permissions .header .add-user-button .text {
  font-size: 12px;
  width: 100%;
}
.general-settings__users-and-permissions .header .add-user-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
}
.general-settings__users-and-permissions .header .add-user-button__disabled {
  background: #dfdfdf;
  border: 1px solid #dfdfdf;
  opacity: 0.5;
  pointer-events: none;
}
.general-settings__users-and-permissions .users-table {
  border-collapse: collapse;
  border-spacing: 0;
}
.general-settings__users-and-permissions .users-table__header .header-row .header-cell {
  cursor: default;
  padding: 10px;
  text-align: start;
  font-size: 12px;
  color: #8a8c98;
}
.general-settings__users-and-permissions .users-table__body .table-row {
  background-color: #f0f3fa;
}
.general-settings__users-and-permissions .users-table__body .table-row .table-cell {
  cursor: default;
  padding: 10px;
  text-align: start;
  font-size: 12px;
  color: #212128;
}
.general-settings__users-and-permissions .users-table__body .table-row .table-cell .user-role {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.general-settings__users-and-permissions .users-table__body .table-row .table-cell .disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.general-settings__users-and-permissions .users-table__body .table-row-bottom {
  height: 2px;
  border-top: 1px solid #c1c1c1;
}

.general-settings-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.general-settings-wrapper .save-button {
  width: 100px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.user-roles-popover {
  width: 100%;
  background-color: white;
  border: 1px solid #f0f3fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
}
.user-roles-popover .user-roles-option {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
}
.user-roles-popover .user-roles-option .text {
  color: black;
  font-size: 12px;
}
.user-roles-popover .user-roles-option:hover {
  background-color: #c1c1c1;
}

html[dir=rtl] .general-settings {
  padding: 40px 20px 40px 150px;
}
html[dir=rtl] .general-settings-wrapper .save-button {
  right: unset;
  left: 10px;
}

.reset-password-dialog {
  background-color: white;
  max-width: 280px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  padding: 30px 100px;
}
.reset-password-dialog__form {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reset-password-dialog__form .title {
  color: #000000;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.reset-password-dialog__form .input-form-control {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.reset-password-dialog__form .input-form-control .label {
  font-size: 14px;
  font-weight: bold;
}
.reset-password-dialog__form .input-form-control .input-box {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  border: 1px solid #c3c3c3;
  border-radius: 8px;
}
.reset-password-dialog__form .input-form-control .input-box .input {
  flex: 1;
  color: rgba(0, 0, 0, 0.3490196078);
}
.reset-password-dialog__form .input-form-control .input-box .input::placeholder {
  color: #9a9a9a;
}
.reset-password-dialog__form .input-form-control .input-box .visibility-icon {
  font-size: 14px;
  display: flex;
  cursor: pointer;
  color: #efefef;
  align-items: center;
  height: 14px;
  width: 14px;
}
.reset-password-dialog__form .input-form-control .input-error {
  color: red;
  font-size: 12px;
  text-align: start;
}
.reset-password-dialog__form .reset-password-button {
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  color: #0a287c;
  background-color: #fecb2f;
  border: 3px solid #fecb2f;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: bold;
}
.reset-password-dialog__form .loading {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}
.reset-password-dialog__form .loading .loader {
  height: 24px;
  width: 24px;
  zoom: 0.4;
}

.reset-password-button-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.add-user-dialog-main-wrapper {
  width: 430px;
  min-height: 220px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 36px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #d0d0d0;
  display: flex;
}
.add-user-dialog-main-wrapper .side-bar {
  width: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background: #fecb2e;
}
.add-user-dialog-main-wrapper .main-content {
  direction: ltr;
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 95%;
  padding: 10px;
}
.add-user-dialog-main-wrapper .main-content .close-icon {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.add-user-dialog-main-wrapper .main-content .add-user-header {
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: space-evenly;
}
.add-user-dialog-main-wrapper .main-content .add-user-header .header {
  font: normal normal bold 16px/36px OpenSans;
  letter-spacing: 0.07px;
  color: #171725;
}
.add-user-dialog-main-wrapper .main-content .form {
  display: contents;
}
.add-user-dialog-main-wrapper .main-content .form .content-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  margin-top: 10px;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .name-surname-wrapper {
  display: flex;
  align-items: center;
  padding-left: 17px;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .name-surname-wrapper .input-wrapper {
  width: 100%;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .name-surname-wrapper input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 1px solid #707070;
  width: 83%;
  letter-spacing: -0.24px;
  padding: 8px;
  font: 12px/18px OpenSans;
  color: #5f5f5f;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .header-error-wrapper {
  display: flex;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .header-error-wrapper .header-input {
  text-align: center;
  font: normal normal 600 13px/18p;
  font-family: OpenSans;
  letter-spacing: -0.26px;
  color: #000000;
  display: flex;
  justify-content: space-around;
  padding-left: 18px;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-error {
  text-align: left;
  font: normal 12px/18px OpenSans;
  letter-spacing: -0.24px;
  color: red;
  padding-inline: 5%;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-role-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-role-wrapper .input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 6px;
  width: 67%;
  border-right: none;
  margin-right: -5%;
  font: normal 12px/18px OpenSans;
  letter-spacing: -0.24px;
  color: #5f5f5f;
  padding: 8px;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-role-wrapper .sorting-role {
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 6px;
  width: 25%;
  display: flex;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-role-wrapper .sorting-role .sorting-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-role-wrapper .sorting-role .value {
  text-align: center;
  font: normal 12px/18px OpenSans;
  letter-spacing: -0.24px;
  color: #5f5f5f;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .input-role-wrapper .sorting-role .icon {
  color: #000000;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .add-user-button-wrapper {
  width: 96%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5%;
  height: 30px;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .add-user-button-wrapper .add-user-button {
  cursor: pointer;
  background: #fecb2e 0% 0% no-repeat padding-box;
  border: 1px solid #fecb2e;
  border-radius: 8px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .add-user-button-wrapper .add-user-button .button-header {
  text-align: center;
  font: normal normal bold 12px/18px OpenSans;
  letter-spacing: -0.24px;
  color: #0a287c;
}
.add-user-dialog-main-wrapper .main-content .form .content-input .add-user-button-wrapper .add-user-button__disabled {
  background: #dfdfdf;
  border: 1px solid #dfdfdf;
  opacity: 0.5;
  pointer-events: none;
}

html[dir=rtl] .main-content {
  direction: rtl;
}

.smart-agent-wrapper {
  padding: 2rem;
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.smart-agent-wrapper .controls-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.smart-agent-wrapper .controls-row .labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.smart-agent-wrapper .controls-row .labels .sub-header {
  font-size: 13px;
  color: #809fb8;
  line-height: 40px;
}
.smart-agent-wrapper .controls-row .labels .header {
  font-family: "Open Sans";
  font-size: 20px;
  color: #06152b;
  line-height: 40px;
}
.smart-agent-wrapper .controls-row .labels .header .input-error {
  color: red;
  font-size: 13px;
  display: block;
  padding: 5px;
}
.smart-agent-wrapper .controls-row .controls {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.smart-agent-wrapper .controls-row .controls > * {
  box-sizing: border-box;
  height: 41px;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper .hour-select-wrapper {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  opacity: 1;
  height: 20px;
  min-width: 75px;
  cursor: pointer;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper .time-select-input {
  border: 1px solid #c1c1c1;
  border-radius: 0.5rem;
  max-width: 5rem;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper .time-select-input .time-placeholder {
  padding-left: 1rem;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper .time-select-input .arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.smart-agent-wrapper .controls-row .controls .when-wrapper .when-label {
  color: black;
  font-size: 13px;
  opacity: 0.5;
}
.smart-agent-wrapper .controls-row .controls .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.4em;
}
.smart-agent-wrapper .controls-row .controls .checkbox-wrapper > input[type=checkbox] {
  padding: 2px;
  position: relative;
  box-sizing: content-box;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #b5b5be;
  border-radius: 5px;
}
.smart-agent-wrapper .controls-row .controls .checkbox-wrapper > input[type=checkbox]:checked {
  border: 1px solid #0a287c;
  border-radius: 5px;
  background-color: #0a287c;
}
.smart-agent-wrapper .controls-row .controls .checkbox-wrapper > input[type=checkbox]:before {
  content: "";
  display: block;
  transition: transform 200ms;
}
.smart-agent-wrapper .controls-row .controls .checkbox-wrapper > input[type=checkbox]:checked:before {
  width: calc(5.1428571429px);
  height: calc(12px);
  margin: 0 calc(5.1428571429px);
  border-bottom: calc(2.5714285714px) solid #ffffff;
  border-right: calc(2.5714285714px) solid #ffffff;
  transform: rotate(45deg);
}
.smart-agent-wrapper .controls-row .controls .checkbox-wrapper > label {
  color: black;
  font-size: 13px;
  opacity: 0.5;
}
.smart-agent-wrapper .controls-row .controls .accent-btn {
  background-color: #0a287c;
  border-radius: 8px;
  color: white;
  font-size: 14px;
}
.smart-agent-wrapper .controls-row .controls .create-btn {
  font-family: "Open Sans";
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 41px;
  box-sizing: border-box;
  cursor: pointer;
}
.smart-agent-wrapper .controls-row .controls .create-btn > img {
  width: 18px;
  height: 18px;
}
.smart-agent-wrapper .search-row {
  display: flex;
  justify-content: flex-start;
}
.smart-agent-wrapper .search-row .smart-agent-search {
  height: 41px;
  box-sizing: border-box;
  width: 35%;
}
.smart-agent-wrapper .search-row > input[type=text] {
  padding-left: 1.5rem;
  font-size: 0.8125rem;
}
.smart-agent-wrapper .search-row ::placeholder {
  opacity: 0.5;
  font-size: 0.8125rem;
}
.smart-agent-wrapper .search-row .search-border {
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;
}
.smart-agent-wrapper .table-container {
  width: 100%;
}

app-smart-agent .mat-form-field-underline {
  display: none;
}
app-smart-agent .mat-select-arrow-wrapper {
  display: none !important;
}
app-smart-agent .mat-form-field-infix {
  padding: 0 !important;
  line-height: 20px !important;
  font-size: 13px;
  height: 30px;
  display: flex;
  border-width: 0;
  align-items: center;
}
app-smart-agent mat-form-field {
  width: 100%;
  overflow: auto;
}
app-smart-agent .mat-select-value-text {
  color: black;
  padding-left: 16px;
}

.hours-popover {
  display: flex;
  flex-direction: column;
  background: white;
  overflow: scroll;
  max-height: 300px;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
}
.hours-popover .hour-option {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px;
  gap: 10px;
}

.small-text {
  font-size: 14px;
}

html[dir=rtl] .search-row > input[type=text] {
  padding-right: 1.5rem;
  font-size: 0.8125rem;
}

@media (max-width: 776px) {
  .smart-agent-wrapper .search-row .smart-agent-search {
    width: 100%;
  }
  .smart-agent-wrapper {
    padding: 0.5rem;
  }
  .smart-agent-wrapper .controls-row {
    flex-direction: column;
  }
}
.smart-agent-table {
  width: 100%;
  position: relative;
  min-height: 200px;
  height: 100%;
  overflow: auto;
}
.smart-agent-table tbody {
  display: block;
  max-height: 384px;
  overflow: auto;
}
.smart-agent-table tbody::-webkit-scrollbar {
  width: 5px;
}
.smart-agent-table tbody::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.smart-agent-table tbody::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.smart-agent-table thead,
.smart-agent-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
.smart-agent-table .table-container {
  width: 100%;
  min-width: 800px;
}
.smart-agent-table .table-container tr.mat-header-row {
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  color: #8a8c98;
}
.smart-agent-table .table-container .mat-column-funds.mat-cell {
  color: #809fb8;
  font-weight: 400;
}
.smart-agent-table .table-container .mat-column-date.mat-cell {
  color: #212128;
  font-weight: 400;
  font-size: 12px;
}
.smart-agent-table .table-container .mat-column-action.mat-cell {
  text-decoration: underline;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.06px;
  color: #809fb8;
}
.smart-agent-table .table-container .status-container {
  color: #fff;
  font-size: 13px;
  padding: 0.2rem 1rem;
  line-height: 25px;
  border-radius: 16px;
  background-color: var(--color-approved);
}
.smart-agent-table .table-container .type-indicator {
  display: flex;
  font-size: 13px;
  max-width: 90px;
  border-radius: 16px;
  justify-content: center;
}
.smart-agent-table .table-container .type-color-blue {
  color: rgb(15, 87, 245);
  background-color: rgba(15, 87, 245, 0.29);
}
.smart-agent-table .table-container .type-color-orange {
  color: rgb(254, 174, 46);
  background-color: rgba(254, 174, 46, 0.29);
}
.smart-agent-table .table-container .actions-icons-column {
  display: flex;
  gap: 1rem;
}
.smart-agent-table .table-container .mat-icon {
  color: #809fb8;
}
.smart-agent-table .table-container .mat-row .mat-cell {
  border: none;
  width: 30%;
  text-align: left;
  font: normal normal medium OpenSans;
  font-family: OpenSans, sans-serif;
  font-size: 13px;
}
.smart-agent-table .table-container th.mat-header-cell {
  border: none;
  opacity: 1;
  background: transparent;
}
.smart-agent-table .table-container th.mat-column-deadline,
.smart-agent-table .table-container th.mat-column-status {
  align-content: center;
}
.smart-agent-table .table-container th.mat-header-cell:first-of-type,
.smart-agent-table .table-container td.mat-cell:first-of-type,
.smart-agent-table .table-container td.mat-footer-cell:first-of-type {
  padding-left: 20px;
}
.smart-agent-table .table-container th.mat-header-cell.mat-column-status {
  padding-left: 10px;
}
.smart-agent-table .table-container td.mat-cell:last-of-type,
.smart-agent-table .table-container th.mat-header-cell:last-of-type,
.smart-agent-table .table-container td.mat-footer-cell:last-of-type {
  cursor: pointer;
}
.smart-agent-table .table-container .mat-column-date,
.smart-agent-table .table-container .mat-column-status,
.smart-agent-table .table-container .mat-column-lastUpdated,
.smart-agent-table .table-container .mat-column-type {
  min-width: 120px;
}
.smart-agent-table .table-container .mat-row:nth-child(odd) {
  background-color: #f6f9ff;
}
.smart-agent-table .table-container .more-btn {
  padding-left: 10px;
  width: 23px;
  height: 23px;
  padding-top: 9px;
}

html[dir=rtl] .table-container .mat-row .mat-cell {
  border: none;
  width: 30%;
  text-align: right;
  font: normal normal medium OpenSans;
  font-family: OpenSans, sans-serif;
  font-size: 13px;
}

.smart-agent-dialog {
  font-family: OpenSans, sans-serif;
  max-height: 85vh;
  min-width: 700px;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px rgba(0, 0, 0, 0.0784313725);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.smart-agent-dialog img.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}
.smart-agent-dialog .smart-agent-container {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
  gap: 14px;
  display: flex;
  flex-direction: column;
}
.smart-agent-dialog .smart-agent-container::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}
.smart-agent-dialog .smart-agent-container::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 3px;
}
.smart-agent-dialog .smart-agent-container::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.smart-agent-dialog .smart-agent-container img.close-icon {
  width: 18px;
  height: 18px;
  position: unset;
  right: unset;
  top: unset;
  cursor: pointer;
}
.smart-agent-dialog .smart-agent-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.smart-agent-dialog .smart-agent-container .header > span {
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  color: #171725;
}
.smart-agent-dialog .smart-agent-container .header .input-error {
  color: red;
  font-size: 11px;
  display: block;
  padding: 5px;
}
.smart-agent-dialog .smart-agent-container .header .fill-color {
  filter: brightness(0) saturate(100%) invert(79%) sepia(7%) saturate(4327%) hue-rotate(351deg) brightness(109%) contrast(99%);
}
.smart-agent-dialog .smart-agent-container .form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper .input-currency {
  line-height: 1em;
  font-size: 14px;
  justify-content: center;
  align-items: baseline;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper .input-wrapper > input {
  padding-left: 16px;
  font-size: 1em;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper > label {
  font-family: OpenSans, sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper > input {
  margin: 0px 0 10px;
  padding-block: 9px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  padding: 15px 5px;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper > placeholder {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-wrapper input[type=text]:focus {
  border: 1px solid #fecb2e;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .input-error {
  color: red;
  font-size: 13px;
  display: block;
  padding-left: 5px;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .fund-grant-range {
  flex: 1;
  min-width: 200px;
  display: flex;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .textarea-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .textarea-wrapper > label {
  font-family: OpenSans, sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
  color: rgba(0, 0, 0, 0.3490196078);
}
.smart-agent-dialog .smart-agent-container .form-container .form-row .textarea-wrapper > textarea {
  border-radius: 0.5rem;
  min-height: 4.5rem;
  max-width: 100%;
  resize: none;
  color: #5f5f5f;
  font-size: 14px;
  font-family: OpenSans, sans-serif;
  border: 1px solid black;
  color: rgb(0, 0, 0);
  padding: 10px;
}
.smart-agent-dialog .smart-agent-container .actions-row {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
}
.smart-agent-dialog .smart-agent-container .actions-row .btn {
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: -0.26px;
  cursor: pointer;
}
.smart-agent-dialog .smart-agent-container .actions-row .btn-primary {
  background-color: #fecb2e;
  color: #171725;
}
.smart-agent-dialog .smart-agent-container .actions-row .btn-secondary {
  background-color: white;
  color: #0a287c;
  border: 2px solid #0a287c;
}

.smart-agent-dialog-select {
  width: 100%;
}
.smart-agent-dialog-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  padding: 10px 5px;
}
.smart-agent-dialog-select .custom-select .select-button .select-input {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
  padding: 0;
}
.smart-agent-dialog-select .custom-select .select-button ::placeholder {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.smart-agent-dialog-select .custom-select .select-button .text {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.smart-agent-dialog-select .custom-select .select-button .label {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.smart-agent-dialog-select .custom-select .select-button .icon {
  color: #0a287c;
  font-size: 24px;
}
.smart-agent-dialog-select .custom-select .selected-options {
  max-height: 70px;
  overflow: auto;
  overflow-x: hidden;
}
.smart-agent-dialog-select .custom-select .selected-options::-webkit-scrollbar {
  width: 5px;
}
.smart-agent-dialog-select .custom-select .selected-options::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.smart-agent-dialog-select .custom-select .selected-options::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}

.smart-agent-dialog-select-panel {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #c1c1c1;
}
.smart-agent-dialog-select-panel .select-option-wrapper {
  display: flex;
  flex-direction: column;
}
.smart-agent-dialog-select-panel .select-option-wrapper .select-option {
  padding: 5px;
  background-color: white;
  color: #5f5f5f;
  gap: 10px;
}
.smart-agent-dialog-select-panel .select-option-wrapper .select-option .text {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 14px;
}
.smart-agent-dialog-select-panel .select-option-wrapper .select-option.focus {
  background-color: #c1c1c1;
  color: #5f5f5f;
}
.smart-agent-dialog-select-panel .select-option-wrapper .select-option:hover {
  background-color: #c1c1c1;
  color: #5f5f5f;
}
.smart-agent-dialog-select-panel .select-option-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}

.select-label {
  font-family: OpenSans, sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
}

.smart-agent-slider {
  width: 100%;
}

html[dir=rtl] img.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: 16px;
  right: unset;
}
html[dir=rtl] .ngx-slider .ngx-slider-tick-legend {
  left: 5px;
}

.smart-agent-select {
  width: 100%;
}
.smart-agent-select .custom-select .select-label {
  font-family: OpenSans, sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
}
.smart-agent-select .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  font-size: 14px;
  padding: 10px 5px;
}
.smart-agent-select .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.smart-agent-select .custom-select .select-button .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.smart-agent-select .custom-select .select-button .country__icon {
  font-size: 14px;
}
.smart-agent-select .custom-select .select-button .country__text {
  color: #000000;
  font-size: 14px;
}
.smart-agent-select .custom-select .select-button .icon {
  color: #0a287c;
}
.smart-agent-select .custom-select .selected-options {
  max-height: 70px;
  overflow: auto;
  overflow-x: hidden;
}
.smart-agent-select .custom-select .selected-options::-webkit-scrollbar {
  width: 5px;
}
.smart-agent-select .custom-select .selected-options::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 3px;
}
.smart-agent-select .custom-select .selected-options::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}

.smart-agent-select-error {
  width: 100%;
}
.smart-agent-select-error .custom-select .select-label {
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.smart-agent-select-error .custom-select .select-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid red;
  font-size: 14px;
  padding: 10px 5px;
}
.smart-agent-select-error .custom-select .select-button .select-input {
  width: 100%;
  font-size: 14px;
  padding: 0;
}
.smart-agent-select-error .custom-select .select-button .country {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.smart-agent-select-error .custom-select .select-button .country__icon {
  font-size: 14px;
}
.smart-agent-select-error .custom-select .select-button .country__text {
  color: #000000;
  font-size: 14px;
}
.smart-agent-select-error .custom-select .select-button .icon {
  color: #0a287c;
}
@media (max-width: 776px) {
  .smart-agent-dialog {
    max-height: 500px;
    min-width: unset;
  }
  .smart-agent-dialog .slider {
    padding: 0 !important;
  }
  .smart-agent-dialog .smart-agent-container .form-container .form-row {
    flex-direction: column;
    align-items: unset;
  }
}
.form-row .input-wrapper .static-row-data {
  background: #ccc;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  min-height: 16px;
  padding: 9px 20px;
}

.btn.btn-primary.btn-submit-dialog:disabled {
  background-color: #777;
  color: #eee;
  cursor: not-allowed;
}

.help-and-support-wrapper {
  height: calc(100vh - 96px);
  background: transparent linear-gradient(180deg, rgba(75, 157, 204, 0.1411764706) 0, rgba(255, 255, 255, 0) 100%) 0 0 no-repeat;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.help-and-support-wrapper .support-header {
  padding-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.help-and-support-wrapper .support-header .support-header-title {
  text-align: center;
  font: normal normal normal 1.5rem/2rem "Open Sans";
  color: #0a287c;
}
.help-and-support-wrapper .support-header .support-search-bar {
  display: flex;
  width: 45%;
  box-shadow: 0 10px 22px rgba(10, 40, 124, 0.0392156863);
  border-radius: 4px;
  background-color: white;
  height: 3rem;
  align-items: center;
  padding-inline-start: 10px;
}
.help-and-support-wrapper .support-header .support-search-bar .search-icon {
  width: 1.25rem;
  height: 1.25rem;
  padding-inline-end: 10px;
}
.help-and-support-wrapper .support-header .support-search-bar .search-input {
  width: calc(100% - 1.25rem);
}
.help-and-support-wrapper .support-header .help-pop-topics {
  display: flex;
  gap: 4px;
  font: normal normal normal 15px/15px "Open Sans";
}
.help-and-support-wrapper .support-header .help-pop-topics > span {
  color: #77838f;
}
.help-and-support-wrapper .support-header .help-pop-topics > p > a {
  text-decoration: none;
  text-align: left;
  font: normal normal bold 15px/15px "Open Sans";
  letter-spacing: 0.53px;
  color: #0a287c;
}
.help-and-support-wrapper .support-header .help-pop-topics > p > a:hover {
  text-decoration: underline;
}
.help-and-support-wrapper .hns-block {
  margin-top: 6rem;
  width: 90%;
  background-color: white;
  border: 1px solid #eeeeee;
}
.help-and-support-wrapper .hns-block .chip {
  color: #fecb2e;
  font-size: 12px;
  font-weight: bold;
  font-family: "OpenSans", sans-serif;
  padding: 0.3rem 1.2rem;
  background-color: rgba(254, 203, 46, 0.1490196078);
  border-radius: 1rem;
  width: fit-content;
  margin-bottom: 0.6rem;
}
.help-and-support-wrapper .main .help-cards-wrapper {
  display: flex;
  justify-content: space-around;
  gap: 1.3rem;
  margin-top: -60px;
  margin-bottom: 4rem;
  margin-inline: 2rem;
}
.help-and-support-wrapper .main .help-cards-wrapper .help-card {
  width: 28%;
  max-width: 300px;
  display: flex;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 2px 48px rgba(0, 0, 0, 0.0588235294);
}
.help-and-support-wrapper .main .help-cards-wrapper .help-card .card-icon {
  height: 3rem;
  margin-bottom: 0.5rem;
}
.help-and-support-wrapper .main .help-cards-wrapper .help-card .card-title {
  text-align: center;
  margin-bottom: 0.5rem;
  font: normal normal bold 14px/20px "Open Sans";
  letter-spacing: 0.64px;
  color: #1e2022;
  opacity: 1;
}
.help-and-support-wrapper .main .help-cards-wrapper .help-card .help-card-description {
  width: 90%;
  text-align: center;
  color: #77838f;
  margin: auto;
  font: normal normal normal 13px/13px "Open Sans";
  letter-spacing: 0.6px;
  opacity: 1;
}
.help-and-support-wrapper .main .help-cards-wrapper .help-card .help-card-link {
  font-size: 14px;
  color: #4b9dcc;
}
.help-and-support-wrapper .main .help-cards-wrapper .accent-border-orange {
  border-bottom: 6px solid #fe962e;
}
.help-and-support-wrapper .main .help-cards-wrapper .accent-border-blue {
  border-bottom: 6px solid #4b9dcc;
}
.help-and-support-wrapper .main .help-cards-wrapper .accent-border-yellow {
  border-bottom: 6px solid #fecb2e;
}
.help-and-support-wrapper .main .faq-wrapper {
  width: 90%;
  margin: 0 auto;
}
.help-and-support-wrapper .main .faq-wrapper .mb-2 {
  margin-bottom: 2rem;
}
.help-and-support-wrapper .main .faq-wrapper .faq-title {
  display: block;
  letter-spacing: 1.2px;
  font-size: 1.7rem;
}
.help-and-support-wrapper .main .faq-wrapper .faq-subtitle {
  letter-spacing: 0.6px;
  font-size: 13px;
  color: #77838f;
  margin-bottom: 3rem;
  font-family: "Open Sans";
}
.help-and-support-wrapper .main .faq-wrapper .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.help-and-support-wrapper .main .faq-wrapper .mat-expansion-panel {
  box-shadow: none;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eeeeee;
}
.help-and-support-wrapper .main .faq-wrapper .mat-expansion-panel-header:not([aria-disabled=true]) {
  padding-inline: 2rem;
  font-family: "Open Sans";
  letter-spacing: 0.68px;
}
.help-and-support-wrapper .main .faq-wrapper .mat-expansion-panel-body {
  font-family: "Open Sans";
  letter-spacing: 0.6px;
  color: #77838f;
  font-size: 14px;
  letter-spacing: 0.6px;
  opacity: 1;
}
.help-and-support-wrapper .support-ticket {
  padding-top: 3rem;
  padding-inline: 2rem 0;
  margin-bottom: 6rem;
  box-sizing: border-box;
}
.help-and-support-wrapper .support-ticket .support-ticket-title {
  font: normal normal normal 32px/42px "Open Sans";
  letter-spacing: 1.2px;
  color: #1e2022;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 2rem;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form {
  flex-grow: 1;
  max-width: 550px;
  margin-bottom: 2rem;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .contact-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .contact-input-wrapper > label {
  font: normal normal medium 13px/13px "Open Sans";
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .contact-input-wrapper > input[type=text] {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  height: 32px;
  padding-inline-start: 20px;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .contact-input-wrapper > input[type=text]:focus {
  border: 1px solid #fecb2e;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .help-select-input {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .help-select-input .help-placeholder {
  padding-left: 20px;
  font-size: 13px;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .help-select-input .organization-arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .submit-ticket {
  cursor: pointer;
  margin-top: 2rem;
  background-color: #fecb2e;
  padding: 0.7rem 2rem;
  border-radius: 6px;
  font-family: "Open Sans";
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper {
  margin-top: -3rem;
  display: flex;
  flex-direction: column;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method {
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem 3.5rem 1.5rem 0.5rem;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method .contact-icon {
  padding: 14px;
  border-radius: 50%;
  background-color: rgba(254, 203, 46, 0.1254901961);
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method .partition {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method .partition .contact-title {
  font: normal normal medium 15px/15px "Open Sans";
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method .partition .contact-description {
  font: normal normal 300 13px/13px "Open Sans";
}
.help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method + .contact-method {
  border-top: 1px solid rgba(112, 112, 112, 0.1490196078);
}

app-help-support .mat-form-field-underline {
  display: none;
}
app-help-support .mat-select-arrow-wrapper {
  display: none !important;
}
app-help-support .mat-form-field-infix {
  padding: 0 !important;
  display: flex;
  border-width: 0;
  align-items: center;
}
app-help-support mat-form-field {
  width: 100%;
  overflow: auto;
}
app-help-support .mat-select-value-text {
  color: rgba(0, 0, 0, 0.3490196078);
  font-size: 13px;
  padding-inline-start: 20px;
}

@media (max-width: 776px) {
  .help-and-support-wrapper .support-ticket {
    padding-inline: 2rem 2rem;
  }
  .help-and-support-wrapper .support-ticket .support-form-wrapper .contact-us-wrapper .contact-method {
    padding: 1rem;
  }
  .help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row {
    flex-direction: column;
  }
  .help-and-support-wrapper .support-ticket .support-form-wrapper .support-form .form-wrapper .form-row .contact-input-wrapper > input[type=text] {
    font: normal normal normal 16px OpenSans;
    font-family: "OpenSans", sans-serif;
  }
  .help-and-support-wrapper .main .help-cards-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .help-and-support-wrapper .main .help-cards-wrapper .help-card {
    max-width: 420px;
    width: 100%;
  }
}
.earn-point {
  background: transparent linear-gradient(180deg, #ffffff 0%, #fff3ce 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  min-height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 15px;
  overflow: hidden;
  align-items: center;
}
.earn-point .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 70%;
  justify-content: space-between;
}
.earn-point .wrapper .title-wrapper {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.earn-point .wrapper .title-wrapper .close-button-wrapper {
  display: flex;
  width: 8%;
  justify-content: flex-end;
}
.earn-point .wrapper .title-wrapper .close-button-wrapper .close-icon {
  background-color: transparent;
  cursor: pointer;
}
.earn-point .wrapper .title-wrapper .title {
  text-align: left;
  font: normal normal bold 23px/60px Helvetica Neue;
  letter-spacing: 0px;
  color: #212121;
  width: 100%;
}
.earn-point .wrapper .text-img-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.earn-point .wrapper .text-img-wrapper .image {
  width: 160px;
  height: 160px;
}
.earn-point .wrapper .text-img-wrapper .text-coin-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-around;
}
.earn-point .wrapper .text-img-wrapper .text-coin-wrapper .text {
  text-align: left;
  font: normal normal normal 13px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.earn-point .wrapper .text-img-wrapper .text-coin-wrapper .coin-title-section {
  display: flex;
  align-items: center;
  gap: 5px;
}
.earn-point .wrapper .text-img-wrapper .text-coin-wrapper .coin-title-section .coin-image {
  width: 24px;
  height: 24px;
}
.earn-point .wrapper .text-img-wrapper .text-coin-wrapper .coin-title-section .coins {
  font-size: 24px;
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
}
.earn-point .wrapper .row-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 80%;
}
.earn-point .wrapper .row-buttons .button {
  font-size: 13px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  font-family: "Helvetica Neue", sans-serif;
  cursor: pointer;
}
.earn-point .wrapper .row-buttons .report {
  color: white;
  background: #0a287c 0% 0% no-repeat padding-box;
}
.earn-point .wrapper .row-buttons .prizes {
  color: #0a287c;
  background: #fecb2e 0% 0% no-repeat padding-box;
}
.earn-point .dialog .mat-dialog-container {
  padding: 0;
}
.earn-point div.vertical-line {
  height: 100px;
  width: 1px;
  background-color: #0a287c;
  opacity: 0.43;
  margin-bottom: -10px;
}
.earn-point div.horizontal-line {
  width: 110%;
  height: 1px;
  background-color: #0a287c;
  opacity: 0.43;
  margin-top: auto;
  margin-inline: -15px;
}
.earn-point .coins-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.earn-point .coins-section .coin-image {
  width: 40px;
  height: 40px;
}
.earn-point .coins-section .earn-coins-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.earn-point .coins-section .text-centered {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-family: "Helvetica Neue", sans-serif;
  color: #0a287c;
}
.earn-point .coins-section .used-coins-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.earn-point .coins-section .coins-text {
  font-size: 15px;
  font-family: "Helvetica Neue", sans-serif;
  color: #0a287c;
  font-weight: bold;
}
.earn-point .coins-section .column-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.prizes .main {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  width: fit-content;
  background: white;
  margin: 20px auto;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
}
.prizes .main::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}
.prizes .main::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 3px;
}
.prizes .main::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 3px;
}
.prizes .wrapper {
  padding: 30px;
}
.prizes .mat-dialog-container {
  padding: 0;
}
.prizes .text {
  font-size: 11px;
  text-align: left;
  font-family: "Helvetica Neue";
}
.prizes .title {
  font-size: 21px;
  font-family: "Helvetica Neue";
}
.prizes .sub-title {
  font-size: 25px;
  color: #070707;
  opacity: 1;
  font-weight: bold;
  margin-top: 10px;
  font-family: "Helvetica Neue";
}
.prizes .icon {
  color: #4b9dcc;
  margin-right: 5px;
}
.prizes .close-icon {
  position: relative;
  display: flex;
  background-color: transparent;
  float: right;
  padding: 5px;
  cursor: pointer;
}
.prizes .sub-text {
  font-family: "OpenSans";
  font-size: 11px;
}
.prizes .coin-section {
  display: flex;
  gap: 5px;
  align-items: center;
}
.prizes .item {
  display: flex;
  align-items: flex-start;
}
.prizes .item-title {
  font-family: "OpenSans";
  font-weight: bold;
  font-size: 13px;
  color: #4b9dcc;
  margin: 0;
}
.prizes .divider {
  margin-bottom: 15px;
}
.prizes ul {
  padding-left: 1em;
}

.report-dialog {
  width: 100%;
  background: white;
  border-radius: 5px;
  min-width: 470px;
  min-height: 420px;
  display: flex;
  flex-direction: column;
}
.report-dialog .close-icon {
  background-color: transparent;
  align-self: end;
  cursor: pointer;
}
.report-dialog .wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.report-dialog .wrapper .text {
  text-align: left;
  font: normal normal normal 13px/17px Helvetica Neue;
}
.report-dialog .wrapper .link {
  text-decoration: underline;
  font: normal normal normal 13px/17px;
  font-family: "OpenSans", sans-serif;
  letter-spacing: 0;
  color: #4b9dcc;
}
.report-dialog .wrapper .title {
  font: normal normal bold 23px Helvetica Neue;
  padding-bottom: 10px;
  font-family: "Helvetica Neue", sans-serif;
}
.report-dialog .wrapper .sub-title {
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  font: normal normal 600 14px/17px;
  font-family: "OpenSans", sans-serif;
}
.report-dialog .wrapper .share-section {
  display: flex;
  gap: 5px;
}
.report-dialog .wrapper .coin-image {
  width: 15px;
  height: 15px;
}
.report-dialog .textarea {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: 4.5rem;
  max-width: 100%;
}
.report-dialog .row-buttons {
  display: flex;
  gap: 20px;
  position: relative;
  justify-content: flex-end;
}
.report-dialog .row-buttons .button {
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  width: 110px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.report-dialog .row-buttons .cancel {
  color: #0a287c;
  background: white;
  border: 1px solid #0a287c;
}
.report-dialog .row-buttons .send {
  color: #0a287c;
  background: #fecb2e 0 0 no-repeat padding-box;
  border: 1px solid #fecb2e;
  opacity: 1;
}
.report-dialog .select {
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
}
.report-dialog .input-wrapper {
  width: 70%;
}
.report-dialog .input-wrapper .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.report-dialog .input-wrapper > label {
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal normal 13px/17px "Helvetica Neue";
  font-family: "Helvetica Neue", sans-serif;
}
.report-dialog .relevant-select-wrapper {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  opacity: 1;
  height: 20px;
  min-width: 75px;
  cursor: pointer;
}
.report-dialog .relevant-select-wrapper .arrow-down {
  padding-right: 10px;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.report-dialog .relevant-select-wrapper .placeholder {
  color: #5f5f5f;
  font-family: OpenSans;
  font-size: 12px;
}
.report-dialog .small-text {
  font-size: 14px;
}
.report-dialog .textarea-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.report-dialog .textarea-wrapper > label {
  font-size: 11px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: "Helvetica Neue", sans-serif;
}
.report-dialog .textarea-wrapper > textarea {
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  min-height: 3.5rem;
  max-width: 100%;
  resize: none;
  color: #5f5f5f;
  font-size: 12px;
  font-family: OpenSans;
  border: 1px solid #707070;
}

app-report .mat-form-field-underline {
  display: none;
}
app-report .mat-form-field-infix {
  padding: 0 !important;
  line-height: 20px !important;
  font-size: 13px;
  height: 30px;
  display: flex;
  border-width: 0;
  align-items: center;
}
app-report mat-form-field {
  width: 100%;
  overflow: auto;
}
app-report .area::-webkit-input-placeholder {
  padding: 5px;
}
app-report .area::-ms-input-placeholder {
  padding: 5px;
}

.relevant-popover {
  z-index: 10000;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: auto;
  max-height: 300px;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
}
.relevant-popover .relevant-option {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
}
.relevant-popover .relevant-option:hover {
  background-color: rgba(112, 112, 112, 0.2196078431);
}

.confirm-dialog {
  width: 500px;
  height: 100%;
  background: white;
  border: 1px solid gray;
  border-radius: 10px;
  gap: 10px;
  padding: 20px 40px;
}
.confirm-dialog__header {
  padding: 10px;
  display: flex;
  justify-content: center;
  margin: 0;
}
.confirm-dialog__header .title {
  color: #1c2d57;
  font-size: 24px;
  text-align: center;
}
.confirm-dialog__content {
  display: flex;
  justify-content: center;
  margin: 10px !important;
  flex-direction: column;
  align-items: center;
}
.confirm-dialog__content .message {
  color: #636c84;
  font-size: 14px;
  text-align: center;
}
.confirm-dialog__content .image {
  width: 250px;
  height: auto;
}
.confirm-dialog__footer .buttons {
  display: flex;
  justify-content: space-around;
}
.confirm-dialog__footer .buttons .btn {
  width: 40%;
  padding: 2px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
  display: flex;
  justify-content: center;
}
.confirm-dialog__footer .buttons .save-button {
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
}
.confirm-dialog__footer .buttons .cancel-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0a287c;
}

@media (max-width: 776px) {
  .confirm-dialog {
    width: unset;
    height: unset;
    border: none;
  }
}
.notifications-dialog {
  width: 360px;
  padding: 20px 20px 0 20px;
  background: white;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  border-radius: 10px;
  direction: rtl;
}
.notifications-dialog .cards-list-section {
  overflow: auto;
  width: 100%;
  padding-inline-end: 20px;
}
.notifications-dialog .cards-list-section::-webkit-scrollbar {
  width: 5px;
}
.notifications-dialog .cards-list-section::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
.notifications-dialog .cards-list-section::-webkit-scrollbar-thumb {
  background: #0a287c 0 0 no-repeat padding-box;
}
.notifications-dialog .dialog-header {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  font-size: 15px;
  font-family: "OpenSans", sans-serif;
  justify-content: space-between;
}
.notifications-dialog .title {
  display: flex;
  align-items: center;
  gap: 5px;
}
.notifications-dialog .close-icon {
  top: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  float: right;
  padding: 5px;
}
.notifications-dialog .card {
  cursor: pointer;
  height: 80px;
  border: solid 1px #fecb2e;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.notifications-dialog .card .icon {
  color: #fecb2e;
  transform: scale(0.7);
}
.notifications-dialog .card .icon-read {
  color: gray;
  transform: scale(0.7);
}
.notifications-dialog .card .header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notifications-dialog .card .header-row .header {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
}
.notifications-dialog .card .header-row .date-section {
  color: #1e1f22;
  font-size: 9px;
  padding-top: 10px;
}
.notifications-dialog .card .body-section {
  color: #1e1f22;
  font-weight: bold;
  padding: 10px;
  font-size: 10px;
}

html[dir=ltr] .notifications-dialog {
  direction: ltr;
}

.visitors-signup-main {
  max-height: 700px;
  min-height: 32rem;
  background: white;
  max-width: 800px;
  min-width: 800px;
  width: 100%;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.visitors-signup-main .visitors-signup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden scroll;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .title {
  margin: 10px auto;
  font-size: 32px;
  font-weight: bold;
  color: #2e4765;
}
.visitors-signup-main .visitors-signup-wrapper .bottom-wrapper {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin: auto auto;
  min-height: 50px;
}
.visitors-signup-main .visitors-signup-wrapper .bottom-wrapper .atlas-line {
  padding-right: 4%;
  color: #b1b1b1;
}
.visitors-signup-main .visitors-signup-wrapper .signup__welcome {
  height: 100%;
  max-height: 100%;
  width: 60%;
  position: relative;
  font-size: 32px;
  display: flex;
  flex-wrap: wrap;
}
.visitors-signup-main .visitors-signup-wrapper .signup__welcome .theme-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
  opacity: 1;
  display: flex;
}
.visitors-signup-main .visitors-signup-wrapper .signup__welcome__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.visitors-signup-main .visitors-signup-wrapper .signup__welcome__content .welcome-image {
  height: 190px;
  width: 190px;
  margin-left: 50px;
  margin-top: 50px;
  position: relative;
  display: flex;
}
.visitors-signup-main .visitors-signup-wrapper .signup__welcome__content .welcome-text-headline {
  text-align: left;
  font: normal normal bold 67px/79px OpenSans;
  letter-spacing: 1.05px;
  color: #ffffff;
  opacity: 1;
  width: 40%;
  display: flex;
  position: relative;
  margin-left: 160px;
  margin-top: -10px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__welcome__content .welcome-text-sub-headline {
  font: normal normal 500 33px/39px OpenSans;
  letter-spacing: 0.52px;
  color: white;
  opacity: 1;
  display: flex;
  width: 40%;
  position: relative;
  margin-top: 20px;
  margin-left: 160px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__comp-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form {
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 100%;
  justify-content: space-around;
  z-index: 3;
  overflow-y: scroll;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 20px;
  height: 80%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #0a287c 0 0 no-repeat padding-box;
  border-radius: 20px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .logo {
  height: 12%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .login-loading {
  margin: 13.5px auto;
  padding-bottom: 0px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .google-button {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .google-button .logo {
  height: 4vh;
  width: auto;
  margin: 10px auto;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .join-button-wrapper {
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .sep-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .sep-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1019607843);
  position: absolute;
  top: 50%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .sep-wrapper .sep-content {
  font-size: 15px;
  font-weight: 300;
  background-color: white;
  padding-inline: 8px;
  text-align: center;
  display: flex;
  z-index: 2;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__content .join-button {
  width: 100%;
  display: flex;
  cursor: pointer;
  line-height: 14px;
  border-radius: 5px;
  text-align: center;
  color: #090e3a;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #c9c9c9;
  text-decoration: solid;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
  align-items: center;
  justify-content: space-evenly;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form__cotent-coponnent {
  width: 50%;
  padding-bottom: 12%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-checkbox {
  padding-right: 4%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #4c9dcb !important;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-checkbox .mat-ripple-element {
  background-color: #4c9dcb !important;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .approval-block {
  overflow: hidden;
  background: #f0faff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1019607843);
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 430px;
  width: 700px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .approval-block .titles-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .approval-block .titles-block .approval-title {
  font-size: 35px;
  font-weight: bold;
  color: #2e4765;
  padding-bottom: 28px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .approval-block .titles-block .approval-title-bold {
  font-size: 40px;
  font-weight: bold;
  color: black;
  padding-bottom: 28px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .approval-block .titles-block .approval-sub-title {
  font-size: 20px;
  font-weight: bold;
  color: #2e4765;
  padding-bottom: 28px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .radio-group {
  display: flex;
  flex-direction: column;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .radio-group .radio-button {
  border-radius: 20px;
  background-color: #f0faff;
  padding: 25px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4c9dcb;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #4c9dcb;
  background-color: #4c9dcb;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .signup-form {
  width: 80%;
  max-width: 80%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .signup-form strong {
  color: red;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .title {
  font-size: 32px;
  font-weight: 600;
  color: black;
  margin: 1.2rem;
  display: flex;
  align-items: center;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .span {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper {
  direction: ltr;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .input-headline {
  margin-top: 12px;
  font-size: 13px;
  display: inline-block;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname {
  font: 15px OpenSans;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;
  padding-left: 8px;
  margin-top: 6px;
  display: flex;
  border: 1px solid #e3e3e3;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .icon {
  margin: auto 10px;
  color: #1667c7;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .visibility-icon {
  cursor: pointer;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .input {
  font: 15px OpenSans;
  width: 100%;
  background-color: transparent;
  direction: ltr;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .input::placeholder {
  color: #9a9a9a;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper {
  direction: ltr;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .input-headline {
  font-size: 13px;
  margin-top: 12px;
  display: inline-block;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field {
  display: flex;
  align-items: center;
  width: 100%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-wrapper {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: initial;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-select-arrow {
  border: none;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-underline {
  display: none;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-select-panel-wrap {
  width: 45%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .input-wrapper .signup-input-container .mat-form-field-infix {
  padding: 0;
  width: 100%;
  border-top: 0;
  /* border-top: 0.84375em solid transparent; */
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper .shrink {
  width: 30%;
  max-width: 100px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper {
  direction: ltr;
  width: 100%;
  margin-bottom: 15px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .input-headline {
  width: 100%;
  font-size: 13px;
  display: inline-block;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container {
  font: 15px OpenSans;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;
  padding-left: 8px;
  margin-top: 6px;
  display: flex;
  border: 1px solid #e3e3e3;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container .icon {
  margin: auto 10px;
  color: rgb(153, 152, 152);
  overflow: hidden;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container .visibility-icon {
  cursor: pointer;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container .input {
  font: 15px OpenSans;
  width: 85%;
  background-color: transparent;
  direction: ltr;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .input-wrapper .signup-input-container .input::placeholder {
  color: #9a9a9a;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper {
  display: flex;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 3%;
  flex-direction: row;
  align-items: center;
  font: 15px/15px OpenSans;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper input[type=checkbox] {
  width: 15px;
  height: 15px;
  accent-color: #0a287c;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .input-headline {
  margin: 8px 0 8px 12px;
  font: 13px OpenSans;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container {
  width: 415px;
  height: 40px;
  border-radius: 5px;
  margin: 0 10px;
  margin-bottom: 10px;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #e3e3e3;
  background: #ffffff 0 0 no-repeat padding-box;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container .icon {
  margin: auto 10px;
  color: #efefef;
  overflow: hidden;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container .visibility-icon {
  cursor: pointer;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
  direction: ltr;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container .select {
  color: #9a9a9a;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .checkbox-wrapper .signup-input-container .input::placeholder {
  color: #9a9a9a;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .signup-input-error-checkbox {
  color: red;
  font-size: 12px;
  width: 81%;
  margin: 9px 8px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: flex-end;
  margin-top: -2%;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .signup-input-error {
  color: red;
  font-size: 12px;
  width: 350px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .signup-loading {
  margin: 10px auto;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .signup-button {
  width: 100%;
  height: 48px;
  display: flex;
  color: #0a287c;
  cursor: pointer;
  font: normal normal bold 16px/21px OpenSans;
  line-height: 24px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  background-color: #fecb2f;
  justify-content: space-evenly;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1529411765);
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .dont-have-account-wrapper {
  cursor: pointer;
  width: 100%;
  text-align: center;
  font: normal normal normal 13px OpenSans;
  color: #0f57f5;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .lang-select-input {
  height: 20px;
  margin: 1rem;
  display: contents;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .lang-select-input mat-select {
  width: fit-content;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .lang-select-input .fi {
  padding-left: 20px;
  height: 18px;
  width: 18px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .lang-select-input .country-placeholder {
  padding-left: 60px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .lang-select-input .country-flag-icon {
  margin-left: 10px;
  width: 35px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .lang-select-input .lang-arrow-down {
  display: none;
  padding-right: 10px;
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.visitors-signup-main .visitors-signup-wrapper .signup__form .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: initial;
}
.visitors-signup-main .visitors-signup-wrapper .signup .spacer {
  flex: 1 1 auto;
}
.visitors-signup-main .visitors-signup-wrapper a:-webkit-any-link {
  color: #0266d6;
  text-decoration: underline;
  cursor: pointer;
}

app-visitors-signup .mat-form-field-underline {
  display: none;
}
app-visitors-signup .mat-select-arrow-wrapper {
  display: none !important;
}
app-visitors-signup .mat-form-field-infix {
  padding: 0 !important;
  line-height: 18px !important;
  display: flex;
  border-width: 0;
  align-items: center;
  width: fit-content;
}
app-visitors-signup mat-form-field {
  overflow: auto;
}
app-visitors-signup .mat-select-value-text {
  color: black;
}

@media (max-width: 1450px) {
  .visitors-signup-main {
    max-height: 480px;
    min-height: 25rem;
    background: white;
    max-width: 800px;
    width: 100%;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
html[dir=rtl] .visitors-signup-main {
  max-height: 570px;
  background: white;
  max-width: 800px;
  width: 100%;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__welcome__content .welcome-image {
  margin-right: 50px;
  margin-left: 0;
  transform: scaleX(-1);
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__welcome__content .welcome-text-headline {
  margin-right: 181px;
  margin-left: 123px;
  text-align: right;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__welcome__content .welcome-text-sub-headline {
  margin-right: 200px;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__form__content .input-wrapper {
  direction: rtl;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__form__content .input-wrapper .signup-input-container .input {
  padding-right: 8px;
  padding-left: unset;
  direction: rtl;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__form__content .spacer {
  flex: 1 1 auto;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__form__content .name-surname-wrapper .input-wrapper {
  direction: rtl;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__form__content .name-surname-wrapper .input-wrapper .signup-input-container-name-surname .input {
  padding-right: 8px;
  direction: rtl;
}
html[dir=rtl] .visitors-signup-main .visitors-signup-wrapper .signup__form__content .checkbox-wrapper .input-headline {
  margin-left: 0;
  margin-right: 12px;
}
@media (max-width: 776px) {
  .visitors-signup-main {
    min-width: unset;
  }
  .visitors-signup-main .visitors-signup-wrapper .signup__form .phone-area-code-wrapper,
.visitors-signup-main .visitors-signup-wrapper .signup__form .name-surname-wrapper {
    flex-direction: column;
  }
}
.visitors-signup-email-verification {
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  justify-content: center;
  align-items: center;
  z-index: 100000;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.visitors-signup-email-verification__card {
  width: 400px;
  padding: 40px 60px;
  max-height: calc(80vh - 80px);
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #707070;
  overflow: scroll;
}
.visitors-signup-email-verification__card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.visitors-signup-email-verification__card .content .title {
  text-align: center;
  font: 26px Arial;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #0a287c;
}
.visitors-signup-email-verification__card .content .subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 8%;
  text-align: center;
  font: normal normal normal 2vh Heebo;
  font-size: 14px;
  color: #636c84;
}
.visitors-signup-email-verification__card .content .image {
  width: auto;
  height: 150px;
}
.visitors-signup-email-verification__card .content .expiration {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.visitors-signup-email-verification__card .content .expiration .text {
  font-size: 12px;
}
.visitors-signup-email-verification__card .content .expiration .timer {
  font-size: 12px;
  color: #fecb2e;
}
.visitors-signup-email-verification__card .content .resend-mail {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.visitors-signup-email-verification__card .content .resend-mail .text {
  font-size: 12px;
}
.visitors-signup-email-verification__card .content .resend-mail .link {
  cursor: pointer;
  font-size: 12px;
  color: #fecb2e;
}
.visitors-signup-email-verification__card .content .verify-button {
  width: 100%;
  padding: 10px 0;
  color: #0a287c;
  margin-top: 6%;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
  background-color: #fecb2f;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  display: flex;
  justify-content: center;
  align-items: center;
}
.visitors-signup-email-verification__card .content .verify-loading {
  margin: 4vh;
}

@media (max-width: 776px) {
  .visitors-signup-email-verification__card {
    width: unset;
    margin: 10px;
  }
}
.main-plan-payments-wrapper {
  width: 100%;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 3px #939393;
}
.main-plan-payments-wrapper input[type=checkbox] {
  display: none;
}
.main-plan-payments-wrapper .blur-wrapper {
  position: fixed;
  top: 10%;
  height: 80%;
  border-radius: 20px;
  z-index: 10000;
  background: white;
  opacity: 0.5;
  width: clamp(900px, 70%, 1200px);
}
.main-plan-payments-wrapper .blur-wrapper-after {
  position: fixed;
  z-index: 100000;
  left: 44.5%;
  top: 43.5%;
  width: 12%;
  height: 14%;
  border-radius: 8%;
  background-color: white;
}
.main-plan-payments-wrapper .blur-wrapper-after .login-loading {
  position: fixed;
  left: 50%;
  top: 50%;
}
.main-plan-payments-wrapper .sub-plan-wrapper {
  height: 100%;
  display: flex;
  overflow: hidden;
  background: white;
  margin-top: -1%;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  width: clamp(900px, 70%, 1200px);
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 820px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .ellipse {
  position: absolute;
  width: 140%;
  right: -20%;
  height: 75%;
  top: 34%;
  border-radius: 50% 50% 0 0/100% 100% 0 0;
  background: transparent linear-gradient(180deg, #d8e3fd 0, #ffffff 75%) 0 0 no-repeat padding-box;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .plan-wrapper {
  padding: 0rem 0rem 2.5rem 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  position: relative;
  height: 60%;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .plan-wrapper .move-up {
  margin-top: -20px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .plan-wrapper ul:first-child {
  padding-inline: 0;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .plan-wrapper ul {
  width: 100%;
  height: 95%;
  max-width: 280px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .plan-wrapper .custom-ul {
  padding-inline-start: 0;
  height: 100%;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper {
  display: flex;
  bottom: 0;
  height: 25%;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-top: 4%;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline .main-header {
  font-size: 26px;
  font-weight: 900;
  color: #0a287c;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline .back-button {
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  font: normal normal normal 19px/21px Heebo;
  letter-spacing: 0;
  color: #636c84;
  width: 20px;
  height: 20px;
  justify-content: center;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .headline-wrapper-inline .right-div {
  width: 10%;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-header {
  text-align: center;
  font: normal normal normal 14px/21px Heebo;
  letter-spacing: 0;
  color: #636c84;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  margin-top: 20px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .sep {
  flex: 1 1 auto;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper {
  display: flex;
  align-items: center;
  padding-left: calc(46% - 103px);
  margin-right: 25px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .bill-slider {
  direction: rtl;
  text-align: center;
  white-space: nowrap;
  font: normal normal normal 11px/41px Heebo;
  letter-spacing: -0.22px;
  color: #8a8c98;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle-content {
  color: #8a8c98;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle {
  color: #4c9dcb;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle .mat-slide-toggle-bar {
  height: 22px;
  border-radius: 30px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle .mat-slide-toggle-bar .mat-slide-toggle-thumb {
  background: #fecb2e;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fecb2e;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle-thumb-container {
  color: #fecb2e;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle .mat-slide-toggle-bar {
  height: 36px !important;
  width: 80px !important;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 12px 26px rgba(197, 191, 214, 0.2);
  border: 1px solid #bfbfbf;
  border-radius: 20px;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
  background-color: #fecb2e;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  display: none;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-right: 15vh;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper .save-header {
  text-align: center;
  white-space: nowrap;
  font: normal normal normal 12px/20px Heebo;
  letter-spacing: -0.24px;
  color: #fecb2e;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper .underline {
  width: 56px;
  height: 0;
  border-radius: 100% 85% 0 0;
  border: 4px none #fecb2e;
  border-top-style: solid;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .currency-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.main-plan-payments-wrapper .sub-plan-wrapper .plans-container .logo-wrapper {
  margin-bottom: 2%;
  z-index: 1;
  display: flex;
  justify-content: center;
}
app-plan-payments .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(36px, 0, 0) !important;
}
app-plan-payments .main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper .mat-slide-toggle-thumb-container {
  color: #fecb2e;
  margin: 6px !important;
  height: fit-content !important;
  width: fit-content !important;
}
app-plan-payments .mat-slide-toggle-thumb {
  width: 32px;
  height: 30px;
  display: block;
  border-radius: 50%;
  box-shadow: none;
}

html[dir=rtl] .main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .save-header-wrapper {
  margin-right: 0;
  margin-left: 15vh;
}
html[dir=rtl] .main-plan-payments-wrapper .sub-plan-wrapper .plans-container .headline-wrapper .sub-head-functions-wrapper .slider-wrapper {
  padding-left: 0;
  padding-right: calc(50% - 117px);
  margin-right: 0;
  margin-left: 25px;
}
html[dir=rtl] app-plan-payments .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(-36px, 0, 0) !important;
}
html[dir=rtl] app-plan-payments .mat-slide-toggle-label {
  flex-direction: row-reverse;
}
html[dir=rtl] app-plan-payments .mat-slide-toggle-bar {
  margin-left: 8px;
  margin-right: 8px;
}

.visitors-plan-options {
  cursor: pointer;
  display: flex;
  border-radius: 25px;
  flex-direction: column;
  background: white;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  max-height: 28rem;
  position: relative;
  padding-bottom: 6%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0);
  border: 1px solid #ececf1;
  font-family: "Mulish", sans-serif;
}
.visitors-plan-options__plan-uper {
  border-radius: 25px 25px 0 0;
}
.visitors-plan-options__p-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  justify-content: flex-start;
}
.visitors-plan-options__p-form .plan-title {
  font-size: 19px;
  color: #121d2a;
  letter-spacing: -0.38px;
  height: 11%;
  display: flex;
  align-items: flex-end;
}
.visitors-plan-options__p-form .visitors-plan-options__p-form__content-yearly-custom {
  height: 65%;
}
.visitors-plan-options__p-form__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 87%;
  width: 85%;
}
.visitors-plan-options__p-form__content .description-wrapper {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  color: #677177;
  letter-spacing: -0.24px;
  font-size: 12px;
}
.visitors-plan-options__p-form__content .feature-container {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}
.visitors-plan-options__p-form__content .feature-container .feature-row {
  display: flex;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
}
.visitors-plan-options__p-form__content .feature-container .feature-row .check-icon {
  color: #fecb2e;
}
.visitors-plan-options__p-form__content .feature-container .feature-row .feature-description {
  display: flex;
}
.visitors-plan-options__p-form__content .feature-container .feature-row .feature-description__text {
  text-align: start;
}
.visitors-plan-options__p-form__content .feature-container-custom {
  display: none;
}
.visitors-plan-options__p-form__content .logo {
  height: 64px;
  width: auto;
  margin: 10px auto;
}
.visitors-plan-options__p-form__content .custom-title {
  font: normal normal 900 31px OpenSans;
  letter-spacing: -0.62px;
}
.visitors-plan-options__p-form__content__titles-low-div {
  align-self: center;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.visitors-plan-options__p-form__content__titles-low-div .title-low {
  text-align: center;
  font: normal normal 600 31px Mulish;
  letter-spacing: -0.62px;
  color: #121d2a;
  width: 69%;
  overflow: hidden;
}
.visitors-plan-options__p-form__content__titles-low-div .per-month-label {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 29px;
  color: #96a5ae;
  width: 3.4rem;
}
.visitors-plan-options__p-form__content__titles-low-div .sub-title-low {
  margin: 10px auto;
  display: inline;
  font-size: 21px;
  font-weight: bold;
  color: #4c9dcb;
}
.visitors-plan-options__p-form__content .plan-input-container {
  width: 247px;
  height: 48px;
  margin: 10px auto;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1607843137), 0 3px 16px rgba(0, 0, 0, 0.1607843137);
}
.visitors-plan-options__p-form__content .icon {
  height: 30px;
  width: 30px;
}
.visitors-plan-options__p-form__content .star-icon {
  height: 20px;
  width: 20px;
  margin-right: -55px;
  padding-left: 60px;
  margin-bottom: 12px;
}
.visitors-plan-options__p-form__content .visibility-icon {
  cursor: pointer;
}
.visitors-plan-options__p-form__content .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.visitors-plan-options__p-form__content .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.visitors-plan-options__p-form__content .plan-button {
  border: 1px solid #fecb2e;
  text-align: center;
  color: #fecb2e;
  background: white;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.visitors-plan-options__p-form__content .save-more-header {
  text-align: center;
  letter-spacing: -0.26px;
  color: #809fb8;
}
.visitors-plan-options__p-form__content .save-more-under-header {
  text-align: center;
  letter-spacing: -0.26px;
  color: #000000;
}
.visitors-plan-options__p-form__content .choose-plan-button {
  border: none;
  text-align: center;
  color: #0a287c;
  background: #fecb2e 0% 0% no-repeat padding-box;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.visitors-plan-options__p-form__content .separator {
  height: 2px;
  width: 10px;
  background-color: #4c9dcb;
  margin-inline-end: 30px;
}
.visitors-plan-options__p-form__content-yearly {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 85%;
  padding: 0 17px;
}
.visitors-plan-options__p-form__content-yearly .description-wrapper {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-items: center;
  color: #677177;
  letter-spacing: -0.24px;
  font-size: 12px;
}
.visitors-plan-options__p-form__content-yearly .feature-container {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}
.visitors-plan-options__p-form__content-yearly .feature-container .feature-row {
  display: flex;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
}
.visitors-plan-options__p-form__content-yearly .feature-container .feature-row .check-icon {
  color: #fecb2e;
}
.visitors-plan-options__p-form__content-yearly .feature-container .feature-row .feature-description {
  display: flex;
}
.visitors-plan-options__p-form__content-yearly .feature-container .feature-row .feature-description__text {
  text-align: start;
}
.visitors-plan-options__p-form__content-yearly .feature-container-custom {
  display: none;
}
.visitors-plan-options__p-form__content-yearly .logo {
  height: 64px;
  width: auto;
  margin: 10px auto;
}
.visitors-plan-options__p-form__content-yearly .custom-title {
  font: normal normal 900 31px OpenSans;
  letter-spacing: -0.62px;
}
.visitors-plan-options__p-form__content-yearly__titles-low-div {
  align-self: center;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}
.visitors-plan-options__p-form__content-yearly__titles-low-div .title-low {
  text-align: center;
  font: normal normal 600 31px Mulish;
  letter-spacing: -0.62px;
  color: #121d2a;
  width: 69%;
  overflow: hidden;
}
.visitors-plan-options__p-form__content-yearly__titles-low-div .per-month-label {
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 29px;
  color: #96a5ae;
  width: 3.4rem;
}
.visitors-plan-options__p-form__content-yearly__titles-low-div .sub-title-low {
  margin: 10px auto;
  display: inline;
  font-size: 21px;
  font-weight: bold;
  color: #4c9dcb;
}
.visitors-plan-options__p-form__content-yearly .plan-input-container {
  width: 247px;
  height: 48px;
  margin: 10px auto;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1607843137), 0 3px 16px rgba(0, 0, 0, 0.1607843137);
}
.visitors-plan-options__p-form__content-yearly .icon {
  height: 30px;
  width: 30px;
}
.visitors-plan-options__p-form__content-yearly .star-icon {
  height: 20px;
  width: 20px;
  margin-right: -55px;
  padding-left: 60px;
  margin-bottom: 12px;
}
.visitors-plan-options__p-form__content-yearly .visibility-icon {
  cursor: pointer;
}
.visitors-plan-options__p-form__content-yearly .separator {
  height: 24px;
  width: 1px;
  background-color: #1667c7;
  margin-inline-end: 30px;
}
.visitors-plan-options__p-form__content-yearly .input {
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.visitors-plan-options__p-form__content-yearly .plan-button {
  border: 1px solid #fecb2e;
  text-align: center;
  color: #fecb2e;
  background: white;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.visitors-plan-options__p-form__content-yearly .choose-plan-button {
  border: none;
  text-align: center;
  color: #0a287c;
  background: #fecb2e 0% 0% no-repeat padding-box;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  font-size: 17px;
  font-family: "OpenSans", sans-serif;
  font-weight: 900;
  letter-spacing: -0.36px;
  height: 43px;
  width: 80%;
  cursor: pointer;
}
.visitors-plan-options__p-form__content-yearly .separator {
  height: 2px;
  width: 10px;
  background-color: #4c9dcb;
  margin-inline-end: 30px;
}

.visitors-plan-options:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137), 0 3px 16px rgba(0, 0, 0, 0.1607843137);
  max-height: 28rem;
}
.visitors-plan-options:hover .feature-container-custom {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}
.visitors-plan-options:hover .feature-container-custom .feature-row {
  display: flex;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
}
.visitors-plan-options:hover .feature-container-custom .feature-row .check-icon {
  color: #fecb2e;
}
.visitors-plan-options:hover .feature-container-custom .feature-row .feature-description {
  display: flex;
}
.visitors-plan-options:hover .feature-container-custom .feature-row .feature-description__text {
  text-align: start;
}
.visitors-plan-options:hover .visitors-plan-options__p-form .visitors-plan-options__p-form__content-yearly-custom {
  height: 87%;
}

.opacity {
  opacity: 0.35;
}

.bdc-walk-dialog {
  background-color: white;
  border-radius: 14px;
}

/*div.mat-menu-panel.bdc-walk-popup.mat-menu-above {
  box-shadow: 0 0 15px #0006;
  border: solid 1px #0006;
}
*/
.mat-form-field {
  text-align: right;
  display: flex;
  flex-direction: row;
}

.mat-form-field-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  right: unset;
}

/*.writing-form {
  background-color: red;
}

.writing-form {
  background-color: blue;
}*/
.writing-title {
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-bottom: 3px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.0823529412) 0 0 20px;
}
.writing-title .title {
  font-size: 24px;
  font-weight: 700;
  color: #262460;
  font-family: system-ui;
}
.writing-title .subtitle {
  font-size: 16px;
  color: #0a287c;
  font-family: "system-ui";
}

.writing-wrapper {
  display: flex;
  flex-direction: row;
}

.writing-settings-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.eng-field {
  direction: ltr;
}

.icon-info {
  height: 24px;
  vertical-align: bottom;
  cursor: pointer;
}

.writing-output {
  display: flex;
  width: 55%;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px 30px 30px 10px;
  gap: 10px;
  margin-bottom: 64px;
  min-height: 60vh;
}
.writing-output .input-label {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: #636363;
}
.writing-output .paper {
  box-shadow: rgba(0, 0, 0, 0.031372549) 0 10px 20px;
  width: 100%;
  height: 100%;
  padding: 4rem;
  border-radius: 16px;
  background-color: #f9f9f9;
  border: 2px solid #eee;
  direction: ltr;
}

.response {
  margin-top: -2rem;
  text-align: justify;
}

.copy-button {
  text-align: right;
  position: relative;
  top: -45px;
  right: -45px;
}

.word-wrap {
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  margin: 0;
  font-family: "OpenSans";
}

.writing-select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 30px 0px 30px;
  gap: 14px;
  /* Dotted border */
}
.writing-select-wrapper mat-select {
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #262460;
  margin-top: 7px;
}
.writing-select-wrapper hr.dotted {
  border-top: 2px dotted #bbb;
  width: 100%;
}

.writing-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px 30px 30px;
  gap: 18px;
}
.writing-settings h4 {
  margin: 0;
}
.writing-settings__header .title {
  font-size: 32px;
  color: #171725;
}
.writing-settings__header .subtitle {
  font-size: 16px;
  color: #0a287c;
}
.writing-settings__organization-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.writing-settings__organization-form .form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.writing-settings__organization-form .form-row .form-control {
  width: 45%;
}
.writing-settings .divider {
  width: 100%;
  height: 1px;
  background-color: #d7d7d7;
  visibility: visible;
  margin-block: 30px;
}
.writing-settings__user-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.writing-settings__user-form .form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.writing-settings__user-form .form-row .form-control {
  width: 50%;
}
.writing-settings__user-form .form-row .user-permission {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.writing-settings__user-form .form-row .user-permission__label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.writing-settings__user-form .form-row .user-permission__select-box {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
}
.writing-settings__user-form .form-row .user-permission__select-box .text {
  font-size: 14px;
  color: black;
}
.writing-settings__user-form .form-row .user-permission__select-box .icon {
  font-size: 24px;
  display: flex;
  color: #d7d7d7;
  justify-content: center;
  align-items: center;
}
.writing-settings__user-form .form-row .user-profile-picture {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.writing-settings__user-form .form-row .user-profile-picture .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 50%;
}
.writing-settings__user-form .form-row .user-profile-picture .change-photo-button {
  cursor: pointer;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a287c;
  color: #0a287c;
  border-radius: 20px;
}
.writing-settings__user-form .form-row .user-profile-picture .change-photo-button .text {
  font-size: 14px;
  color: #0a287c;
}
.writing-settings__preferences-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.writing-settings__preferences-form .form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.writing-settings__preferences-form .form-row .form-control {
  width: 100%;
}
.writing-settings__preferences-form .form-row .user-permission {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.writing-settings__preferences-form .form-row .user-permission__label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  font-size: 16px;
  line-height: 16px;
  color: #636363;
}
.writing-settings__preferences-form .form-row .user-permission__select-box {
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
}
.writing-settings__preferences-form .form-row .user-permission__select-box .text {
  font-size: 14px;
  color: black;
}
.writing-settings__preferences-form .form-row .user-permission__select-box .icon {
  font-size: 24px;
  display: flex;
  color: #d7d7d7;
  justify-content: center;
  align-items: center;
}
.writing-settings__preferences-form .form-row .user-profile-picture {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.writing-settings__preferences-form .form-row .user-profile-picture .avatar {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 50%;
}
.writing-settings__preferences-form .form-row .user-profile-picture .change-photo-button {
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a287c;
  color: #0a287c;
  border-radius: 20px;
}
.writing-settings__preferences-form .form-row .user-profile-picture .change-photo-button .text {
  font-size: 14px;
  color: #0a287c;
}
.writing-settings__preferences-form .form-row .funding-range-slider {
  padding: 10px;
  display: flex;
}
.writing-settings .preference {
  text-align: center;
  font: normal normal 300 20px/36px OpenSans;
  letter-spacing: 0.08px;
  color: #171725;
}

.avatar {
  max-width: 8rem;
  max-height: 6rem;
}

.form-control {
  width: 100%;
}

.goal-title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 5px 0;
}

.textarea-upper-radius {
  border-radius: 0.5rem 0.5rem 0 0;
}

.section-button {
  cursor: pointer;
  background: #dd5353 0% 0% no-repeat padding-box;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 100%;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
}

.file-upload-wrapper {
  margin-top: 5px;
}

.textarea-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.textarea-wrapper > label {
  font-family: "OpenSans", sans-serif;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.26px;
  color: rgba(0, 0, 0, 0.3490196078);
}
.textarea-wrapper > textarea {
  border-radius: 5px;
  min-height: 5rem;
  max-width: 100%;
  resize: vertical;
  color: #5f5f5f;
  font-size: 0.95rem;
  font-family: "OpenSans", sans-serif;
  border: 1px solid #262460;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
}

.section-wrapper {
  border-radius: 0.5rem;
  border: 2px dotted #c1c1c1;
  padding: 10px;
  display: flex;
  flex-flow: column;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.1333333333) 0px 6px 15px;
}

.section-title-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.section-title-wrapper .delete-section-icon {
  color: tomato;
  cursor: pointer;
  font-size: 22px;
}

.submit-button {
  cursor: pointer;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 24px;
}

html[dir=rtl] {
  /*mat-option {
      text-align: left !important;
      direction: ltr !important;
  }*/
}
html[dir=rtl] .bdc-walk-dialog {
  background-color: white;
  border-radius: 14px;
}
@media (max-width: 776px) {
  .tutorial-popup {
    margin-right: 50%;
  }
  .tutorial-popup2 {
    margin-right: unset;
  }
  .bdc-walk-dialog {
    padding: 15px;
    text-align: center;
  }
  .writing-wrapper {
    flex-direction: column;
  }
  .writing-wrapper .writing-settings-wrapper {
    width: 100%;
  }
  .writing-wrapper .writing-output {
    width: auto;
  }
}
p {
  word-wrap: break-word;
  word-break: keep-all;
  hyphens: none;
}

.cke_content {
  width: 680px;
}

.writing-dialog {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 35px 55px 25px 50px;
  border-radius: 20px;
  background-color: white;
  max-height: 80vh;
  overflow: scroll;
}
.writing-dialog .close-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.writing-dialog__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 350px;
  margin: auto;
  gap: 10px;
}
.writing-dialog__content .title {
  color: #1c2d57;
  font-size: 24px;
  text-align: center;
}
.writing-dialog__content .instructions {
  color: #636c84;
  font-size: 14px;
  text-align: justify;
}
.writing-dialog__content .image {
  width: 250px;
  height: auto;
}
.writing-dialog__content .back-button {
  width: 100%;
  padding: 10px 0;
  background: #fecb2e 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #0a287c;
  display: flex;
  justify-content: center;
}
.writing-dialog__content .skip-button {
  font-size: 16px;
  color: #a2a2a2;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 776px) {
  .writing-dialog {
    padding: 30px 30px;
  }
  .writing-dialog__content {
    width: 100%;
  }
}