.work-plan-table {
  .mat-header-cell {
    text-align: center !important;
    padding: 0 10px;
    //background: red;
    //border: solid 1px white;
  }

  .mat-cell {
    text-align: center !important;
    //border: solid 1px blue;
    padding: 0 10px;
  }

  td.no-center-text {
    text-align: start !important;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 0.6rem;
  }

  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 0.6rem;
  }

  .main-table-container {
    border: 1px solid #0000002f;
    width: 100%;
    border-radius: 10px;
    overflow: hidden !important;

    .status-cell {
      display: inline-block;
      padding: 4px 8px;
      font-size: 14px;
      line-height: 1;
      border-radius: 25px;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      transition: background-color 0.3s;
      width: fit-content;
    }

    .message-no-subs {
      padding: 15px 10px;
      font-weight: bold;
    }

    .main-table-header-row {
      height: 50px;
    }

    .main-table-header-row th:first-child {
      border-left: 8px var(--color-workplan) solid;
    }
    // Main Table Row

    tr.main-table-element-row:not(.main-table-expanded-row):hover {
      background: rgb(241, 244, 250);
    }

    tr.main-table-expanded-row {
      background: rgb(241, 244, 250);
    }

    .main-table-element-row td {
      border-bottom-width: 0;
    }
    // Main Table Details Row

    tr.main-table-detail-row {
      height: 0;
      background: rgb(241, 244, 250);
    }

    tr.main-table-detail-row td:first-child {
      border-left: 1px var(--color-workplan) solid;
    }

    .main-table-element-detail {
      overflow: hidden;
      display: flex;
      padding: 0 1rem;
    }

    .main-table-element-row td:first-child {
      border-left: 8px var(--color-workplan) solid;
    }
    // SUB TABLE

    .sub-table-container {
      border: 1px solid #0000002f;
      width: 100%;
      border-radius: 10px;
      overflow: hidden !important;
      margin-bottom: 10px;

      .sub-table-header-row {
        height: 30px;
      }

      .sub-table-header-row th:first-child {
        border-left: 8px var(--color-submission) solid;
      }

      .sub-table-row {
        height: 40px;
      }

      .sub-table-row td:first-child {
        border-left: 8px var(--color-submission) solid;
      }
    }
  }
}

.mat-icon-btn {
  cursor: pointer;
  //font-size: 18px;
}

html[dir='rtl'] {
  .main-table-container {
    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-right: 0.6rem;
      padding-left: unset;
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-left: 0.6rem;
      padding-right: unset;
    }

    .main-table-header-row th:first-child {
      border-right: 8px var(--color-workplan) solid;
      border-left: unset;
    }

    tr.main-table-detail-row td:first-child {
      border-right: 1px var(--color-workplan) solid;
      border-left: unset;
    }

    .main-table-element-row td:first-child {
      border-right: 8px var(--color-workplan) solid;
      border-left: unset;
    }

    .sub-table-container {
      .sub-table-header-row th:first-child {
        border-right: 8px var(--color-submission) solid;
        border-left: unset;
      }

      .sub-table-row td:first-child {
        border-right: 8px var(--color-submission) solid;
        border-left: unset;
      }
    }
  }
}
