.deadline-owner-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}

.task-dialog {
  max-height: 80vh;
  min-width: 300px;
  overflow: auto;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px #00000014;
  flex-direction: column;

  img.close-icon {
    top: 16px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    float: right;
    padding: 5px;
  }

  .red {
    color: red;
  }

  .task-container {
    padding: 10px;

    .form-container {
      .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .close-icon {
          width: 18px;
          height: 18px;
          //position: sticky;
          cursor: pointer;
          top: unset;
          left: unset;
          right: unset;
          position: unset;
        }

        .header-icon {
          width: 18px;
          height: 18px;
          //position: sticky;
          cursor: pointer;
        }
      }

      .form-row {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .owner-select-input {
          border: 1px solid #707070;
          border-radius: 8px;

          .owner-placeholder {
            padding-left: 16px;
          }

          .arrow-down {
            padding-right: 10px;
            font-size: 30px;
            height: 30px;
            width: 30px;
          }
        }

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
          padding: 0;
        }

        .title {
          font-size: 13px;
          font-weight: 600;
        }

        .input-wrapper {
          width: 100%;
          border: 1px solid #707070;
          border-radius: 8px;
          height: 32px;
          display: flex;
          box-sizing: border-box;

          &:focus-within {
            border: 1px solid #fecb2e;
          }

          input {
            padding-left: 16px;
            width: 100%;
            background-color: transparent;
          }

          ::placeholder {
            color: #5f5f5f;
            letter-spacing: 0.24px;
            font-size: 12px;
          }
        }

        .toggle-wrapper {
          height: 32px;
          display: flex;
          width: fit-content;

          .status-btn {
            color: #707070;
            display: flex;
            font-size: 13px;
            padding: 0 1rem;
            background-color: white;
            line-height: 32px;
            border-radius: 6px;
            border: 1px solid #707070;
            z-index: 1;
          }

          .btn-overlap {
            margin-right: -8px;
          }

          .selected-btn {
            background-color: #0a287c;
            color: white;
            z-index: 2;
          }
        }

        .input-error {
          color: red;
          font-size: 13px;
          display: block;
          padding-left: 5px;
        }

        .textarea-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          > label {
            font-family: OpenSans, sans-serif;
            padding-left: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: -0.26px;
          }

          > textarea {
            border-radius: 0.5rem;
            padding-left: 0.5rem;
            min-height: 4.5rem;
            max-width: 100%;
            resize: none;
            color: #5f5f5f;
            font-size: 14px;
            font-family: OpenSans, sans-serif;
            border: 1px solid black;
          }
        }
      }
    }

    .tasks-table {
      width: 95%;
      margin: 0 auto;

      .columns-text {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        letter-spacing: -0.26px;
        opacity: 1;
        //font-size: 14px;
        //font-weight: 900;
        font: normal normal 600 14px/14px OpenSans;
        padding-right: 20px;
      }

      .line {
        border: 1px solid #e9e9e9;
        width: 96%;
        margin: 5px;
      }

      .open-tasks {
        margin-bottom: 16px;
        font-size: 17px;
        display: flex;
        gap: 3px;
        color: #171725;
        align-items: center;
      }

      .open-task-text {
        text-align: center;
        letter-spacing: -0.26px;
        color: #809fb8;
        opacity: 1;
        //font-size: 13px;
        font: normal normal 600 13px/13px OpenSans;
      }

      .tasks-list {
        display: flex;
        padding-right: 17px;
        padding-left: 2px;
        justify-content: space-between;

        .name-text {
          width: 200px;
          height: 20px;
          overflow: hidden;
        }

        .list-text {
          font: normal normal 600 13px/13px OpenSans;
        }

        .icons {
          flex-direction: row;

          .view-action {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }

          .delete-action {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
    }

    .finish-btn-wrapper {
      width: 88%;
      display: flex;
      padding-left: 6%;
      justify-content: flex-end;

      .finish-btn {
        height: 32px;
        color: #0a287c;
        font-size: 12px;
        letter-spacing: -0.24;
        margin-top: 20px;
        cursor: pointer;
        font-weight: 600;
        border-radius: 8px;
        align-self: center;
        margin-bottom: 20px;
        padding-inline: 1rem;
        background-color: #fecb2e;
        border: 1px solid #fecb2e;
        font-family: OpenSans, sans-serif;
      }

      .disabled {
        cursor: none;
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}

// select
.task-dialog-select {
  width: 100%;

  .custom-select {
    gap: 0px;
    color: #5f5f5f;

    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      height: 12px;
      color: #5f5f5f;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.task-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    .select-option {
      padding: 10px 5px;
      background-color: white;
      font-size: 14px;

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #000000;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #000000;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}

@media (max-width: 776px) {
  .task-dialog {
    min-width: unset;
  }
}
