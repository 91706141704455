.help-and-support-wrapper {
  height: calc(100vh - 96px);
  background: transparent linear-gradient(180deg, #4b9dcc24 0, #ffffff00 100%) 0 0 no-repeat;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .support-header {
    padding-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .support-header-title {
      text-align: center;
      font: normal normal normal 1.5rem/2rem $font-site;
      color: #0a287c;
    }

    .support-search-bar {
      display: flex;
      width: 45%;
      box-shadow: 0 10px 22px #0a287c0a;
      border-radius: 4px;
      background-color: white;
      height: 3rem;
      align-items: center;
      padding-inline-start: 10px;

      .search-icon {
        width: 1.25rem;
        height: 1.25rem;
        padding-inline-end: 10px;
      }

      .search-input {
        width: calc(100% - 1.25rem);
      }
    }

    .help-pop-topics {
      display: flex;
      gap: 4px;
      font: normal normal normal 15px/15px $font-site;

      > span {
        color: #77838f;
      }

      > p > a {
        text-decoration: none;
        text-align: left;
        font: normal normal bold 15px/15px $font-site;
        letter-spacing: 0.53px;
        color: #0a287c;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .hns-block {
    margin-top: 6rem;
    width: 90%;
    background-color: white;
    border: 1px solid #eeeeee;

    .chip {
      color: #fecb2e;
      font-size: 12px;
      font-weight: bold;
      font-family: 'OpenSans', sans-serif;
      padding: 0.3rem 1.2rem;
      background-color: #fecb2e26;
      border-radius: 1rem;
      width: fit-content;
      margin-bottom: 0.6rem;
    }
  }

  .main {
    .help-cards-wrapper {
      display: flex;
      justify-content: space-around;
      gap: 1.3rem;
      margin-top: -60px;
      margin-bottom: 4rem;
      margin-inline: 2rem;

      .help-card {
        width: 28%;
        max-width: 300px;
        display: flex;
        padding: 1rem;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 2px 48px rgba(0, 0, 0, 0.0588235294);

        .card-icon {
          height: 3rem;
          margin-bottom: 0.5rem;
        }

        .card-title {
          //font-weight: 600;
          text-align: center;
          margin-bottom: 0.5rem;
          font: normal normal bold 14px/20px $font-site;
          letter-spacing: 0.64px;
          color: #1e2022;
          opacity: 1;
        }

        .help-card-description {
          //font-size: 13px;
          width: 90%;
          text-align: center;
          color: #77838f;
          margin: auto;
          font: normal normal normal 13px/13px $font-site;
          letter-spacing: 0.6px;
          opacity: 1;
        }

        .help-card-link {
          font-size: 14px;
          color: #4b9dcc;
        }
      }

      .accent-border-orange {
        border-bottom: 6px solid #fe962e;
      }

      .accent-border-blue {
        border-bottom: 6px solid #4b9dcc;
      }

      .accent-border-yellow {
        border-bottom: 6px solid #fecb2e;
      }
    }

    .faq-wrapper {
      width: 90%;
      margin: 0 auto;

      .mb-2 {
        margin-bottom: 2rem;
      }

      .faq-title {
        display: block;
        letter-spacing: 1.2px;
        font-size: 1.7rem;
        //font-weight: 300;
      }

      .faq-subtitle {
        letter-spacing: 0.6px;
        font-size: 13px;
        color: #77838f;
        margin-bottom: 3rem;
        font-family: $font-site;
      }

      .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: none;
      }

      .mat-expansion-panel {
        box-shadow: none;
        margin-bottom: 1rem;
        border-radius: 4px;
        border: 1px solid #eeeeee;
      }

      .mat-expansion-panel-header:not([aria-disabled='true']) {
        padding-inline: 2rem;
        font-family: $font-site;
        letter-spacing: 0.68px;
      }

      .mat-expansion-panel-body {
        font-family: $font-site;
        letter-spacing: 0.6px;
        color: #77838f;
        font-size: 14px;
        letter-spacing: 0.6px;
        opacity: 1;
      }
    }
  }

  .support-ticket {
    padding-top: 3rem;
    padding-inline: 2rem 0;
    margin-bottom: 6rem;
    box-sizing: border-box;

    .support-ticket-title {
      font: normal normal normal 32px/42px $font-site;
      letter-spacing: 1.2px;
      color: #1e2022;
    }

    .support-form-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap-reverse;
      justify-content: space-between;
      margin-top: 3rem;
      gap: 2rem;

      .support-form {
        // width: 65%;
        flex-grow: 1;
        max-width: 550px;
        margin-bottom: 2rem;

        .form-wrapper {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .form-row {
            display: flex;
            gap: 1rem;
            width: 100%;

            .contact-input-wrapper {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 0.5rem;

              > label {
                //font-size: 13px;
                font: normal normal medium 13px/13px $font-site;
                //font-weight: bold;
              }

              > input[type='text'] {
                border: 1px solid #eaeaea;
                border-radius: 8px;
                height: 32px;
                padding-inline-start: 20px;
              }

              > input[type='text']:focus {
                border: 1px solid #fecb2e;
              }
            }

            .help-select-input {
              border: 1px solid #e3e3e3;
              border-radius: 8px;

              .help-placeholder {
                padding-left: 20px;
                font-size: 13px;
              }

              .organization-arrow-down {
                padding-right: 10px;
                font-size: 30px;
                height: 30px;
                width: 30px;
              }
            }

            .mat-form-field-appearance-legacy .mat-form-field-wrapper {
              padding: 0;
            }
          }
        }

        .submit-ticket {
          cursor: pointer;
          margin-top: 2rem;
          background-color: #fecb2e;
          padding: 0.7rem 2rem;
          border-radius: 6px;
          font-family: $font-site;
        }
      }

      .contact-us-wrapper {
        margin-top: -3rem;
        //width: 35%;
        display: flex;
        flex-direction: column;
        // margin-inline: auto;

        .contact-method {
          display: flex;
          gap: 1.5rem;
          padding: 1.5rem 3.5rem 1.5rem 0.5rem;

          .contact-icon {
            padding: 14px;
            border-radius: 50%;
            background-color: #fecb2e20;
          }

          .partition {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .contact-title {
              //font-size: 15px;
              //font-weight: bold;
              font: normal normal medium 15px/15px $font-site;
            }

            .contact-description {
              //font-size: 13px;
              // color: #00000050;
              font: normal normal 300 13px/13px $font-site;
            }
          }
        }

        .contact-method + .contact-method {
          border-top: 1px solid #70707026;
        }
      }
    }
  }
}

app-help-support {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    display: flex;
    border-width: 0;
    align-items: center;
  }

  mat-form-field {
    width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: #00000059;
    font-size: 13px;
    padding-inline-start: 20px;
  }
}

// mobile
@media (max-width: 776px) {
  .help-and-support-wrapper {
    .support-ticket {
      padding-inline: 2rem 2rem;
      .support-form-wrapper {
        .contact-us-wrapper {
          .contact-method {
            padding: 1rem;
          }
        }
        .support-form {
          .form-wrapper {
            .form-row {
              .contact-input-wrapper {
                > input[type='text'] {
                  font: normal normal normal 16px OpenSans;
                  font-family: 'OpenSans', sans-serif;
                }
              }
              flex-direction: column;
            }
          }
        }
      }
    }

    .main {
      .help-cards-wrapper {
        flex-direction: column;
        align-items: center;
        .help-card {
          max-width: 420px;
          width: 100%;
        }
      }
    }
  }
}
