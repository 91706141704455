.payment-container {
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: safe center;
  align-items: safe center;

  .payment-form-container {
    height: 80%;
    width: 50%;
    min-width: 600px;
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 2.3vh;

    .form-container {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 10% 12%;

      .form-class {
        width: 100%;
        height: 100%;

        .form_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 30px;
          height: 100%;
          width: 100%;
          margin: auto;

          .credit-card {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;

            .title {
              text-align: left;
              font: normal normal normal 18px/21px OpenSans;
              font-family: OpenSans, sans-serif;
              letter-spacing: 0;
              color: #536777;
              opacity: 1;
            }

            .card-element {
              background: #ffffff 0 0 no-repeat padding-box;
              border: 1px solid #b9b9b9;
              border-radius: 7px;
              opacity: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 10px;
            }

            &__number {
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 10px;
            }

            &__extras {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .date {
                display: flex;
                flex-direction: column;
                width: 120px;
                gap: 10px;
              }

              .cvc {
                display: flex;
                flex-direction: column;
                width: 100px;
                gap: 10px;
              }
            }
          }

          .title-row {
            display: flex;
            flex-direction: column;

            .card-error {
              color: red;
            }

            .payment-title {
              color: #0a287c;
              letter-spacing: 0;
              font-size: 26px;
            }

            .sub-title {
              font-size: 18px;
              letter-spacing: 0;
              color: #a5a5a5;
            }
          }

          .card-type {
            display: flex;
            flex-direction: column;
            width: 40%;

            .select-option-icon {
            }
          }

          .owner-name-row {
            flex-direction: column;
            display: flex;
            gap: 10px;

            .title {
              text-align: left;
              font: normal normal normal 18px/21px OpenSans;
              font-family: OpenSans, sans-serif;
              letter-spacing: 0;
              color: #536777;
              opacity: 1;
            }

            .payment-input {
              color: #144167;
              font-weight: 500;
              font-family: OpenSans, sans-serif;
              font-size: 18px;
            }
          }

          .agree-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .t-c-container {
              display: flex;
              align-items: center;

              & label {
                padding: 0 0 0 1vh;
                font-size: 15px;
                color: #94a5b2;
              }

              & input[type='checkbox'] {
                accent-color: #0a287c;
                height: unset;
                width: unset;
                box-sizing: unset;
              }
            }

            .payment-total {
              display: flex;
              align-items: center;
              color: #463e65;

              .total-label {
                font: normal normal bold 21px/25px Heebo;
                color: #463e65;
                padding-bottom: 0;
              }

              .total-amount-label {
                font: normal normal 600 21px/25px Heebo;
                letter-spacing: 0;
                color: #0a287c;
                padding-bottom: 0;
                margin-left: 2vh;
              }
            }
          }

          .payment-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5.5px 0;

            .back-container {
              .payment-back-icon {
                cursor: pointer;
                height: 14px;
              }

              label {
                cursor: pointer;
                padding: 0 0.35vh;
                color: #a7a7a7;
                font: normal normal bold 18px/20px Heebo;
              }
            }

            .pay-now-btn {
              cursor: pointer;
              gap: 1.5vh;
              width: max(35%, 200px);
              display: flex;
              justify-content: center;
              background: #fecb2e 0 0 no-repeat padding-box;
              font: normal normal bold 21px Heebo;
              box-shadow: 0 1px 3px #00000026;
              border-radius: 1.1vh;
              padding: 0.9vh 0.7vh;
              align-items: center;
              max-width: 208px;
              color: #0a287c;

              .credit-icon {
                cursor: pointer;
                width: 34px;
                height: 34px;
                transform: rotate(-15deg);
              }
            }
          }

          .no-credit-expansion {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #bebebe;
            width: 110%;
            margin-top: -61.44px;
            margin-left: -5%;
            padding: 6px 5%;
            box-sizing: border-box;
            border-radius: 12px;
            background-color: white;
            box-shadow: 0 6px 14px #00000024;

            animation-name: show;
            animation-duration: 400ms;
            @keyframes show {
              // maybe needs to e on parent element
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }

            .contact-me-btn {
              width: max(35%, 200px);
              display: flex;
              justify-content: center;
              background: #fecb2e;
              font: normal normal bold 21px Heebo;
              box-shadow: 0 1px 3px #00000026;
              border-radius: 1.1vh;
              padding: 0.9vh 0.7vh;
              align-items: center;
              color: #0a287c;
              max-width: 208px;
            }

            .t-c-container {
              display: flex;
              align-items: center;

              & label {
                padding: 0 0 0 1vh;
                font-size: 15px;
                color: #94a5b2;
              }

              & input[type='checkbox'] {
                accent-color: #0a287c;
                height: 17px;
                width: 17px;
              }
            }
          }

          .no-credit-card {
            display: flex;
            margin-top: -30px;
            justify-content: safe flex-end;

            span {
              color: #a7a7a7;
              cursor: pointer;
              max-width: 208px;
              text-align: center;
              width: max(35%, 200px);
              text-decoration: underline;
            }
          }

          input,
          select {
            box-sizing: border-box;
            border: 1px solid #b9b9b9;
            border-radius: 7px;
            height: 51px;
            margin: 0;
          }

          label {
            color: #536777;
            padding-bottom: 1.1px;
            font-size: 18px;
          }

          select {
            color: #0a287c;
            padding-left: 5vh;
            background-size: 3vh;
            background-position: 1vh;
            background-repeat: no-repeat;
            font: normal normal 18px/21px Heebo;
          }

          input:focus {
            border: 3px solid rgba(15, 87, 245, 0.24);
          }

          input.ng-invalid.ng-touched {
            border: 2px solid rgba(255, 0, 0, 0.64);
          }

          input::placeholder {
            color: #8c98a2;
            font-size: 15px;
            font-family: OpenSans, sans-serif;
          }

          input[type='text'],
          input[type='number'] {
            color: #144167;
            font-size: 18px;
            font-family: OpenSans, sans-serif;
          }
        }
      }
    }
  }
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}

html[dir='rtl'] {
  .payment-container {
    .payment-form-container {
      .form-container {
        .form-class {
          .form_wrapper {
            .title-row {
              .payment-title {
              }

              .sub-title {
              }
            }

            .card-details-row {
              .card-type {
                .select-option-icon {
                }
              }

              .expire-date {
              }

              .cvv {
              }
            }

            .card-number-row {
            }

            .owner-name-row {
            }

            .agree-row {
              .t-c-container {
                & label {
                  font-size: 15px;
                  padding: 0 10px 0 0;
                }

                & input[type='checkbox'] {
                }
              }

              .payment-total {
                .total-label {
                }

                .total-amount-label {
                  margin-right: 2vh;
                  margin-left: 0;
                }
              }
            }

            .payment-row {
              .back-container {
                .payment-back-icon {
                  transform: rotate(180deg);
                }

                label {
                  padding: 0 0.35vh;
                }
              }

              .pay-now-btn {
              }
            }

            .no-credit-expansion {
              margin-left: 0;
              margin-right: -5%;

              .t-c-container {
                label {
                  padding: 0 1vh 0 0;
                }
              }
            }

            input,
            select {
            }

            label {
            }

            select {
              padding-right: 5vh;
              padding-left: 0;
              background-position: 96%;
            }

            input:focus {
            }

            input.ng-invalid.ng-touched {
            }

            input::placeholder {
            }

            input[type='text'],
            input[type='number'] {
              padding-right: 20px;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 776px) {
  .payment-container {
    .payment-form-container {
      height: unset;
      width: unset;
      min-width: unset;
      margin: 10px;
    }
    .form-container {
      .form-class {
        .form_wrapper {
          .agree-row {
            flex-direction: column;
          }
          .credit-card {
            flex-direction: column !important;
          }
        }
      }
    }
  }
}
