.submission-dialog {
  min-width: 270px;
  max-height: 87vh;
  margin: 5px auto;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px #00000014;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;

  img.close-icon {
    top: 16px;
    width: 18px;
    height: 18px;
    position: sticky;
    cursor: pointer;
    left: calc(100% - 16px - 18px);

    position: relative;
    display: flex;
    background-color: transparent;
    float: right;
    padding: 5px;
    cursor: pointer;
  }

  .submission-container {
    flex-direction: row;
    display: flex;
    padding: 10px;
    gap: 12px;
    overflow: auto;
    overflow-x: hidden;
    /*align-items: center;
    margin: 10px;*/

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #0a287c 0 0 no-repeat padding-box;
      border-radius: 3px;
    }

    .form-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      min-width: 300px;

      .header-title {
        font-size: 17px;
        display: flex;
        gap: 10px;
        color: #171725;
        align-items: center;

        .header-icon {
          height: 32px;
        }
      }

      .form-row {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .owner-select-input {
          border: 1px solid #707070;
          border-radius: 8px;

          .owner-placeholder {
            padding-left: 16px;
          }

          .arrow-down {
            padding-right: 10px;
            font-size: 30px;
            height: 30px;
            width: 30px;
          }
        }

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
          padding: 0;
        }

        .title {
          font-size: 13px;
          font-weight: 600;
        }

        .file-input-wrapper {
          width: 100%;
          height: 32px;
          display: flex;
          box-sizing: border-box;
          background: #8a8c9819 0% 0% no-repeat padding-box;
          border: 1px dashed #707070;
          border-radius: 6px;
          opacity: 1;

          input {
            padding-left: 8px;
            width: 100%;
            background-color: transparent;
          }

          ::placeholder {
            color: #5f5f5f;
          }
        }

        .input-wrapper {
          width: 100%;
          border: 1px solid #707070;
          border-radius: 8px;
          height: 32px;
          display: flex;
          box-sizing: border-box;
          align-items: center;

          &:focus-within {
            border: 1px solid #fecb2e;
          }

          .input-icon {
            opacity: 1;
            color: #707070;
            padding-right: 3px;
          }

          input {
            padding-left: 16px;
            width: 100%;
            background-color: transparent;
          }

          ::placeholder {
            color: #5f5f5f;
            letter-spacing: 0.24px;
            font-size: 12px;
          }
        }

        .toggle-wrapper {
          height: 32px;
          display: flex;
          width: fit-content;

          .status-btn {
            color: #707070;
            display: flex;
            font-size: 13px;
            padding: 0 1rem;
            background-color: white;
            line-height: 32px;
            border-radius: 6px;
            border: 1px solid #707070;
            z-index: 1;
            cursor: pointer;
          }

          .btn-overlap {
            margin-right: -8px;
          }

          .selected-btn {
            background-color: #0a287c;
            color: white;
            z-index: 2;
          }
        }
      }

      .red {
        color: red;
      }
    }

    .finish-btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .finish-btn {
        height: 32px;
        color: #0a287c;
        font-size: 12px;
        margin-top: 20px;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: -0.24;
        border-radius: 8px;
        align-self: center;
        margin-bottom: 20px;
        padding-inline: 1rem;
        background-color: #fecb2e;
        border: 1px solid #fecb2e;
        font-family: OpenSans, sans-serif;
      }

      .disabled {
        cursor: none;
        pointer-events: none;
        opacity: 0.6;
      }
    }

    .save-btn-wrapper {
      display: flex;
      justify-content: flex-end;

      .save-btn {
        width: 100px;
        height: 32px;
        color: #0a287c;
        font-size: 12px;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: -0.24;
        border-radius: 8px;
        align-self: center;
        padding-inline: 1rem;
        background-color: #fecb2e;
        border: 1px solid #fecb2e;
        font-family: OpenSans, sans-serif;
      }

      .disabled {
        cursor: none;
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}

.edit-submission {
  .edit-container {
    flex-direction: column;
    min-width: 300px;
    //margin: 10% 10px 10px;
    justify-content: space-evenly;
    display: flex;
  }

  .scroll-section {
    overflow-y: auto;
    height: 70%;
  }

  div.vertical-line {
    height: auto;
    width: 1px;
    background-color: black;
    opacity: 0.43;
    margin-left: 0;
    margin-top: 10%;
  }

  .open-task-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .add-task-btn {
      color: white;
      background: #0a287c;
      border-radius: 8px;

      width: 110px;
      height: 32px;
      font-size: 12px;
      //margin-top: 20px;
      cursor: pointer;
      font-weight: 600;
      letter-spacing: -0.24;
      align-self: center;
      //margin-bottom: 20px;
      padding-inline: 1rem;
      font-family: OpenSans, sans-serif;
    }
  }

  .open-tasks {
    //margin-bottom: 16px;
    display: flex;
  }

  .open-task-text {
    text-align: center;
    font: normal normal 600 13px/18px OpenSans;
    letter-spacing: -0.26px;
    color: #809fb8;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }

  .tasks-table {
    width: 95%;
    margin: 0 auto;
    height: 40%;
    overflow-y: scroll;
  }

  .columns-text {
    display: flex;
    flex-direction: row;
    //gap: 165px;
    justify-content: space-between;
    width: 90%;
  }

  .table-header {
    font-size: 11px;
    letter-spacing: 0.05px;
    color: #809fb8;
    opacity: 1;
  }

  .line {
    border: 1px solid #e9e9e9;
    width: 96%;
  }

  .action-icon {
    cursor: pointer;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

  .title-icon {
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    margin-right: 3px;
  }

  .tasks-list {
    display: flex;
    //padding-right: 17px;
    padding-left: 2px;
    justify-content: space-between;
    align-items: center;

    .name-text {
      font-size: 12px;
      text-overflow: ellipsis;

      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;
    }

    .icons {
      display: flex;
      flex-direction: row;
      margin-right: 14px;

      .view-action {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .delete-action {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}

div.scroll-section::-webkit-scrollbar {
  width: 5px;
}

/* Track */
div.scroll-section::-webkit-scrollbar-track {
  background: #d0d0d0;
}

div.scroll-section::-webkit-scrollbar-thumb {
  background: #0a287c;
  height: 3px;
}

html[dir='rtl'] {
  .overlay {
    .create-work-plan-wrapper {
      img.close-icon {
        left: calc(16px);
      }

      .form-container {
        .header-title {
          .header-icon {
          }
        }

        .form-row {
          .title {
          }

          .input-wrapper {
            input {
              padding-left: 0;
              padding-right: 8px;
            }

            ::placeholder {
            }
          }

          .toggle-wrapper {
            .status-btn {
            }

            .selected-btn {
            }

            .btn-overlap {
              margin-right: 0;
              margin-left: -8px;
            }
          }
        }
      }

      .finish-btn-wrapper {
        .finish-btn {
        }
      }
    }
  }

  .edit-submission {
    .create-overlay {
      position: fixed;
      inset: 0;
      display: flex;
      height: 100vh;
      width: 100vw;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 200;

      .create-wrapper {
        width: 30%;
        max-width: 350px;
        min-width: 270px;
        height: fit-content;
        background-color: white;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        border: 1px solid #d0d0d0;
        border-left: 8px solid #fecb2e;
        box-shadow: 0 13px 36px #00000014;
        position: relative;
        overflow: auto;
        display: flex;
        flex-direction: column;

        img.close-icon {
          top: 16px;
          width: 18px;
          height: 18px;
          position: sticky;
          cursor: pointer;
          left: calc(100% - 16px - 18px);
        }
      }
    }
  }

  .submission-dialog {
    min-width: 270px;
    max-height: 87vh;
    margin: 5px auto;
    background-color: white;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    border: 1px solid #d0d0d0;
    border-left: unset;
    border-right: 8px solid #fecb2e;
    box-shadow: 0 13px 36px rgb(0 0 0 / 8%);
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;

    img.close-icon {
      top: 16px;
      width: 18px;
      height: 18px;
      position: sticky;
      cursor: pointer;
      right: calc(100% - 16px - 18px);
      position: relative;
      display: flex;
      background-color: transparent;
      float: right;
      padding: 5px;
      cursor: pointer;
    }
  }
}

// select
.submission-dialog-select {
  width: 100%;

  .custom-select {
    gap: 0px;

    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      height: 20px;
      min-width: 150px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.submission-dialog-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    .select-option {
      padding: 10px 5px;
      background-color: white;
      font-size: 14px;

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #000000;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #000000;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}
// mobile
@media (max-width: 776px) {
  .submission-dialog {
    min-width: unset;
    .submission-container .form-container {
      min-width: unset;
      width: 100%;
    }
  }
}
