// select component
.custom-select {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .select-button {
    cursor: pointer;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #00000059;

    .select-input {
      width: 100%;
      color: black;
      font-size: 14px;
      padding: 0;
    }

    ::placeholder {
      color: #00000059;
      font-size: 14px;
    }

    .text {
      color: #00000059;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: auto;
      height: 18px;
      width: 290px;
    }

    .icon {
      color: #0a287c;
    }
  }

  .selected-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .selected-chip {
      cursor: pointer;
      padding: 6px 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ecebfb;
      border-radius: 16px;

      .text {
        font-size: 12px;
        line-height: 12px;
        color: #3a36db;
        transition: all 400ms;
      }

      .close {
        display: none;
        cursor: pointer;
        height: 12px;
        width: 12px;
        font-size: 12px;
        color: #3a36db;
        transition: all 400ms;
      }
    }

    .selected-chip:hover {
      padding: 6px 12px;

      .text {
        font-size: 12px;
        line-height: 12px;
        color: #c6c4f5;
        margin-left: -6px;
        margin-right: 6px;
        transition: all 400ms;
      }

      .close {
        display: block;
      }

      .close:hover {
        color: #c6c4f5;
        transition: all 400ms;
      }
    }
  }

  .select-error {
    color: red;
    font-size: 12px;
    line-height: 12px;
  }
}

.custom-select-panel {
  width: 100%;
  max-height: 250px;
  margin-top: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #00000059;

  // scroll
  &::-webkit-scrollbar {
    cursor: pointer;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    cursor: pointer;
    background-color: #e4e4e4;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #0a287c 0 0 no-repeat padding-box;
    border-radius: 3px;
  }

  .select-option-wrapper {
    display: flex;
    flex-direction: column;

    .select-option {
      cursor: pointer;
      padding: 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
      color: #00000059;
      gap: 10px;

      input[type='checkbox'] {
        @include checkbox(18px);
      }

      .text {
      }
    }

    .select-option.focus {
      background-color: #d9e1e7;
      color: #00000059;
    }

    .select-option:hover {
      background-color: #d9e1e7;
      color: #00000059;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #00000059;
    }
  }
}

// rtl
html[dir='rtl'] {
  .custom-select {
    .selected-options {
      .selected-chip:hover {
        .text {
          margin-left: 6px;
          margin-right: -6px;
        }
      }
    }
  }
}
