.popup-wrapper {
  width: 685px;
  height: 363px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 36px rgb(0 0 0 / 8%);
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spacer {
    flex: 1 1 auto;
    height: 10px;
  }

  .close-icon {
    cursor: pointer;
    padding: 14px;
    width: 93%;
    height: 1%;
  }

  .contact-details-wrapper {
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .header-wrapper {
      width: 85%;
      display: flex;
      align-items: center;
      //justify-content: space-between;
      padding-bottom: 10px;
      gap: 15px;

      .header {
        text-align: initial;
        font: normal normal 600 22px/36px 'OpenSans';
        letter-spacing: 0.09px;
        color: #171725;
      }

      .fund-type {
        cursor: default;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 10px;
      }

      .last-updated {
        font-size: 12px;
        color: #809fb8;
      }
    }

    .divider {
      width: 85%;
      background-color: #707070;
      border: 1px solid #707070;
      opacity: 0.4;
    }

    .content-wrapper {
      direction: initial;
      width: 85%;
      height: 65%;
      display: flex;
      gap: 20px;
      padding: 20px;

      .content-wrapper-col {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .wrapper-obj {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          .icon {
            height: 20px;
            width: 20px;
          }

          .text {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          .icon {
            height: 20px;
            width: 20px;
          }

          .text {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .phone-wrapper {
          display: flex;
          justify-content: space-between;
          width: 90%;

          .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            .icon {
              height: 20px;
              width: 20px;
            }

            .text {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }

        .social-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 25%;
          justify-content: space-between;
          width: 100%;

          .text {
            font-size: 12px;
            font-weight: bold;
          }

          .social-icons {
            width: 60%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon-obj {
              cursor: pointer;
              height: 20px;
              width: 20px;
            }
          }
        }

        .delegation-wrapper {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          .icon {
            height: 20px;
            width: 40px;
            color: #fecb2d;
          }

          .text {
            font-size: 12px;
            font-weight: bold;
          }

          .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            .details {
              display: flex;
              flex-direction: column;
              gap: 5px;

              .text-header {
                font-size: 16px;
                color: #cca435;
                text-decoration: underline;
              }
            }

            .icon {
              height: 20px;
              width: 40px;
            }

            .text {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 776px) {
  .popup-wrapper {
    width: unset;
    height: unset;
    .contact-details-wrapper {
      .header-wrapper {
        flex-direction: column;
      }
      .content-wrapper {
        width: 100%;
        flex-direction: column;
        .content-wrapper-col {
          gap: 15px;
        }
      }
    }
  }
}
