.expandable {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    .expand-icon {
      margin-left: auto;
      color: black;
    }
  }

  &__content {
    overflow: hidden;
  }
}

.spa-expandable {
  height: 1px;
  width: 100%;
  background-color: #d3dde8;
}
