.comments-list-main {
  height: 100%;
  display: flex;
  flex-direction: column;

  .table-container-wrapper {
    .table-container {
      width: 100%;
      box-shadow: none;
      min-width: 800px;

      .white {
        background-color: white !important;
      }

      tr.mat-header-row {
        height: 56px;
        display: flex;
        width: 100%;
        align-items: center;
      }

      .mat-row .mat-cell {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        //width: 11%;
        text-align: initial;
        font: normal normal medium 12px/36px Roboto;
        letter-spacing: 0.05px;
        color: #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        .user-name {
          display: flex;
          align-items: center;

          .avatar {
            width: 2rem;
          }
        }
      }

      td.mat-column-DateAdded {
        text-align: initial;
        align-content: center;
        //width: 19.5% !important;
      }

      td.mat-column-UserName {
        text-align: initial;
        align-content: center;
        //width: 25% !important;
      }

      td.mat-column-Comment {
        text-align: initial;
        align-content: center;
        //width: 25% !important;
        margin-right: 5%;
        cursor: pointer;
      }

      td.mat-column-DeleteNote {
        text-align: initial;
        align-content: center;
        //width: 12% !important;
        padding-left: 1%;
      }

      th.mat-header-cell:first-of-type,
      td.mat-cell:first-of-type,
      td.mat-footer-cell:first-of-type {
        padding-left: 8px;
        width: 20%;
        text-align: initial;
        font: normal normal medium 12px/14px $font-site;
        letter-spacing: 0px;
        color: #809fb8;
      }

      td.mat-cell:last-of-type,
      th.mat-header-cell:last-of-type,
      td.mat-footer-cell:last-of-type {
        cursor: pointer;
        text-align: center;
        text-decoration: underline;
        font: normal normal medium 12px/36px $font-site;
        letter-spacing: 0.05px;
        color: #809fb8;
        display: flex;
        justify-content: flex-end;
        //width: 1%;
      }

      .mat-row:nth-child(odd) {
        display: flex;
        background-color: #f0f3fa;
      }

      .mat-row:nth-child(even) {
        display: flex;
      }

      th.mat-header-cell,
      td.mat-cell,
      td.mat-footer-cell {
        padding: 0;
        border-bottom-width: 1px;
        border-bottom-style: none;
        width: 20%;
      }
    }
  }

  .bottom-wrapper {
    width: 100%;
    display: flex;
    padding-top: 5%;

    .spacer {
      flex: 1 1 auto;
    }

    .write-comment-button {
      cursor: pointer;
      background: #fecb2e 0% 0% no-repeat padding-box;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 12px;
      width: 150px;
      justify-content: center;

      .icon {
        text-align: center;
        //font: normal normal 900 28px/34px ProximaNova;
        font-family: $font-site;
        font-size: $font-medium;
        letter-spacing: 0px;
        color: #0a287c;
      }

      .button-header {
        text-align: center;
        //font: normal normal bold 14px/17px ProximaNova;
        font-family: $font-site;
        font-size: $font-medium;
        letter-spacing: 0px;
        color: #0a287c;
      }
    }
  }
}

.disabled-write-comment-button {
  pointer-events: none;
  cursor: none;
  opacity: 0.6;
  background: #7d7d7d;
}

.mat-tooltip-show {
  height: max-content !important;
  width: fit-content !important;
  line-break: auto !important;
  background: grey 0 !important;
  font-size: 12px;
}
