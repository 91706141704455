.funds-search {
  height: calc(100vh - 96px);
  width: 100%;
  display: flex;
  flex-direction: row;
  // filters sidebar

  .filters-sidebar {
    height: calc(100vh - 96px);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow-y: auto;
    width: 25%;

    &__header {
      width: 100%;
      height: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-left: -3%;
      margin-top: 2%;
      .icon-btn {
        background-color: #f1f4fa;
        box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        width: 30px;
        height: 30px;
        &:hover {
          background-color: #fecb2e;
        }
      }
      .expand-icon {
        //cursor: pointer;
        color: black;
        margin-top: -12px;
        //margin: 15px 5px;
      }
    }

    &__content {
      min-width: 250px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
      overflow-y: scroll;

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .title {
          color: #06152b;
          font-size: 16px;
        }

        .reset-filters {
          cursor: pointer;
          color: #809fb8;
          font-size: 14px;
          text-decoration: underline;
        }
      }

      .divider {
        height: 1px;
        width: 100%;
        background-color: #d3dde8;
      }

      .filters {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: scroll;
        overflow-x: hidden;
        flex: 1;
        padding-inline-end: 1vw;

        .filter {
          gap: 10px;
          display: flex;
          flex-direction: column;
          width: 100%;

          .expandable {
            &__header {
              .title {
                font-size: 14px;
                font-weight: bold;
              }

              .expand-icon {
                color: black;
              }
            }

            &__content {
              .filter-option {
                cursor: pointer;
                padding: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;

                input[type='checkbox'] {
                  @include checkbox(16px);
                }

                .text {
                  color: #44444f;
                  font-size: 14px;
                }

                .count {
                  color: #44444f;
                  font-size: 12px;
                }
              }

              .filter-options {
                cursor: pointer;
                padding-inline: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                padding-block: 5px;

                input[type='checkbox'] {
                  @include checkbox(16px);
                }

                .text {
                  color: #44444f;
                  font-size: 14px;
                }

                .count {
                  color: #44444f;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .filters-parent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        flex: 1;
        padding-inline-end: 1vw;

        .filter {
          gap: 10px;
          display: flex;
          flex-direction: column;
          width: 100%;

          .expandable {
            &__header {
              .title {
                font-size: 14px;
                font-weight: bold;
              }

              .expand-icon {
                color: black;
              }
            }

            &__content {
              .filter-option {
                cursor: pointer;
                padding: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;

                input[type='checkbox'] {
                  @include checkbox(16px);
                }

                .text {
                  color: #44444f;
                  font-size: 14px;
                }

                .count {
                  color: #44444f;
                  font-size: 12px;
                }
              }

              .filter-options {
                cursor: pointer;
                padding-inline: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                padding-block: 5px;

                input[type='checkbox'] {
                  @include checkbox(16px);
                }

                .text {
                  color: #44444f;
                  font-size: 14px;
                }

                .count {
                  color: #44444f;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      ////// scroll
      ::-webkit-scrollbar {
        width: 0.1vw;
        margin-left: 1vw;
      }

      ::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        //box-shadow: inset 0 0 5px grey;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        background: #0a287c 0 0 no-repeat padding-box;
        border-radius: 4px;
      }
    }
  }

  &__content {
    height: calc(100vh - 96px);
    flex: 1;
    display: flex;
    flex-direction: column;
    // toolbar
    .toolbar {
      height: 50px;
      max-height: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 10px;
      gap: 15px;
      background-color: white;
      top: 0;
      z-index: 1;
      box-shadow: 15px 0px 15px rgb(10 40 124 / 20%);

      .title {
        text-align: start;
        font-size: 18px;
        color: #171725;
        display: flex;
        align-items: center;
      }

      .active-automatic-search-button {
        padding: 5px 15px;
        background: #fecb2e 0 0 no-repeat padding-box;
        border-radius: 8px;
        color: #0a287c;
        text-align: center;
        letter-spacing: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: $font-medium;
      }

      .save-search-button {
        padding: 5px 15px;
        background: #b98f0f 0 0 no-repeat padding-box;
        border-radius: 8px;
        color: #0a287c;
        text-align: center;
        letter-spacing: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: $font-medium;
      }

      .alignment-toggle {
        .button-group {
          border-radius: 8px;
          border: none;

          mat-button-toggle {
            background-color: #ffffff;
          }

          .mat-button-toggle {
            border: none;
          }

          .mat-button-toggle-disabled {
            background-color: #e1dde5;
            color: #90abc1;
          }

          .mat-button-toggle-checked {
            background-color: #0a287c;
            color: #ffffff;
          }

          .mat-button-toggle-label-content {
            padding: 6px;
            line-height: 24px;
          }
        }
      }

      .sorting {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .title {
          font-size: $font-medium;
          color: #92929d;
        }

        .sorting-button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .value {
            font-size: $font-medium;
            color: black;
          }

          .icon {
          }
        }
      }
    }

    // results
    .results {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 10px;
      padding: 10px 10px 10px 10px;
      overflow: auto;
      overflow-x: hidden;
      // scroll
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #0a287c 0 0 no-repeat padding-box;
        border-radius: 3px;
      }

      // funds list
      .funds-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__item {
        }
      }

      .load-more {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

        &__button {
          cursor: pointer;
          padding: 7px 15px;
          border: none;
          width: 16%;
          border-radius: 8px;
          border: 1px solid #3e96dc;
          color: #3e96dc;
          text-align: center;
          font-size: 14px;
        }

        &__loading {
          zoom: 0.5;
        }
      }

      .search-history-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;

        .search-history-header {
          text-align: left;
          font-size: 24px;
          letter-spacing: 0.08px;
          color: #171725;
        }

        .search-history-categories-wrapper {
          width: 97%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: auto;
          gap: 20px;

          .search-history-category-wrapper {
            width: 100%;
            gap: 20px;
            display: flex;
            flex-direction: column;

            .search-history-category-header {
              text-align: left;
              font-size: 20px;
              letter-spacing: 0.08px;
              color: #0a287c;
            }

            .table-container {
              width: 100%;
              text-align: left;
              letter-spacing: 0.08px;
              color: #0a287c;
              gap: 13px;
              display: flex;
              flex-direction: column;

              .table-body {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 98%;
                cursor: pointer;
                height: 40px;
                align-items: center;
                padding-inline: 19px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border-bottom: solid 1px #707070;

                &:hover {
                  box-shadow: 0px 10px 100px #8aa1cb26;
                }

                .date-td {
                  width: 18%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .date {
                    text-align: initial;
                    letter-spacing: 0.06px;
                    color: #000000;
                    font-size: 12px;
                    font-weight: bold;
                  }
                }

                .result-td {
                  width: 10%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .sap {
                    border: solid 0.1px;
                    background: gray;
                    height: 10px;
                    margin-inline: 10%;
                    align-items: center;
                  }

                  .result {
                    text-align: initial;
                    font-size: 12px;
                    letter-spacing: 0.06px;
                    color: #809fb8;
                  }
                }

                .filters-td {
                  width: 40%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  .sap {
                    border: solid 0.1px;
                    background: gray;
                    height: 10px;
                    margin-inline: 4%;
                    align-items: center;
                  }

                  .filters {
                    text-align: initial;
                    font-size: 12px;
                    letter-spacing: 0.06px;
                    color: #809fb8;
                  }
                }

                .open-search-td {
                  width: 18%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .sap {
                    border: solid 0.1px;
                    background: gray;
                    height: 10px;
                    margin-inline: 10%;
                    align-items: center;
                  }

                  .open-search {
                    text-align: initial;
                    text-decoration: underline;
                    font-size: 12px;
                    letter-spacing: 0.06px;
                    color: #0a287c;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 1;
}

.sorting-popover {
  width: 100%;
  background-color: white;
  border: 1px solid #92929d;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;

  .sorting-option {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;

    .text {
      color: black;
      font-size: 12px;
    }
  }

  .sorting-option:hover {
    background-color: #92929d;
  }
}

// for rtl styles
html[dir='ltr'] {
  .funds-search {
  }
}

html[dir='rtl'] {
  .funds-search {
    .toolbar {
      height: 50px;
      max-height: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 10px;
      gap: 15px;
      background-color: white;
      top: 0;
      z-index: 1;
      box-shadow: -15px 0px 15px rgb(10 40 124 / 20%);
    }
  }
}

// mobile
@media (max-width: 776px) {
  .funds-search {
    &__content {
      width: 100%;

      .results {
        .search-history-wrapper {
          .search-history-categories-wrapper {
            .search-history-category-wrapper {
              .search-history-category-header {
                text-align: center;
              }
              .table-container {
                .table-body {
                  padding-inline: 0px;
                  .open-search-td,
                  .filters-td,
                  .result-td,
                  .date-td {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
