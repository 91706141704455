.add-user-dialog-main-wrapper {
  width: 430px;
  min-height: 220px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 36px #00000014;
  border: 1px solid #d0d0d0;
  display: flex;

  .side-bar {
    width: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background: #fecb2e;
  }

  .main-content {
    direction: ltr;
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 95%;
    padding: 10px;

    .close-icon {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
    }

    .add-user-header {
      display: flex;
      align-items: center;
      width: 35%;
      justify-content: space-evenly;

      .header {
        font: normal normal bold 16px/36px OpenSans;
        letter-spacing: 0.07px;
        color: #171725;
      }
    }

    .form {
      display: contents;

      .content-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 10px;
        margin-top: 10px;

        .name-surname-wrapper {
          display: flex;
          align-items: center;
          padding-left: 17px;

          .input-wrapper {
            width: 100%;
          }

          input {
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 6px;
            border: 1px solid #707070;
            width: 83%;
            letter-spacing: -0.24px;
            padding: 8px;
            font: 12px/18px OpenSans;
            color: #5f5f5f;
          }
        }

        .header-error-wrapper {
          display: flex;

          .header-input {
            text-align: center;
            font: normal normal 600 13px/18p;
            font-family: OpenSans;
            letter-spacing: -0.26px;
            color: #000000;
            display: flex;
            justify-content: space-around;
            padding-left: 18px;
          }
        }

        .input-error {
          text-align: left;
          font: normal 12px/18px OpenSans;
          letter-spacing: -0.24px;
          color: red;
          padding-inline: 5%;
        }

        .input-role-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;

          .input {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 6px;
            width: 67%;
            border-right: none;
            margin-right: -5%;
            font: normal 12px/18px OpenSans;
            letter-spacing: -0.24px;
            color: #5f5f5f;
            padding: 8px;
          }

          .sorting-role {
            cursor: pointer;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 6px;
            width: 25%;
            display: flex;

            .sorting-button {
              display: flex;
              align-items: center;
              justify-content: space-around;
              width: 100%;
            }

            .value {
              text-align: center;
              font: normal 12px/18px OpenSans;
              letter-spacing: -0.24px;
              color: #5f5f5f;
            }

            .icon {
              color: #000000;
            }
          }
        }

        .add-user-button-wrapper {
          width: 96%;
          display: flex;
          justify-content: flex-end;
          margin-top: 5%;
          height: 30px;

          .add-user-button {
            cursor: pointer;
            background: #fecb2e 0% 0% no-repeat padding-box;
            border: 1px solid #fecb2e;
            border-radius: 8px;
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;

            .button-header {
              text-align: center;
              font: normal normal bold 12px/18px OpenSans;
              letter-spacing: -0.24px;
              color: #0a287c;
            }

            &__disabled {
              background: #dfdfdf;
              border: 1px solid #dfdfdf;
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

// rtl
html[dir='rtl'] {
  .main-content {
    direction: rtl;
  }
}
