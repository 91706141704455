.earn-point {
  background: transparent linear-gradient(180deg, #ffffff 0%, #fff3ce 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  min-height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 15px;
  overflow: hidden;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    height: 70%;
    justify-content: space-between;

    .title-wrapper {
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .close-button-wrapper {
        display: flex;
        width: 8%;
        justify-content: flex-end;

        .close-icon {
          background-color: transparent;
          cursor: pointer;
        }
      }

      .title {
        text-align: left;
        font: normal normal bold 23px/60px Helvetica Neue;
        letter-spacing: 0px;
        color: #212121;
        width: 100%;
      }
    }

    .text-img-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 100%;
      align-items: center;

      .image {
        width: 160px;
        height: 160px;
      }

      .text-coin-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        justify-content: space-around;

        .text {
          text-align: left;
          font: normal normal normal 13px/17px Helvetica Neue;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }

        .coin-title-section {
          display: flex;
          align-items: center;
          gap: 5px;

          .coin-image {
            width: 24px;
            height: 24px;
          }

          .coins {
            font-size: 24px;
            margin: 0;
            font-family: 'Helvetica Neue', sans-serif;
          }
        }
      }
    }

    .row-buttons {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      width: 80%;

      .button {
        font-size: 13px;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
        font-family: 'Helvetica Neue', sans-serif;
        cursor: pointer;
      }

      .report {
        color: white;
        background: #0a287c 0% 0% no-repeat padding-box;
      }

      .prizes {
        color: #0a287c;
        background: #fecb2e 0% 0% no-repeat padding-box;
      }
    }
  }

  .dialog .mat-dialog-container {
    padding: 0;
  }

  div.vertical-line {
    height: 100px;
    width: 1px;
    background-color: #0a287c;
    opacity: 0.43;
    margin-bottom: -10px;
  }

  div.horizontal-line {
    width: 110%;
    height: 1px;
    background-color: #0a287c;
    opacity: 0.43;
    margin-top: auto;
    margin-inline: -15px;
  }

  .coins-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .coin-image {
      width: 40px;
      height: 40px;
    }

    .earn-coins-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .text-centered {
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-family: 'Helvetica Neue', sans-serif;
      color: #0a287c;
    }

    .used-coins-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .coins-text {
      font-size: 15px;
      font-family: 'Helvetica Neue', sans-serif;
      color: #0a287c;
      font-weight: bold;
    }

    .column-section {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
