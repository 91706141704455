// desktop
.reset-password-dialog {
  background-color: white;
  max-width: 280px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: normal normal normal 13px OpenSans;
  padding: 30px 100px;

  &__form {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      color: #000000;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }

    .input-form-control {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .label {
        font-size: 14px;
        font-weight: bold;
      }

      .input-box {
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        border: 1px solid #c3c3c3;
        border-radius: 8px;

        .input {
          flex: 1;
          color: #00000059;

          &::placeholder {
            color: #9a9a9a;
          }
        }

        .visibility-icon {
          font-size: 14px;
          display: flex;
          cursor: pointer;
          color: #efefef;
          align-items: center;
          height: 14px;
          width: 14px;
        }
      }

      .input-error {
        color: red;
        font-size: 12px;
        text-align: start;
      }
    }

    .reset-password-button {
      padding: 10px;
      cursor: pointer;
      border-radius: 8px;
      text-align: center;
      color: #0a287c;
      background-color: #fecb2f;
      border: 3px solid #fecb2f;
      box-shadow: -1px 1px 5px #00000027;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 14px;
      font-weight: bold;
    }

    .loading {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;

      .loader {
        height: 24px;
        width: 24px;
        zoom: 0.4;
      }
    }
  }
}

.reset-password-button-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
