.profile-settings {
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 150px 40px 20px;
  gap: 30px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .title {
      font-size: 18px;
      color: #171725;
    }

    .subtitle {
      font-size: 18px;
      color: #0a287c;
    }
  }

  &__organization-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      .form-control {
        width: 45%;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #d7d7d7;
    visibility: visible;
    margin-block: 30px;
  }

  &__user-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      .form-control {
        width: 50%;
      }

      .user-permission {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          text-align: start;
          font-size: 16px;
          line-height: 16px;
          color: #636363;
        }

        &__select-box {
          cursor: pointer;
          padding: 10px 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #d7d7d7;

          .text {
            font-size: 14px;
            color: black;
          }

          .icon {
            font-size: 24px;
            display: flex;
            color: #d7d7d7;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .user-profile-picture {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .avatar {
          height: 48px;
          width: 48px;
          box-sizing: border-box;
          border: 2px solid white;
          border-radius: 50%;
        }

        .change-photo-button {
          cursor: pointer;
          padding: 5px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0a287c;
          color: #0a287c;
          border-radius: 20px;

          .text {
            font-size: 14px;
            color: #0a287c;
          }
        }
      }
    }
  }

  &__preferences-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      .form-control {
        width: 100%;
      }

      .user-permission {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          text-align: start;
          font-size: 16px;
          line-height: 16px;
          color: #636363;
        }

        &__select-box {
          cursor: pointer;
          padding: 10px 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #d7d7d7;

          .text {
            font-size: 14px;
            color: black;
          }

          .icon {
            font-size: 24px;
            display: flex;
            color: #d7d7d7;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .user-profile-picture {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .avatar {
          height: 48px;
          width: 48px;
          box-sizing: border-box;
          border: 2px solid white;
          border-radius: 50%;
        }

        .change-photo-button {
          padding: 5px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0a287c;
          color: #0a287c;
          border-radius: 20px;

          .text {
            font-size: 14px;
            color: #0a287c;
          }
        }
      }

      .funding-range-slider {
        padding: 10px;
        display: flex;
      }
    }
  }

  .preference {
    text-align: center;
    font: normal normal 300 20px/36px OpenSans;
    letter-spacing: 0.08px;
    color: #171725;
  }
}

.profile-settings-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0 0 0;

  .save-button {
    width: 100px;
    height: 40px;
    line-height: 17px;
    padding: 0 15px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    background: #0a287c 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
  }
}

// input
.profile-settings-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: start;
    font-size: 16px;
    line-height: 16px;
    color: #636363;
  }

  .input-box {
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #262460;
    color: #000000;
    font-size: 1rem;

    &:focus-within {
      // border: 1px solid #FECB2E;
    }

    &__input {
      width: 100%;
      height: 24px;
      padding: 0;
      font-size: 0.95rem;
    }
  }
}

// select
.profile-settings-select {
  width: 100%;

  .custom-select {
    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      padding: 10px 5px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.profile-settings-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }

  .select-option-wrapper {
    .select-option {
      padding: 10px 5px;
      background-color: white;
      font-size: 14px;

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #000000;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #000000;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}

// rtl
html[dir='rtl'] {
  .profile-settings {
    padding: 40px 20px 40px 150px;
  }

  .profile-settings-wrapper {
    .save-button {
      right: unset;
      left: 10px;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .profile-settings-wrapper {
    .save-button {
      bottom: 191px !important;
      z-index: 10000;
    }
  }
  .profile-settings {
    padding: 5px !important;
    &__organization-form,
    &__user-form {
      .form-row {
        align-items: center;
        flex-direction: column;
        .form-control {
          width: 75%;
        }
      }
    }
    &__preferences-form {
      padding-bottom: 70%;
      .form-row {
        flex-direction: column;
      }
    }
  }
}
