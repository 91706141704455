.create-organization {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  //background: #0A287C;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);

  &__card {
    width: 650px;
    padding: 80px 40px;
    max-height: calc(80vh - 60px);
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #707070;

    .sign-out {
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      color: #a7a7a7;
      font: normal normal bold 16px Lato;
      padding: 20px;

      .go-back {
        color: #707070;
        font-family: 'OpenSans', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        padding-left: 15px;
        position: relative;
      }

      .go-back::before,
      .go-back::after {
        content: '';
        display: block;
        left: 0px;
        position: absolute;
        top: 50%;
      }

      .go-back::before {
        border-color: #707070;
        border-style: solid;
        border-width: 0 0 2px 2px;
        height: 6px;
        margin-top: -4px;
        width: 6px;

        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .go-back::after {
        background: #707070;
        height: 2px;
        width: 18px;
        margin-top: -1px;
      }
    }

    .logo-box {
      top: 34px;
      left: 34px;
      width: 110px;
      height: 110px;
      border-radius: 8px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      border: 1px solid #7070702f;
      box-shadow: 0 13px 20px #00000014;

      .logo {
        width: 60px;
        height: auto;
      }
    }

    .organization-form {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .title {
        width: 100%;
        color: #1c2d57;
        font: bold 25px arial;
        text-align: start;
        margin-bottom: 20px;
      }

      .form-control {
        width: 100%;
      }

      .next-button {
        width: 100%;
        min-width: 200px;
        margin-top: 20px;
        padding: 10px 0;
        background: #fecb2e 0 0 no-repeat padding-box;
        box-shadow: 0 1px 3px #00000026;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        color: #0a287c;
        display: flex;
        justify-content: center;
        align-items: center;

        &__disabled {
          background: #dfdfdf;
        }
      }
    }
  }
}

// input
.create-organization-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: start;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: black;

    .error-dot {
      color: red;
    }
  }

  .input-box {
    cursor: pointer;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #00000059;
    color: #00000059;
    font-size: 14px;

    &__input {
      width: 100%;
      height: 24px;
      padding: 0;
    }
  }

  .input-error {
    color: red;
    font-size: 12px;
    line-height: 12px;
  }
}

// select
.create-organization-select {
  width: 100%;

  .select-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .select-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: start;
      font-size: 14px;
      line-height: 14px;
      color: black;

      .error-dot {
        color: red;
      }
    }

    .select-button {
      cursor: pointer;
      padding: 10px 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      border: 1px solid #00000059;
      font-size: 14px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #00000059;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .select-selected {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .selected-chip {
        padding: 2px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        background: #ecebfb;
        border-radius: 16px;

        .text {
          font-size: 12px;
          color: #3a36db;
        }

        .text:hover {
          color: #c6c4f5;
        }

        .close {
          display: none;
          cursor: pointer;
          height: 12px;
          width: 12px;
          font-size: 12px;
          color: #3a36db;
        }
      }

      .selected-chip:hover .close {
        display: block;
      }
    }

    .select-error {
      color: red;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.create-organization-select-panel {
  width: 100%;
  max-height: 250px;
  margin-top: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    display: flex;
    flex-direction: column;

    .select-option {
      cursor: pointer;
      padding: 10px 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
      font-size: 14px;

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: black;
          font-size: 14px;
        }
      }

      input[type='checkbox'] {
        @include checkbox(18px);
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #d9e1e7;
      color: #809fb8;
    }

    .select-option:hover {
      background-color: #d9e1e7;
      color: #809fb8;
    }

    .divider {
      width: 100%;
      height: 2px;
      background-color: #d9e1e7;
    }
  }
}

// desktop
.organization {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  //background: #0A287C;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);

  .container {
    width: clamp(650px, 50%, 100%);
    display: flex;
    position: relative;
    border-radius: 20px;
    justify-content: center;
    background-color: white;
    border: 1px solid #707070;

    .title-organization {
      /*height: 46px;
      width: 100%;
      text-align: left;
      color: #1C2D57;
      font: bold 25px arial;
      margin-top: 27px;
      padding-bottom: 50px;*/

      color: #1c2d57;
      font: bold 25px arial;
      padding-bottom: 45px;
    }

    .box-shadow {
      top: 0;
      left: 0;
      width: 110px;
      height: 110px;
      display: flex;
      margin: 34px 0 0 34px;
      position: absolute;
      border-radius: 3vh;
      align-items: center;
      justify-content: center;
      border: 1px solid #7070702f;
      box-shadow: 0 13px 20px #00000014;

      .corner-sunshine-icon {
        width: 60%;
      }
    }
  }

  .organization-form {
    z-index: 1;
    margin: 11% 0 8% 0;
    width: 50%;
  }

  &__form {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
      display: flex;
      gap: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__cotent-coponnent {
        width: 50%;
        padding-bottom: 12%;
      }

      .name-surname-wrapper {
        display: flex;

        .input-wrapper {
          .input-headline {
            margin-top: 12px;
            margin-left: 12px;
          }
        }
      }

      .input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .organization-select-input {
          border: 1px solid #e3e3e3;
          border-radius: 8px;
          margin-top: 8px;

          .fi {
            padding-left: 20px;
            height: 25px;
            width: 40px;
          }

          .country-placeholder {
            padding-left: 60px;
          }

          .country-flag-icon {
            margin-left: 10px;
            width: 35px;
          }

          .organization-arrow-down {
            padding-right: 10px;
            font-size: 30px;
            height: 30px;
            width: 30px;
          }
        }

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
          padding: 0;
        }

        .input_title {
          display: flex;
          gap: 10px;
          flex-direction: row;
          //flex-wrap: nowrap;
        }

        .input-headline {
          //width: 197px;
          //margin-left: 12px;
          font: bold 14px arial;
        }

        .organization-input-container {
          height: 42px;
          display: flex;
          margin: 8px 0 0 0;
          border-radius: 8px;
          align-items: center;
          padding: 0 15px 0 12px;
          border: 1px solid #e3e3e3;
          justify-content: flex-start;
          background: #ffffff 0 0 no-repeat padding-box;

          .icon {
            margin: auto 10px;
            color: #1667c7;
          }

          .visibility-icon {
            cursor: pointer;
          }

          .separator {
            height: 24px;
            width: 1px;
            background-color: #1667c7;
            margin-inline-end: 30px;
          }

          .input {
            font-size: 13px;
            width: 100%;
            background-color: transparent;
          }

          .select {
            color: #9a9a9a;
            font-size: 18px;
            width: 100%;
            background-color: transparent;
          }

          .input::placeholder {
            color: #9a9a9a;
          }
        }
      }

      .checkbox-wrapper {
        display: flex;
        margin-top: 2%;
        margin-bottom: 3%;
        width: 94%;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        .input-headline {
          margin-left: 12px;
        }

        .organization-input-container {
          width: 415px;
          height: 40px;
          border-radius: 8px;
          margin: 0 10px 10px 10px;
          //margin-bottom: 10px;
          padding: 0 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 2px solid #e3e3e3;
          background: #ffffff 0 0 no-repeat padding-box;

          .icon {
            margin: auto 10px;
            color: #1667c7;
          }

          .visibility-icon {
            cursor: pointer;
          }

          .separator {
            height: 24px;
            width: 1px;
            background-color: #1667c7;
            margin-inline-end: 30px;
          }

          .input {
            font-size: 18px;
            width: 100%;
            background-color: transparent;
          }

          .select {
            color: #9a9a9a;
            font-size: 18px;
            width: 100%;
            background-color: transparent;
          }

          .input::placeholder {
            color: #9a9a9a;
          }
        }
      }

      .organization-input-error-checkbox {
        color: red;
        font-size: 12px;
        width: 81%;
        margin: 9px 8px;
        display: flex;
        padding: 0 15px;
        align-items: center;
        justify-content: flex-end;
        margin-top: -2%;
      }

      .organization-input-error {
        color: red;
        font-size: 12px;
        line-height: 16px;
        //width: 350px;
        //margin: 5px auto;
        //padding: 0 15px;
      }

      .organization-loading {
        margin: 10px auto;
      }

      .login-loading {
        margin: 0px auto;
        margin-top: 35px;
        margin-bottom: 0px;
        padding-bottom: 2px;
      }

      .organization-button {
        cursor: pointer;
        height: 42px;
        border-radius: 8px;
        width: 100%;
        margin-top: 16px;
        font-weight: bold;
        font-size: 17px;
        color: #0a287c;
        background-color: #fecb31;
        //border: 3px solid #FECB2F;
        box-shadow: 0 1px 3px #00000026;
      }
    }
  }
}

app-organization {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 30px !important;
    height: 42px;
    display: flex;
    border-width: 0;
    align-items: center;
  }

  mat-form-field {
    width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: #00000059;
    //padding-left: 20px;
  }
}

mat-option .organization-country-selection-wrapper {
  display: flex;
  gap: 20px;
}

// mobile
@media (max-width: 776px) {
  .organization {
  }
}

// for rtl styles
html[dir='rtl'] {
  // desktop
  .organization {
    .container {
      .title-organization {
        //height: 46px;
        //width: 391px;
        //text-align: right;
        //margin-top: 27px;
        //padding-bottom: 50px;
      }

      .box-shadow {
        position: absolute;
        top: 0;
        margin: 34px 34px 0 0;
        right: 0;
      }
    }

    &__form {
      &__content {
        .input-wrapper {
          .organization-select-input {
            .country-placeholder {
              padding-left: 0;
              padding-right: 60px;
            }

            .country-flag-icon {
              margin-left: 0;
              padding-right: 0;
              margin-right: 10px;
              padding-left: 10px;
            }

            .organization-arrow-down {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

// mobile
@media (max-width: 776px) {
  .create-organization {
    &__card {
      width: unset;
      margin: 10px;
      .logo-box {
        position: unset;
        width: 50px;
        padding: 10px;
        height: 40px;
        .logo {
          width: 35px;
        }
      }
      .organization-form {
        width: unset;
      }
    }
  }
}
