.bdc-walk-dialog {
  background-color: white;
  border-radius: 14px;
}

/*div.mat-menu-panel.bdc-walk-popup.mat-menu-above {
  box-shadow: 0 0 15px #0006;
  border: solid 1px #0006;
}
*/
.mat-form-field {
  text-align: right;
  display: flex;
  flex-direction: row;
}

.mat-form-field-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  right: unset;
}

/*.writing-form {
  background-color: red;
}

.writing-form {
  background-color: blue;
}*/

.writing-title {
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-bottom: 3px solid #eee;
  box-shadow: #00000015 0 0 20px;

  .title {
    font-size: 24px;
    font-weight: 700;
    color: #262460;
    font-family: system-ui;
  }

  .subtitle {
    font-size: 16px;
    color: #0a287c;
    font-family: 'system-ui';
  }
}

.writing-wrapper {
  display: flex;
  flex-direction: row;
}

.writing-settings-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.eng-field {
  direction: ltr;
}

.icon-info {
  height: 24px;
  vertical-align: bottom;
  cursor: pointer;
}

.tutorial-popup {
}
.tutorial-popup2 {
}
.writing-output {
  //min-height: 100vh;
  //overflow: scroll;
  display: flex;
  width: 55%;
  flex-direction: row;
  //justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 30px 30px 10px;
  gap: 10px;
  margin-bottom: 64px;
  min-height: 60vh;

  .input-label {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    text-align: start;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    color: #636363;
  }

  .paper {
    box-shadow: #00000008 0 10px 20px;
    width: 100%;
    height: 100%;
    padding: 4rem;
    border-radius: 16px;
    background-color: #f9f9f9;
    border: 2px solid #eee;
    direction: ltr;
  }
}

.response {
  margin-top: -2rem;
  text-align: justify;
}

.copy-button {
  text-align: right;
  position: relative;
  top: -45px;
  right: -45px;
}

.word-wrap {
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  margin: 0;
  font-family: 'OpenSans';
}

.writing-select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 30px 0px 30px;
  gap: 14px;

  mat-select {
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #262460;
    margin-top: 7px;
  }
  /* Dotted border */
  hr.dotted {
    border-top: 2px dotted #bbb;
    width: 100%;
  }
}

.writing-settings {
  //min-height: 100vh;
  //overflow: scroll;
  display: flex;
  //width: 40%;
  flex-direction: column;
  //justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 30px 30px 30px;
  gap: 18px;

  h4 {
    margin: 0;
  }

  &__header {
    .title {
      font-size: 32px;
      color: #171725;
    }

    .subtitle {
      font-size: 16px;
      color: #0a287c;
    }
  }

  &__organization-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      .form-control {
        width: 45%;
      }
    }
  }

  .writing-options {
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #d7d7d7;
    visibility: visible;
    margin-block: 30px;
  }

  &__user-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      .form-control {
        width: 50%;
      }

      .user-permission {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          text-align: start;
          font-size: 16px;
          line-height: 16px;
          color: #636363;
        }

        &__select-box {
          cursor: pointer;
          padding: 10px 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #d7d7d7;

          .text {
            font-size: 14px;
            color: black;
          }

          .icon {
            font-size: 24px;
            display: flex;
            color: #d7d7d7;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .user-profile-picture {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .avatar {
          height: 48px;
          width: 48px;
          box-sizing: border-box;
          border: 2px solid white;
          border-radius: 50%;
        }

        .change-photo-button {
          cursor: pointer;
          padding: 5px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0a287c;
          color: #0a287c;
          border-radius: 20px;

          .text {
            font-size: 14px;
            color: #0a287c;
          }
        }
      }
    }
  }

  &__preferences-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 50px;

      .form-control {
        width: 100%;
      }

      .user-permission {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          text-align: start;
          font-size: 16px;
          line-height: 16px;
          color: #636363;
        }

        &__select-box {
          cursor: pointer;
          padding: 10px 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #d7d7d7;

          .text {
            font-size: 14px;
            color: black;
          }

          .icon {
            font-size: 24px;
            display: flex;
            color: #d7d7d7;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .user-profile-picture {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .avatar {
          height: 48px;
          width: 48px;
          box-sizing: border-box;
          border: 2px solid white;
          border-radius: 50%;
        }

        .change-photo-button {
          padding: 5px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0a287c;
          color: #0a287c;
          border-radius: 20px;

          .text {
            font-size: 14px;
            color: #0a287c;
          }
        }
      }

      .funding-range-slider {
        padding: 10px;
        display: flex;
      }
    }
  }

  .preference {
    text-align: center;
    font: normal normal 300 20px/36px OpenSans;
    letter-spacing: 0.08px;
    color: #171725;
  }
}

// .profile-settings-wrapper {
//   height: 100vh;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//   padding: 20px 0 0 0;

//   .save-button {
//     width: 100px;
//     height: 40px;
//     line-height: 40px;
//     padding: 0 15px;
//     position: absolute;
//     right: 10px;
//     bottom: 10px;
//     cursor: pointer;
//     background: #0A287C 0% 0% no-repeat padding-box;
//     border-radius: 20px;
//     color: #FFFFFF;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 0.8em;
//   }
// }

.avatar {
  max-width: 8rem;
  max-height: 6rem;
  // margin-bottom: 0.6rem;
}

.form-control {
  width: 100%;
}
// input
// .profile-settings-input {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 15px;

//   .input-label {
//     display: flex;
//     width: 100%;
//     flex-direction: row;
//     justify-content: flex-start;
//     text-align: start;
//     font-size: 12px;
//     line-height: 12px;
//     font-weight: 700;
//     color: #636363;
//   }

//   .input-box {
//     padding: 10px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     background-color: white;
//     border-radius: 10px;
//     border: 1px solid #C1C1C1;
//     color: #000000;

//     &:focus-within {
//       border: 1px solid #FECB2E;
//     }

//     &__input {
//       width: 100%;
//       height: 16px;
//       padding: 0;
//       font-size: 13px;
//     }
//   }
// }

.goal-title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 5px 0;
}

.textarea-upper-radius {
  border-radius: 0.5rem 0.5rem 0 0;
}

.section-button {
  cursor: pointer;
  background: #dd5353 0% 0% no-repeat padding-box;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 100%;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
}

.file-upload-wrapper {
  margin-top: 5px;
}

.textarea-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > label {
    font-family: 'OpenSans', sans-serif;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: -0.26px;
    color: rgba(0, 0, 0, 0.3490196078);
  }

  > textarea {
    border-radius: 5px;
    min-height: 5rem;
    max-width: 100%;
    resize: vertical;
    color: #5f5f5f;
    font-size: 0.95rem;
    font-family: 'OpenSans', sans-serif;
    border: 1px solid #262460;
    color: rgba(0, 0, 0, 1.34902);
    padding: 5px 10px;
  }
}

.section-wrapper {
  border-radius: 0.5rem;
  border: 2px dotted #c1c1c1;
  padding: 10px;
  display: flex;
  flex-flow: column;
  gap: 10px;
  box-shadow: #0002 0px 6px 15px;
}

.section-title-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .delete-section-icon {
    color: tomato;
    cursor: pointer;
    font-size: 22px;
  }
}

.submit-button {
  cursor: pointer;
  background: #0a287c 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 24px;
}
// select
// .profile-settings-select {
//   width: 100%;

//   .custom-select {
//     .select-label {
//       font-size: 16px;
//       line-height: 16px;
//       color: #636363;
//     }

//     .select-button {
//       background-color: white;
//       border-radius: 10px;
//       border: 1px solid #C1C1C1;
//       font-size: 14px;
//       padding: 10px 5px;

//       .select-input {
//         width: 100%;
//         font-size: 14px;
//         padding: 0;
//       }

//       .country {
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         gap: 10px;

//         &__icon {
//           font-size: 14px;
//         }

//         &__text {
//           color: #000000;
//           font-size: 14px;
//         }
//       }

//       .icon {
//         color: #0A287C;
//       }
//     }

//     .selected-options {
//     }
//   }
// }

// .profile-settings-select-panel {
//   background-color: white;
//   border-radius: 10px;
//   border: 1px solid #C1C1C1;

//   .disabled {
//     pointer-events: none;
//     cursor: default;
//     opacity: 0.6;
//   }

//   .select-option-wrapper {

//     .select-option {
//       padding: 10px 5px;
//       background-color: white;
//       font-size: 14px;

//       .country {
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         gap: 10px;

//         &__icon {
//           font-size: 14px;
//         }

//         &__text {
//           color: #000000;
//           font-size: 14px;
//         }
//       }

//       .text {
//         font-size: 14px;
//       }
//     }

//     .select-option.focus {
//       background-color: #C1C1C1;
//       color: #000000;
//     }

//     .select-option:hover {
//       background-color: #C1C1C1;
//       color: #000000;
//     }

//     .divider {
//       width: 100%;
//       height: 1px;
//       background-color: #C1C1C1;
//     }
//   }
// }

// rtl
html[dir='rtl'] {
  // .profile-settings {
  //   padding: 40px 20px 40px 150px;
  // }

  // .profile-settings-wrapper {
  //   .save-button {
  //     right: unset;
  //     left: 10px;
  //   }
  // }
  .bdc-walk-dialog {
    background-color: white;
    border-radius: 14px;
  }
  /*mat-option {
      text-align: left !important;
      direction: ltr !important;
  }*/

  .copy-button {
    //text-align: left;
  }
}

// mobile
@media (max-width: 776px) {
  .tutorial-popup {
    margin-right: 50%;
  }

  .tutorial-popup2 {
    margin-right: unset;
  }

  .bdc-walk-dialog {
    padding: 15px;
    text-align: center;
  }
  .writing-wrapper {
    flex-direction: column;
    .writing-settings-wrapper {
      width: 100%;
    }
    .writing-output {
      width: auto;
    }
  }
}

p {
  word-wrap: break-word;
  word-break: keep-all; // Prevent words from breaking
  hyphens: none; // Disable hyphenation
}

.cke_content {
  width: 680px; // Ensure the editor width is 680px
}
