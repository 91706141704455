.main-plan-wrapper {
  width: 100%;
  height: 100%;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='checkbox'] {
    display: none;
  }

  .blur-wrapper {
    position: fixed;
    top: 10%;
    height: 80%;
    border-radius: 20px;
    z-index: 10000;
    background: white;
    opacity: 0.5;
    width: clamp(900px, 70%, 1200px);
  }

  .blur-wrapper-after {
    position: fixed;
    z-index: 100000;
    left: 44.5%;
    top: 43.5%;
    width: 12%;
    height: 14%;
    border-radius: 8%;
    background-color: white;

    .login-loading {
      position: fixed;
      left: 50%;
      top: 50%;
    }
  }

  .sub-plan-wrapper {
    height: 95%;
    display: flex;
    overflow: hidden;
    background: white;
    margin-top: -1%;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    width: clamp(900px, 70%, 1200px);

    .plans-container {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      .ellipse {
        position: absolute;
        width: 140%;
        right: -20%;
        height: 75%;
        top: 34%;
        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
        background: transparent linear-gradient(180deg, #d8e3fd 0, #ffffff 75%) 0 0 no-repeat padding-box;
      }

      .plan-wrapper {
        padding: 0rem 3rem 2rem 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        width: 90%;
        height: 100%;
        position: relative;

        .move-up {
          margin-top: -20px;
        }

        ul:first-child {
          padding-inline: 0;
        }

        ul {
          width: 100%;
          height: 95%;
          max-width: 280px;
        }

        .custom-ul {
          padding-inline-start: 0;
          height: 100%;
        }
      }

      .headline-wrapper {
        display: flex;
        bottom: 0;
        height: 40%;
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        .headline-wrapper-inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 85%;
          margin-top: 4%;

          .main-header {
            font-size: 26px;
            font-weight: 900;
            color: #0a287c;
          }

          .back-button {
            cursor: pointer;
            background: transparent;
            display: flex;
            align-items: center;
            text-align: center;
            font: normal normal normal 19px/21px Heebo;
            letter-spacing: 0;
            color: #636c84;
          }

          .right-div {
            width: 10%;
          }
        }

        .sub-header {
          text-align: center;
          font: normal normal normal 14px/21px Heebo;
          letter-spacing: 0;
          color: #636c84;
        }

        .sub-head-functions-wrapper {
          display: flex;
          align-items: center;
          width: 90%;
          margin-top: 20px; //3v;

          .sep {
            flex: 1 1 auto;
          }

          .slider-wrapper {
            display: flex;
            align-items: center;
            padding-left: calc(46% - 103px);
            margin-right: 25px; //3v;

            .bill-slider {
              direction: rtl;
              text-align: center;
              white-space: nowrap;
              font: normal normal normal 11px/41px Heebo;
              letter-spacing: -0.22px;
              color: #8a8c98;
            }

            .mat-slide-toggle-content {
              color: #8a8c98;
            }

            .mat-slide-toggle {
              color: #4c9dcb;

              .mat-slide-toggle-bar {
                height: 22px;
                border-radius: 30px;

                .mat-slide-toggle-thumb {
                  background: #fecb2e;
                }
              }
            }

            .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
              background-color: #fecb2e;
            }

            .mat-slide-toggle-thumb-container {
              color: #fecb2e;
            }

            .mat-slide-toggle .mat-slide-toggle-bar {
              height: 36px !important;
              width: 80px !important;
              background: #ffffff 0 0 no-repeat padding-box;
              box-shadow: 0 12px 26px #c5bfd633;
              border: 1px solid #bfbfbf;
              border-radius: 20px;
            }

            .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
              background-color: #fecb2e;
            }

            .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
              display: none;
            }
          }

          .save-header-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            margin-right: 15vh;

            .save-header {
              text-align: center;
              white-space: nowrap;
              font: normal normal normal 12px/20px Heebo;
              letter-spacing: -0.24px;
              color: #fecb2e;
            }

            .underline {
              width: 56px;
              height: 0;
              border-radius: 100% 85% 0 0;
              border: 4px none #fecb2e;
              border-top-style: solid;
            }
          }

          .currency-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }

      .logo-wrapper {
        margin-bottom: 2%;
        z-index: 1;
        display: flex;
        justify-content: center;

        .logo {
        }
      }
    }
  }
}

app-plan {
  & .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(36px, 0, 0) !important;
  }

  &
    .main-plan-wrapper
    .sub-plan-wrapper
    .plans-container
    .headline-wrapper
    .sub-head-functions-wrapper
    .slider-wrapper
    .mat-slide-toggle-thumb-container {
    color: #fecb2e;
    margin: 6px !important;
    height: fit-content !important;
    width: fit-content !important;
  }

  .mat-slide-toggle-thumb {
    width: 32px;
    height: 30px;
    display: block;
    border-radius: 50%;
    box-shadow: none;
  }
}

html[dir='rtl'] {
  .main-plan-wrapper {
    .sub-plan-wrapper {
      .plans-container {
        .headline-wrapper {
          .sub-head-functions-wrapper {
            .save-header-wrapper {
              margin-right: 0;
              margin-left: 15vh;
            }

            .slider-wrapper {
              padding-left: 0;
              padding-right: calc(50% - 117px);
              margin-right: 0;
              margin-left: 25px; //3v;
            }
          }
        }
      }
    }
  }

  app-plan {
    & .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
      transform: translate3d(-36px, 0, 0) !important;
    }

    .mat-slide-toggle-label {
      flex-direction: row-reverse;
    }

    .mat-slide-toggle-bar {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

@media (max-width: 776px) {
  .main-plan-wrapper {
    .sub-plan-wrapper {
      margin: 10px;
      .plans-container {
        .logo-wrapper {
          margin-bottom: 10%;
        }
        .headline-wrapper {
          .sub-head-functions-wrapper {
            flex-direction: column;
            .save-header-wrapper {
              margin: 0 !important;
            }
            .slider-wrapper {
              padding: 0 !important;
            }
          }
          .headline-wrapper-inline {
            width: unset;
            display: unset;
          }
        }
        .plan-wrapper {
          padding: 0rem 0rem 0rem 1rem;
          padding: 0rem 1rem 2rem 1rem;
          width: unset;
        }
      }
    }
  }
}
