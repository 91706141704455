.people-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  .people-main-header {
    text-align: initial;
    font: normal normal 600 18px/36px $font-site;
    letter-spacing: 0.07px;
    color: #171725;
  }

  .people-main-table {
    width: 100%;
    position: relative;
    min-height: 200px;
    max-height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .table-container {
      width: 100%;
      // box-shadow: none;
      min-width: 800px;

      // .white {
      //   background-color: white !important;
      // }

      // .mat-column-Full-Name.mat-cell {
      //   text-align: initial;
      //   font: normal normal medium 12px/14px Roboto;
      //   letter-spacing: 0px;
      //   color: #212128;
      //   padding-left: 2px !important;
      // }

      // .mat-column-Link.mat-cell {
      //   text-align: initial;
      //   text-decoration: underline;
      //   font: normal normal medium 12px/14px Roboto;
      //   letter-spacing: 0px;
      //   color: #4B9DCC;
      //   cursor: pointer;
      // }

      // .mat-row .mat-cell {
      //   width: 0%;
      //   padding-top: 30px;
      //   padding-right: 30px;
      //   padding-bottom: 30px;
      // }

      // th.mat-header-cell:first-of-type,
      // td.mat-cell:first-of-type,
      // td.mat-footer-cell:first-of-type {

      // }

      // th.mat-header-cell {
      //   border: none;
      //   font-size: 15px;
      //   z-index: 0 !important;
      // }

      // tr.mat-row, tr.mat-footer-row {
      //   height: 70px;
      // }
    }
  }

  .divider {
    height: 1px;
    background-color: #dddddd;
    width: 100%;
  }
}

// // rtl
// html[dir=rtl] {
//   .people-main {
//     .people-main-table {
//       .table-container {
//         .mat-row .mat-cell {
//           width: 0%;
//         }

//         th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
//           padding: 0;
//           border-bottom-width: 1px;
//           border-bottom-style: solid;
//         }

//         mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
//           border-bottom-color: rgba(0, 0, 0, 0.12);
//           padding-right: unset;
//           padding-bottom: 30px;
//           padding-top: 30px;
//           padding-left: 30px;
//         }

//         th.mat-header-cell:first-of-type,
//         td.mat-cell:first-of-type,
//         td.mat-footer-cell:first-of-type {
//           padding-left: unset;
//           padding-right: 8px;
//           border-top: none;
//         }

//         th.mat-header-cell:last-of-type,
//         td.mat-cell:last-of-type,
//         td.mat-footer-cell:last-of-type {
//           padding-left: unset;
//           padding-right: 24px;
//         }

//       }
//     }
//   }
// }
