.calendar-board-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .calendar-board-main-wrapper {
    width: 95%;
    height: 95%;

    .calendar-board-row-one {
      width: 100%;
      height: 22%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .row-one-date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 35%;

        .row-one-date-button-wrapper {
          border: solid 1px #d9e1e7;
          background: #fff;
          border-radius: 5px;
        }
      }
    }
    .calendar-board-row-two {
      width: 100%;
      height: 78%;
    }
  }
}
