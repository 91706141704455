.visitors-signup-main {
  max-height: 700px;
  min-height: 32rem;
  background: white;
  max-width: 800px;
  min-width: 800px;
  width: 100%;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .visitors-signup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden scroll;
    width: 100%;

    .title {
      margin: 10px auto;
      font-size: 32px;
      font-weight: bold;
      color: #2e4765;
    }

    .bottom-wrapper {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      margin: auto auto;
      min-height: 50px;

      .atlas-line {
        padding-right: 4%;
        color: #b1b1b1;
      }
    }

    .signup {
      &__welcome {
        height: 100%;
        max-height: 100%;
        width: 60%;
        position: relative;
        font-size: 32px;
        display: flex;
        flex-wrap: wrap;

        .theme-picture {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: transparent linear-gradient(180deg, #0a287c 0, #3a36db 100%);
          opacity: 1;
          display: flex;
        }

        &__content {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .welcome-image {
            height: 190px;
            width: 190px;
            margin-left: 50px;
            margin-top: 50px;
            position: relative;
            display: flex;
          }

          .welcome-text-headline {
            text-align: left;
            font: normal normal bold 67px/79px OpenSans;
            letter-spacing: 1.05px;
            color: #ffffff;
            opacity: 1;
            width: 40%;
            display: flex;
            position: relative;
            margin-left: 160px;
            margin-top: -10px;
          }

          .welcome-text-sub-headline {
            //text-align: left;
            font: normal normal 500 33px/39px OpenSans;
            letter-spacing: 0.52px;
            color: white;
            opacity: 1;
            display: flex;
            width: 40%;
            position: relative;
            margin-top: 20px;
            margin-left: 160px;
          }
        }
      }

      &__comp-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      }

      &__form {
        height: 100%;
        width: 100%;
        display: flex;
        max-width: 100%;
        justify-content: space-around;
        z-index: 3;
        overflow-y: scroll;

        // scroll
        &::-webkit-scrollbar {
          cursor: pointer;
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          cursor: pointer;
          background-color: #e4e4e4;
          border-radius: 20px;
          height: 80%;
        }

        &::-webkit-scrollbar-thumb {
          cursor: pointer;
          background: #0a287c 0 0 no-repeat padding-box;
          border-radius: 20px;
        }

        .logo {
          height: 12%;
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .login-loading {
            margin: 13.5px auto;
            padding-bottom: 0px;
          }

          .google-button {
            display: flex;
            gap: 10px;
            flex-direction: row;
            //flex-wrap: wrap;
            align-items: center;

            .logo {
              height: 4vh;
              width: auto;
              margin: 10px auto;
              //padding-bottom: 5%;
            }

            .text {
            }
          }

          .join-button-wrapper {
            height: 42px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 2%; // 10%;
          }

          .sep-wrapper {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 5%; // 10%;

            .divider {
              width: 100%;
              height: 1px;
              background-color: #0000001a;
              position: absolute;
              top: 50%;
            }

            .sep-content {
              font-size: 15px;
              font-weight: 300;
              background-color: white;
              padding-inline: 8px;
              text-align: center;
              display: flex;
              z-index: 2;
            }
          }

          .join-button {
            width: 100%;
            display: flex;
            cursor: pointer;
            line-height: 14px;
            border-radius: 5px;
            text-align: center;
            //font-weight: bold;
            color: #090e3a;
            font-size: 14px;
            background-color: #f5f5f5;
            border: 1px solid #c9c9c9;
            text-decoration: solid;
            box-shadow: -1px 1px 5px #00000027;
            align-items: center;
            justify-content: space-evenly;
          }
        }

        &__cotent-coponnent {
          width: 50%;
          padding-bottom: 12%;
        }

        .mat-checkbox {
          padding-right: 4%;

          &.mat-checkbox-checked {
            .mat-checkbox-background {
              background-color: #4c9dcb !important;
            }
          }

          .mat-ripple-element {
            background-color: #4c9dcb !important;
          }
        }

        .approval-block {
          overflow: hidden;
          background: #f0faff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 20px #0000001a;
          border-radius: 30px;
          display: flex;
          align-items: center;
          flex-direction: column;
          height: 430px;
          width: 700px;

          .titles-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin-top: 5%;

            .approval-title {
              font-size: 35px;
              font-weight: bold;
              color: #2e4765;
              padding-bottom: 28px;
            }

            .approval-title-bold {
              font-size: 40px;
              font-weight: bold;
              color: black;
              padding-bottom: 28px;
            }

            .approval-sub-title {
              font-size: 20px;
              font-weight: bold;
              color: #2e4765;
              padding-bottom: 28px;
            }
          }
        }

        .radio-group {
          display: flex;
          flex-direction: column;

          .radio-button {
            border-radius: 20px;
            background-color: #f0faff;
            padding: 25px;
          }
        }

        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
          border-color: #4c9dcb;
        }

        .mat-radio-button.mat-accent .mat-radio-inner-circle {
          color: #4c9dcb;
          background-color: #4c9dcb;
        }

        .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
          background-color: transparent;
        }

        .signup-form {
          width: 80%;
          max-width: 80%;

          strong {
            color: red;
          }
        }

        .title {
          font-size: 32px;
          font-weight: 600;
          color: black;
          margin: 1.2rem;
          display: flex;
          align-items: center;
        }

        .span {
          display: flex;
          text-align: center;
          align-items: center;
          width: 100%;
        }

        .name-surname-wrapper {
          display: flex;
          gap: 20px; // test
          width: 100%;

          .input-wrapper {
            direction: ltr;
            width: 100%;
            //strong {
            //  color: red;
            //}

            .input-headline {
              margin-top: 12px;
              font-size: 13px;
              display: inline-block;
            }

            .signup-input-container-name-surname {
              font: 15px OpenSans;
              border-radius: 8px;
              height: 42px;
              box-sizing: border-box;
              padding-left: 8px;
              margin-top: 6px;
              display: flex;
              border: 1px solid #e3e3e3;

              .icon {
                margin: auto 10px;
                color: #1667c7;
              }

              .visibility-icon {
                cursor: pointer;
              }

              .input {
                font: 15px OpenSans;
                width: 100%;
                background-color: transparent;
                direction: ltr;
              }

              .select {
                color: #9a9a9a;
                font-size: 18px;
                width: 100%;
                background-color: transparent;
              }

              .input::placeholder {
                color: #9a9a9a;
              }
            }
          }
        }

        .phone-area-code-wrapper {
          display: flex;
          gap: 20px; // test
          width: 100%;

          .input-wrapper {
            direction: ltr;
            width: 100%;
            //strong {
            //  color: red;
            //}

            .input-headline {
              font-size: 13px;
              margin-top: 12px;
              display: inline-block;
            }

            .signup-input-container {
              .mat-form-field {
                display: flex;
                align-items: center;
                width: 100%;
              }

              .mat-form-field-appearance-standard .mat-form-field-flex {
                padding-top: 0;
              }

              .mat-form-field-wrapper {
                padding-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: initial;
              }

              .mat-select-arrow {
                border: none;
              }

              .mat-form-field-underline {
                display: none;
              }

              .mat-select-panel-wrap {
                width: 45%;
              }

              .mat-form-field-infix {
                padding: 0;
                //padding-top: calc(50% - 30px);
                width: 100%;
                border-top: 0;
                /* border-top: 0.84375em solid transparent; */
              }
            }
          }

          .shrink {
            width: 30%;
            max-width: 100px;
          }
        }

        .input-wrapper {
          direction: ltr;
          width: 100%;
          margin-bottom: 15px;
          //strong {
          //  color: red;
          //}

          .input-headline {
            width: 100%;
            font-size: 13px;
            display: inline-block;
          }

          .signup-input-container {
            font: 15px OpenSans;
            border-radius: 8px;
            height: 42px;
            box-sizing: border-box;
            padding-left: 8px;
            margin-top: 6px;
            display: flex;
            border: 1px solid #e3e3e3;

            .icon {
              margin: auto 10px;
              color: rgb(153, 152, 152);
              overflow: hidden;
            }

            .visibility-icon {
              cursor: pointer;
            }

            .separator {
              height: 24px;
              width: 1px;
              background-color: #1667c7;
              margin-inline-end: 30px;
            }

            .input {
              font: 15px OpenSans;
              width: 85%;
              background-color: transparent;
              direction: ltr;
            }

            .select {
              color: #9a9a9a;
              font-size: 18px;
              width: 100%;
              background-color: transparent;
            }

            .input::placeholder {
              color: #9a9a9a;
            }
          }
        }

        .checkbox-wrapper {
          display: flex;
          width: 100%;
          margin-top: 2%;
          margin-bottom: 3%;
          flex-direction: row;
          align-items: center;
          font: 15px/15px OpenSans;

          input[type='checkbox'] {
            width: 15px;
            height: 15px;
            accent-color: #0a287c;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 1px 3px #00000026;
          }

          .input-headline {
            margin: 8px 0 8px 12px;
            font: 13px OpenSans;
          }

          .signup-input-container {
            width: 415px;
            height: 40px;
            border-radius: 5px;
            margin: 0 10px;
            margin-bottom: 10px;
            padding: 0 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid #e3e3e3;
            background: #ffffff 0 0 no-repeat padding-box;

            .icon {
              margin: auto 10px;
              color: #efefef;
              overflow: hidden;
            }

            .visibility-icon {
              cursor: pointer;
            }

            .separator {
              height: 24px;
              width: 1px;
              background-color: #1667c7;
              margin-inline-end: 30px;
            }

            .input {
              font-size: 18px;
              width: 100%;
              background-color: transparent;
              direction: ltr;
            }

            .select {
              color: #9a9a9a;
              font-size: 18px;
              width: 100%;
              background-color: transparent;
            }

            .input::placeholder {
              color: #9a9a9a;
            }
          }
        }

        .signup-input-error-checkbox {
          color: red;
          font-size: 12px;
          width: 81%;
          margin: 9px 8px;
          display: flex;
          padding: 0 15px;
          align-items: center;
          justify-content: flex-end;
          margin-top: -2%;
        }

        .signup-input-error {
          color: red;
          font-size: 12px;
          width: 350px;
        }

        .signup-loading {
          margin: 10px auto;
        }

        .signup-button {
          width: 100%;
          height: 48px;
          display: flex;
          color: #0a287c;
          cursor: pointer;
          font: normal normal bold 16px/21px OpenSans;
          line-height: 24px;
          border-radius: 8px;
          text-align: center;
          align-items: center;
          background-color: #fecb2f;
          justify-content: space-evenly;
          box-shadow: -1px 1px 5px #00000027;
        }

        .dont-have-account-wrapper {
          cursor: pointer;
          width: 100%;
          text-align: center;
          font: normal normal normal 13px OpenSans;
          color: #0f57f5;
        }

        .lang-select-input {
          height: 20px;
          margin: 1rem;
          display: contents;

          mat-select {
            width: fit-content;
          }

          .fi {
            padding-left: 20px;
            height: 18px;
            width: 18px;
          }

          .country-placeholder {
            padding-left: 60px;
          }

          .country-flag-icon {
            margin-left: 10px;
            width: 35px;
          }

          .lang-arrow-down {
            display: none;
            padding-right: 10px;
            font-size: 20px;
            height: 20px;
            width: 20px;
          }
        }

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: initial;
        }
      }

      .spacer {
        flex: 1 1 auto;
      }
    }

    a:-webkit-any-link {
      color: #0266d6;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

app-visitors-signup {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 18px !important;
    // height: 42px;
    display: flex;
    border-width: 0;
    align-items: center;
    width: fit-content;
  }

  mat-form-field {
    // width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: black;
    //padding-left: 20px;
  }
}

// mobile
@media (max-width: 1450px) {
  .visitors-signup-main {
    max-height: 480px;
    min-height: 25rem;
    background: white;
    max-width: 800px;
    width: 100%;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// for rtl styles
html[dir='rtl'] {
  // desktop
  .visitors-signup-main {
    max-height: 570px;
    background: white;
    max-width: 800px;
    width: 100%;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .visitors-signup-wrapper {
      .signup {
        &__welcome {
          &__content {
            .welcome-image {
              margin-right: 50px;
              margin-left: 0;
              transform: scaleX(-1);
            }

            .welcome-text-headline {
              margin-right: 181px;
              margin-left: 123px;
              text-align: right;
            }

            .welcome-text-sub-headline {
              margin-right: 200px;
            }
          }
        }

        &__form {
          &__content {
            .input-wrapper {
              direction: rtl;

              .signup-input-container {
                .input {
                  padding-right: 8px;
                  padding-left: unset;
                  direction: rtl;
                }
              }
            }

            .spacer {
              flex: 1 1 auto;
            }

            .name-surname-wrapper {
              .input-wrapper {
                direction: rtl;

                .signup-input-container-name-surname {
                  .input {
                    padding-right: 8px;
                    direction: rtl;
                  }
                }
              }
            }

            .checkbox-wrapper {
              .input-headline {
                margin-left: 0;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  // mobile
  @media (max-width: 776px) {
  }
}
// mobile
@media (max-width: 776px) {
  .visitors-signup-main {
    min-width: unset;
    .visitors-signup-wrapper .signup__form {
      .phone-area-code-wrapper,
      .name-surname-wrapper {
        flex-direction: column;
      }
    }
  }
}
