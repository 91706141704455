.funding-experts-success-dialog {
  min-width: 500px;
  min-height: 400px;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 13px 36px rgb(0 0 0 / 8%);
  border: 1px solid #d0d0d0;
  background: transparent linear-gradient(180deg, #ffffff 0%, #ffefbc 100%) 0% 0% no-repeat padding-box;
  display: flex;
  border-radius: 0 10px 10px 0;
  border-left: 5px solid #fecb2e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .logo {
      height: 48px;
    }

    .avatars {
      margin: 5px 0;
      display: flex;

      .avatar {
        height: 48px;
        width: 48px;
        box-sizing: border-box;
        border: 2px solid #fecb2e;
        border-radius: 50%;
      }

      .avatar:not(:first-child) {
        margin-left: -10px;
      }
    }

    .message {
      text-align: center;
      color: #3b3b3b;
      font-size: 14px;
    }

    .resources-center-button {
      cursor: pointer;
      padding: 15px 20px;
      background-color: #fecb2e;
      color: #0a287c;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      .icon {
        height: 24px;
      }

      .text {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

// rtl
html[dir='rtl'] {
  .funding-experts-schedule-dialog {
  }
}
