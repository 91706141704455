//desktop
.main-sidenav {
  background: #f1f4fa;
  color: #8b8c98;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(103vh - 96px);
  align-items: flex-start;

  .automatic-search-section {
    display: flex;
    flex-flow: column wrap;
    align-content: center;
  }

  &__navigation-buttons {
    display: flex;
    padding: 12px 10px;
    height: 56%;
    width: 92%;
    flex-direction: column;

    .navigation-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: #262460;
      padding: 15px 10px;
      border-radius: 10px;
      margin: 2px 0;
      text-decoration: none;
      width: 90%;

      .title {
        margin: 0 10px;

        // font: normal normal 600 16px/22px OpenSans;
      }

      .icon {
        margin: 0 10px;
        filter: invert(58%) sepia(8%) saturate(400%) hue-rotate(194deg) brightness(94%) contrast(87%);
        height: 1.25rem;
        color: #262460;
      }

      .spacer {
        flex: 1 1 auto;
      }

      .new-funds {
        background: #fecb2e 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal 600 12px/12px OpenSans;
        font-family: 'OpenSans', sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 5px 10px;
        line-height: 12px;
      }

      &__active {
        background-color: #fecb2f;
        color: #262460;
        box-shadow: 0 4px 15px #00000014;
        font-weight: 700;

        .icon {
          margin: 0 10px;
          filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(3%) hue-rotate(197deg) brightness(95%)
            contrast(96%);
        }

        .new-funds {
          background: #262460 0% 0% no-repeat padding-box;
          border-radius: 25px;
        }
      }
    }

    .navigation-button:hover {
      background-color: #fecb2f;
      color: #262460;
      box-shadow: 0 4px 15px #00000014;
      font-weight: 700;

      .icon {
        margin: 0 10px;
        filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(3%) hue-rotate(197deg) brightness(95%)
          contrast(96%);
      }

      .new-funds {
        background: #262460 0% 0% no-repeat padding-box;
        border-radius: 25px;
      }
    }

    .sub-navigation-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #8a8c98;
      padding: 15px 10px;
      border-radius: 10px;
      //margin: 5px 0;
      text-decoration: none;

      .title {
        margin: 0 10px;
        font: normal normal 600 16px/22px OpenSans;
      }

      .count {
        background: #262460 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal 600 12px/12px OpenSans;
        font-family: 'OpenSans', sans-serif;
        letter-spacing: 0px;
        color: #0a287c;
        padding: 5px 10px;
        line-height: 12px;
      }

      &__active {
        color: #080808;

        .count {
          background: #262460 0% 0% no-repeat padding-box;
        }
      }
    }

    .sub-navigation-button:hover {
      color: #080808;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    // padding: 0px 10px;
    gap: 5px;
    //height: 32%;
    justify-content: space-between;
    // margin-bottom: 4%;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #e2e8f5;
      border-radius: 10px;
      padding: 25px 30px;
      overflow: hidden;
      gap: 10px;

      &__text-box {
        position: relative;

        .icon {
          transform: rotate(-10deg);
          height: 28px;
          width: 40px;
          position: absolute;
          top: -15px;
          left: -20px;
        }

        .text {
          color: black;
          width: 100px;
          font-size: 15px;
        }
      }

      &__image {
        height: 96px;
        width: auto;
        bottom: -32px;
        right: 12px;
        position: absolute;
      }

      .more-details-icon {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
        height: 12px;
      }

      .learn-more-button {
        cursor: pointer;
        background: #fecb2e;
        font-size: 12px;
        border-radius: 10px;
        text-align: center;
        padding: 5px 0.7rem;
        width: fit-content;
      }
    }

    .profile-options {
      //z-index: 10000;
      // min-width: 220px;
      display: flex;
      justify-content: center;
      // border-radius: 5px;
      // margin-bottom: 20px;
      background: white;
      // box-shadow: 0 5px 13px #16347729;
      // border: 2px solid #D9E1E7;
      padding: 5px 10px;
      gap: 10px;

      .avatar {
        box-sizing: border-box;
        width: 32px;
        border: 2px solid #fecb2e;
        border-radius: 50%;
        height: 2rem;
      }

      .text-box {
        font: normal normal normal 1em/1em Heebo;
        color: #8b8c98;
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          font-size: 24px;
        }

        .name {
          font-size: 14px;
        }
      }
    }
  }
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 1;
}

.profile-options-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px #16347729;
  border: 2px solid #d9e1e7;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: -5px;

  .menu-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;

    .text {
      color: #8a8c98;
    }

    .icon {
      color: #8a8c98;
    }
  }

  .logout-menu-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    font-size: 14px;
    align-items: center;

    .text {
      color: #8a8c98;
    }

    .icon {
      color: #8a8c98;
    }
  }

  .upgrade-notification {
    cursor: pointer;
    background-color: #faf0cd;
    display: flex;
    border-radius: 10px;
    margin: 12px 5px;

    .text {
      margin: 10px;
      color: #fecb2e;
    }

    .image {
      margin: 10px;
      //color: #FECB2E;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .main-sidenav {
  }
}

// for rtl styles
html[dir='rtl'] {
  .main-sidenav {
    &__footer {
      .banner {
        &__image {
          right: unset;
          left: 12px;
        }
      }
    }

    // mobile
    @media (max-width: 776px) {
      .main-sidenav {
        &__footer {
          .profile-options {
            min-width: 300px;
          }
        }
      }
    }
  }
}
