.document-dialog {
  min-width: 270px;
  max-width: 400px;
  max-height: 50vh;
  min-height: 290px;
  background-color: white;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #d0d0d0;
  border-left: 8px solid #fecb2e;
  box-shadow: 0 13px 36px #00000014;
  //position: relative;
  overflow: auto;
  //display: flex;
  flex-direction: column;

  img.close-icon {
    top: 16px;
    width: 18px;
    height: 18px;
    //position: sticky;
    cursor: pointer;
    //left: calc(100% - 16px - 18px);
    float: right;
    padding: 5px;
  }

  .document-container {
    padding: 10px;

    .header-title {
      font-family: OpenSans;
      font-size: 17px;
      display: flex;
      gap: 10px;
      color: #171725;
      align-items: center;
    }

    img.close-icon {
      top: 16px;
      width: 18px;
      height: 18px;
      position: sticky;
      cursor: pointer;
      left: 0px;
    }

    .documents-table {
      .columns-text {
        display: flex;
        flex-direction: row;
        //gap: 165px;
        justify-content: space-between;
        //font-size: 13px;
        font: normal normal 600 13px/13px OpenSans;
        opacity: 1;
      }

      .line {
        border: 1px solid #e9e9e9;
        width: 96%;
      }

      .open-documents {
        margin-bottom: 16px;
      }

      .action-icon {
        cursor: pointer;
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
      }

      .open-documents-text {
        text-align: center;
        font: normal normal 600 13px/13px OpenSans;
        letter-spacing: -0.26px;
        color: #809fb8;
        opacity: 1;
        //font-size: 13px;
        //font-weight: 900;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .documents-list {
        display: flex;
        //padding-right: 17px;
        padding-left: 2px;
        justify-content: space-between;
        align-items: center;

        .name-text {
          width: 200px;
          overflow: hidden;
          //font-size: 12px;
          font: normal normal 600 12px/32px OpenSans;
        }

        .icons {
          display: flex;

          .view-action {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }

          .delete-action {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
