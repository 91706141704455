.add-to-workplan-wrapper {
  padding: 20px 30px;
  background-color: white;
  height: 100%;
  box-shadow: 0 13px 36px #00000014;
  border: 1px solid #d0d0d0;
  border-radius: 0 10px 10px 0;
  border-left: 8px solid #fecb2e;
  position: relative;

  img.close-icon {
    top: 16px;
    width: 18px;
    height: 18px;
    position: absolute;
    cursor: pointer;
    left: calc(100% - 16px - 18px);
  }

  .add-to-workplan {
    padding: 30px 16px 0 16px;

    &__header {
      font-weight: 600;
      font-size: 16px;
      display: flex;
      gap: 10px;
      color: #171725;
      align-items: center;

      .header-icon {
        height: 32px;
      }
    }

    .disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    &__actions {
      display: flex;
      padding-top: 22px;
      flex-direction: column;
      gap: 18px;

      .form-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        min-width: 370px;

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
          padding: 0;
        }

        .chooser-btn {
          width: 100%;
          max-width: 150px;
          height: 32px;
          border-radius: 8px;
          font-size: $font-medium;
          font-weight: 600;
          cursor: pointer;
        }

        .create-new-btn {
          background-color: #0a287c;
          color: white;
        }

        .next-btn {
          background-color: #fecb2e;
          color: #0a287c;
        }
      }

      .flex-end {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

app-add-to-workplan {
  & .mat-form-field-underline {
    display: none;
  }

  & .mat-select-arrow-wrapper {
    display: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
    line-height: 20px !important;
    font-size: 13px;
    height: 30px;
    display: flex;
    border-width: 0;
    align-items: center;
  }

  mat-form-field {
    width: 100%;
    overflow: auto;
  }

  .mat-select-value-text {
    color: #5f5f5f;
    padding-left: 20px;
  }
}

// select
.add-to-workplan-select {
  width: 100%;

  .custom-select {
    gap: 0px;
    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      height: 20px;
      min-width: 150px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.add-to-workplan-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    .select-option {
      padding: 10px 5px;
      background-color: white;
      font-size: 14px;

      .workplan {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #000000;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #000000;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .add-to-workplan-wrapper {
    .add-to-workplan {
      &__actions {
        .form-row {
          min-width: unset;
          flex-direction: column;
          .chooser-btn {
            height: unset;
            min-height: 32px;
            max-width: unset;
          }
        }
      }
    }
  }
}
