.data-card {
  gap: 5px;
  max-width: 280px;
  display: flex;
  overflow-y: scroll;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  //height: clamp(100px, 20%, 150px);
  height: 100%;
  position: relative;

  background: #e9eef8;
  box-shadow: 0 10px 30px #0a287c1a;
  border: 1px solid #7070703c;
  border-left: 1px solid transparent;
  border-radius: 10px;

  .accent-border {
    height: 100%;
    width: 7px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .accent-ellipse-border {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    z-index: 2;
    opacity: 0.26;
    filter: blur(30px);
  }

  .card-title {
    font-size: 13px;
    font-family: $font-site;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #809fb8;
  }

  .card-data {
    font-size: 25px;
    font-weight: bold;
  }
}

html[dir='rtl'] {
  .data-card {
    padding-right: 15px;
    padding-left: 0;

    .accent-border {
      left: unset;
      right: 0;
    }

    //align-items: flex-end;
  }
}
