.areas-of-activities {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 70px 100px 30px 100px;

  .back-button {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 15px;
    left: 15px;
    height: 24px;
    width: auto;

    .text {
      font-size: 18px;
      color: black;
    }

    .icon {
      height: 24px;
      width: 24px;
      color: black;
    }
  }

  .close-button {
    cursor: pointer;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .step-title {
    padding: 15px;
    position: absolute;
    font-weight: 600;
    font-size: 18px;
    color: #3a36db;
    text-align: center;
    top: 0;
    right: 0;
    left: 0;
  }

  &__content {
    width: 350px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    gap: 10px;

    .title {
      color: #1c2d57;
      font-size: 24px;
    }

    .instructions {
      color: #636c84;
      font-size: 14px;
      text-align: center;
    }

    .activities-select {
      width: 100%;
      margin-bottom: 20px;
    }

    .next-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      background: #fecb2e 0 0 no-repeat padding-box;
      box-shadow: 0 1px 3px #00000026;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      color: #0a287c;
    }

    .skip-button {
      font-size: 14px;
      color: #a2a2a2;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// select
.areas-of-activities-select {
  width: 100%;

  .custom-select {
    .select-label {
      font-size: 16px;
      line-height: 16px;
      color: #636363;
    }

    .select-button {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      font-size: 14px;
      padding: 10px 5px;

      .select-input {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .icon {
        color: #0a287c;
      }
    }

    .selected-options {
    }
  }
}

.areas-of-activities-select-panel {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  .select-option-wrapper {
    .select-option {
      padding: 10px 5px;
      background-color: white;
      font-size: 14px;

      .country {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        &__icon {
          font-size: 14px;
        }

        &__text {
          color: #000000;
          font-size: 14px;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .select-option.focus {
      background-color: #c1c1c1;
      color: #000000;
    }

    .select-option:hover {
      background-color: #c1c1c1;
      color: #000000;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #c1c1c1;
    }
  }
}

// rtl
html[dir='rtl'] {
  .areas-of-activities {
  }
}

// mobile
@media (max-width: 776px) {
  .areas-of-activities {
    padding: 20% 0% 10% 0%;
    &__content {
      padding: 10px;
    }
  }
}
