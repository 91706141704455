.onboarding-base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/images/screen-background.png');

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(10, 40, 124, 0.63);
    backdrop-filter: blur(4px);
  }

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .onboarding-card {
      margin: auto;
      border-radius: 20px;
      background-color: white;
      border: 1px solid #707070;
      max-height: 80vh;
      overflow: scroll;
    }
  }
}
