.account-settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 1002;

  &__menu {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 1.5vh;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.15vw solid #d9e1e7;

      .icon-close {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 24px;
      }
    }

    .top-menu-wrapper {
      display: flex;
      margin: 20px;
      justify-content: space-between;

      .menu {
        display: flex;
        flex-direction: column;
        border: none;

        .menu-button {
          cursor: pointer;
          color: #9d9d9d;
          font: normal normal 600 17px/30px OpenSans;
          display: flex;
          align-items: center;
          height: 48px;

          &__content {
            margin: 5px 20px;
          }

          &__line {
            height: 100%;
            position: relative;
            display: flex;
            width: 3px;

            .line-background {
              height: 100%;
              width: 1px;
              position: absolute;
              margin: auto;
              right: 0;
              left: 0;
              top: 0;
              bottom: 0;
              background-color: #f3f3f3;
              z-index: 1;
            }

            .active-line {
              height: calc(100% - 15px);
              width: 3px;
              margin: auto;
              right: 0;
              left: 0;
              top: 0;
              bottom: 0;
              background-color: #fecb2e;
              z-index: 2;
            }
          }

          &__active {
            color: #0a287c;
            font-weight: bold;
          }
        }
      }
    }

    .bottom-menu-wrapper {
      min-width: 250px;
      margin: 20px;

      .banner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background: #fafbfc;
        border-radius: 10px;
        padding: 30px 20px;

        .upgrade-notification {
          background-color: #faf0cd;
          display: flex;
          border-radius: 10px;
          margin: 5px 0;

          &__content {
            margin: 10px 20px;
            color: #fecb2e;
          }

          &__image {
            margin: 10px;
            //color: #FECB2E;
          }
        }

        &__text-box {
          margin: 5px 0;
          display: flex;
          flex-direction: column;
        }

        &__title {
          color: #000000;
          font-weight: bold;
          font-size: 16px;
        }

        &__subtitle {
          color: #000000;
          font-weight: bold;
          font-size: 12px;
        }

        &__profiles {
          margin: 5px 0;
          display: flex;

          .avatar {
            height: 32px;
            width: 32px;
            box-sizing: border-box;
            border: 2px solid white;
            border-radius: 50%;
          }

          .avatar:not(:first-child) {
            margin-left: -10px;
          }
        }

        &__footer {
          width: 100%;
          margin: 5px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .contact-link {
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            text-decoration: underline;
          }

          .upgrade-button {
            background-color: #fecb2f;
            height: 40px;
            line-height: 40px;
            padding: 0 24px;
            cursor: pointer;
            border-radius: 20px;
            color: #000000;
            align-items: center;
            justify-content: center;
            font-size: 0.8em;
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

// rtl
html[dir='rtl'] {
  .account-settings {
    &__menu {
      .close-button {
        right: unset;
        left: 20px;
      }
    }
  }
}

// mobile
@media (max-width: 776px) {
  .account-settings {
    flex-direction: column-reverse;

    .bottom-menu-wrapper {
      display: none;
    }
  }
}
