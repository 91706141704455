.form-row {
  .input-wrapper {
    .static-row-data {
      background: #ccc;
      border-radius: 10px;
      border: 1px solid #c1c1c1;
      min-height: 16px;
      padding: 9px 20px;
    }
  }
}

.btn.btn-primary.btn-submit-dialog {
  &:disabled {
    background-color: #777;
    color: #eee;
    cursor: not-allowed;
  }
}
