.events-panels {
  height: 100%;
  padding: 25px;
  background-color: #f4f3fd95;

  .events-previous-dates {
    border-left: 4px solid var(--color-score-low);
  }

  .events-this-week {
    border-left: 4px solid var(--color-score-medium);
  }

  .events-future {
    border-left: 4px solid var(--color-score-high);
  }

  .mat-expansion-panel-header.mat-expanded {
    background-color: whitesmoke !important;
  }

  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: whitesmoke !important;
  }

  .mat-expanded .mat-expansion-panel-content {
    padding-top: 14px !important;
  }
}

.calendar-events-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;

  .calendar-events-wrapper {
    width: 95%;
    height: 100%;

    .calendar-events-row-one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30%;

      .header {
        text-align: left;
        font: normal normal medium 16px/32px 'OpenSans';
        letter-spacing: 0px;
        color: #06152b;
      }

      .buttons-wrapper {
        display: flex;

        .menu-wrapper {
          box-shadow: none;
          background: transparent;
          border: none;
        }

        .row-one-button {
          display: flex;
          align-items: center;
          background: #0a287c 0% 0% no-repeat padding-box;
          border-radius: 8px;
          color: #ffffff;
        }
      }
    }

    .calendar-events-row-two {
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .event-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;

        .type-button {
          height: 25px;
          width: 50px;
          border-radius: 20px;
          background: #d4ebff;
        }

        .summary {
          overflow: hidden;
          height: 20px;
          width: 105px;
        }

        .date {
        }

        .clock-icon {
          width: 50px;
          font-size: 14px;
          height: 15px;
        }

        .menu-wrapper-event {
          background: transparent;
          border: none;
        }
      }
    }
  }
}

// rtl
html[dir='rtl'] {
  .events-panels {
    .events-previous-dates {
      border-right: 4px solid var(--color-score-low);
      border-left: unset;
    }

    .events-this-week {
      border-right: 4px solid var(--color-score-medium);
      border-left: unset;
    }

    .events-future {
      border-right: 4px solid var(--color-score-high);
      border-left: unset;
    }
  }
}
