.financial-charts-container {
  padding: 40px;
}

.financial-charts-rows {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  justify-content: space-between;
}

.financial-charts-cols {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
}

.chart-card {
}

.chart-container {
  width: 50%;
  padding: 20px 40px;
  box-shadow: 0px 0px 75px rgba(138, 161, 203, 0.15);
  border: 1px solid #e9f1ff;
  border-radius: 15px;
}

.chart-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-header-value {
  font-weight: bold;
  font-size: larger;
  margin-right: 0.25rem;
}

.chart-header-year {
  color: #949292;
}

.financial-reports-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 65%;

  .financial-reports-main-header {
    text-align: initial;
    font: normal normal 600 18px/36px $font-site;
    letter-spacing: 0.07px;
    color: #171725;
  }

  .divider {
    height: 1px;
    background-color: #dddddd;
    max-width: 520px;
  }

  .financial-reports-list-wrapper {
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-inline: 10px;

    .financial-report-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 500px;

      .img-pdf-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 300px;

        .img {
          padding: 10px;
        }

        .pdf-name {
          text-align: left;
          font: normal normal normal 15px/20px OpenSans;
          letter-spacing: 0.06px;
          color: #171725;
          opacity: 1;
          max-width: 280px;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .clickable {
        cursor: pointer;
        padding: 10px 20px;
        text-decoration: underline;
        color: #4b9dcc;
      }
    }
  }
}
