.onboarding-welcome {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding: 30px 120px;

  .close-button {
    cursor: pointer;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 350px;
    margin: auto;
    gap: 10px;

    .title {
      color: #1c2d57;
      font-size: 24px;
      text-align: center;
    }

    .instructions {
      color: #636c84;
      font-size: 14px;
      text-align: center;
    }

    .image {
      width: 250px;
      height: auto;
    }

    .note {
      color: #cccdd6;
      font-size: 12px;
      text-align: center;
    }

    .lets-start-button {
      width: 100%;
      padding: 10px 0;
      background: #fecb2e 0 0 no-repeat padding-box;
      box-shadow: 0 1px 3px #00000026;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      color: #0a287c;
      display: flex;
      justify-content: center;
    }

    .skip-button {
      font-size: 14px;
      color: #a2a2a2;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// rtl
html[dir='rtl'] {
  .onboarding-welcome {
  }
}

// mobile
@media (max-width: 776px) {
  .onboarding-welcome {
    padding: 10% 10%;
  }
}
