// desktop
.signup-email-verification {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(219deg, #0a287c 0, #3a36db 100%);

  &__card {
    width: 400px;
    padding: 40px 60px;
    max-height: calc(80vh - 80px);
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #707070;
    overflow: scroll;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .title {
        text-align: center;
        font: 26px Arial;
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #0a287c;
      }

      .subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 8%;
        text-align: center;
        font: normal normal normal 2vh Heebo;
        font-size: 14px;
        color: #636c84;
      }

      .image {
        width: auto;
        height: 150px;
      }

      .expiration {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .text {
          font-size: 12px;
        }

        .timer {
          font-size: 12px;
          color: #fecb2e;
        }
      }

      .resend-mail {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .text {
          font-size: 12px;
        }

        .link {
          cursor: pointer;
          font-size: 12px;
          color: #fecb2e;
        }
      }

      .verify-button {
        width: 100%;
        padding: 10px 0;
        color: #0a287c;
        margin-top: 6%;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        border-radius: 10px;
        background-color: #fecb2f;
        box-shadow: 0 1px 3px #00000026;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .verify-loading {
        margin: 4vh;
      }
    }
  }
}

// mobile
@media (max-width: 776px) {
  .emailVerification {
  }
}

// for rtl styles
html[dir='rtl'] {
  // desktop
  .emailVerification {
  }
}
// mobile
@media (max-width: 776px) {
  .signup-email-verification {
    &__card {
      width: unset;
      margin: 10px;
    }
  }
}
