//desktop
.admin {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__mobile {
    display: none;
  }

  &__toolbar {
    height: $header-height;
    // height: 80px;
    width: 100vw;
    z-index: 2;
  }

  &__content {
    width: 100vw;
    height: calc(100vh - 96px);
    display: flex;
    flex-direction: row;

    &__sidenav {
      min-width: 264px;
    }

    &__main-content {
      width: 100%;
      height: calc(103vh - 96px);
      display: flex;
      flex-direction: column;
      overflow: scroll;
    }
  }
}

// mobile
@media (max-width: 776px) {
  .admin {
  }
}

// for rtl styles
html[dir='ltr'] {
  // mobile
  @media (max-width: 776px) {
    .admin {
    }
  }
}

.mat-drawer-backdrop {
  position: fixed !important;
  background: transparent;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}

mat-drawer-transition .mat-drawer-backdrop {
  transition-duration: 0ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: background-color, visibility;
}

// mobile
@media (max-width: 776px) {
  .admin {
    &__mobile {
      display: inline;
    }
    &__content {
      flex-direction: column;
      //padding-bottom: 15%;
      &__sidenav {
        display: none;
        &__open {
          display: inline;
        }
      }
    }
  }
}
