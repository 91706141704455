//Font
//$font-site: Heebo, Arial, sans-serif, OpenSans, Inter, Mulish, Poppins, ProximaNova, Helvetica Neue, Roboto, Nunito;
//$font-site: OpenSans;
$font-site: 'Open Sans';

$header-height: 90px;

// Colors
$success-color: #009944;
$error-color: #cf000f;
$warning-color: #f0541e;
$info-color: #63c0df;

:root {
  // Score
  --color-score-high: #00aa69;
  --color-score-medium: #fecb2f;
  --color-score-low: #ed4059;
  // Fund Type
  --color-foundation: #3abec1;
  --color-business: #9d6dbc;
  --color-rfp: #f4a671;
  // Workplans
  --color-workplan: #0a287c;
  --color-submission: #568bd8;
  --color-pending: #fecb2f;
  --color-approved: #00aa69;
  --color-closed: #eee;
  // Atlas
  --color-blue-atlas: #0a287c;
  --color-yellow-atlas: #fecb2f;
}

// Gaps
$gap-small: 5px;
$gap-medium: 10px;
$gap-large: 20px;
$gap-huge: 30px;

// Font Sizes
$font-small: 12px;
$font-medium: 14px;
$font-large: 16px;
$font-huge: 32px;
