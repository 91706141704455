.work-plan-container {
  width: 95%;
  //height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 2rem;
  padding-top: 32px;
  padding-bottom: 3%;

  .data-cards-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    //height: 100px;
    height: clamp(110px, 16%, 140px);
    gap: 2%;

    .card-container {
      width: 100%;
      //max-width: 250px;
      height: 6rem;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: #707070;
    opacity: 0.25;
  }

  .buttons-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .buttons-row-left {
      display: flex;
      align-items: center;
      width: 50%;
      gap: 80px;

      .search-wrapper {
        display: flex;
        border: 2px solid #d9e1e7;
        border-radius: 10px;
        flex-grow: 1;
        max-width: 300px;

        > input {
          height: 30px;
          padding-left: 8px;
          background-color: transparent;
          width: 100%;
          max-width: 300px;
          font-family: $font-site;
          font-size: $font-medium;
        }

        ::placeholder {
          color: #809fb8;
        }

        > img {
          padding-left: 8px;
        }
      }

      .select-menu {
        font-size: $font-medium;
        letter-spacing: 0.08px;
        color: #92929d;
        display: flex;
        align-items: center;

        .mat-select {
          display: inline-block;
          width: fit-content;
          outline: none;
        }

        .mat-select-value-text {
          margin-right: 0.5vh;
          font-weight: 600;
          padding-left: 0.5vh;
          color: #44444f;
        }
      }

      .sort-section {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .sort-icon {
          height: 24px;
          width: fit-content;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .sort-selected-text {
          font-size: $font-medium;
          cursor: pointer;
          color: black;
          padding: 5px;
        }

        .icon-wrapper {
          height: 24px;
          width: 24px;
          border: 2px solid #d9e1e7;
          border-radius: 10px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .buttons-row-right {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      flex-grow: 1;

      .xml-download-btn {
        display: flex;
        gap: 5px;
        height: 35px;
        cursor: pointer;
        color: #0a287c;
        font-size: 14px;
        font-weight: bold;
        border-radius: 10px;
        align-items: center;
        background-color: white;
        justify-content: center;
        border: 1px solid #0a287c;
        width: clamp(150px, 50%, 160px);
        font-family: $font-site;

        > img {
          height: 18px;
          width: 18px;
        }
      }

      .add-work-plan-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fecb2e;
        border-radius: 10px;
        cursor: pointer;
        color: #0a287c;
        gap: 5px;
        font-size: 14px;
        font-weight: bold;
        width: clamp(225px, 50%, 190px);
        font-family: $font-site;

        > img {
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  .table-row {
    width: 100%;
  }
}

.sort-popover {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 13px #16347729;
  border: 2px solid #d9e1e7;
  border-radius: 10px;

  .popover-text {
    font-size: $font-medium;
    cursor: pointer;
    padding: 10px;
    color: black;
    display: inline-block;
    flex-direction: row;
    justify-content: flex-start;
  }
}

// mobile
@media (max-width: 776px) {
  .work-plan-container {
    .table-row {
      margin-bottom: 15%;
    }
    .hidden-phone {
      display: none;
    }
    .buttons-row {
      flex-direction: column;
      gap: 10px;
      .buttons-row-left {
        gap: 10px;
        flex-direction: column;
        width: 100%;
      }
    }
    .data-card {
      max-width: unset;
      .card-title {
        text-align: center;
      }
      .card-data {
        text-align: center;
      }
    }
    .data-cards-row {
      height: 100%;
      flex-direction: column;
      align-items: center;
      .card-container {
        max-width: unset;
      }
    }
  }
}
