.funds-history {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 65%;

  &__header {
    text-align: initial;
    font: normal normal 600 18px/36px $font-site;
    letter-spacing: 0.07px;
    color: #171725;
  }

  .divider {
    height: 1px;
    background-color: #dddddd;
    width: 100%;
  }

  &__content {
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-inline: 10px;

    .history-row {
      display: flex;
      gap: 2em;
      align-items: center;

      .date {
        font: normal normal medium 15px/36px $font-site;
        letter-spacing: 0.06px;
        color: #000000;
        opacity: 1;
      }

      .description {
        width: 40%;
        text-align: center;
        font: normal normal medium 14px/36px $font-site;
        letter-spacing: 0.06px;
        color: #809fb8;
        opacity: 1;
        text-overflow: ellipsis;
        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
      }

      .status-container {
        display: flex;
        color: #22d598;
        font-size: 13px;
        line-height: 32px;
        align-items: center;
        border-radius: 16px;
        justify-content: center;
        width: clamp(80px, 80%, 120px);
        background-color: rgba(34, 213, 152, 0.15);
      }

      .clickable {
        cursor: pointer;
        padding: 10px 20px;
        text-decoration: underline;
        color: #4b9dcc;
      }
    }
  }
}
@media (max-width: 776px) {
  .funds-history {
    width: 100%;
  }
}
